import React from 'react'
import styled from '@emotion/styled'
import { Script } from 'gatsby'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 16px;

  .qtool .qmod-quotehead .qmod-reghalt .qmod-tooltip,
  .qtool .qmod-sec-filings .qmod-mkt-mid,
  .qtool .qmod-sec-filings h1.qmod-heading,
  .qtool .qmod-sec-filings h2.qmod-heading {
    display: none;
  }
`

const QMSecFilings = () => (
  <Wrapper>
    <Script
      src="//qmod.quotemedia.com/js/qmodLoader.js"
      type="application/javascript"
      data-qmod-wmid="101772"
      id="qmod"
      data-qmod-env="app"
      data-qmod-version=""
    />
    <div className="qm-wrap" />
    <div
      data-qmod-tool="filings"
      data-qmod-params='{"symbol":"AEYE", "resultsPerPage":"12"}'
      className="qtool"
      style={{ width: '100%' }}
    />
  </Wrapper>
)

export default QMSecFilings
