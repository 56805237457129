/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import EarningsReportTables from './earning-reports/EarningsReportTables'

const PostEarningsTable = ({
  slice: {
    primary: { table_title, is_table_header, bs_table_header, gaap_table_header },
  },
}) => (
  <EarningsReportTables
    prismicTableKey={table_title.text}
    isTableHeader={is_table_header}
    bsTableHeader={bs_table_header}
    gaapTableHeader={gaap_table_header}
  />
)

export default PostEarningsTable

export const query = graphql`
  fragment PostEarningsTable on PrismicPostDataBodyPostEarningsTable {
    ...SliceBase
    primary {
      table_title {
        ...TextContent
      }
      is_table_header
      bs_table_header
      gaap_table_header
    }
  }
`
