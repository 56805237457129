/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import AuthorSection from './AuthorSection'
import SharePost from './SharePost'
import Tag from '../common/Tag'

const MetaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

const MetaSection = ({ author, postData }) => (
  <MetaWrapper>
    <AuthorSection authorData={author} publishDate={postData.publish_date} inPost />
    {postData?.categories && (
      <CategoryWrapper>
        {postData.categories.map(
          (category, i) =>
            category?.category?.document?.data?.name &&
            i <= 1 && (
              <Tag
                key={category.category.document?.data?.name}
                text={category.category.document?.data?.name}
                color="gray"
                fontSize="smallCapsSans"
                size="small"
                noMargin
                fontFamily="smallBody"
                isButton
                to={`/blog/posts/${category.category.document?.data?.name}/`}
              />
            )
        )}
        <SharePost dropDown />
      </CategoryWrapper>
    )}
  </MetaWrapper>
)

export default MetaSection
