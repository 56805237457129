/* eslint-disable camelcase */
import React from 'react'
import idx from 'idx'
import { renderPageSlices } from '../utils/renderPageSlices'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const PageContent = ({
  body,
  banner,
  title,
  description,
  show_header,
  show_footer,
  micro_site_header_footer,
  hide_header_footer_nav,
  noindex,
  nofollow,
  canonical_url,
  show_cta,
  show_secondary_cta,
  has_table_of_contents,
  table_of_contents_heading_text,
  table_of_contents_cta_text,
  table_of_contents_cta_link,
  table_of_contents_links,
  hide_table_of_contents_cta,
  show_scanner_form,
  scanner_form_label_text,
  props,
}) => (
  <Layout
    customSEO
    showHeader={show_header}
    showFooter={show_footer}
    microSiteHeaderFooter={micro_site_header_footer}
    hideHeaderFooterNav={hide_header_footer_nav}
    showCTA={show_cta}
    showSecondaryCTA={show_secondary_cta}
    tableOfContentsData={{
      hasTableOfContents: has_table_of_contents,
      ctaData: {
        text: table_of_contents_cta_text,
        link: table_of_contents_cta_link,
      },
      items: table_of_contents_links,
      hideTableOfContentsCta: hide_table_of_contents_cta,
      showScannerForm: show_scanner_form,
      scannerFormLabelText: scanner_form_label_text,
      tableOfContentsHeadingText: table_of_contents_heading_text,
    }}
  >
    <SEO
      bannerUrl={banner && banner.url}
      title={idx(title, _ => _.text)}
      pathname={props.location.pathname}
      desc={idx(description, _ => _.richText[0].text)}
      node={props.data.prismicPage}
      noIndex={noindex}
      noFollow={nofollow}
      canonical={canonical_url}
    />
    {renderPageSlices(body)}
  </Layout>
)

export default PageContent
