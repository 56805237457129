import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

const ModalPortal = ({ active, children }) => {
  const elRef = useRef()

  useEffect(() => {
    if (window) {
      elRef.current = window.document.createElement('div')
    }
  }, [])

  useEffect(() => {
    const modalRoot = window.document.getElementById('modal-root')

    if (active && elRef.current && modalRoot !== null) {
      const { current } = elRef
      modalRoot.appendChild(current)

      return () => {
        modalRoot.removeChild(current)
      }
    }

    return () => {}
  }, [active])

  if (elRef.current && active) {
    return createPortal(children, elRef.current)
  }

  return null
}

export default ModalPortal
