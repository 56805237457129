import React from 'react'
import styled from '@emotion/styled'
import OnboardingForm from './OnboardingForm'

import { useUserLang } from '../../../context/UserLangContext'
import generateAriaLabelTranslations from '../../../data/generateAriaLabelTranslations'

const DialogWrapper = styled.div`
  max-width: 545px;
`

export const ValidatedInputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const OnboardingModal = ({ open }) => {
  const { userLang } = useUserLang()
  const languageData = generateAriaLabelTranslations({ userLang })

  return (
    <DialogWrapper open={open} aria-label={languageData?.signUpForm}>
      <OnboardingForm formName="Onboarding Modal" />
    </DialogWrapper>
  )
}

export default OnboardingModal
