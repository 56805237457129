/* eslint-disable camelcase */
import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'

import { theme } from '../styles'
import TextLink from './common/TextLink'
import Text from './common/Text'
import Space from './common/Space'
import Button from './common/Button'
import chevron from '../../static/icons/arrow-icons/dropdown-chevron-icon.svg'

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  margin: 16px 0px;
`

const OutClosedWrapper = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  background-color: ${p => (p.accordionColor ? theme.color[p.accordionColor] : theme.color.white)};
  padding: 16px 32px;
  border: 3px solid transparent;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  position: relative;

  &:hover {
    background-color: ${theme.color.green100};
  }

  @media (max-width: 1050px) {
    padding: 16px 24px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;

  h3,
  h2 {
    font-size: ${theme.fonts.size.bodyBold}!important;
    font-family: ${theme.fonts.family.bodyBold}!important;
    line-height: ${theme.fonts.lineHeight.bodyBold}!important;
    letter-spacing: ${theme.fonts.letterSpacing.bodyBold}!important;
  }
`

const expandedCss = `
  height: auto;
  padding: 8px 56px 24px 56px;
  opacity: 1;
  p {
    display: block;
  }
  @media (max-width:800px) {
    padding: 8px 16px 16px 16px;
  }
`

const collapsedCss = `
  height: 0px;
  padding: 0px;
  opacity: 0;
  p {
    display: none;
  }
`

const ExpandedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.color.white};
  ${p => (p.isOpen ? expandedCss : collapsedCss)};
  transition: padding-top, padding-bottom 0.4s ease;
`

const Arrow = styled.img`
  transform: ${p => (p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.4s ease;
  height: 18px !important;
  width: auto !important;
`

const modifyColors = bgColor => {
  switch (bgColor) {
    case 'white':
      return {
        backgroundColor: 'white',
        accordionColor: 'offWhite',
      }
    case 'offWhite':
      return {
        backgroundColor: 'offWhite',
        accordionColor: 'white',
      }
    default:
      return {
        backgroundColor: 'offWhite',
        accordionColor: 'white',
      }
  }
}

const Accordion = ({ title, text, index, readMoreText, readMoreLink, accordionColor }) => {
  const itemTitle = title.richText?.[0]?.text && <PrismicRichText field={title.richText} />
  const itemText = text.richText?.[0]?.text && <PrismicRichText field={text.richText} />
  const [isOpen, setOpen] = useState(false)
  const linkProps = {
    'aria-hidden': !isOpen,
    tabIndex: isOpen ? '0' : '-1',
    style: { display: isOpen ? 'inherit' : 'none' },
  }

  return (
    <AccordionWrapper key={index} onClick={() => setOpen(!isOpen)}>
      <OutClosedWrapper aria-expanded={isOpen} isOpen={isOpen} accordionColor={accordionColor}>
        {itemTitle && (
          <TextWrapper>
            <Text richText font="heading3">
              {itemTitle}
            </Text>
          </TextWrapper>
        )}
        <Arrow alt="" role="presentation" src={chevron} isOpen={isOpen} />
      </OutClosedWrapper>
      <ExpandedWrapper aria-hidden={!isOpen} isOpen={isOpen}>
        {itemText && <Text richText>{itemText}</Text>}
        {readMoreLink && (
          <TextLink to={readMoreLink} font="bodyBold" text={readMoreText} richText={false} {...linkProps} />
        )}
      </ExpandedWrapper>
    </AccordionWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0px;
  position: relative;
  transition: all 300ms ease;
`

const BG = styled.div`
  display: flex;
  justify-self: center;
  position: absolute;
  width: 500vw;
  margin-left: -100%;
  height: 100%;
  z-index: -1;
  background-color: ${p => theme.color[p.backgroundColor]};
`

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;

  h2 {
    font-size: ${theme.fonts.size.heading3}!important;
    font-family: ${theme.fonts.family.heading3}!important;
    line-height: ${theme.fonts.lineHeight.heading3}!important;
    letter-spacing: ${theme.fonts.letterSpacing.heading3}!important;
  }

  @media (max-width: 1050px) {
    padding-bottom: 32px;
  }
`

const AccordionContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  z-index: 1;
`

const FaqType = ({ slice: { primary } }) => {
  const { faq_type, anchorId } = primary

  const faqQuery = graphql`
    query faqQuery {
      allPrismicFaq {
        edges {
          node {
            data {
              faq_type
              faq_color
              show_view_all_faq_button
              faq_group {
                text {
                  ...TextContent
                }
                title {
                  ...TextContent
                }
                read_more_text {
                  ...TextContent
                }
                read_more_link {
                  document {
                    ... on PrismicPage {
                      ...Page
                    }
                    ... on PrismicPost {
                      ...Post
                    }
                  }
                  url
                  type
                  target
                }
              }
            }
          }
        }
      }
    }
  `

  return (
    <Wrapper id={anchorId || null}>
      <TitleWrapper>
        <Text heading2 center>
          Frequently asked questions
        </Text>
      </TitleWrapper>
      <StaticQuery
        query={`${faqQuery}`}
        render={data => {
          const faqToRender = data.allPrismicFaq.edges.find(edge => edge.node.data.faq_type === faq_type)
          const modifiedBgColor = modifyColors('offWhite')

          if (!faqToRender) {
            return null
          }

          const { show_view_all_faq_button } = faqToRender.node.data

          return (
            <>
              <AccordionContainer>
                {faqToRender.node.data.faq_group.map((item, i) => (
                  <Accordion
                    key={item.text?.richText?.[0]?.text}
                    index={i + 1}
                    text={item.text}
                    title={item.title}
                    readMoreText={item.read_more_text?.richText?.[0]?.text}
                    readMoreLink={item.read_more_link && item.read_more_link.url}
                    accordionColor={modifiedBgColor.accordionColor}
                  />
                ))}
              </AccordionContainer>
              {(show_view_all_faq_button || show_view_all_faq_button === null) && (
                <>
                  <Space height={32} />
                  <Button text="View all FAQ" to="https://audioeye.zendesk.com/hc/en-us" />
                </>
              )}
              <BG className="bg" backgroundColor={modifiedBgColor.backgroundColor} />
            </>
          )
        }}
      />
    </Wrapper>
  )
}

export default FaqType

export const query = graphql`
  fragment FaqType on PrismicPageDataBodyFaqSection {
    ...SliceBase
    primary {
      anchor_id
      faq_type
    }
  }

  fragment PostFaqType on PrismicPostDataBodyFaqSection {
    ...SliceBase
    primary {
      anchor_id
      faq_type
    }
  }
`
