import styled from '@emotion/styled'
import Sticky from 'react-stickynode'
import React, { useEffect, useState } from 'react'
import Button from './common/Button'
import TextLink from './common/TextLink'
import Scanner from './form/Scanner'
import Text from './common/Text'

const MainWrapper = styled.main`
  margin-top: 40px;
  grid-column: 1 / 2;
  display: grid;
  gap: 16px;
  height: fit-content;
  width: 100%;
  padding: 24px;
  background-color: ${p => p.theme.color.white};
  border-radius: 16px;
  box-shadow: 0px 0px 14px 3px ${p => p.theme.color.dropShadow};

  &.margin-removed {
    @media (max-width: 1800px) {
      margin-top: 0;
      transition: margin-top 0.5s ease-out;
    }
  }
`

const CtaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 48px 24px 32px;
  border-radius: 8px;
  background-color: ${p => p.theme.color.purple100};

  .button * {
    white-space: normal;
    text-align: left;
    line-height: 1.45em;
  }
`

const TOCList = styled.ul`
  display: grid;
  grid-column-gap: 64px;
  list-style: none;
  gap: 16px;

  .link-list-item > a:hover > span,
  .link-list-item > a:focus > span {
    color: ${p => p.theme.color.offBlack};
    font-family: ${p => p.theme.fonts.family.smallBodyBold};
    font-size: ${p => p.theme.fonts.size.smallBodyBold};
    font-weight: ${p => p.theme.fonts.weight.smallBodyBold};
    border-left: 4px solid ${p => p.theme.color.purple500};
    padding-left: 16px;
  }
`

const TOCTextLink = styled(TextLink)`
  display: block;
  transition: all 50ms ease-in-out;
  color: ${p => p.theme.color.gray500};
  font-family: ${p => p.theme.fonts.family.smallBodyMedium};
  font-size: ${p => p.theme.fonts.size.smallBodyMedium};
  font-weight: ${p => p.theme.fonts.weight.smallBodyMedium};
  line-height: ${p => p.theme.fonts.lineHeight.smallBodyMedium};
  letter-spacing: ${p => p.theme.fonts.letterSpacing.smallBodyMedium};
`

const ScannerWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.color.gray200};
  padding-top: 24px;
`

const HeadingWrapper = styled.div`
  border-bottom: 1px solid ${p => p.theme.color.gray200};
  padding-bottom: 12px;
`

const TOCLinks = ({ items }) => (
  <TOCList>
    {items.map(link => (
      <li key={link.table_of_content_link_text.text} className="link-list-item">
        <TOCTextLink
          to={`#${link.table_of_content_anchor_link.text}`}
          text={link.table_of_content_link_text.text}
          anchorClassName="unstyled-link"
        />
      </li>
    ))}
  </TOCList>
)

const Cta = ({ text, link }) => {
  if (!text.text || !link.url) return null
  return (
    <CtaWrapper>
      <Button buttonType="link CTA" text={text.text} to={link.url} />
    </CtaWrapper>
  )
}

export default function TableOfContents({
  ctaData,
  items,
  hideTableOfContentsCta,
  scannerFormLabelText,
  tableOfContentsHeadingText,
}) {
  const [isNearFooter, setIsNearFooter] = useState(false)

  // necessary to avoid a clipping issue with library + large footer on smaller screens
  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.pageYOffset
      const bodyHeight = document.body.clientHeight
      const lowerEndOfViewPort = bodyHeight * 0.8

      if (scrollPosition > lowerEndOfViewPort) {
        setIsNearFooter(true)
      } else {
        setIsNearFooter(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!items.length) {
    return null
  }

  return (
    <Sticky enabled innerZ={1} bottomBoundary="#content" className="sticky">
      <MainWrapper className={isNearFooter ? 'margin-removed' : ''}>
        {tableOfContentsHeadingText && (
          <HeadingWrapper>
            <Text bodyBold>{tableOfContentsHeadingText}</Text>
          </HeadingWrapper>
        )}
        <TOCLinks items={items} />
        {!hideTableOfContentsCta && <Cta {...ctaData} />}
        <ScannerWrapper>
          <Scanner mobileForm labelText={scannerFormLabelText} />
        </ScannerWrapper>
      </MainWrapper>
    </Sticky>
  )
}
