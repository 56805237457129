import React from 'react'

export const PersonIcon = ({ fill }) => (
  <svg
    width="48"
    height="54"
    viewBox="0 0 48 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="example icon"
    role="img"
  >
    <path
      d="M23.8941 34.76C23.7552 35.0973 23.661 35.3006 23.5866 35.509C21.5431 40.9303 19.5045 46.3516 17.456 51.768C16.9054 53.2213 15.6952 54.0198 14.1625 53.9802C12.7588 53.9454 11.6031 53.0973 11.1518 51.7829C10.874 50.9744 10.9831 50.1907 11.2411 49.3921C12.5208 45.4786 13.8897 41.5949 15.0206 37.6368C16.5681 32.2403 17.208 26.695 17.2179 21.0802C17.2179 19.9047 16.9451 19.5674 15.7944 19.3045C11.4147 18.3174 7.03492 17.3304 2.6552 16.3483C1.62351 16.1201 0.790227 15.6241 0.304143 14.6569C-0.672986 12.7126 0.824961 10.4111 3.07682 10.5302C4.55491 10.6095 6.02307 10.9419 7.49621 11.1502C12.0793 11.8049 16.6177 12.7374 21.2653 12.9408C25.5161 13.1292 29.7271 12.8168 33.9233 12.1521C37.321 11.6115 40.7236 11.0758 44.1262 10.55C45.9167 10.2723 47.4742 11.299 47.7519 12.9209C48.0297 14.523 46.9732 15.9267 45.1777 16.3334C40.798 17.3254 36.4182 18.3075 32.0385 19.2946C30.8382 19.5624 30.5852 19.8799 30.5803 21.11C30.5307 29.4577 32.3163 37.4583 35.1088 45.2703C35.6098 46.674 36.0958 48.0777 36.5819 49.4864C37.2317 51.3613 36.4579 53.127 34.712 53.7768C32.9164 54.4414 31.0267 53.5784 30.3372 51.7531C28.2887 46.3367 26.2501 40.9154 24.2066 35.4941C24.1223 35.2907 24.033 35.0923 23.8941 34.76Z"
      fill={fill || 'black'}
    />
    <path
      d="M23.8835 2.89073e-05C27.0827 -0.00989118 29.657 2.53461 29.6818 5.7388C29.7115 8.95291 27.1125 11.5817 23.9033 11.5867C20.6991 11.5917 18.0752 8.96283 18.0951 5.75864C18.1149 2.56437 20.6793 0.00994899 23.8835 2.89073e-05Z"
      fill={fill || 'black'}
    />
  </svg>
)
