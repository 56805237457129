import React from 'react'
import { FocusedBorder, InputContainer, CheckboxInput, CheckboxLabel, CheckboxControl } from '../common'
import Text from '../../common/Text'
import Space from '../../common/Space'
import { trackEvent } from '../../../utils/eventTrack'
import generateAgencyFormFieldsTranslations from '../../../data/generateAgencyFormFieldsTranslations'
import { useUserLang } from '../../../context/UserLangContext'

export const AgencyFormFields = ({ formData, updateFormData }) => {
  const { userLang } = useUserLang()
  const languageData = generateAgencyFormFieldsTranslations({ userLang })

  return (
    <>
      <Space height={4} />
      <InputContainer style={{ flexDirection: 'column' }}>
        <Text id="agency-or-freelancer" smallBodyMedium>
          {languageData?.label}
        </Text>
        <CheckboxInput
          name="agency_or_freelancer__c"
          type="checkbox"
          value="Yes"
          aria-labelledby="agency-or-freelancer"
          aria-describedby="agency-label"
          id="yes"
          onChange={e => {
            // eslint-disable-next-line no-unused-expressions
            formData.freelancerAgency !== e.target.value
              ? updateFormData({ freelancerAgency: e.target.value })
              : updateFormData({ freelancerAgency: '' })
            if (e.target.value === 'Yes' && formData.freelancerAgency !== e.target.value) {
              trackEvent(`Agency Input Value Changed`, {
                Value: true,
                'Form Name': formData.form_name,
              })
            }
          }}
        />
        <CheckboxLabel htmlFor="yes">
          <CheckboxControl selected={formData.freelancerAgency === 'Yes'}>
            <FocusedBorder className="focused-border reduced-radius" />
          </CheckboxControl>
          <Text smallBody id="agency-label">
            {languageData?.checkboxLabel}
          </Text>
        </CheckboxLabel>
      </InputContainer>
    </>
  )
}
