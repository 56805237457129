import React from 'react'
import styled from '@emotion/styled'
import { FocusedBorder, InputContainer, CheckboxInput, CheckboxLabel, CheckboxControl } from '../common'
import Text from '../../common/Text'
import Space from '../../common/Space'
import { useUserLang } from '../../../context/UserLangContext'
import generateConsentFormFieldsTranslations from '../../../data/generateConsentFormFieldsTranslations'

const LongInputLabel = styled(Text)`
  flex-basis: fit-content;
`

export const RequiredSpan = styled.span`
  display: inline-block;
  font-size: ${props => props.theme.fonts.size.smallBodyMedium};
  font-weight: ${props => props.theme.fonts.weight.smallBodyMedium};
  font-family: ${props => props.theme.fonts.family.smallBodyMedium};
  color: ${props => props.theme.color.red500};
`

export const ConsentFormFields = ({ formData, updateFormData }) => {
  const { userLang } = useUserLang()
  const languageData = generateConsentFormFieldsTranslations({ userLang })

  return (
    <>
      <Space height={4} />
      <InputContainer style={{ flexDirection: 'column' }}>
        <CheckboxInput
          name="legal_consent_subscription-input"
          type="checkbox"
          value="Yes"
          aria-labelledby="legal-consent-subscription"
          aria-describedby="legal-consent-subscription-label"
          id="legal-consent-subscription-yes"
          required
          onChange={e => {
            // eslint-disable-next-line no-unused-expressions
            formData.legalConsentSubscription !== e.target.value
              ? updateFormData({ legalConsentSubscription: e.target.value })
              : updateFormData({ legalConsentSubscription: '' })
          }}
        />
        <CheckboxLabel htmlFor="legal-consent-subscription-yes">
          <CheckboxControl selected={formData.legalConsentSubscription === 'Yes'}>
            <FocusedBorder className="focused-border reduced-radius" />
          </CheckboxControl>
          <LongInputLabel smallBody id="legal-consent-subscription-label">
            {languageData?.checkboxLabelOne} <RequiredSpan>*</RequiredSpan>
          </LongInputLabel>
        </CheckboxLabel>
      </InputContainer>
      <InputContainer style={{ flexDirection: 'column' }}>
        <CheckboxInput
          name="legal-consent-processing-input"
          type="checkbox"
          value="Yes"
          aria-labelledby="legal-consent-processing"
          aria-describedby="legal-consent-processing-label"
          id="legal-consent-processing-yes"
          required
          onChange={e => {
            // eslint-disable-next-line no-unused-expressions
            formData.legalConsentProcessing !== e.target.value
              ? updateFormData({ legalConsentProcessing: e.target.value })
              : updateFormData({ legalConsentProcessing: '' })
          }}
        />
        <CheckboxLabel htmlFor="legal-consent-processing-yes">
          <CheckboxControl selected={formData.legalConsentProcessing === 'Yes'}>
            <FocusedBorder className="focused-border reduced-radius" />
          </CheckboxControl>
          <LongInputLabel smallBody id="legal-consent-processing-label">
            {languageData?.checkboxLabelTwo} <RequiredSpan>*</RequiredSpan>
          </LongInputLabel>
        </CheckboxLabel>
      </InputContainer>
      <Space height={8} />
      <Text tinyBody>{languageData?.disclaimerText}</Text>
    </>
  )
}
