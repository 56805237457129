import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import '@fontsource/epilogue/variable.css'
import '@fontsource/inter/variable.css'
import '@fontsource/jetbrains-mono/variable.css'
import interFont from '@fontsource/inter/files/inter-latin-variable-wghtOnly-normal.woff2'
import epilogueFont from '@fontsource/epilogue/files/epilogue-latin-variable-wghtOnly-normal.woff2'
import jetBrainsFont from '@fontsource/jetbrains-mono/files/jetbrains-mono-latin-variable-wghtOnly-normal.woff2'
import '@reach/skip-nav/styles.css'

import Footer from './Footer'
import Header from './header/Header'
import SEO from './SEO'
import SkipNavLink from './SkipNavLink'
import { reset, theme } from '../styles'
import consentPreferencesStyles from '../styles/consentPreferencesStyles'
import highlander from '../utils/highlander'
import OnboardingModal from './form/Onboarding/OnboardingModal'
import ModalAnimated from './common/modal/ModalAnimated'
import TableOfContents from './TableOfContents'
import ExpertAuditForm from './dai/ExpertAuditForm'
import ScheduleDemoForm from './common/ScheduleDemoForm'
import { UserLangProvider } from '../context/UserLangContext'

const globalStyle = css`
  ${reset}
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.color.offBlack};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.25rem;
    font-family: ${theme.fonts.family.body};
    font-weight: ${theme.fonts.weight.body};
    line-height: ${theme.fonts.lineHeight.body};
    /* Fix overflow issue on safari */
    overflow-x: hidden;

    &.no-scroll {
      height: 100vh;
      width: 100%;
      position: fixed;
      overflow-y: scroll;
    }
  }
  body {
    color: ${theme.color.offBlack};
    background-color: ${theme.color.white};
    padding: 0px 150px;
    overflow-x: hidden;

    @media (max-width: 1050px) {
      padding: 0px 24px;
    }
  }
  ::selection {
    color: ${theme.color.white};
    background-color: ${theme.color.offBlack};
  }
  a,
  input,
  button,
  textarea {
    border: none;
  }
  button {
    cursor: pointer;
    position: relative;
  }
  p a,
  a span p,
  p a strong,
  strong a,
  a strong,
  li .header-text-link a,
  li:not(.link-list-item) a:not(.button),
  .menu-button,
  a:not(.unstyled-link).text-link span,
  .dark-bg-link {
    text-decoration: underline;
    text-underline-offset: 6px;
    color: ${theme.color.purple500};
    background-image: linear-gradient(to bottom, ${theme.color.purple500} 0%, ${theme.color.purple500} 100%);
    background-position: 0% 110%;
    background-repeat: repeat-x;
    background-size: 0px 0px;
    transition: color 150ms, background-size 150ms;
    padding: 0px 3px;
    margin: 0px -3px;

    &.dark-bg-link {
      background-image: linear-gradient(to bottom, ${theme.color.white} 0%, ${theme.color.white} 100%);
      text-decoration: none;
      color: ${theme.color.white};
    }

    &:hover {
      text-decoration: none;
      color: ${theme.color.white}!important;
      background-size: 2px 100%;
      z-index: 1;

      &.dark-bg-link {
        color: ${theme.color.purple500}!important;
      }
    }
    @media (max-width: 800px) {
      &:hover {
        text-decoration: none;
        background-size: 0px 0px;
        color: ${theme.color.purple500}!important;

        &.dark-bg-link {
          text-decoration: none;
          color: ${theme.color.white}!important;
        }
      }
    }
  }
  .dark-bg-rich-text {
    p a,
    a {
      background-image: linear-gradient(to bottom, ${theme.color.white} 0%, ${theme.color.white} 100%);
      color: ${theme.color.white};
      &:hover {
        text-decoration: none;
        color: ${theme.color.purple500}!important;
      }
    }
  }

  #modal-root {
    position: relative;
    z-index: 3;
  }

  a:focus,
  input:focus,
  select:focus,
  button:focus,
  textarea:focus,
  .focus-outline:focus {
    border-radius: 8px;
    outline-offset: 4px;
    outline: ${theme.focusOutline};

    &.dark-bg-focus,
    &.dark-bg-link {
      outline: ${theme.darkBgFocusOutline}!important;
    }
  }
  a:focus {
    border-radius: 4px;
  }
  a,
  .external-link-wrapper {
    text-decoration: none;
    position: relative;
    &:focus,
    &:hover,
    &:active {
      .external-link {
        top: 1.5em;
        left: 1em;
        width: auto;
        height: auto;
        min-width: 150px;
        color: ${theme.color.offBlack};
        clip: unset;
        z-index: 9999;
        border: 1px solid ${theme.color.gray400};
        font-size: 14px !important;
        text-decoration: none;
        background-image: none;
        background-color: ${theme.color.white};
        overflow: visible;

        &.over-graphic {
          top: 6.5em;
        }

        &.short-over-graphic {
          top: 4em;
        }

        &.external-button {
          top: 4em;
          left: 0em;
        }
        &:hover {
          background-color: ${theme.color.white};
        }
      }
    }
  }
  input {
    position: relative;
  }
  a.no-focus {
    border: none !important;
    outline: none;
  }
  li {
    margin-bottom: 8px;
  }
  .smooth-scroll {
    scroll-behavior: smooth;
  }
  .overflow-hidden {
    overflow: hidden !important;
    body {
      padding-right: 165px !important;
      @media (max-width: 800px) {
        padding-right: 10px !important;
      }
    }
  }
  .sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  div[data-consent-manager-dialog] {
    ${consentPreferencesStyles}
  }

  /* Global font defaults */
  h1 {
    font-size: ${theme.fonts.size.heading1};
    font-family: ${theme.fonts.family.heading1};
    font-weight: ${theme.fonts.weight.heading1};
    line-height: ${theme.fonts.lineHeight.heading1};
    letter-spacing: ${theme.fonts.letterSpacing.heading1};
  }
  h2 {
    font-size: ${theme.fonts.size.heading2};
    font-family: ${theme.fonts.family.heading2};
    font-weight: ${theme.fonts.weight.heading2};
    line-height: ${theme.fonts.lineHeight.heading2};
    letter-spacing: ${theme.fonts.letterSpacing.heading2};
  }
  h3 {
    font-size: ${theme.fonts.size.heading3};
    font-family: ${theme.fonts.family.heading3};
    font-weight: ${theme.fonts.weight.heading3};
    line-height: ${theme.fonts.lineHeight.heading3};
    letter-spacing: ${theme.fonts.letterSpacing.heading3};
  }
  h3.asH4 {
    font-size: ${theme.fonts.size.heading4};
    font-family: ${theme.fonts.family.heading4};
    font-weight: ${theme.fonts.weight.heading4};
    line-height: ${theme.fonts.lineHeight.heading4};
    letter-spacing: ${theme.fonts.letterSpacing.heading4};
  }
  h4 {
    font-size: ${theme.fonts.size.heading4};
    font-family: ${theme.fonts.family.heading4};
    font-weight: ${theme.fonts.weight.heading4};
    line-height: ${theme.fonts.lineHeight.heading4};
    letter-spacing: ${theme.fonts.letterSpacing.heading4};
  }
  p,
  li,
  span {
    font-size: ${theme.fonts.size.body};
    font-family: ${theme.fonts.family.body};
    font-weight: ${theme.fonts.weight.body};
    line-height: ${theme.fonts.lineHeight.body};
    letter-spacing: ${theme.fonts.letterSpacing.body};
  }
  button {
    font-size: ${theme.fonts.size.smallBody};
    font-family: ${theme.fonts.family.bodyBold};
    font-weight: ${theme.fonts.weight.bodyBold};
    letter-spacing: ${theme.fonts.letterSpacing.body};
  }
  pre {
    font-family: ${theme.fonts.family.smallCaps};
    font-weight: ${theme.fonts.weight.smallCaps};
    font-size: ${theme.fonts.size.smallBody};
  }
  strong {
    font-weight: 700;
  }

  @media (max-width: 800px) {
    html {
      font-size: 1.125rem;
    }
  }

  .cky-consent-container {
    z-index: 9999999999 !important;
  }

  #hubspot-messages-iframe-container {
    z-index: 999999999 !important;
  }
`

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1160px;

  ${p =>
    p.hasTableOfContents &&
    `
  max-width: 1500px;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 2fr;
  gap: 64px;

  @media (max-width: 1300px) {
    gap: 48px;
    max-width: unset;
  }

  @media (max-width: 1400px) {
    display: inherit;
    grid-template-columns: unset;
    max-width: 1160px;

    .sticky {
      display: none;
    }
  }
`}
`

const ContentWrapper = styled.div`
  grid-column: 2 / -1;
`

const Layout = ({
  children,
  customSEO = false,
  homePage = false,
  microSiteHeaderFooter,
  showHeader,
  showFooter,
  hideHeaderFooterNav = false,
  showCTA,
  showSecondaryCTA,
  preloadImg,
  tableOfContentsData,
  removeLogoLink,
}) => {
  const windowGlobal = typeof window !== `undefined` && window
  const modalShouldOpen = windowGlobal && window.location.hash.includes('get-started-free')
  const [active, setActive] = useState(modalShouldOpen)
  const expertAuditShouldOpen =
    windowGlobal && window.location.hash.includes('expert-audit') && window.location.pathname === '/scan/results/'
  const [expertAuditOpen, setExpertAuditOpen] = useState(expertAuditShouldOpen)
  const scheduleDemoShouldOpen = windowGlobal && window.location.hash.includes('schedule-demo')
  const [scheduleDemoOpen, setScheduleDemoOpen] = useState(scheduleDemoShouldOpen)

  const hasTableOfContents = tableOfContentsData?.hasTableOfContents

  const closeOnboardingModal = () => {
    setActive(false)
    if (windowGlobal) {
      navigate(windowGlobal.location.pathname + windowGlobal.location.search)
    }
  }

  useEffect(() => {
    setActive(modalShouldOpen)
  }, [modalShouldOpen])

  const closeExpertAudit = () => {
    setExpertAuditOpen(false)
    if (window.location.hash) {
      window.history.replaceState({}, document.title, window.location.pathname + window.location.search)
    }
  }

  useEffect(() => {
    setExpertAuditOpen(expertAuditShouldOpen)
  }, [expertAuditShouldOpen])

  const closeScheduleDemo = () => {
    setScheduleDemoOpen(false)
    if (window.location.hash) {
      window.history.replaceState({}, document.title, window.location.pathname + window.location.search)
    }
  }

  useEffect(() => {
    setScheduleDemoOpen(scheduleDemoShouldOpen)
  }, [scheduleDemoShouldOpen])

  useEffect(() => {
    highlander()
  }, [])

  useEffect(() => {
    document.querySelectorAll('span.external-link').forEach(toolTip => {
      toolTip.parentElement.addEventListener('keydown', e => {
        if (e.key === 'Escape') toolTip.parentElement.blur()
      })
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const scroller = document?.scrollingElement
      requestAnimationFrame(() => {
        scroller?.style.setProperty('--scrollbar-width', `${window.innerWidth - scroller.clientWidth}px`)
      })
    }, 0)
  }, [])

  return (
    <UserLangProvider>
      <Helmet>
        <link rel="preload" as="font" href={interFont} type="font/woff" crossOrigin="anonymous" />
        <link rel="preload" as="font" href={epilogueFont} type="font/woff" crossOrigin="anonymous" />
        <link rel="preload" as="font" href={jetBrainsFont} type="font/woff" crossOrigin="anonymous" />
        {preloadImg && <link rel="preload" as="image" href={preloadImg} crossOrigin="anonymous" />}
      </Helmet>
      <div id="at_message" aria-live="polite" />
      <SkipNavLink />
      {showHeader !== false ? (
        <Header
          homePage={homePage}
          microSiteHeaderFooter={microSiteHeaderFooter}
          hideHeaderFooterNav={hideHeaderFooterNav}
          showCTA={showCTA === null || showCTA === undefined ? true : showCTA}
          showSecondaryCTA={showSecondaryCTA === null || showSecondaryCTA === undefined ? true : showSecondaryCTA}
          removeLogoLink={removeLogoLink}
        />
      ) : null}
      <Global styles={globalStyle} />
      {!customSEO && <SEO />}
      <Wrapper id="content" role="main" hasTableOfContents={hasTableOfContents}>
        {hasTableOfContents && <TableOfContents {...tableOfContentsData} />}
        <ContentWrapper>{children}</ContentWrapper>
      </Wrapper>
      {showFooter !== false ? (
        <Footer hideHeaderFooterNav={hideHeaderFooterNav} microSiteHeaderFooter={microSiteHeaderFooter} />
      ) : null}
      <div id="modal-root" aria-live="assertive" />
      <ModalAnimated
        active={expertAuditOpen}
        onClose={() => closeExpertAudit()}
        ariaLabel="expert audit request form"
        modalWidth={545}
        mobileStyles={{
          maxHeight: '90vh',
          top: '2vh',
          paddingTop: '40px',
        }}
      >
        <ExpertAuditForm ctaLocation="scan-results" />
      </ModalAnimated>
      <ModalAnimated
        active={scheduleDemoOpen}
        onClose={() => closeScheduleDemo()}
        ariaLabel="schedule demo request form"
        modalWidth={545}
        mobileStyles={{
          maxHeight: '90vh',
          top: '2vh',
          paddingTop: '40px',
        }}
      >
        <ScheduleDemoForm />
      </ModalAnimated>
      <ModalAnimated
        active={active}
        onClose={() => closeOnboardingModal()}
        ariaLabel="sign up form"
        modalWidth={545}
        onboardingModal
        mobileStyles={{
          maxHeight: '90vh',
          top: '2vh',
          height: '100%',
          paddingTop: '40px',
        }}
      >
        <OnboardingModal open={active} setOpen={setActive} />
      </ModalAnimated>
    </UserLangProvider>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
