/* eslint-disable camelcase */
import React from 'react'
import ChecklistTable from './CheckListTable'

const sections = [
  {
    items: [
      {
        column1: 'Site Crawlability',
        column2:
          'Blocked pages, robots.txt errors, or improper use of meta tags can prevent search engines from crawling your site effectively. Be sure these elements are working properly to improve crawlability.',
      },
      {
        column1: 'Indexing Status',
        column2:
          'Verify your most important pages are properly indexed by search pages and that none of your critical pages have been inadvertently excluded.',
      },
      {
        column1: 'XML Sitemap',
        column2:
          'Confirm your XML sitemap is up-to-date and submitted properly to search engines. This will help them better understand your site’s structure and what your content is about.',
      },
      {
        column1: 'URL Structure',
        column2: 'Be sure your URLs are clean, descriptive, and free of unnecessary parameters or redirects.',
      },
      {
        column1: 'Title Tags and Meta Descriptions',
        column2:
          'Check that each of your pages includes a unique, descriptive title tag and meta description that is optimized for relevant keywords.',
      },
      {
        column1: 'Header Tags',
        column2:
          'Ensure you’ve used headers properly throughout your content (H1, H2, H3, etc.), as this keeps your site structured and improves readability.',
      },
      {
        column1: 'Content Quality',
        column2:
          'Review your digital content for relevance, keyword optimization, and engagement; your content should be original, informative, aligned with user intent, and engaging.',
      },
      {
        column1: 'Image Optimization',
        column2: 'Verify that your images have descriptive, keyword-rich alt text and are optimized for fast loading.',
      },
      {
        column1: 'Quality of Backlinks',
        column2:
          'Analyze the quality and relevance of backlinks that point to your site. Focus on removing or disavowing any toxic or low-quality links.',
      },
      {
        column1: 'Mobile-Friendly',
        column2:
          'Ensure your content works well on mobile devices. Evaluate your mobile page speed and overall usability and ensure it’s optimized for these devices.',
      },
      {
        column1: 'Anchor Text Distribution',
        column2: 'Check the variety and relevance of anchor text used in backlinks to ensure a natural link profile.',
      },
      {
        column1: 'Google My Business Listing',
        column2:
          'Ensure your Google My Business profile is complete, accurate, and up-to-date. Be sure your NAP (Name, Address, Phone) are consistent across channels.',
      },
    ],
  },
]

const SEOContentAuditChecklist = () => <ChecklistTable checklistItems={sections} title="SEO Content Audit Checklist" />

export default SEOContentAuditChecklist
