import format from 'date-fns/format'
import addDays from 'date-fns/addDays'

const formatDate = (date, daysToAdd = 0) => {
  let correctDate
  let formattedDate

  if (date) {
    correctDate = addDays(new Date(date), daysToAdd)
    formattedDate = format(new Date(correctDate), 'MMMM dd, yyyy')
  }
  return formattedDate
}
export default formatDate
