import React from 'react'
import styled from '@emotion/styled'
import { OpenNewTabHiddenSpan } from './NewTabHiddenSpan'
import { Facebook } from '../../../static/icons/component-icons/FacebookIcon.js'
import { Instagram } from '../../../static/icons/component-icons/InstagramIcon.js'
import { Twitter } from '../../../static/icons/component-icons/TwitterIcon.js'
import { LinkedIn } from '../../../static/icons/component-icons/LinkedInIcon.js'
import { Youtube } from '../../../static/icons/component-icons/YoutubeIcon.js'
import { Medium } from '../../../static/icons/component-icons/MediumIcon.js'

const Wrapper = styled.ul`
  display: flex;
  flex: 1;
  gap: 16px;
  align-items: center;

  svg {
    width: ${p => (p.maxSize ? `${p.maxSize}px` : '30px')};
    height: ${p => (p.maxSize ? `${p.maxSize}px` : '30px')};
  }

  li {
    margin-bottom: 0;
    list-style-type: none;

    a {
      border-radius: 50%;
      height: fit-content;
      display: flex;

      .icon {
        transition: all 50ms ease-in-out;
        fill: ${p => (p.color ? p.theme.color[p.color] : p.theme.color.gray500)};
      }

      &:hover,
      &:focus {
        border: 1px solid transparent;
        padding: 2px;
        margin: -3px;
        background: none;
        .icon {
          fill: ${p => (p.color === 'white' ? p.theme.color.green500 : p.theme.color.purple500)};
        }
      }
    }
  }

  @media (max-width: 800px) {
    svg {
      width: 24px;
      height: 24px;
    }

    li a {
      &:hover,
      &:focus {
        .icon {
          fill: ${p => (p.color === 'white' ? p.theme.color.green500 : p.theme.color.purple500)};
        }
      }
    }
  }
`

const SocialLink = ({ href, platform, color }) => (
  <li className="link-list-item">
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      className={color === 'white' ? 'dark-bg-link dark-bg-focus' : ''}
    >
      {platform === 'facebook' && <Facebook alt="" />}
      {platform === 'twitter' && <Twitter alt="" />}
      {platform === 'instagram' && <Instagram alt="" />}
      {platform === 'linkedin' && <LinkedIn alt="" />}
      {platform === 'youtube' && <Youtube alt="" />}
      {platform === 'medium' && <Medium alt="" />}
      <OpenNewTabHiddenSpan newTabText={platform} />
    </a>
  </li>
)

const SocialLinks = ({ color, maxSize }) => (
  <Wrapper color={color} maxSize={maxSize} className="social-links">
    <SocialLink href="https://www.linkedin.com/company/audioeye-inc/" platform="linkedin" />
    <SocialLink href="https://www.facebook.com/audioeyeinc" platform="facebook" />
    <SocialLink href="https://twitter.com/audioeyeinc" platform="twitter" alt="twitter" />
    <SocialLink href="https://www.instagram.com/audioeyeinc/" platform="instagram" />
    <SocialLink href="https://www.youtube.com/c/AudioEye" platform="youtube" />
    <SocialLink href="https://audioeye.medium.com/" platform="medium" />
  </Wrapper>
)

export default SocialLinks
