export const addSmoothScrollClass = () => {
  const windowGlobal = typeof window !== 'undefined' && window
  const html = windowGlobal && document.querySelector('html')
  if (windowGlobal) {
    html.classList.add('smooth-scroll')
    setTimeout(() => {
      if (windowGlobal.location.hash === '') {
        windowGlobal.scrollTo(0, 0)
      } else {
        const id = windowGlobal.location.hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    }, 0)
  }
}
