/* There can be only one h1 */
export default () => {
  const document = typeof window !== 'undefined' && window && window.document
  if (!document) {
    console.log('no document')
    return
  }

  // unnest h tags from p headings
  Array.from(document.querySelectorAll('[role=heading]')).forEach(pHeading => {
    const pChildren = pHeading.childNodes
    if (pChildren.length === 1 && /h\d/i.test(pChildren[0].nodeName)) {
      pHeading.replaceWith(pChildren[0])
    }
  })

  // combine h1 and aria h1's
  const h1s = Array.from(document.querySelectorAll('h1, [role=heading][aria-level="1"]'))

  // number of h1s is perfect
  if (h1s.length === 1) {
    return
  }

  // too many h1s, downgrade all remaining h1s to h2s
  if (h1s.length >= 1) {
    h1s.forEach((h1, index) => {
      if (index === 0) {
        return
      }
      if (h1.tagName !== 'H1') {
        // is aria level 1
        h1.setAttribute('aria-level', '2')
      } else {
        // is H1 tag
        const h2 = document.createElement('h2')
        h2.innerHTML = h1.innerHTML
        h1.replaceWith(h2)
      }
    })
    return
  }

  // no h1s, but there should be one
  const fakeHeader = document.querySelector('[role=heading]')
  if (fakeHeader) {
    fakeHeader.setAttribute('aria-level', '1')
    return
  }

  // as a last resort, if there is any h2 or h3 tags, promote the first
  const heading = document.querySelector('h2,h3')
  if (heading) {
    const h1 = document.createElement('h1')
    h1.innerHTML = heading.innerHTML
    heading.replaceWith(h1)
  }
}
