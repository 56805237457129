import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import styled from '@emotion/styled'

import Text from '../common/Text'
import Tag from '../common/Tag'
import Button from '../common/Button'

const Wrapper = styled.div`
  display: grid;
  margin: 48px auto;
  padding: 48px 36px;
  gap: 24px;
  border-top: 2px solid ${p => p.theme.color.gray200};
  border-bottom: 2px solid ${p => p.theme.color.gray200};
  max-width: 750px;

  @media (max-width: 1050px) {
    margin: 40px auto;
    padding: 40px 16px;
    gap: 16px;

    .button {
      margin: 0px;
      width: fit-content !important;
    }
  }

  p {
    margin-bottom: 0px !important;
  }
`

const InlineCta = ({ slice: { primary } }) => {
  const tagText = primary.tag_text
  const linkedContent = primary.linked_content
  const linkedContentTitle = linkedContent.document?.data.title.text
  const overrideTitle = primary.text.richText[0]?.text ? <PrismicRichText field={primary.text.richText} /> : null
  const buttonText = primary.button_text.richText[0]?.text

  return (
    <Wrapper>
      <Tag text={tagText || 'Suggested Article'} color="gray" fontSize="smallCaps" fontFamily="smallCaps" noMargin />
      {overrideTitle ? (
        <Text font="bodyBold" richText>
          {overrideTitle}
        </Text>
      ) : (
        <Text bodyBold>{linkedContentTitle}</Text>
      )}
      {linkedContent.document?.url && (
        <Button text={buttonText || 'Read more'} to={linkedContent.document.url} buttonType="link CTA" />
      )}
    </Wrapper>
  )
}

export default InlineCta

export const query = graphql`
  fragment InlineCta on PrismicPageDataBodyInlineCta {
    ...SliceBase
    primary {
      tag_text
      linked_content {
        document {
          ... on PrismicPost {
            data {
              title {
                text
              }
            }
            url
          }
          ... on PrismicPage {
            data {
              title {
                text
              }
            }
            url
          }
        }
      }
      text {
        ...TextContent
      }
      button_text {
        ...TextContent
      }
    }
  }
`
