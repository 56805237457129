/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React from 'react'
import { StyledTable, TableHead, TableHeadRow, TableHeader, TableRow, TableCell } from './EarningsReportTable.style'
import Text from '../../common/Text'

const Table = ({ metaData, tableData, skipUnauditedHeader, ...rest }) => {
  if (!tableData) return null

  const rowDataArr = Object.entries(tableData)

  // Remove <unaudited> formatting util row
  if (skipUnauditedHeader) rowDataArr.shift()
  const rowData = rowDataArr
    .filter(data => data[0] !== 'colNames')
    .map(data => ({ rowHeader: data[0], rowData: data[1] }))

  const fourColTable = tableData.colNames?.length >= 3
  const formatCurrency = currency =>
    currency ? currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : <span>&mdash;</span>

  const reorderColumns = colNames => {
    if (fourColTable) {
      colNames.sort()
      // eslint-disable-next-line no-return-assign
      return ([colNames[0], colNames[1], colNames[2], colNames[3]] = [
        colNames[1],
        colNames[0],
        colNames[3],
        colNames[2],
      ])
    }
    return tableData.colNames
  }

  const reorderedColumnData = reorderColumns(tableData.colNames)

  return (
    <StyledTable summary={metaData.summary} id={metaData.id} {...rest}>
      {tableData?.colNames && (
        <TableHead>
          <TableHeadRow colCount={tableData?.colNames.length} rowCount={fourColTable ? 2 : 1}>
            <TableHeader className="no-border" style={{ gridRow: fourColTable && '2 / 3' }} />
            {fourColTable
              ? reorderedColumnData.map((colName, i) => (
                  <React.Fragment key={`${colName}-${i}`}>
                    {i % 2 === 0 && (
                      <Text role="presentation" bodyBold center style={{ gridRow: '1 / 2', gridColumn: 'span 2' }}>
                        {colName.split(',')[0]}
                      </Text>
                    )}
                    <TableHeader
                      scope="col"
                      alignment="center"
                      className="table-header"
                      aria-label={colName}
                      style={{ gridRow: '2 / 3' }}
                    >
                      {colName.split(', ')[1]}
                    </TableHeader>
                  </React.Fragment>
                ))
              : reorderedColumnData.map((colName, i) => (
                  <TableHeader scope="col" alignment="center" className="table-header" key={`${colName}-${i}`}>
                    {colName.split(',')[0]},
                    <br />
                    {colName.split(',')[1]}
                  </TableHeader>
                ))}
          </TableHeadRow>
          <TableHeadRow colCount={tableData?.colNames.length}>
            <TableCell alignment="left" style={{ gridColumn: '1 / -1', height: '50px' }}>
              (in thousands, except per share data)
            </TableCell>
          </TableHeadRow>
        </TableHead>
      )}
      <tbody>
        {rowData.map((row, i) =>
          row.rowHeader.includes('undefined') ? (
            // Spacer rows
            <TableRow role="presentation" colCount={tableData?.colNames.length} key={`${row.rowHeader}-${i}`}>
              <TableCell style={{ gridColumn: '1 / -1', height: '50px' }} />
            </TableRow>
          ) : (
            <TableRow colCount={tableData?.colNames.length} key={`${row.rowHeader}-${i}`}>
              {/* Strip out header utility text */}
              {row.rowHeader.includes('<header>') || row.rowHeader.includes('<total>') ? (
                row.rowHeader.includes('<header>') ? (
                  <TableHeader scope="row" className="table-header">
                    {row.rowHeader.split('<header>')[1]}
                  </TableHeader>
                ) : (
                  <TableHeader scope="row">{row.rowHeader.split('<total>')[1]}</TableHeader>
                )
              ) : row.rowHeader.includes('<subtotal>') ? (
                <TableHeader scope="row">
                  {row.rowHeader.includes('_')
                    ? row.rowHeader.split('_')[0].split('<subtotal>')[1]
                    : row.rowHeader.split('<subtotal>')[1]}
                </TableHeader>
              ) : (
                <TableHeader scope="row">
                  {row.rowHeader.includes('_') ? row.rowHeader.split('_')[0] : row.rowHeader}
                </TableHeader>
              )}
              {tableData?.colNames &&
                row.rowData &&
                reorderedColumnData.map((colName, i1) =>
                  !row.rowHeader.includes('<header>') ? (
                    <TableCell
                      alignment="right"
                      aria-label={row.rowData?.[colName]?.toString()?.includes('(') ? 'negative value' : null}
                      className={
                        row.rowHeader.includes('<total>')
                          ? 'total'
                          : row.rowHeader.includes('<subtotal>')
                          ? 'sub-total'
                          : null
                      }
                      key={`${row.rowHeader}-${i1}`}
                    >
                      {formatCurrency(row.rowData[colName])}
                    </TableCell>
                  ) : (
                    <TableCell role="presentation" key={`${row.rowHeader}-${i1}`} />
                  )
                )}
            </TableRow>
          )
        )}
      </tbody>
    </StyledTable>
  )
}

export default Table
