/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import idx from 'idx'

import Text from '../common/Text'
import Button from '../common/Button'
import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'
import getImage from '../../utils/getImage'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -100%;
  height: 100%;
  z-index: -1;
  background-color: ${p => p.theme.color[p.backgroundColor] || p.theme.color.white};

  @media (max-width: 1050px) {
    margin-top: -40px;
  }
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${p =>
    p.titleText
      ? `
    width: calc(100% + 300px);
    margin-left: -150px;
  `
      : `
    width: 100%;
  `};

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    margin-left: -10px;
    padding: 40px 10px;
  }
`

const CompaniesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: ${p => (p.hasTopPadding ? '64px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '64px' : '0px')};

  p {
    margin-bottom: 0px !important;
  }

  @media (max-width: 1300px) {
    padding: ${p => (p.hasLink ? '40px 0px' : '40px 0px 32px 0px')};
  }

  @media (max-width: 1050px) {
    padding: 0px;
  }
`

const ImagesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-bottom: ${p => p.hasLink && '10px'};
  margin-top: 10px;

  @media (max-width: 1300px) {
    margin-bottom: ${p => p.hasLink && '8px'};
  }

  @media (max-width: 1050px) {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
  }
`

const LogoLink = styled.a`
  width: 100%;
  padding: 16px;

  @media (max-width: 1050px) {
    width: auto;
    padding: 0px;
  }
`

const Logo = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;
  padding: 16px;

  @media (max-width: 1050px) {
    width: 125px;
  }
  @media (max-width: 800px) {
    width: 90px;
    padding: 8px;
  }
`

const TrustedByCompanies = ({ slice: { primary, items } }) => {
  const hasTopPadding = idx(primary, _ => _.has_top_padding)
  const hasBottomPadding = idx(primary, _ => _.has_bottom_padding)
  const title = primary.title1.richText?.[0]?.text && <PrismicRichText field={primary.title1.richText} />
  const backgroundColor = primary.background_color
  const linkText = idx(primary, _ => _.link_text.text)
  const linkUrl = idx(primary, _ => _.link_url.url) || idx(primary, _ => _.link_url.uid)
  const anchorId = primary.anchor_id
  const darkBG = primary.background_color === 'purple900'

  return (
    <Wrapper titleText={title} id={anchorId || null}>
      {items && items[0] && items[0].company_image && items[0].company_image.url && (
        <CompaniesWrapper
          hasLink={linkText}
          hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
          hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
        >
          {title && (
            <Text font="bodyMedium" center aria-level="2" role="heading" richText color={darkBG ? 'white' : 'gray600'}>
              {title || `Proudly partnered with`}
            </Text>
          )}
          <ImagesWrapper hasLink={linkText}>
            {items.map(item =>
              item.link.url ? (
                <LogoLink
                  href={item.link && item.link.url}
                  target={item.link && item.link.url ? '_blank' : null}
                  rel="noopener noreferrer"
                  key={item.company_image.url}
                >
                  {getImage(item.company_image, Logo)}
                  {item.link && item.link.url && <OpenNewTabHiddenSpan overGraphic />}
                </LogoLink>
              ) : (
                getImage(item.company_image, Logo, { key: item.company_image.url })
              )
            )}
          </ImagesWrapper>
          {linkText && <Button buttonType="link CTA" text={linkText} to={linkUrl} />}
        </CompaniesWrapper>
      )}
      <BG className="bg" backgroundColor={backgroundColor} />
    </Wrapper>
  )
}

export default TrustedByCompanies

export const query = graphql`
  fragment TrustedByCompanies on PrismicPageDataBodyTrustedByCompanies {
    ...SliceBase
    items {
      company_image {
        alt
        url
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
      }
    }
    primary {
      anchor_id
      has_top_padding
      has_bottom_padding
      background_color
      link_url {
        uid
        url
      }
      link_text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
    }
  }
`
