const generateScannerTranslations = ({ userLang }) => {
  const data = {
    en: {
      buttonText: 'Free Scan',
      errorText: 'Please use a valid website address.',
    },
    de: {
      buttonText: 'Kostenloser Scan',
      errorText: 'Bitte verwenden Sie eine gültige Website-Adresse.',
    },
    fr: {
      buttonText: 'Analyse gratuite',
      errorText: 'Veuillez utiliser une adresse de site Web valide.',
    },
    es: {
      buttonText: 'Escaneo gratuito',
      errorText: 'Utilice una dirección de sitio web válida.',
    },
    it: {
      buttonText: 'Scansione gratuita',
      errorText: 'Si prega di utilizzare un indirizzo web valido.',
    },
  }

  return data[userLang] || data.en
}

export default generateScannerTranslations
