export const config = {
  gaPropertyId: process.env.GATSBY_ACTIVE_ENV === 'production' ? 'UA-22205528-1' : 'UA-22205528-11',
  airtable: {
    baseIDs: {
      'second-quarter-2020': 'appwN27XxQ6mx0Ba2',
      'third-quarter-2020': 'appNqz8APm95YNggE',
      'fourth-quarter-2020': 'appubgE3gbwVagAEx',
      'first-quarter-2021': 'appHvokxLwbDqcxaC',
      'second-quarter-2021': 'appX0iDcsg2Wkm3dY',
      'third-quarter-2021': 'app5De250RLuWnI1c',
      'fourth-quarter-2021': 'appBTLRcCDfM42VQL',
      'first-quarter-2022': 'app4B4nBw4wvUtQN6',
      'second-quarter-2022': 'appR62MVvz7hlkyyX',
      'third-quarter-2022': 'appuwKPHkO9z3Pvvj',
      'fourth-quarter-2022': 'apptKr83eNeYlmYC2',
      'first-quarter-2023': 'appamIojPEbwCmOKL',
      'second-quarter-2023': 'appykrXS3vQ3DyYDR',
      'third-quarter-2023': 'app16pV3O1oJgct1f',
      'fourth-quarter-2023': 'apprrim7tuv6FBfkP',
      'first-quarter-2024': 'app2MJdyC5mdlKm6X',
      'second-quarter-2024': 'app8Byomk0twWciGC',
      'third-quarter-2024': 'appbEuX47bv85S3Iv',
    },
    tableMetaData: {
      isTable: {
        summary: 'Column one has the revenue source, other columns show the amounts by date period',
        id: 'ae-is-table',
      },
      bsTable: {
        summary: 'Column one has the revenue source, other columns show the amounts by date period',
        id: 'ae-bs-table',
      },
      gaapTable: {
        summary: 'Column one has the non-GAAP earnings loss source, other columns show the amounts by date period',
        id: 'ae-gaap-table',
      },
    },
  },
}
export const invalidEmails = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'icloud.com',
  'test.com',
  'outlook.com',
  'aol.com',
  'mailinator.com',
  'audioeye.com',
  'comcast.net',
  'live.com',
  'me.com',
  'msn.com',
  'email.com',
  'protonmail.com',
  'mail.com',
  'qq.com',
  'att.net',
  'coxautoinc.com',
  'hotmail.co.uk',
  'google.com',
  'ymail.com',
]
