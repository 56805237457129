/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import Input from './Input'
import Button from '../common/Button'
import { theme } from '../../styles'
import Text from '../common/Text'
import useUrlParam from '../../utils/useUrlParam'
import { trackEvent } from '../../utils/eventTrack'
import { useUserLang } from '../../context/UserLangContext'
import generateScannerTranslations from '../../data/generateScannerTranslations'

const Form = styled.form`
  display: flex;
  width: 100%;

  ${props =>
    props.mobileForm &&
    `
      flex-direction: column;
      label {
        width: 100%;
      }
      .button {
        border-radius: 8px !important;
        width: 100%;
        margin-top: 20px;
      }
  `}

  label {
    margin-bottom: 0px;
  }

  .button {
    border-radius: 0px 8px 8px 0px;
    align-self: flex-end;
    padding: 17px 32px;
    height: auto;
    margin-left: 0px;

    @media (max-width: 1050px) {
      border-radius: 8px;
      width: 100%;
      margin-top: 20px;
    }
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    label {
      width: 100%;
    }
  }
`

const ScannerInput = styled(Input)`
  &.input-component-input {
    border-radius: 8px 0px 0px 8px;
    padding: 15px;
    border-width: 2px;
    border-color: ${theme.color.purple500};

    &:focus {
      border-width: 2px;
      border-color: ${theme.color.purple500}!important;
    }

    @media (max-width: 1050px) {
      border-radius: 8px;
      width: 100%;
      padding: 14px;
    }
  }

  ${props =>
    props.mobileForm &&
    `
    &.input-component-input {
      border-radius: 8px;
      width: 100%;
      padding: 14px;
    }
  `}
`

const Scanner = ({
  partner = false,
  darkBG,
  labelText,
  buttonText,
  newTab,
  mobileForm,
  onSubmit,
  refresh,
  placeholderText,
  sales,
}) => {
  const windowGlobal = typeof window !== `undefined` && window
  const { userLang } = useUserLang()
  const languageData = generateScannerTranslations({ userLang })
  const [inputURL, setInputURL] = useState('')
  const [validationError, setValidationError] = useState('')
  const [gateForm] = useState(windowGlobal ? useUrlParam(window.location.search, 'gate') : false)

  useEffect(() => {
    const showForm = windowGlobal && window.sessionStorage.getItem('gate')
    if (gateForm === 'false' || showForm === 'false') {
      window.sessionStorage.setItem('gate', false)
    } else {
      window.sessionStorage.setItem('gate', true)
    }
  }, [])

  function handler(e) {
    e.preventDefault()
    if (windowGlobal) {
      if (inputURL.match(/^((ftp|http|https):\/\/)?([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/i)) {
        window.sessionStorage.setItem('scan_url', inputURL)

        const url = partner
          ? encodeURI(`https://partner-scanner.audioeye.com/default/${inputURL}`)
          : encodeURI(
              `/scan/results/?url=${inputURL}&partner=${partner}&lang=${userLang || 'en'}&mktg=${sales || false}`
            )

        if (onSubmit) onSubmit()

        trackEvent('Site Scan URL Submitted', {
          'Site URL': inputURL,
          'BOIA Scan': false,
        })

        if (newTab) {
          window.open(url, '_blank')
        } else if (refresh) {
          navigate(url)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          navigate(url)
        }
      } else setValidationError(languageData?.errorText || 'Please use a valid website address.')
    }
  }

  return (
    <>
      {/* eslint-disable-next-line */}
      <Form onSubmit={handler} mobileForm={mobileForm}>
        <ScannerInput
          label={labelText || 'Enter URL to find accessibility issues on your site'}
          value={inputURL}
          onChange={e => setInputURL(e.target.value)}
          onBlur={e => {
            trackEvent(`Form Value Changed`, {
              'Form Name': 'Scanner',
              'Form Element Name': e.target.name,
              'Form Element Type': 'input',
              'New Value': e.target.value,
            })
          }}
          onFocus={e => {
            trackEvent(`Form Value Focused`, {
              'Form Name': 'Scanner',
              'Form Element Name': e.target.name,
              'Form Element Type': 'input',
              'New Value': e.target.value,
            })
          }}
          placeholder={placeholderText || 'www.yourwebsite.com'}
          name="website_url"
          type="text"
          scanner
          required
          labelFont="smallBodyBold"
          mobileForm={mobileForm}
        />
        <Button
          text={buttonText || languageData?.buttonText || 'Free Scan'}
          type="submit"
          noScroll
          buttonType="primary"
        />
      </Form>
      {validationError && (
        <Text tinyBody color={darkBG ? 'yellow500' : 'red500'} role="alert" aria-live="polite" small>
          {validationError}
        </Text>
      )}
    </>
  )
}

export default Scanner
