import React from 'react'

export const Instagram = ({ alt }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt={alt}>
    <path
      className="icon"
      d="M18.5363 7.8775C17.6138 7.835 17.3362 7.8275 15 7.8275C12.6638 7.8275 12.3875 7.83625 11.465 7.8775C9.09125 7.98625 7.98625 9.11 7.8775 11.465C7.83625 12.3875 7.82625 12.6638 7.82625 15C7.82625 17.3362 7.83625 17.6125 7.8775 18.5363C7.98625 20.885 9.08625 22.015 11.465 22.1237C12.3862 22.165 12.6638 22.175 15 22.175C17.3375 22.175 17.6138 22.1662 18.5363 22.1237C20.91 22.0162 22.0137 20.8888 22.1237 18.5363C22.165 17.6138 22.1738 17.3362 22.1738 15C22.1738 12.6638 22.165 12.3875 22.1237 11.465C22.0137 9.11125 20.9075 7.98625 18.5363 7.8775V7.8775ZM15 19.4938C12.5187 19.4938 10.5062 17.4825 10.5062 15C10.5062 12.5187 12.5187 10.5075 15 10.5075C17.4813 10.5075 19.4938 12.5187 19.4938 15C19.4938 17.4813 17.4813 19.4938 15 19.4938ZM19.6713 11.38C19.0913 11.38 18.6213 10.91 18.6213 10.33C18.6213 9.75 19.0913 9.28 19.6713 9.28C20.2513 9.28 20.7212 9.75 20.7212 10.33C20.7212 10.9087 20.2513 11.38 19.6713 11.38V11.38ZM17.9163 15C17.9163 16.6113 16.61 17.9163 15 17.9163C13.39 17.9163 12.0837 16.6113 12.0837 15C12.0837 13.3887 13.39 12.0837 15 12.0837C16.61 12.0837 17.9163 13.3887 17.9163 15ZM15 0C6.71625 0 0 6.71625 0 15C0 23.2837 6.71625 30 15 30C23.2837 30 30 23.2837 30 15C30 6.71625 23.2837 0 15 0ZM23.6975 18.6075C23.5538 21.7887 21.7825 23.5513 18.6088 23.6975C17.675 23.74 17.3763 23.75 15 23.75C12.6237 23.75 12.3263 23.74 11.3925 23.6975C8.2125 23.5513 6.44875 21.7862 6.3025 18.6075C6.26 17.675 6.25 17.3763 6.25 15C6.25 12.6237 6.26 12.3263 6.3025 11.3925C6.44875 8.2125 8.21375 6.44875 11.3925 6.30375C12.3263 6.26 12.6237 6.25 15 6.25C17.3763 6.25 17.675 6.26 18.6088 6.30375C21.79 6.45 23.555 8.21875 23.6975 11.3925C23.74 12.3263 23.75 12.6237 23.75 15C23.75 17.3763 23.74 17.675 23.6975 18.6075Z"
      fill="none"
    />
  </svg>
)
