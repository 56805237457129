/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import Space from '../common/Space'
import Text from '../common/Text'
import getImage from '../../utils/getImage'
import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${p => (!p.reverse ? 'row' : 'row-reverse')};
  justify-content: center;
  min-height: ${p => (p.hasPadding ? '488px' : 'unset')};
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 0px 150px;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '0px')};
  z-index: 0;
  position: relative;

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
    padding-top: ${p => (p.hasTopPadding ? '56px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '56px' : '0px')};
    padding-left: 16px;
    padding-right: 16px;
    height: auto;
  }

  @media (max-width: 800px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Image = styled.img`
  display: flex;
  width: 100%;
  object-fit: contain;
  margin: 0 auto;

  @media (max-width: 1050px) {
    max-width: 500px;
  }

  @media (max-width: 800px) {
    max-width: unset;
  }
`

const QuoteWrapper = styled.div`
  flex: 1;
  margin-top: 24px;
  margin-bottom: 24px;
`

const InfoOutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;

  @media (max-width: 800px) {
    gap: 0px;
    flex-direction: column;
  }
`

const Quote = styled.div`
  display: flex;
  max-width: 800px;
  gap: 24px;

  @media (max-width: 500px) {
    gap: 16px;
    flex-direction: column-reverse;
  }

  .gatsby-image-wrapper {
    width: unset;
    min-width: 120px;

    @media (max-width: 999px) {
      max-width: 100px;
    }
  }

  img {
    max-width: 120px;
    max-height: 120px;
    width: 120px;
    height: 120px;

    @media (max-width: 999px) {
      max-width: 100px;
      max-height: 100px;
      width: 100px;
      height: 100px;
    }
  }
`

const QuoteText = styled(Text)`
  text-align: left !important;
`

const TitleText = styled(Text)`
  @media (max-width: 500px) {
    h4 {
      text-align: center;
    }
  }
`

const LogosWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 8px 8px 0px;

  @media (max-width: 500px) {
    justify-content: center;
  }
`

const Logo = styled.img`
  height: 90px;
  object-fit: contain;
  padding: 16px 24px 16px 0;

  @media (max-width: 1500px) {
    height: 75px;
  }
`

const TrustedBy = styled.div`
  flex: 1;
`

const QuoteAndLogos = ({ slice: { items, primary } }) => {
  const hasSectionPadding = primary?.has_section_padding
  const quote = <PrismicRichText field={primary?.quote_text.richText} />
  const quotePersonName = primary.quote_person_name
  const quotePersonTitle = primary.quote_person_title
  const quoteImage = primary.quote_image
  const richTitle = primary.rich_text_heading.richText?.[0]?.text && (
    <PrismicRichText field={primary.rich_text_heading.richText} />
  )

  return (
    <Wrapper
      hasTopPadding={hasSectionPadding === null ? true : hasSectionPadding}
      hasBottomPadding={hasSectionPadding === null ? true : hasSectionPadding}
    >
      <InfoOutWrapper>
        {richTitle && (
          <TrustedBy>
            {richTitle && (
              <TitleText font="heading4" left aria-level="4" richText>
                {richTitle}
              </TitleText>
            )}
            <LogosWrapper hasTitle={richTitle?.props?.field?.[0]?.text}>
              {items.map(item =>
                item.logo_link.url ? (
                  <a
                    href={item?.logo_link?.url}
                    target={item?.logo_link?.url ? '_blank' : null}
                    key={item?.logo_link?.url}
                    rel="noopener noreferrer"
                  >
                    {getImage(item.logo, Logo)}
                    {item?.logo_link?.url && (
                      <OpenNewTabHiddenSpan overGraphic newTabText={item?.logo?.alt.split('logo')[0] || ''} />
                    )}
                  </a>
                ) : (
                  getImage(item.logo, Logo, { key: item.logo.url })
                )
              )}
            </LogosWrapper>
          </TrustedBy>
        )}
        <QuoteWrapper>
          {quote && quotePersonName && quoteImage && (
            <Quote>
              {getImage(quoteImage, Image)}
              <div>
                <QuoteText font="smallBody" richText>
                  {quote}
                </QuoteText>
                <Space height={8} mobileHeight={8} />
                <QuoteText smallBodyBold>
                  {quotePersonName}, {quotePersonTitle}
                </QuoteText>
              </div>
            </Quote>
          )}
        </QuoteWrapper>
      </InfoOutWrapper>
    </Wrapper>
  )
}

export default QuoteAndLogos

export const query = graphql`
  fragment QuoteAndLogos on PrismicPageDataBodyQuoteAndLogos {
    ...SliceBase
    items {
      logo {
        alt
        url
        gatsbyImageData(width: 180)
      }
      logo_link {
        url
        uid
      }
    }
    primary {
      has_section_padding
      quote_image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      quote_text {
        ...TextContent
      }
      quote_person_name
      quote_person_title
      rich_text_heading {
        ...TextContent
      }
    }
  }
`
