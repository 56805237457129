import React from 'react'

const generateConsentFormFieldsTranslations = ({ userLang }) => {
  const data = {
    en: {
      checkboxLabelOne:
        'AudioEye is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please check the box for Marketing Communications',
      checkboxLabelTwo:
        'In order to provide you the content requested, we need to store and process your personal data. If you consent to us storing your personal data for this purpose, please check the checkbox.',
      disclaimerText: (
        <>
          You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our
          privacy practices, and how we are committed to protecting and respecting your privacy, please review our{' '}
          <a href="/privacy-policy/" target="_blank">
            Privacy Policy
          </a>
        </>
      ),
    },
    de: {
      checkboxLabelOne:
        'AudioEye verpflichtet sich, Ihre Privatsphäre zu schützen und zu respektieren. Wir verwenden Ihre persönlichen Daten nur zur Verwaltung Ihres Kontos und zur Bereitstellung der von Ihnen angeforderten Produkte und Dienstleistungen. Von Zeit zu Zeit möchten wir Sie über unsere Produkte und Dienstleistungen sowie über andere Inhalte informieren, die für Sie von Interesse sein könnten. Wenn Sie damit einverstanden sind, dass wir Sie zu diesem Zweck kontaktieren, aktivieren Sie bitte das Kästchen für Marketingkommunikation',
      checkboxLabelTwo:
        'Um Ihnen die gewünschten Inhalte bereitstellen zu können, ist die Speicherung und Verarbeitung Ihrer personenbezogenen Daten erforderlich. Wenn Sie damit einverstanden sind, dass wir Ihre personenbezogenen Daten zu diesem Zweck speichern, aktivieren Sie bitte das Kontrollkästchen.',
      disclaimerText: (
        <>
          Sie können diese Benachrichtigungen jederzeit abbestellen. Weitere Informationen zum Abbestellen, zu unseren
          Datenschutzverfahren und dazu, wie wir Ihre Privatsphäre schützen und respektieren, finden Sie in unserer{' '}
          <a href="/de/privacy-policy-de/" target="_blank">
            Datenschutzrichtlinie
          </a>
        </>
      ),
    },
    fr: {
      checkboxLabelOne:
        "AudioEye s'engage à protéger et à respecter votre vie privée, et nous n'utiliserons vos informations personnelles que pour administrer votre compte et pour fournir les produits et services que vous nous avez demandés. De temps en temps, nous aimerions vous contacter au sujet de nos produits et services, ainsi que d'autres contenus susceptibles de vous intéresser. Si vous consentez à ce que nous vous contactions à cette fin, veuillez cocher la case Communications marketing.",
      checkboxLabelTwo:
        'Afin de vous fournir le contenu demandé, nous devons stocker et traiter vos données personnelles. Si vous consentez à ce que nous stockions vos données personnelles à cette fin, veuillez cocher la case.',
      disclaimerText: (
        <>
          Vous pouvez vous désabonner de ces communications à tout moment. Consultez notre{' '}
          <a href="/fr/privacy-policy-fr/" target="_blank">
            Politique de confidentialité
          </a>{' '}
          pour en savoir plus sur nos modalités de désabonnement, nos politiques de confidentialité et sur notre
          engagement vis-à-vis de la protection et du respect de la vie privée.
        </>
      ),
    },
    es: {
      checkboxLabelOne:
        'AudioEye se compromete a proteger y respetar su privacidad, y solo usaremos su información personal para administrar su cuenta y proporcionarle los productos y servicios que nos solicitó. De vez en cuando, nos gustaría comunicarnos con usted acerca de nuestros productos y servicios, así como sobre otro contenido que pueda ser de su interés. Si acepta que nos comuniquemos con usted para este propósito, marque la casilla de Comunicaciones de marketing.',
      checkboxLabelTwo:
        'Para proporcionarle el contenido solicitado, necesitamos almacenar y procesar sus datos personales. Si acepta que almacenemos sus datos personales para este fin, marque la casilla de verificación.',
      disclaimerText: (
        <>
          Podrás darte de baja de estas comunicaciones en cualquier momento. Para obtener más información sobre cómo
          cancelar la suscripción, nuestras prácticas de privacidad y cómo nos comprometemos a proteger y respetar su
          privacidad, revise nuestra{' '}
          <a href="/es/privacy-policy-es/" target="_blank">
            Política de privacidad
          </a>
        </>
      ),
    },
    it: {
      checkboxLabelOne:
        'AudioEye si impegna a proteggere e rispettare la tua privacy e utilizzeremo le tue informazioni personali solo per amministrare il tuo account e per fornire i prodotti e i servizi che ci hai richiesto. Di tanto in tanto, vorremmo contattarti in merito ai nostri prodotti e servizi, nonché ad altri contenuti che potrebbero interessarti. Se acconsenti a essere contattato per questo scopo, seleziona la casella per Comunicazioni di marketing',
      checkboxLabelTwo:
        'Per fornirti i contenuti richiesti, abbiamo bisogno di archiviare ed elaborare i tuoi dati personali. Se acconsenti alla memorizzazione dei tuoi dati personali per questo scopo, seleziona la casella di controllo.',
      disclaimerText: (
        <>
          Potrai cancellarti da queste comunicazioni in ogni momento. Per ulteriori informazioni su come annullare
          l'iscrizione, sulle nostre pratiche sulla privacy e su come ci impegniamo a proteggere e rispettare la tua
          privacy, consulta la nostra{' '}
          <a href="/it/privacy-policy-it/" target="_blank">
            Informativa sulla privacy
          </a>
        </>
      ),
    },
  }

  return data[userLang] || data.en
}

export default generateConsentFormFieldsTranslations
