import React from 'react'

export const Twitter = ({ alt }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt={alt}>
    <path
      className="icon"
      d="M15 0C6.71625 0 0 6.71625 0 15C0 23.2837 6.71625 30 15 30C23.2837 30 30 23.2837 30 15C30 6.71625 23.2837 0 15 0ZM22.5825 12.0563C22.8112 17.1063 19.045 22.7362 12.3775 22.7362C10.35 22.7362 8.46375 22.1413 6.875 21.1225C8.78 21.3475 10.6812 20.8175 12.19 19.6362C10.62 19.6075 9.29375 18.5687 8.835 17.1425C9.39875 17.25 9.95375 17.2187 10.4575 17.0812C8.73125 16.7337 7.53875 15.1788 7.5775 13.515C8.0625 13.7838 8.615 13.945 9.20375 13.9637C7.605 12.895 7.1525 10.7837 8.0925 9.17C9.8625 11.3425 12.5087 12.7712 15.4925 12.9212C14.9688 10.6762 16.6725 8.5125 18.9912 8.5125C20.0225 8.5125 20.9562 8.94875 21.6112 9.64625C22.4287 9.48625 23.1988 9.18625 23.8913 8.775C23.6225 9.61375 23.0538 10.3163 22.3125 10.7613C23.0388 10.6738 23.7313 10.4813 24.3738 10.195C23.8938 10.9175 23.2863 11.55 22.5825 12.0563V12.0563Z"
      fill="none"
    />
  </svg>
)
