/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react'
import { Link } from 'gatsby'

import Text from './Text'
import { trackEvent } from '../../utils/eventTrack'
import { OpenNewTabHiddenSpan } from './NewTabHiddenSpan'
import { addSmoothScrollClass } from '../../utils/addSmoothScrollClass'

const TextLink = React.forwardRef(
  (
    {
      to,
      text,
      onKeyDown,
      role,
      tabIndex,
      outboundHeaderLink,
      color,
      font,
      newTab,
      anchorClassName,
      noSmoothScroll,
      loginLink,
      ...textProps
    },
    ref
  ) => {
    const oldOnClick = textProps.onClick || (() => {})
    if (!noSmoothScroll) {
      textProps.onClick = e => {
        // Smooth scroll for anchor links
        addSmoothScrollClass()
        oldOnClick(e)
      }
    }
    const trackedKeyDown = e => {
      if (typeof onKeyDown === 'function') {
        onKeyDown(e)
      }
    }
    const target = to && to.replace(/^https?:\/\/(beta|betastaging|www)?\.?audioeye\.com(.+)/, '$2')
    const opensPdf = to && to.includes('.pdf')

    const linkProps = {
      role,
      tabIndex,
      href: target,
      rel: 'noopener noreferrer',
      className: `${anchorClassName || ''} text-link`,
    }

    const el = text ? (
      <Text isSpan font={font || 'body'} richText={textProps?.richText} color={color || 'purple500'} {...textProps}>
        {text}
      </Text>
    ) : null

    // supporting keyboard listeners
    const aRef = useCallback(node => {
      if (node !== null) {
        if (typeof ref === 'function') {
          ref(node)
        } else if (ref !== null) {
          ref.current = node
        }
        node.addEventListener('keydown', trackedKeyDown)
      }
    }, [])

    if (loginLink) {
      return (
        <a href={to} {...linkProps}>
          {el}
        </a>
      )
    }

    if ((target && target.indexOf('http') > -1) || newTab) {
      delete textProps.onClick
      const newElement = (
        <Text isSpan font={font || 'body'} richText={textProps?.richText} color={color || 'purple500'} {...textProps}>
          {text}
        </Text>
      )

      return (
        <a
          {...linkProps}
          href={target}
          target="_blank"
          // className={anchorClassName || ''}
          onClick={() => {
            if (opensPdf) {
              trackEvent(`PDF Downloaded`, {
                'PDF Name': to,
              })
            }
          }}
          onKeyDown={e => {
            if (outboundHeaderLink) trackedKeyDown(e)
            if (e.key === 'Escape') {
              e.target.blur()
            }
          }}
        >
          {newElement}
          <OpenNewTabHiddenSpan />
        </a>
      )
    }

    if (!to) {
      return (
        <a
          role="button"
          tabIndex={tabIndex || '0'}
          onClick={textProps.onClick}
          className={anchorClassName || ''}
          onKeyDown={e => {
            trackedKeyDown(e)
          }}
        >
          {el}
        </a>
      )
    }

    // test passes if its a relative link. if it doesn't pass then we want to render a normal A tag
    const relativeLink = /^\/(?!\/)/
    const audioEyeLink = /^https?:\/\/(beta|betastaging|www)?\.?audioeye\.com(.+)/
    if ((!relativeLink.test(to) && !audioEyeLink.test(to)) || to.includes('#')) {
      return (
        <a {...linkProps} href={target}>
          {el}
        </a>
      )
    }

    return (
      <Link to={target} ref={aRef} {...linkProps}>
        {el}
      </Link>
    )
  }
)

TextLink.displayName = 'TextLink'

export default TextLink
