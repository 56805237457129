/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'

import Text from '../components/common/Text'
import Space from '../components/common/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import AuthorSection from '../components/blog/AuthorSection'

const Wrapper = styled.div`
  max-width: 750px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 80px 0px;

  @media (max-width: 1050px) {
    padding: 56px 0px;
  }
`

const AuthorContent = ({ authorData, location }) => {
  if (!authorData) {
    return null
  }
  const { first_name, last_name, profile_image, description } = authorData.data
  const richDescription = description?.richText && <PrismicRichText field={description.richText} />

  return (
    <Layout showHeader customSEO>
      <SEO
        bannerUrl={profile_image && profile_image.url}
        title={`${first_name?.text} ${last_name?.text} | AudioEye`}
        pathname={location.pathname}
        desc={description.text}
        node={authorData}
      />
      <Wrapper>
        <AuthorSection authorData={authorData.data} />
        <Space height={32} />
        {richDescription && (
          <Text font="body" richText>
            {richDescription}
          </Text>
        )}
      </Wrapper>
    </Layout>
  )
}

export default AuthorContent
