import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'

import Text from '../common/Text'
import TextLink from '../common/TextLink'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 80px 0 0;
  margin-bottom: 24px;

  @media (max-width: 1050px) {
    flex-direction: column;
    margin: 32px 0 0;
  }
`

const CardWrapper = styled.div`
  text-align: center;
  background: linear-gradient(205.41deg, ${theme.color.gray100} 16.1%, ${theme.color.offWhite} 76.97%);
  width: 47.5%;
  padding: 48px 24px;
  max-height: 400px;
  margin-bottom: 200px;

  @media (max-width: 1050px) {
    width: 100%;
    max-height: unset;
    margin-bottom: 32px;
  }
`

const Body = styled.div`
  padding: 22px 0 24px 0;

  p {
    margin-bottom: 0px !important;
  }
`

const TextLinkWrapper = styled.div`
  margin-bottom: 32px;

  @media (max-width: 1050px) {
    margin-bottom: 24px;
  }

  a {
    display: flex;
    justify-content: center;
  }
`

const CardImage = styled.img`
  max-width: 100%;
  display: block;
  margin: 16px auto 0;

  @media (max-width: 1050px) {
    width: 100%;
    max-width: 600px;
    margin: 24px auto 0;
  }
`

const Card = ({ title, text, image, link, linkText }) => (
  <CardWrapper>
    {title && (
      <Text font="heading3" center richText>
        {title}
      </Text>
    )}

    {text && (
      <Body>
        <Text font="body" center richText>
          {text}
        </Text>
      </Body>
    )}
    {linkText && link.url && (
      <TextLinkWrapper>
        <TextLink to={link.url} text={linkText} />
      </TextLinkWrapper>
    )}
    {getImage(image, CardImage)}
  </CardWrapper>
)

const TwoColumnCards = ({ slice: { primary } }) => {
  const cardOneTitle = primary.card_one_title.richText?.[0]?.text && (
    <PrismicRichText field={primary.card_one_title.richText} />
  )
  const cardOneText = primary.card_one_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.card_one_text.richText} />
  )
  const cardOneLinkText = primary.card_one_link_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.card_one_link_text.richText} />
  )
  const cardOneLink = primary?.card_one_link
  const cardTwoTitle = primary.card_two_title.richText?.[0]?.text && (
    <PrismicRichText field={primary.card_two_title.richText} />
  )
  const cardTwoText = primary.card_two_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.card_two_text.richText} />
  )
  const cardTwoLinkText = primary.card_two_link_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.card_two_link_text.richText} />
  )
  const cardTwoLink = primary?.card_two_link

  return (
    <Wrapper>
      <Card
        title={cardOneTitle}
        text={cardOneText}
        image={primary.card_one_image}
        link={cardOneLink}
        linkText={cardOneLinkText}
      />
      <Card
        title={cardTwoTitle}
        text={cardTwoText}
        image={primary.card_two_image}
        link={cardTwoLink}
        linkText={cardTwoLinkText}
      />
    </Wrapper>
  )
}

export default TwoColumnCards

export const query = graphql`
  fragment TwoColumnCards on PrismicPageDataBodyTwoColumnCards {
    ...SliceBase
    primary {
      card_one_title {
        ...TextContent
      }
      card_one_text {
        ...TextContent
      }
      card_one_link_text {
        ...TextContent
      }
      card_one_link {
        ...LinkContent
      }
      card_one_image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      card_two_title {
        ...TextContent
      }
      card_two_text {
        ...TextContent
      }
      card_two_link_text {
        ...TextContent
      }
      card_two_link {
        ...LinkContent
      }
      card_two_image {
        alt
        url
        gatsbyImageData(width: 540)
      }
    }
  }
`
