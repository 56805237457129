/* eslint-disable camelcase */
import React from 'react'
import ChecklistTable from './CheckListTable'

const sections = [
  {
    items: [
      {
        column1: 'Check Page Load Speed',
        column2:
          'Ensure your site loads quickly on both desktop and mobile devices. Slow load times can frustrate users and negatively impact your SEO.',
      },
      {
        column1: 'Test Links and Navigation',
        column2:
          'Ensure all internal and external links work correctly and that your site’s navigation is intuitive and free of broken links.',
      },
      {
        column1: 'Mobile Responsiveness',
        column2: 'Confirm your site displays and functions properly across various mobile devices and screen sizes.',
      },
      {
        column1: 'User Experience (UX) Assessment',
        column2:
          'Evaluate the overall ease of use, including how easily users can find information and navigate your web pages. Look for any obstacles that might impede the user experience.',
      },
      {
        column1: 'Content Readability',
        column2:
          'Check that your content is clear, concise, and engaging. Your text should also be readable and formatted correctly.',
      },
      {
        column1: 'Accessibility Check',
        column2:
          'Perform a basic review to ensure your site is accessible to users with disabilities. This includes checking for adequate color contrast ratios, alt text for images, clear headings, etc.',
      },
      {
        column1: 'Call-to-Action (CTA) Functionality',
        column2:
          'Test all of your CTAs (buttons, forms, links) to ensure they work correctly and lead users to the intended actions or pages.',
      },
      {
        column1: 'Form Functionality',
        column2:
          'Verify that your contact forms, sign-up forms, and other interactive elements are working and are easy to complete.',
      },
      {
        column1: 'Tracking and Analytics',
        column2:
          'Ensure your analytics tools are set up to accurately track your users’ behavior, conversions, and any other key metrics.',
      },
    ],
  },
]

const QuickAuditChecklist = () => <ChecklistTable checklistItems={sections} title="Quick Audit Checklist" />

export default QuickAuditChecklist
