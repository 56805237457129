import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { sanitizeVideoUrl, videoPlatforms } from '../../utils/sanitizeVideoUrl'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  ${p =>
    p.isModal &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    border-width: 0px;
  }
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-width: 0px;
`

const VideoIframe = ({ isModal, videoSrc, autoPlay, allowFullScreen }) => {
  const [showVideo, setShowVideo] = useState(false)
  const [videoPlatform, setVideoPlatform] = useState(null)

  useEffect(() => {
    const isYoutube = /youtu/.test(videoSrc)
    const isVimeo = /vimeo\.com/.test(videoSrc)
    if (isVimeo) setVideoPlatform(videoPlatforms.VIMEO)
    if (isYoutube) setVideoPlatform(videoPlatforms.YOUTUBE)
  }, [])

  const wrapperRef = React.createRef()
  const observerOptions = {
    root: null,
    rootMargin: '100px',
    threshold: [0, 0.25, 0.5, 0.75, 1],
  }

  function updateShowVideo(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setShowVideo(true)
      } else {
        setShowVideo(false)
      }
    })
  }

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(updateShowVideo, observerOptions)
      observer.observe(wrapperRef.current)
    } else {
      setShowVideo(true)
    }
    return () => {
      setShowVideo(false)
    }
  }, [])

  if (videoPlatform === videoPlatforms.INVALID) return null

  return (
    <Wrapper isModal={isModal} ref={wrapperRef}>
      {showVideo && (
        <Iframe
          autoPlay={autoPlay}
          isModal={isModal}
          allowFullScreen={allowFullScreen}
          webkitAllowFullScreen={allowFullScreen}
          mozAllowFullScreen={allowFullScreen}
          title="Video"
          src={sanitizeVideoUrl(videoSrc, autoPlay, videoPlatform)}
          className="video-iframe"
        />
      )}
    </Wrapper>
  )
}
export default VideoIframe
