import styled from '@emotion/styled'
import { css } from '@emotion/react'

const Space = styled.div`
  ${p => (p.width || p.width === 0 ? `width: ${p.width.toFixed()}px;` : '')}
  ${p => (p.height || p.height === 0 ? `height: ${p.height.toFixed()}px;` : '')}
  ${p => p.css || ''}
  background-color: ${p => (p.backgroundColor ? p.theme.color[p.backgroundColor] : '')};

  ${p =>
    (p.tabletHeight || p.tabletWidth || p.tabletHeight === 0 || p.tabletWidth === 0) &&
    css`
      @media (max-width: 1050px) {
        height: ${p.tabletHeight || p.tabletHeight === 0 ? `${p.tabletHeight.toFixed()}px` : ''};
        width: ${p.tabletWidth || p.tabletWidth === 0 ? `${p.tabletWidth.toFixed()}px` : ''};
      }
    `}
  ${p =>
    (p.mobileHeight || p.mobileWidth || p.mobileHeight === 0 || p.mobileWidth === 0) &&
    css`
      @media (max-width: 800px) {
        height: ${p.mobileHeight || p.mobileHeight === 0 ? `${p.mobileHeight.toFixed()}px` : ''};
        width: ${p.mobileWidth || p.mobileWidth === 0 ? `${p.mobileWidth.toFixed()}px` : ''};
      }
    `}
`

export default Space
