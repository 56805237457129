import React from 'react'
import styled from '@emotion/styled'
import { useUserLang } from '../../context/UserLangContext'
import generateOpensInANewTabTranslations from '../../data/generateOpensInANewTabTranslations'

const StyledSpan = styled.span`
  position: absolute;
  left: -9000px;
  width: 0;
  height: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  font-size: 14px;
  text-align: center;
  padding: 4px;
  border-radius: 3px;
  background-color: ${p => p.theme.color.white};
  white-space: normal;
`

export const OpenNewTabHiddenSpan = ({ newTabText, isButton, overGraphic, shortOverGraphic }) => {
  const { userLang } = useUserLang()
  const languageData = generateOpensInANewTabTranslations({ userLang })

  return (
    <StyledSpan
      className={`external-link ${isButton ? 'external-button' : ''} ${overGraphic ? 'over-graphic' : ''} ${
        shortOverGraphic ? 'short-over-graphic' : ''
      }`}
    >
      {newTabText ? `${newTabText} ${languageData?.lowercase}` : `${languageData?.uppercase}`}
    </StyledSpan>
  )
}
