/* eslint-disable camelcase */
import React from 'react'
import ChecklistTable from './CheckListTable'

const sections = [
  {
    items: [
      {
        column1: 'Menu Usability',
        column2: 'Ensure your navigation menus are easy-to-use, intuitive, and accessible across your pages.',
      },
      {
        column1: 'Information Architecture',
        column2: 'Confirm your site’s structure is logical and supports smooth user journeys.',
      },
      {
        column1: 'Heatmap Analysis',
        column2: 'Look at heatmaps to see where your users are clicking most frequently and which areas are ignored.',
      },
      {
        column1: 'Session Recordings',
        column2:
          'Observe user sessions to identify navigation issues or barriers, common drop-off points, and areas of confusion.',
      },
      {
        column1: 'Visual Consistency',
        column2:
          'Check for uniformity in your design elements, including fonts, colors, button styles, and typography across all your pages.',
      },
      {
        column1: 'Responsive Design',
        column2:
          'Ensure your website design is consistent and functional across different devices and screen sizes. This includes increasing the mobile-friendliness of your site.',
      },
      {
        column1: 'User-Friendly 404 Pages',
        column2: 'Design your error pages in a way that guides users back to relevant content on your site.',
      },
      {
        column1: 'Error-Handling',
        column2: 'Check that your error pages (such as 500 errors) provide useful information and navigation options.',
      },
    ],
  },
]

const UserExperienceAndDesignAuditChecklist = () => (
  <ChecklistTable checklistItems={sections} title="User Experience and Design Audit Checklist" />
)

export default UserExperienceAndDesignAuditChecklist
