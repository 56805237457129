import React from 'react'
import styled from '@emotion/styled'

const Iframe = styled.iframe`
  width: 100%;
  height: 2300px;
  border: none;

  @media (max-width: 1150px) {
    height: 2450px;
  }

  @media (max-width: 1050px) {
    height: 2300px;
  }

  @media (max-width: 900px) {
    height: 2400px;
  }

  @media (max-width: 820px) {
    height: 2425px;
  }

  @media (max-width: 768px) {
    height: 2475px;
  }

  @media (max-width: 700px) {
    height: 2575px;
  }

  @media (max-width: 425px) {
    height: 3500px;
  }

  @media (max-width: 375px) {
    height: 3900px;
  }

  @media (max-width: 320px) {
    height: 5000px;
  }
`

const AccessibilityStatement = () => (
  <Iframe
    title="Accessibility Statement"
    src="https://portal.audioeye.com/accessibility-statement.html?domain=audioeye.com"
  />
)

export default AccessibilityStatement
