/* eslint-disable react/no-array-index-key */

/* eslint-disable indent */
import React from 'react'
import { graphql, Link } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import idx from 'idx'
import { PrismicRichText } from '@prismicio/react'
import { theme } from '../../styles'
import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'
import Text from '../common/Text'
import Button from '../common/Button'
import getImage from '../../utils/getImage'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  ${p =>
    p.backgroundColor &&
    css`
      background-color: ${theme.color[p.backgroundColor]};
    `};

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 150px;

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    padding: 0px;
    align-items: center;
    flex-direction: column-reverse;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  text-align: left;

  @media (max-width: 1050px) {
    flex-direction: column;
    padding: 32px 0px;
    padding-bottom: 56px;
  }
`

const TextWrapper = styled.div`
  margin-right: 64px;
  p {
    margin-bottom: 0px !important;
  }

  @media (max-width: 1050px) {
    margin-right: 0px;
    text-align: center;
    margin-bottom: 32px;
  }

  @media (max-width: 800px) {
    * {
      text-align: center !important;
    }
  }
`

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  flex-wrap: nowrap;
  padding-top: 48px;

  @media (max-width: 1050px) {
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 0px;
    padding-top: 24px;
    max-width: 500px;
  }
`

const Logo = styled.img`
  height: 100px;
  max-width: 175px;
  object-fit: contain;
  @media (max-width: 1050px) {
    width: 120px;
    margin: 16px;
  }
`

const modifyStyles = bgColor => {
  switch (bgColor) {
    case 'white':
      return {
        bannerColor: 'white',
        fontColor: 'offBlack',
        buttonType: 'primary',
      }
    case 'offWhite':
      return {
        bannerColor: 'offWhite',
        fontColor: 'offBlack',
        buttonType: 'primary',
      }
    case 'purple500':
      return {
        bannerColor: 'purple500',
        fontColor: 'white',
        buttonType: 'tertiary reversed',
      }
    case 'purple900':
      return {
        bannerColor: 'purple900',
        fontColor: 'white',
        buttonType: 'tertiary reversed',
      }
    default:
      return {
        bannerColor: 'white',
        fontColor: 'offBlack',
        buttonType: 'primary',
      }
  }
}

const CTABannerLogos = ({ slice: { primary, items } }) => {
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const buttonText = idx(primary, _ => _.button_text.text)
  const backgroundColor = idx(primary, _ => _.background_color)
  const buttonUrl = idx(primary, _ => _.button_url.url)
  const anchorId = primary.anchor_id

  const { bannerColor, fontColor, buttonType } = modifyStyles(backgroundColor)

  return (
    <BannerWrapper id={anchorId || null}>
      <InfoWrapper>
        {text && (
          <TextWrapper>
            <Text font="heading3" richText color={fontColor}>
              {text}
            </Text>
          </TextWrapper>
        )}
        {buttonUrl && <Button text={buttonText} to={buttonUrl} buttonType={buttonType} />}
      </InfoWrapper>
      {items[0]?.logo?.url && (
        <ImagesWrapper>
          {items.map((item, index) =>
            !idx(item.link, _ => _.type) && !idx(item.link, _ => _.url) ? (
              getImage(item.logo, Logo)
            ) : idx(item.link, _ => _.type) ? (
              <Link
                to={idx(item.link, _ => _.url)}
                style={{ display: 'flex', alignItems: 'center' }}
                key={item.logo.url}
              >
                {getImage(item.logo, Logo)}
              </Link>
            ) : (
              <a
                href={idx(item.link, _ => _.uid) || item.link.url}
                target={idx(item.link, _ => _.type) ? null : '_blank'}
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center' }}
                key={item.logo.url}
              >
                <Logo alt={item.logo.alt} key={`${index}:${item.logo.url}`} src={item.logo.url} />
                {item.link && !idx(item.link, _ => _.type) && <OpenNewTabHiddenSpan overGraphic />}
              </a>
            )
          )}
        </ImagesWrapper>
      )}
      <BG className="bg" backgroundColor={bannerColor} />
    </BannerWrapper>
  )
}

export default CTABannerLogos

export const query = graphql`
  fragment CTABannerLogos on PrismicPageDataBodyCtaBannerLogos {
    ...SliceBase
    items {
      link {
        ...LinkContent
      }
      logo {
        alt
        url
        gatsbyImageData(width: 175)
      }
    }
    primary {
      anchor_id
      background_color
      button_text {
        ...TextContent
      }
      button_url {
        url
        uid
      }
      text {
        ...TextContent
      }
    }
  }
`
