/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import idx from 'idx'
import { useUserLang } from '../../context/UserLangContext'
import generateOpensInANewTabTranslations from '../../data/generateOpensInANewTabTranslations'

const Wrapper = styled.div`
  padding: 80px 0px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.5rem;
    margin-bottom: -1rem;
  }

  p {
    margin-top: 1.75rem;
  }
  ul,
  ol {
    list-style-position: outside;
    margin-top: 1.75rem;
    margin-left: 32px;

    @media (max-width: 800px) {
      margin-left: 24px;
    }
  }

  a[target='_blank'],
  a[target='blank'] {
    :after {
      content: '${p => p.opensInNewTabText || 'Opens in a new tab'}';
      position: absolute;
      left: -9000px;
      width: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
      white-space: nowrap;
      font-size: ${p => p.theme.fonts.size.tinyBody};
      text-align: center;
      padding: 4px;
      border-radius: 3px;
      background-color: ${p => p.theme.color.white};
    }
    &:focus,
    &:hover,
    &:active {
      :after {
        top: 2em;
        left: 1em;
        width: auto;
        color: ${p => p.theme.color.offBlack};
        clip: unset;
        z-index: 9999;
        border: 1px solid ${p => p.theme.color.gray400};
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }
`

const RichTextSlice = ({ slice: { primary } }) => {
  const { userLang } = useUserLang()
  const languageData = generateOpensInANewTabTranslations({ userLang })
  const anchorId = idx(primary, _ => _.anchor_id)
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />

  return (
    <Wrapper id={anchorId || null} opensInNewTabText={languageData?.uppercase}>
      {text}
    </Wrapper>
  )
}

export default RichTextSlice

export const query = graphql`
  fragment RichTextSlice on PrismicPageDataBodyRichText {
    ...SliceBase
    primary {
      anchor_id
      text {
        ...TextContent
      }
    }
  }
`
