/* eslint-disable camelcase */
import React from 'react'
import WCAGChecklist from './WCAGChecklist'

const sections = [
  {
    items: [
      {
        column1: 'Captions',
        column2: 'Live media with audio constraints synchronized captions.',
      },
      {
        column1: 'Audio Description',
        column2: 'Users have access to audio descriptions for non-live video.',
      },
      {
        column1: 'Orientation',
        column2: 'Web page orientation is not limited to portrait or landscape; users can adjust as needed.',
      },
      {
        column1: 'Identify Input Purpose',
        column2: 'Fields that collect certain user information have an ‘autocomplete’ attribute defined.',
      },
      {
        column1: 'Contrast',
        column2:
          'Both text and images have a contrast ratio of at least 4.5:1. Large text has a ratio of at least 3:1.',
      },
      {
        column1: 'Resize Text',
        column2: 'Page elements are still readable and functional when zoomed to 200%.',
      },
      {
        column1: 'Images of Text',
        column2:
          'If a visual representation can be portrayed through text alone, an image alone is not used to present text.',
      },
      {
        column1: 'Reflow',
        column2:
          'Functionality is not lost when content is presented at a width of 320 pixels. Horizontal scrolling is avoided as much as possible.',
      },
      {
        column1: 'Non-Text Contrast (Minimum)',
        column2:
          'A minimum contrast ratio of 3:1 is available for different objects, such as icons, charts, or graphs.',
      },
      {
        column1: 'Text Spacing',
        column2: 'Content or functionality is not lost when users adjust paragraph spacing.',
      },
      {
        column1: 'Content on Hover or Focus',
        column2: 'Content presented in hover or focus can be dismissed without moving the keyboard or printer.',
      },
      {
        column1: 'Multiple Ways',
        column2: 'Users have more than one way to find other web pages on a site.',
      },
      {
        column1: 'Headings and Labels',
        column2: 'Page headings and labels are informative and are not duplicated unless absolutely necessary.',
      },
      {
        column1: 'Focus Visible',
        column2: 'A visible indicator is present for page elements when they receive a keyboard focus.',
      },
      {
        column1: 'Focus Not Observed',
        column2: 'Elements with keyboard focus are entirely visible.',
      },
      {
        column1: 'Dragging Movement',
        column2: 'Actions that require pointer dragging can also be done with a single pointer.',
      },
      {
        column1: 'Target Size',
        column2: 'Pointer sizes are at minimum 24 by 24 pixels unless specified otherwise.',
      },
      {
        column1: 'Language of Parts/Page',
        column2: 'Language of pages is easily identifiable via the ‘lang’ attribute.',
      },
      {
        column1: 'Consistent Navigation',
        column2:
          'Elements that repeat across a web page (e.g., a search bar) are consistently labeled as such across individual web pages.',
      },
      {
        column1: 'Error Suggestion',
        column2:
          'When an error is detected, suggestions are provided for how to remedy the issue in a timely, accessible way.',
      },
      {
        column1: 'Error Prevention',
        column2:
          'If a user changes or deletes financial, legal, or test data, the action can be reversed, confirmed, or verified as needed.',
      },
      {
        column1: 'Excessive Authentication',
        column2:
          'Cognitive functions are not required during authentication processes unless it can be completed in another way, bypassed, or identification of non-text content is provided by the user (e.g., user-provided image). ',
      },
      {
        column1: 'Status Messages',
        column2: 'Status messages are announced to screen readers via live region or ARIA alerts.',
      },
    ],
  },
]

const WCAGChecklistAA = () => <WCAGChecklist checklistItems={sections} title="WCAG Checklist Level AA" />

export default WCAGChecklistAA
