/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import AuthorContent from '../templates/AuthorContent'

const AuthorPage = ({ data: { prismicAuthor }, location }) => {
  if (!prismicAuthor) {
    return null
  }

  return <AuthorContent authorData={prismicAuthor} location={location} />
}

export default withPrismicPreview(AuthorPage)

export const query = graphql`
  query AuthorBySlug($id: String!) {
    prismicAuthor(id: { eq: $id }, lang: { eq: "en-us" }) {
      _previewable
      uid
      url
      id
      data {
        description {
          ...TextContent
        }
        first_name {
          ...TextContent
        }
        last_name {
          ...TextContent
        }
        profile_image {
          alt
          url
          gatsbyImageData(width: 120)
        }
        title {
          ...TextContent
        }
      }
    }
  }
`
