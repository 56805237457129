import styled from '@emotion/styled'
import { theme } from '../../styles'

export const gradients = [
  `linear-gradient(0deg, ${theme.color.green500} 0%, ${theme.color.purple500} 100%);`,
  `linear-gradient(0deg, ${theme.color.purple500} 0%, ${theme.color.green500} 100%);`,
  `linear-gradient(0deg, ${theme.color.green500} 0%, ${theme.color.purple500} 100%);`,
  `linear-gradient(0deg, ${theme.color.purple500} 0%, ${theme.color.green500} 100%);`,
  `linear-gradient(0deg, ${theme.color.green500} 0%, ${theme.color.purple500} 100%);`,
  `linear-gradient(0deg, ${theme.color.purple500} 0%, ${theme.color.green500} 100%);`,
  `linear-gradient(0deg, ${theme.color.green500} 0%, ${theme.color.purple500} 100%);`,
  `linear-gradient(0deg, ${theme.color.purple500} 0%, ${theme.color.green500} 100%);`,
  `linear-gradient(0deg, ${theme.color.green500} 0%, ${theme.color.purple500} 100%);`,
  `linear-gradient(0deg, ${theme.color.purple500} 0%, ${theme.color.green500} 100%);`,
  `linear-gradient(0deg, ${theme.color.green500} 0%, ${theme.color.purple500} 100%);`,
  `linear-gradient(0deg, ${theme.color.purple500} 0%, ${theme.color.green500} 100%);`,
  `linear-gradient(0deg, ${theme.color.green500} 0%, ${theme.color.purple500} 100%);`,
  `linear-gradient(0deg, ${theme.color.purple500} 0%, ${theme.color.green500} 100%);`,
  `linear-gradient(0deg, ${theme.color.green500} 0%, ${theme.color.purple500} 100%);`,
  `linear-gradient(0deg, ${theme.color.purple500} 0%, ${theme.color.green500} 100%);`,
  `linear-gradient(0deg, ${theme.color.green500} 0%, ${theme.color.purple500} 100%);`,
  `linear-gradient(0deg, ${theme.color.purple500} 0%, ${theme.color.green500} 100%);`,
]

export const BottomGradientBar = styled.div`
  height: 4px;
  width: 100%;
  background: ${p => gradients[p.index]};
`

export const CardBottomGradientBar = styled(BottomGradientBar)`
  background: ${p => (p.index < gradients.length ? gradients[p.index] : gradients[p.index - gradients.length])};
  position: absolute;
  bottom: 0px;
  border-radius: 0px 0px 4px 4px;
`

export const LeftGradientBar = styled.div`
  height: calc(100% + 4px);
  width: 4px;
  background: ${p => gradients[p.index]};
  position: absolute;
  left: 0px;
  margin-top: -32px;
`

export const ShortLeftGradientBar = styled(LeftGradientBar)`
  height: 100%;
  top: 0px;
  margin-top: 0px;
`
