// Cookie helpers

const parseCookie = cookieStr =>
  cookieStr
    .split(';')
    .map(c => c.trim().split('=').map(decodeURIComponent))
    .reduce((acc, cookie) => {
      const [key, value] = cookie
      try {
        acc[key] = JSON.parse(value)
      } catch (e) {
        acc[key] = value
      }
      return acc
    }, {})

export const getCookie = key => {
  if (typeof document === 'undefined') {
    return
  }
  const cookies = parseCookie(document.cookie)
  return cookies[key]
}
