export const WCAG_SUCCESS_CRITERIA = {
  '1.1.1': {
    description:
      'All non-text content that is presented to the user has a text alternative that serves the equivalent purpose, with certain exceptions: <ul><li>Controls and User Input: Provide a name that describes the input/control\'s purpose. Refer to Guideline 4.1 for additional requirements.</li><li>Time-Based Media: Provide text alternatives to describe and identify the media. Refer to Guideline 1.2 for additional requirements.</li><li>Test: Provide text alternatives that identify and describe any exercise that would be invalid if presented in text.</li><li>Sensory: Provide text alternatives that identify and describe any specific sensory experiences.</li><li>CAPTCHA: Provide text alternatives that identify and describe the CAPTCHA, and accommodate different disabilities by providing alternative forms of CAPTCHA that use different types of output modes for sensory perception.</li><li>Decoration, Formatting, Invisible: Implement any element that is purely decorative, is a "spacer" or format helper, or is invisible, in a way that it can be ignored by assistive technology.</li></ul>',
    level: 'A',
    name: 'Non-text Content',
    resource: 'https://www.w3.org/TR/WCAG21/#non-text-content',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '1.1.1',
    version_added: '2.0',
  },
  '1.2.1': {
    description:
      'Prerecorded media that is audio-only or video-only must have an appropriate media alternative, unless it is itself a media alternative for text and clearly marked as such. For prerecorded audio, provide an alternative that presents equivalent information, such as a text transcript. For prerecorded video, provide either an alternative or an audio track that presents equivalent information.',
    level: 'A',
    name: 'Audio-only and video-only (Pre-recorded)',
    resource: 'https://www.w3.org/TR/WCAG21/#audio-only-and-video-only-pre-recorded',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '1.2.1',
    version_added: '2.0',
  },
  '1.2.2': {
    description:
      'Captions are provided for all prerecorded audio content in synchronized media, except when the media is a media alternative for text and is clearly labeled as such.',
    level: 'A',
    name: 'Captions (Pre-recorded)',
    resource: 'https://www.w3.org/TR/WCAG21/#captions-pre-recorded',
    responsibleParty: 'Content/Copywriter, Front-End Developer',
    successCriteria: '1.2.2',
    version_added: '2.0',
  },
  '1.2.3': {
    description:
      'An alternative for time-based media or audio description of the prerecorded video content is provided for synchronized media, except when the media is a media alternative for text and is clearly labeled as such.',
    level: 'A',
    name: 'Audio Description or Media Alternative (Prerecorded)',
    resource: 'https://www.w3.org/TR/WCAG21/#audio-description-or-media-alternative-prerecorded',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '1.2.3',
    version_added: '2.0',
  },
  '1.2.4': {
    description: 'Captions are provided for all live audio content in synchronized media.',
    level: 'AA',
    name: 'Captions (Live)',
    resource: 'https://www.w3.org/TR/WCAG21/#captions-live',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '1.2.4',
    version_added: '2.0',
  },
  '1.2.5': {
    description: 'Audio description is provided for all prerecorded video content in synchronized media.',
    level: 'AA',
    name: 'Audio Description (Prerecorded)',
    resource: 'https://www.w3.org/TR/WCAG21/#audio-description-prerecorded',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '1.2.5',
    version_added: '2.0',
  },
  '1.2.6': {
    description: 'Sign language interpretation is provided for all prerecorded audio content in synchronized media.',
    level: 'AAA',
    name: 'Sign Language (Prerecorded)',
    resource: 'https://www.w3.org/TR/WCAG21/#sign-language-prerecorded',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '1.2.6',
    version_added: '2.0',
  },
  '1.2.7': {
    description:
      'Where pauses in foreground audio are insufficient to allow audio descriptions to convey the sense of the video, extended audio description is provided for all prerecorded video content in synchronized media.',
    level: 'AAA',
    name: 'Extended Audio Description (Prerecorded)',
    resource: 'https://www.w3.org/TR/WCAG21/#extended-audio-description-prerecorded',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '1.2.7',
    version_added: '2.0',
  },
  '1.2.8': {
    description:
      'An alternative for time-based media is provided for all prerecorded synchronized media and for all prerecorded video-only media.',
    level: 'AAA',
    name: 'Media Alternative (Prerecorded)',
    resource: 'https://www.w3.org/TR/WCAG21/#media-alternative-prerecorded',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '1.2.8',
    version_added: '2.0',
  },
  '1.2.9': {
    description:
      'An alternative for time-based media that presents equivalent information for live audio-only content is provided.',
    level: 'AAA',
    name: 'Audio-only (Live)',
    resource: 'https://www.w3.org/TR/WCAG21/#audio-only-live',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '1.2.9',
    version_added: '2.0',
  },
  '1.3.1': {
    description:
      'Information, structure, and relationships conveyed through presentation can be programmatically determined or are available in text.',
    level: 'A',
    name: 'Info and Relationships',
    resource: 'https://www.w3.org/TR/WCAG21/#info-and-relationships',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '1.3.1',
    version_added: '2.0',
  },
  '1.3.2': {
    description:
      'When the sequence in which content is presented affects its meaning, a correct reading sequence can be programmatically determined.',
    level: 'A',
    name: 'Meaningful Sequence',
    resource: 'https://www.w3.org/TR/WCAG21/#meaningful-sequence',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '1.3.2',
    version_added: '2.0',
  },
  '1.3.3': {
    description:
      'Instructions provided for understanding and operating content do not rely solely on sensory characteristics of components such as shape, size, visual location, orientation, or sound. Refer to Guideline 1.4 for requirements related to color.',
    level: 'A',
    name: 'Sensory Characteristics',
    resource: 'https://www.w3.org/TR/WCAG21/#sensory-characteristics',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '1.3.3',
    version_added: '2.0',
  },
  '1.3.4': {
    description:
      'Content is not locked to a specific display orientation, and functionality of the content is operable in all display orientations, except where display orientation is essential for use of the content.',
    level: 'AA',
    name: 'Orientation',
    resource: 'https://www.w3.org/TR/WCAG21/#orientation',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '1.3.4',
    version_added: '2.1',
  },
  '1.3.5': {
    description:
      'The meaning of each input field collecting information about the user can be programmatically determined when: The input field has a meaning that maps to the HTML 5.2 Autofill field names; and The content is implemented using technologies with support for identifying the expected meaning for form input data.',
    level: 'AA',
    name: 'Identify Common Purpose',
    resource: 'https://www.w3.org/TR/WCAG21/#identify-common-purpose',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '1.3.5',
    version_added: '2.1',
  },
  '1.3.6': {
    description:
      'In content implemented using markup languages, the purpose of User Interface Components, icons, and regions can be programmatically determined.',
    level: 'AAA',
    name: 'Identify Purpose',
    resource: 'https://www.w3.org/TR/WCAG21/#identify-purpose',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '1.3.6',
    version_added: '2.1',
  },
  '1.4.1': {
    description:
      'Color is not used as the only visual means of conveying information, indicating an action, prompting a response, or distinguishing a visual element. Note: This success criterion addresses color perception specifically. Other forms of perception are covered in Guideline 1.3 including programmatic access to color and other visual presentation coding.',
    level: 'A',
    name: 'Use of Color',
    resource: 'https://www.w3.org/TR/WCAG21/#use-of-color',
    responsibleParty: 'Designer',
    successCriteria: '1.4.1',
    version_added: '2.0',
  },
  '1.4.2': {
    description:
      'If any audio on a Web page plays automatically for more than 3 seconds, either a mechanism is available to pause or stop the audio, or a mechanism is available to control audio volume independently from the overall system volume level.',
    level: 'A',
    name: 'Audio Control',
    resource: 'https://www.w3.org/TR/WCAG21/#audio-control',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '1.4.2',
    version_added: '2.0',
  },
  '1.4.3': {
    description:
      'Images of text and styles applied to HTML text must have a contrast ratio of at least 4.5:1, with certain exceptions: large-scale text in any format may have a contrast ratio of 3:1, and there is no contrast requirement for any text that is purely decorative, is part of an inactive user interface component or otherwise invisible, or is part of a picture that contains significant other visual content. Text that is part of a logo or brand name also has no minimum contrast requirement.',
    level: 'AA',
    name: 'Contrast (Minimum)',
    resource: 'https://www.w3.org/TR/WCAG21/#contrast-minimum',
    responsibleParty: 'Designer',
    successCriteria: '1.4.3',
    version_added: '2.0',
  },
  '1.4.4': {
    description:
      'Except for captions and images of text, text can be resized without assistive technology up to 200 percent without loss of content or functionality.',
    level: 'AA',
    name: 'Resize text',
    resource: 'https://www.w3.org/TR/WCAG21/#resize-text',
    responsibleParty: 'Front-End Developer',
    successCriteria: '1.4.4',
    version_added: '2.0',
  },
  '1.4.5': {
    description:
      "Wherever possible, use text to convey information and style it to fit a visual presentation rather than use images of text. Exceptions include images of text that can be visually customized to the user's requirements and particular presentation of text (e.g. logotypes or text that is part of a brand name) is essential to the information being conveyed.",
    level: 'AA',
    name: 'Images of Text',
    resource: 'https://www.w3.org/TR/WCAG21/#images-of-text',
    responsibleParty: 'Front-End Developer',
    successCriteria: '1.4.5',
    version_added: '2.0',
  },
  '1.4.6': {
    description:
      'The visual presentation of text and images of text has a contrast ratio of at least 7:1, with certain exceptions: large-scale text and images of large-scale text may have a contrast ratio of at least 4.5:1, and there is no contrast requirement for any text that is purely decorative, is part of an inactive user interface component or otherwise invisible, or is part of a picture that contains significant other visual content. Text that is part of a logo or brand name also has no minimum contrast requirement.',
    level: 'AAA',
    name: 'Contrast (Enhanced)',
    resource: 'https://www.w3.org/TR/WCAG21/#contrast-enhanced',
    responsibleParty: 'Designer',
    successCriteria: '1.4.6',
    version_added: '2.0',
  },
  '1.4.7': {
    description:
      'For prerecorded audio-only content that (1) contains primarily speech in the foreground, (2) is not an audio CAPTCHA or audio logo, and (3) is not vocalization intended to be primarily musical expression such as singing or rapping, at least one of the following is true: the audio does not contain background sounds, the background sounds can be turned off, or the background sounds are at least 20 decibels lower than the foreground speech content, with the exception of occasional sounds that last for only one or two seconds.',
    level: 'AAA',
    name: 'Low or No Background Audio',
    resource: 'https://www.w3.org/TR/WCAG21/#low-or-no-background-audio',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '1.4.7',
    version_added: '2.0',
  },
  '1.4.8': {
    description:
      'For the visual presentation of blocks of text, a mechanism is available to achieve the following: foreground and background colors can be selected by the user, width is no more than 80 characters or glyphs (40 if CJK), text is not justified (aligned to both the left and the right margins), line spacing (leading) is at least space-and-a-half within paragraphs, and paragraph spacing is at least 1.5 times larger than the line spacing, and text can be resized without assistive technology up to 200 percent in a way that does not require the user to scroll horizontally to read a line of text on a full-screen window.',
    level: 'AAA',
    name: 'Visual Presentation',
    resource: 'https://www.w3.org/TR/WCAG21/#visual-presentation',
    responsibleParty: 'Designer, Front-End Developer',
    successCriteria: '1.4.8',
    version_added: '2.0',
  },
  '1.4.9': {
    description:
      'Images of text are only used for pure decoration or where a particular presentation of text is essential to the information being conveyed. Logotypes (text that is part of a logo or brand name) are considered essential.',
    level: 'AAA',
    name: 'Images of Text (No Exception)',
    resource: 'https://www.w3.org/TR/WCAG21/#images-of-text-no-exception',
    responsibleParty: 'Front-End Developer',
    successCriteria: '1.4.9',
    version_added: '2.0',
  },
  '1.4.10': {
    description:
      'Content can be zoomed to an equivalent width of 320 CSS pixels without loss of content or functionality, and without requiring scrolling on more than one axis, except for parts of the content which require two-dimensional layout for usage or meaning.',
    level: 'AA',
    name: 'Reflow',
    resource: 'https://www.w3.org/TR/WCAG21/#reflow',
    responsibleParty: 'Designer, Front-End Developer',
    successCriteria: '1.4.10',
    version_added: '2.1',
  },
  '1.4.11': {
    description:
      'The visual presentation of the following have a contrast ratio of at least 3:1 against adjacent color(s): <ul><li>User Interface Components. Visual information used to indicate states and boundaries of user interface components, except for inactive components or where the appearance of the component is determined by the user agent and not modified by the author;</li><li>Graphical Objects. Parts of graphics required to understand the content, except when a particular presentation of graphics is essential to the information being conveyed.</li></ul>',
    level: 'AA',
    name: 'Non-text Contrast',
    resource: 'https://www.w3.org/TR/WCAG21/#non-text-contrast',
    responsibleParty: 'Designer, Front-End Developer',
    successCriteria: '1.4.11',
    version_added: '2.1',
  },
  '1.4.12': {
    description:
      'If the technologies being used allow the user agent to adapt style properties of text, then no loss of essential content or functionality occurs by adapting all of the following:<ul><li>Line height (line spacing) to at least 1.5 times the font size;</li><li>Spacing underneath paragraphs to at least 2 times the font size;</li><li>Letter spacing (tracking) to at least 0.12 times the font size;</li><li>Word spacing to at least 0.16 times the font size.</li></ul>',
    level: 'AA',
    name: 'Text Spacing',
    resource: 'https://www.w3.org/TR/WCAG21/#text-spacing',
    responsibleParty: 'Designer, Front-End Developer',
    successCriteria: '1.4.12',
    version_added: '2.1',
  },
  '1.4.13': {
    description:
      'When a user interface component which receives keyboard focus or pointer hover causes content to become visible, the following are true:<ul><li>Visible Trigger: Either the additional content does not obscure any essential content within the triggering user interface component, or the additional content can be closed or repositioned by the user;</li><li>Hover: If the additional content is triggered via pointer hover, then that content remains visible when the pointer is moved over it;</li><li>Focus: The additional content remains visible while the triggering user interface component has keyboard focus, unless the user dismisses the additional content.</li></ul>Exception: The visual presentation of the content is controlled by the user agent and is not modified by the author.',
    level: 'AA',
    name: 'Content on Hover or Focus',
    resource: 'https://www.w3.org/TR/WCAG21/#content-on-hover-or-focus',
    responsibleParty: 'Designer, Front-End Developer',
    successCriteria: '1.4.13',
    version_added: '2.1',
  },
  '2.1.1': {
    description:
      "All functionality of the content is operable through a keyboard interface without requiring specific timings for individual keystrokes, except where the underlying function requires input that depends on the path of the user's movement and not just the endpoints.",
    level: 'A',
    name: 'Keyboard',
    resource: 'https://www.w3.org/TR/WCAG21/#keyboard',
    responsibleParty: 'Front-End Developer',
    successCriteria: '2.1.1',
    version_added: '2.0',
  },
  '2.1.2': {
    description:
      'If keyboard focus can be moved to a component of the page using a keyboard interface, then focus can be moved away from that component using only a keyboard interface, and, if it requires more than unmodified arrow or tab keys or other standard exit methods, the user is advised of the method for moving focus away.',
    level: 'A',
    name: 'No Keyboard Trap',
    resource: 'https://www.w3.org/TR/WCAG21/#no-keyboard-trap',
    responsibleParty: 'Front-End Developer',
    successCriteria: '2.1.2',
    version_added: '2.0',
  },
  '2.1.3': {
    description:
      'All functionality of the content is operable through a keyboard interface without requiring specific timings for individual keystrokes.',
    level: 'AAA',
    name: 'Keyboard (No Exception)',
    resource: 'https://www.w3.org/TR/WCAG21/#keyboard-no-exception',
    responsibleParty: 'Front-End Developer',
    successCriteria: '2.1.3',
    version_added: '2.0',
  },
  '2.1.4': {
    description:
      'If a keyboard shortcut consisting entirely of one or more character keys is implemented by the content, then a mechanism is available to turn it off or to remap it to a shortcut that uses at least one non-character key.',
    level: 'A',
    name: 'Character Key Shortcuts',
    resource: 'https://www.w3.org/TR/WCAG21/#character-key-shortcuts',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.1.4',
    version_added: '2.1',
  },
  '2.2.1': {
    description:
      'For each time limit that is set by the content, at least one of the following is true:<ul><li>Turn off: The user is allowed to turn off the time limit before encountering it; or</li><li>Adjust: The user is allowed to adjust the time limit before encountering it over a wide range that is at least ten times the length of the default setting; or</li><li>Extend: The user is warned before time expires and given at least 20 seconds to extend the time limit with a simple action (for example, "press the space bar"), and the user is allowed to extend the time limit at least ten times; or</li><li>Real-time Exception: The time limit is a required part of a real-time event (for example, an auction), and no alternative to the time limit is possible; or</li><li>Essential Exception: The time limit is essential and extending it would invalidate the activity; or</li><li>20 Hour Exception: The time limit is longer than 20 hours.</li></ul>',
    level: 'A',
    name: 'Timing Adjustable',
    resource: 'https://www.w3.org/TR/WCAG21/#timing-adjustable',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.2.1',
    version_added: '2.0',
  },
  '2.2.2': {
    description:
      'For moving, blinking, scrolling, or auto-updating information, all of the following are true:<ul><li>Moving, blinking, scrolling: For any moving, blinking or scrolling information that (1) starts automatically, (2) lasts more than five seconds, and (3) is presented in parallel with other content, there is a mechanism for the user to pause, stop, or hide it unless the movement, blinking, or scrolling is part of an activity where it is essential; and</li><li>Auto-updating: For any auto-updating information that (1) starts automatically and (2) is presented in parallel with other content, there is a mechanism for the user to pause, stop, or hide it or to control the frequency of the update unless the auto-updating is part of an activity where it is essential.</li></ul>',
    level: 'A',
    name: 'Pause, Stop, Hide',
    resource: 'https://www.w3.org/TR/WCAG21/#pause-stop-hide',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.2.2',
    version_added: '2.0',
  },
  '2.2.3': {
    description:
      'Timing is not an essential part of the event or activity presented by the content, except for non-interactive synchronized media and real-time events.',
    level: 'AAA',
    name: 'No Timing',
    resource: 'https://www.w3.org/TR/WCAG21/#no-timing',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.2.3',
    version_added: '2.0',
  },
  '2.2.4': {
    description:
      'Interruptions can be postponed or suppressed by the user, except interruptions involving an emergency.',
    level: 'AAA',
    name: 'Interruptions',
    resource: 'https://www.w3.org/TR/WCAG21/#interruptions',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.2.4',
    version_added: '2.0',
  },
  '2.2.5': {
    description:
      'When an authenticated session expires, the user can continue the activity without loss of data after re-authenticating.',
    level: 'AAA',
    name: 'Re-authenticating',
    resource: 'https://www.w3.org/TR/WCAG21/#re-authenticating',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.2.5',
    version_added: '2.0',
  },
  '2.2.6': {
    description:
      'Where data can be lost due to user inactivity, users are warned at the start of a process about the length of inactivity that generates the timeout, unless the data is preserved for a minimum of 24 hours of user inactivity.',
    level: 'AAA',
    name: 'Timeouts',
    resource: 'https://www.w3.org/TR/WCAG21/#timeouts',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.2.6',
    version_added: '2.1',
  },
  '2.3.1': {
    description:
      'Web pages do not contain anything that flashes more than three times in any one second period, or the flash is below the general flash and red flash thresholds.',
    level: 'A',
    name: 'Three Flashes or Below Threshold',
    resource: 'https://www.w3.org/TR/WCAG21/#three-flashes-or-below-threshold',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.3.1',
    version_added: '2.0',
  },
  '2.3.2': {
    description: 'Web pages do not contain anything that flashes more than three times in any one second period.',
    level: 'AAA',
    name: 'Three Flashes',
    resource: 'https://www.w3.org/TR/WCAG21/#three-flashes',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.3.2',
    version_added: '2.0',
  },
  '2.3.3': {
    description:
      'For non-essential animations triggered by a user action, there is a mechanism to disable the animations yet still perform the action.',
    level: 'AAA',
    name: 'Animation from Interactions',
    resource: 'https://www.w3.org/TR/WCAG21/#animation-from-interactions',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.3.3',
    version_added: '2.1',
  },
  '2.4.1': {
    description: 'A mechanism is available to bypass blocks of content that are repeated on multiple Web pages.',
    level: 'A',
    name: 'Bypass Blocks',
    resource: 'https://www.w3.org/TR/WCAG21/#bypass-blocks',
    responsibleParty: 'Front-End Developer',
    successCriteria: '2.4.1',
    version_added: '2.0',
  },
  '2.4.2': {
    description: 'Web pages have titles that describe topic or purpose.',
    level: 'A',
    name: 'Page Titled',
    resource: 'https://www.w3.org/TR/WCAG21/#page-titled',
    responsibleParty: 'Front-End Developer',
    successCriteria: '2.4.2',
    version_added: '2.0',
  },
  '2.4.3': {
    description:
      'If a Web page can be navigated sequentially and the navigation sequences affect meaning or operation, focusable components receive focus in an order that preserves meaning and operability.',
    level: 'A',
    name: 'Focus Order',
    resource: 'https://www.w3.org/TR/WCAG21/#focus-order',
    responsibleParty: 'Front-End Developer',
    successCriteria: '2.4.3',
    version_added: '2.0',
  },
  '2.4.4': {
    description:
      'The purpose of each link can be determined from the link text alone or from the link text together with its programmatically determined link context, except where the purpose of the link would be ambiguous to users in general.',
    level: 'A',
    name: 'Link Purpose (In Context)',
    resource: 'https://www.w3.org/TR/WCAG21/#link-purpose-in-context',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.4.4',
    version_added: '2.0',
  },
  '2.4.5': {
    description:
      'More than one way is available to locate a Web page within a set of Web pages except where the Web Page is the result of, or a step in, a process.',
    level: 'AA',
    name: 'Multiple Ways',
    resource: 'https://www.w3.org/TR/WCAG21/#multiple-ways',
    responsibleParty: 'Developer, Front-End Developer, User Experience',
    successCriteria: '2.4.5',
    version_added: '2.0',
  },
  '2.4.6': {
    description: 'Headings and labels describe topic or purpose. ',
    level: 'AA',
    name: 'Headings and Labels',
    resource: 'https://www.w3.org/TR/WCAG21/#headings-and-labels',
    responsibleParty: 'Front-End Developer',
    successCriteria: '2.4.6',
    version_added: '2.0',
  },
  '2.4.7': {
    description:
      'Any keyboard operable user interface has a mode of operation where the keyboard focus indicator is visible.',
    level: 'AA',
    name: 'Focus Visible',
    resource: 'https://www.w3.org/TR/WCAG21/#focus-visible',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.4.7',
    version_added: '2.0',
  },
  '2.4.8': {
    description: "Information about the user's location within a set of Web pages is available.",
    level: 'AAA',
    name: 'Location',
    resource: 'https://www.w3.org/TR/WCAG21/#location',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '2.4.8',
    version_added: '2.0',
  },
  '2.4.9': {
    description:
      'A mechanism is available to allow the purpose of each link to be identified from link text alone, except where the purpose of the link would be ambiguous to users in general.',
    level: 'AAA',
    name: 'Link Purpose (Link Only)',
    resource: 'https://www.w3.org/TR/WCAG21/#link-purpose-link-only',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '2.4.9',
    version_added: '2.0',
  },
  '2.4.10': {
    description: 'Section headings are used to organize the content. ',
    level: 'AAA',
    name: 'Section Headings',
    resource: 'https://www.w3.org/TR/WCAG21/#section-headings',
    responsibleParty: 'Content/Copywriter, Front-End Developer, User Experience',
    successCriteria: '2.4.10',
    version_added: '2.0',
  },
  '2.4.11': {
    description:
      'When a user interface component receives keyboard focus, the component is not entirely hidden due to author-created content.',
    name: 'Focus Not Obscured (Minimum)',
    level: 'AA',
    resource: 'https://www.w3.org/TR/WCAG22/#focus-not-obscured-minimum',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.4.11',
    version_added: '2.2',
  },
  '2.4.12': {
    description:
      'When a user interface component receives keyboard focus, no part of the component is hidden by author-created content.',
    name: 'Focus Not Obscured (Enhanced)',
    level: 'AAA',
    resource: 'https://www.w3.org/TR/WCAG22/#focus-not-obscured-enhanced',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.4.12',
    version_added: '2.2',
  },
  '2.4.13': {
    description: `When the keyboard focus indicator is visible, an area of the focus indicator meets all the following:
        <ul>
            <li>is at least as large as the area of a 2 CSS pixel thick perimeter of the unfocused component or sub-component, and</li>
            <li>has a contrast ratio of at least 3:1 between the same pixels in the focused and unfocused states.</li>
        </ul>
        Exceptions:
        <ul>
            <li>The focus indicator is determined by the user agent and cannot be adjusted by the author, or</li>
            <li>hThe focus indicator and the indicator's background color are not modified by the author.</li>
        </ul>`,
    name: 'Focus Appearance',
    level: 'AAA',
    resource: 'https://www.w3.org/TR/WCAG22/#focus-appearance',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.4.13',
    version_added: '2.2',
  },
  '2.5.1': {
    description:
      'All functionality can be operated with a single untimed pointer gesture unless a multipoint or timed gesture is essential.',
    level: 'A',
    name: 'Pointer Gestures',
    resource: 'https://www.w3.org/TR/WCAG21/#pointer-gestures',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.5.1',
    version_added: '2.1',
  },
  '2.5.2': {
    description:
      'For functionality that can be operated using a single pointer, at least one of the following is true: <ul><li>No Down-Event. The down-event of the pointer is not used to execute any part of the function;</li><li>Abort or Undo. Completion of the function is on the up-event, and a mechanism is available to abort the function before completion or undo the function after completion;</li><li>Up Reversal. The up-event reverses any outcome of the preceding down-event;</li><li>Essential. Completing the function on the down-event is essential.</li></ul>',
    level: 'A',
    name: 'Pointer Cancellation',
    resource: 'https://www.w3.org/TR/WCAG21/#pointer-cancellation',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.5.2',
    version_added: '2.1',
  },
  '2.5.3': {
    description:
      'Where an active control has a visible label, the accessible name for the control includes the text string used for its visible label.',
    level: 'A',
    name: 'Label in Name',
    resource: 'https://www.w3.org/TR/WCAG21/#label-in-name',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.5.3',
    version_added: '2.1',
  },
  '2.5.4': {
    description:
      'All functionality of the content can be operated without requiring specific device sensor information, unless the device sensor is essential for the function and not using it would invalidate the activity.',
    level: 'A',
    name: 'Motion Actuation',
    resource: 'https://www.w3.org/TR/WCAG21/#motion-actuation',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.5.4',
    version_added: '2.1',
  },
  '2.5.5': {
    description:
      'The size of the target for pointer inputs is at least 44 by 44 CSS pixels except when:<ul><li>Customizable: A mechanism is available to change the size of the target independent of the level of page magnification;</li><li>Equivalent: The target is available through an equivalent link or control on the same page that is at least 44 by 44 CSS pixels;</li><li>Essential: A particular presentation of target is essential to the information being conveyed;</li><li>In-Page: The target is a text link where the destination is on the same page;</li><li>Inline: The target is in a block of text;</li><li>Grouped: Targets in groups of more than five have at least one dimension that is 44 pixels and the other is at least 22 pixels;</li><li>User Agent Control: The appearance of the target is determined by the user agent and is not modified by the author.</li></ul>',
    level: 'AAA',
    name: 'Target Size',
    resource: 'https://www.w3.org/TR/WCAG21/#target-size',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.5.5',
    version_added: '2.1',
  },
  '2.5.6': {
    description:
      'Web content does not restrict use of input modalities available on a platform except where the restriction is essential, required to ensure the security of the content, or respect user settings.',
    level: 'AAA',
    name: 'Concurrent Input Mechanisms',
    resource: 'https://www.w3.org/TR/WCAG21/#concurrent-input-mechanisms',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '2.5.6',
    version_added: '2.1',
  },
  '2.5.7': {
    description:
      'All functionality that uses a dragging movement for operation can be achieved by a single pointer without dragging, unless dragging is essential or the functionality is determined by the user agent and not modified by the author.',
    level: 'AA',
    name: 'Dragging Movements',
    resource: 'https://www.w3.org/TR/WCAG22/#dragging-movements',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '2.5.7',
    version_added: '2.2',
  },
  '2.5.8': {
    description: `
    The size of the target for pointer inputs is at least 24 by 24 CSS pixels, except where:
    <ul>
        <li><em>Spacing:</em> Undersized targets (those less than 24 by 24 CSS pixels) are positioned so that if a 24 CSS pixel diameter circle is centered on the bounding box of each, the circles do not intersect another target or the circle for another undersized target;</li>
        <li><em>Equivalent:</em> The function can be achieved through a different control on the same page that meets this criterion;</li>
        <li><em>Inline:</em> The target is in a sentence or its size is otherwise constrained by the line-height of non-target text;</li>
        <li><em>User agent control:</em> The size of the target is determined by the user agent and is not modified by the author;</li>
        <li><em>Essential:</em> A particular presentation of the target is essential or is legally required for the information being conveyed.</li>
    </ul>
    `,
    level: 'AA',
    name: 'Target Size (Minimum)',
    resource: 'https://www.w3.org/TR/WCAG22/#target-size-minimum',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '2.5.8',
    version_added: '2.2',
  },
  '3.1.1': {
    description: 'The default human language of each Web page can be programmatically determined.',
    level: 'A',
    name: 'Language of Page',
    resource: 'https://www.w3.org/TR/WCAG21/#language-of-page',
    responsibleParty: 'Front-End Developer',
    successCriteria: '3.1.1',
    version_added: '2.0',
  },
  '3.1.2': {
    description:
      'The human language of each passage or phrase in the content can be programmatically determined except for proper names, technical terms, words of indeterminate language, and words or phrases that have become part of the vernacular of the immediately surrounding text.',
    level: 'A',
    name: 'Language of Parts',
    resource: 'https://www.w3.org/TR/WCAG21/#language-of-parts',
    responsibleParty: 'Front-End Developer',
    successCriteria: '3.1.2',
    version_added: '2.0',
  },
  '3.1.3': {
    description:
      'A mechanism is available for identifying specific definitions of words or phrases used in an unusual or restricted way, including idioms and jargon.',
    level: 'AAA',
    name: 'Unusual Words',
    resource: 'https://www.w3.org/TR/WCAG21/#unusual-words',
    responsibleParty: 'Content/Copywriter, Designer, Front-End Developer, User Experience',
    successCriteria: '3.1.3',
    version_added: '2.0',
  },
  '3.1.4': {
    description: 'A mechanism for identifying the expanded form or meaning of abbreviations is available.',
    level: 'AAA',
    name: 'Abbreviations',
    resource: 'https://www.w3.org/TR/WCAG21/#abbreviations',
    responsibleParty: 'Content/Copywriter, Designer, Front-End Developer, User Experience',
    successCriteria: '3.1.4',
    version_added: '2.0',
  },
  '3.1.5': {
    description:
      'When text requires reading ability more advanced than the lower secondary education level after removal of proper names and titles, supplemental content, or a version that does not require reading ability more advanced than the lower secondary education level, is available. ',
    level: 'AAA',
    name: 'Reading Level',
    resource: 'https://www.w3.org/TR/WCAG21/#reading-level',
    responsibleParty: 'Content/Copywriter, Designer, Front-End Developer, User Experience',
    successCriteria: '3.1.5',
    version_added: '2.0',
  },
  '3.1.6': {
    description:
      'A mechanism is available for identifying specific pronunciation of words where meaning of the words, in context, is ambiguous without knowing the pronunciation.',
    level: 'AAA',
    name: 'Pronunciation',
    resource: 'https://www.w3.org/TR/WCAG21/#pronunciation',
    responsibleParty: 'Content/Copywriter, Designer, Front-End Developer, User Experience',
    successCriteria: '3.1.6',
    version_added: '2.0',
  },
  '3.2.1': {
    description: 'When any component receives focus, it does not initiate a change of context.',
    level: 'A',
    name: 'On Focus',
    resource: 'https://www.w3.org/TR/WCAG21/#on-focus',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '3.2.1',
    version_added: '2.0',
  },
  '3.2.2': {
    description:
      'Changing the setting of any user interface component does not automatically cause a change of context unless the user has been advised of the behavior before using the component.',
    level: 'A',
    name: 'On Input',
    resource: 'https://www.w3.org/TR/WCAG21/#on-input',
    responsibleParty: 'Front-End Developer, User Experience',
    successCriteria: '3.2.2',
    version_added: '2.0',
  },
  '3.2.3': {
    description:
      'Navigational mechanisms that are repeated on multiple Web pages within a set of Web pages occur in the same relative order each time they are repeated, unless a change is initiated by the user.',
    level: 'AA',
    name: 'Consistent Navigation',
    resource: 'https://www.w3.org/TR/WCAG21/#consistent-navigation',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.2.3',
    version_added: '2.0',
  },
  '3.2.4': {
    description: 'Components that have the same functionality within a set of Web pages are identified consistently.',
    level: 'AA',
    name: 'Consistent Identification',
    resource: 'https://www.w3.org/TR/WCAG21/#consistent-identification',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.2.4',
    version_added: '2.0',
  },
  '3.2.5': {
    description:
      'Changes of context are initiated only by user request or a mechanism is available to turn off such changes.',
    level: 'AAA',
    name: 'Change on Request',
    resource: 'https://www.w3.org/TR/WCAG21/#change-on-request',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.2.5',
    version_added: '2.0',
  },
  '3.2.6': {
    description: `If a Web page contains any of the following help mechanisms, and those mechanisms are repeated on multiple Web pages within a set of Web pages, they occur in the same order relative to other page content, unless a change is initiated by the user:
    <ul>
        <li>Human contact details;</li>
        <li>Human contact mechanism;</li>
        <li>Self-help option;</li>
        <li>A fully automated contact mechanism.</li>
    </ul>`,
    level: 'A',
    name: 'Consistent Help',
    resource: 'https://www.w3.org/TR/WCAG22/#consistent-help',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.2.6',
    version_added: '2.2',
  },
  '3.3.1': {
    description:
      'If an input error is automatically detected, the item that is in error is identified and the error is described to the user in text.',
    level: 'A',
    name: 'Error Identification',
    resource: 'https://www.w3.org/TR/WCAG21/#error-identification',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.3.1',
    version_added: '2.0',
  },
  '3.3.2': {
    description: 'Labels or instructions are provided when content requires user input. ',
    level: 'A',
    name: 'Labels or Instructions',
    resource: 'https://www.w3.org/TR/WCAG21/#labels-or-instructions',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.3.2',
    version_added: '2.0',
  },
  '3.3.3': {
    description:
      'If an input error is automatically detected and suggestions for correction are known, then the suggestions are provided to the user, unless it would jeopardize the security or purpose of the content.',
    level: 'AA',
    name: 'Error Suggestion',
    resource: 'https://www.w3.org/TR/WCAG21/#error-suggestion',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.3.3',
    version_added: '2.0',
  },
  '3.3.4': {
    description:
      'For Web pages that cause legal commitments or financial transactions for the user to occur, that modify or delete user-controllable data in data storage systems, or that submit user test responses, at least one of the following is true: Submissions are reversible, data entered by the user is checked for input errors and the user is provided an opportunity to correct them, or a mechanism is available for reviewing, confirming, and correcting information before finalizing the submission.',
    level: 'AA',
    name: 'Error Prevention (Legal, Financial, Data)',
    resource: 'https://www.w3.org/TR/WCAG21/#error-prevention-legal-financial-data',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.3.4',
    version_added: '2.0',
  },
  '3.3.5': {
    description: 'Context-sensitive help is available.',
    level: 'AAA',
    name: 'Help',
    resource: 'https://www.w3.org/TR/WCAG21/#help',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.3.5',
    version_added: '2.0',
  },
  '3.3.6': {
    description:
      'For Web pages that require the user to submit information, at least one of the following is true: Submissions are reversible, data entered by the user is checked for input errors and the user is provided an opportunity to correct them, or a mechanism is available for reviewing, confirming, and correcting information before finalizing the submission.',
    level: 'AAA',
    name: 'Error Prevention (All)',
    resource: 'https://www.w3.org/TR/WCAG21/#error-prevention-all',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.3.6',
    version_added: '2.0',
  },
  '3.3.7': {
    description: `Information previously entered by or provided to the user that is required to be entered again in the same process is either:
    <ul>
        <li>auto-populated, or</li>
        <li>available for the user to select.</li>
    </ul>
    Except when:
    <ul>
        <li>re-entering the information is essential,</li>
        <li>the information is required to ensure the security of the content, or</li>
        <li>previously entered information is no longer valid.</li>
    </ul>`,
    level: 'A',
    name: 'Redundant Entry',
    resource: 'https://www.w3.org/TR/WCAG22/#redundant-entry',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.3.7',
    version_added: '2.2',
  },
  '3.3.8': {
    // this breaks from the formatting on the resource site but the content is consistent
    description: `A cognitive function test (such as remembering a password or solving a puzzle) is not required for any step in an authentication process unless that step provides at least one of the following:
    <ul>
        <li><em>Alternative</em>: Another authentication method that does not rely on a cognitive function test.</li>
        <li><em>Mechanism</em>: A mechanism is available to assist the user in completing the cognitive function test.</li>
        <li><em>Object Recognition</em>: The cognitive function test is to recognize objects.</li>
        <li><em>Personal Content</em>: The cognitive function test is to identify non-text content the user provided to the Web site.</li>
    </ul>
    `,
    level: 'AA',
    name: 'Accessible Authentication (Minimum)',
    resource: 'https://www.w3.org/TR/WCAG22/#accessible-authentication-minimum',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.3.8',
    version_added: '2.2',
  },
  '3.3.9': {
    // this breaks from the formatting on the resource site but the content is consistent
    description: `A cognitive function test (such as remembering a password or solving a puzzle) is not required for any step in an authentication process unless that step provides at least one of the following:
    <ul>
        <li><em>Alternative</em>: Another authentication method that does not rely on a cognitive function test.</li>
        <li><em>Mechanism</em>: A mechanism is available to assist the user in completing the cognitive function test.</li>
    </ul>
    `,
    level: 'AAA',
    name: 'Accessible Authentication (Enhanced)',
    resource: 'https://www.w3.org/TR/WCAG22/#accessible-authentication-enhanced',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '3.3.9',
    version_added: '2.2',
  },
  '4.1.1': {
    description:
      'In content implemented using markup languages, elements have complete start and end tags, elements are nested according to their specifications, elements do not contain duplicate attributes, and any IDs are unique, except where the specifications allow these features.',
    level: 'A',
    name: 'Parsing',
    resource: 'https://www.w3.org/TR/WCAG21/#parsing',
    responsibleParty: 'Developer, Front-End Developer',
    successCriteria: '4.1.1',
    version_added: '2.0',
  },
  '4.1.2': {
    description:
      'For all user interface components (including but not limited to: form elements, links and components generated by scripts), the name and role can be programmatically determined; states, properties, and values that can be set by the user can be programmatically set; and notification of changes to these items is available to user agents, including assistive technologies.',
    level: 'A',
    name: 'Name, Role, Value',
    resource: 'https://www.w3.org/TR/WCAG21/#name-role-value',
    responsibleParty: 'Developer, Front-End Developer',
    successCriteria: '4.1.2',
    version_added: '2.0',
  },
  '4.1.3': {
    description:
      'In content implemented using markup languages, status messages can be programmatically determined through role or properties such that they can be presented to the user by assistive technologies without receiving focus.',
    level: 'AA',
    name: 'Status Messages',
    resource: 'https://www.w3.org/TR/WCAG21/#status-messages',
    responsibleParty: 'Designer, Front-End Developer, User Experience',
    successCriteria: '4.1.3',
    version_added: '2.1',
  },
}
