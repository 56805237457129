import React, { useState } from 'react'
import styled from '@emotion/styled'
import Input, { RequiredSpan } from '../Input'
import EyeIcon from '../../../../static/get-started-free/eye.svg'
import CrossedEyeIcon from '../../../../static/get-started-free/crossed-eye.svg'
import WarningGraphic from '../../../../static/get-started-free/warning-graphic.svg'
import Text from '../../common/Text'
import { ErrorText } from '../common'

import { useUserLang } from '../../../context/UserLangContext'
import generateAriaLabelTranslations from '../../../data/generateAriaLabelTranslations'

const ValidatedInputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 8px;
`

const PasswordContainer = styled.div`
  position: relative;
  display: flex;
`

const EyeImg = styled.img`
  width: 28px;
  margin-left: -40px;
  margin-top: 10px;
  cursor: pointer;
  z-index: 1;
`

const WarningImg = styled.img`
  position: absolute;
  left: 30%;
  top: -18px;
  width: 140px;
  z-index: 3;
`

const PasswordText = styled.label`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  grid-template-columns: auto 1fr;
  justify-items: flex-end;
  align-items: baseline;
  margin-bottom: 0px;
`

/* eslint-disable-next-line */
const PasswordInput = ({
  passwordErr,
  setPasswordErr,
  updateFormData,
  formData,
  setPasswordWarn,
  labelText,
  helperText,
}) => {
  const [showPass, setShowPass] = useState(false)

  const { userLang } = useUserLang()
  const languageData = generateAriaLabelTranslations({ userLang })

  const validatePass = password => {
    const pattern = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{8,})')
    setPasswordWarn(true)
    if (pattern.test(password) === true) {
      setPasswordWarn(false)
      setPasswordErr(false)
    }
  }

  return (
    <ValidatedInputWrapper id="password-wrapper">
      <PasswordText htmlFor="password-input">
        <Text smallBodyMedium>
          {labelText || 'Password'} <RequiredSpan>*</RequiredSpan>
          <span className="sr-only">{helperText || '8+ characters, with a number, lower and uppercase letter'}</span>
        </Text>
      </PasswordText>
      <PasswordContainer id="password-container">
        {passwordErr && <WarningImg id="error-alert" src={WarningGraphic} alt="Correct this field" role="alert" />}
        <Input
          placeholder=""
          name="password"
          autoComplete="new-password"
          required
          type={showPass ? 'text' : 'password'}
          value={formData.password}
          onChange={e => {
            validatePass(e.target.value)
            updateFormData({ password: e.target.value })
          }}
          aria-describedby="password-sub-label"
          id="password-input"
          style={{ paddingRight: '48px' }}
          requiredLabel="password"
        />
        <EyeImg
          src={showPass ? EyeIcon : CrossedEyeIcon}
          alt=""
          role="button"
          tabIndex="0"
          aria-pressed={showPass ? 'true' : 'false'}
          aria-label={languageData?.showPassword}
          className="focus-outline"
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              showPass === false ? setShowPass(true) : setShowPass(false)
            }
          }}
          onClick={() => {
            showPass === false ? setShowPass(true) : setShowPass(false)
          }}
        />
      </PasswordContainer>
      <ErrorText smallBody color="gray900" id="password-sub-label" aria-hidden="true" style={{ marginTop: '8px' }}>
        {helperText || '8+ characters, with a number, lower and uppercase letter'}
      </ErrorText>
    </ValidatedInputWrapper>
  )
}

export default PasswordInput
