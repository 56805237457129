import React, { useState } from 'react'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import getImage from '../../utils/getImage'
import { ImageEl, AltText } from '../postSlices/Image'
import Text from '../common/Text'
import ModalAnimated from '../common/modal/ModalAnimated'
import VideoIframe from '../common/VideoIframe'

const Wrapper = styled.div`
  display: block;
  padding-top: 40px;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const ContentWrapper = styled.div``

const DesktopImgWrapper = styled.div`
  display: block;
  @media (max-width: 800px) {
    display: none;
  }
`

const MobileImgWrapper = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`

const SummaryAltText = styled(AltText)`
  margin-top: 0px;
`

const SummaryWrapper = styled.div`
  margin: 0 auto 56px auto;
  margin-bottom: 56px;
  background-color: ${p => p.theme.color.gray100};
  border-radius: 10px;
  padding: 40px;

  p {
    margin-top: 0px;
    margin-bottom: 0px !important;
  }

  @media (max-width: 800px) {
    margin-bottom: 64px;
    padding: 32px 24px;
  }
`

const Summary = ({ image, text, video, blogCTA }) => {
  const [open, setOpen] = useState(false)
  const summaryText = text?.richText?.[0]?.text && <PrismicRichText field={text.richText} />
  if (!summaryText) return null

  return (
    <Wrapper>
      {image && image.url && (
        <ContentWrapper>
          <DesktopImgWrapper>
            {video ? (
              <a
                tabIndex="0"
                role="button"
                aria-label="Play AudioEye video opens in a modal"
                onKeyDown={e => {
                  if (e.key === 'Enter' && video) setOpen(true)
                }}
                onClick={() => video && setOpen(true)}
                style={{ width: '100%' }}
              >
                {getImage(image, ImageEl, { loading: 'eager' })}
              </a>
            ) : (
              getImage(image, ImageEl, { loading: 'eager' })
            )}
          </DesktopImgWrapper>
          <MobileImgWrapper>
            {video ? (
              <a
                tabIndex="0"
                role="button"
                aria-label="Play AudioEye video opens in a modal"
                onKeyDown={e => {
                  if (e.key === 'Enter' && video) setOpen(true)
                }}
                onClick={() => video && setOpen(true)}
                style={{ width: '100%' }}
              >
                {getImage(image, ImageEl, { loading: 'lazy' })}
              </a>
            ) : (
              getImage(image, ImageEl, { loading: 'lazy' })
            )}
          </MobileImgWrapper>
          {image?.alt && (
            <SummaryAltText smallBody color="gray500" aria-hidden="true">
              {image.alt}
            </SummaryAltText>
          )}
        </ContentWrapper>
      )}
      {summaryText && (
        <>
          {blogCTA && blogCTA}
          <SummaryWrapper className="narrow-width">
            <Text richText>{summaryText}</Text>
          </SummaryWrapper>
        </>
      )}
      {video && (
        <ModalAnimated
          active={(video && open) === true}
          onClose={() => video && setOpen(false)}
          ariaLabel="Video modal"
        >
          <VideoIframe isModal allowFullScreen videoSrc={video} autoPlay={false} />
        </ModalAnimated>
      )}
    </Wrapper>
  )
}

export default Summary
