const generateAgencyFormFieldsTranslations = ({ userLang }) => {
  const data = {
    en: {
      label: 'Do you manage websites for multiple clients?',
      checkboxLabel: "Yes, I'm looking for my clients",
    },
    de: {
      label: 'Sind Sie eine Agentur',
      checkboxLabel: 'Ja, ich schaue für meine Kunden',
    },
    fr: {
      label: 'Êtes-vous une agence',
      checkboxLabel: 'Oui, je regarde pour mes clients',
    },
    es: {
      label: '¿Eres una agencia?',
      checkboxLabel: 'Si busco a mis clientes',
    },
    it: {
      label: "Sei un'agenzia?",
      checkboxLabel: 'Sì, sto cercando i miei clienti',
    },
  }

  return data[userLang] || data.en
}

export default generateAgencyFormFieldsTranslations
