import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

export default function LottieAnimation({ lotti, lottieAriaLabel, width, height, loop, index }) {
  const [animationData, setAnimationData] = useState()
  const [speed, setSpeed] = useState(0)

  useEffect(() => {
    setAnimationData(lotti)
    return () => setAnimationData(null)
  }, [])

  useEffect(() => {
    if (index) {
      if (index === 0) {
        setSpeed(1)
      }
      if (index === 1) {
        setInterval(() => {
          setSpeed(1)
        }, 3000)
      }
      if (index === 2) {
        setInterval(() => {
          setSpeed(1)
        }, 5000)
      }
    } else {
      setSpeed(1)
    }
  }, [])

  return (
    <>
      {!animationData ? null : (
        <Lottie
          role="img"
          aria-label={lottieAriaLabel || ''}
          loop={loop || true}
          animationData={animationData}
          play
          speed={speed}
          style={{ width: width || '100%', height: height || 'auto', margin: '0 auto' }}
        />
      )}
    </>
  )
}
