import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import { theme } from '../../styles'
import Text from './Text'

const Wrapper = styled.div`
  ${p =>
    p.size === 'regular' &&
    `
      padding: 8px 24px;
    `}
  ${p =>
    p.size === 'micro' &&
    `
      padding: 6px 8px;
    `}
  ${p =>
    p.size === 'small' &&
    `
    padding: 8px 12px;
      `}

  ${props => props.icon && `display: flex; justify-content: space-between; align-items: center; gap: 8px;`}

  border-radius: 8px;
  background-color: ${p => theme.color[p.backgroundColor]};
  margin-left: ${p => (p.size === 'small' ? '4px' : '16px')};
  margin-left: ${p => p.noMargin && '0px'};
  text-align: center;
  width: fit-content;
  min-width: ${p => (p.size === 'regular' ? '100px' : '60px')};
  height: min-content;

  p {
    text-align: center;
    text-transform: uppercase;
    font-family: ${p => (p.fontFamily ? theme.fonts.family[p.fontFamily] : theme.fonts.family.heading1)};
    font-weight: ${p => (p.fontFamily ? theme.fonts.weight[p.fontFamily] : theme.fonts.weight.heading1)};
    font-size: ${p => theme.fonts.size[p.fontSize]};
    letter-spacing: ${theme.fonts.letterSpacing.heading1};
  }

  ${p =>
    p.isButton &&
    `
    &:hover {
      background-color: ${theme.color.purple500};
      p {
        color: ${theme.color.white};
      }
    }
  `}

  @media (max-width: 800px) {
    padding: 8px 16px;
  }
`

const ButtonWrapper = Wrapper.withComponent('button')

const modifyColor = bgColor => {
  switch (bgColor) {
    case 'red':
      return {
        backgroundColor: 'red100',
        textColor: 'red800',
      }
    case 'pink':
      return {
        backgroundColor: 'pink50',
        textColor: 'pink500',
      }
    case 'green':
      return {
        backgroundColor: 'green100',
        textColor: 'green800',
      }
    case 'gray':
      return {
        backgroundColor: 'gray100',
        textColor: 'gray800',
      }
    case 'gray50':
      return {
        backgroundColor: 'gray50',
        textColor: 'black',
      }
    default:
      return {
        backgroundColor: 'red100',
        textColor: 'red800',
      }
  }
}

const Tag = ({
  color,
  text,
  size = 'regular',
  fontFamily = 'smallBodyBold',
  fontSize = 'smallBodyBold',
  ariaHidden,
  noMargin,
  isButton,
  to,
  icon,
  ...rest
}) => {
  if (!text) return null
  const modifiedColor = modifyColor(color)
  return isButton ? (
    <ButtonWrapper
      isButton
      size={size}
      backgroundColor="purple100"
      aria-hidden={ariaHidden}
      fontSize={fontSize}
      fontFamily={fontFamily}
      noMargin={noMargin}
      onClick={() => navigate(to)}
      type="button"
      {...rest}
    >
      <Text color="purple500">{text}</Text>
    </ButtonWrapper>
  ) : (
    <Wrapper
      size={size}
      backgroundColor={modifiedColor.backgroundColor}
      aria-hidden={ariaHidden}
      fontSize={fontSize}
      fontFamily={fontFamily}
      noMargin={noMargin}
      {...rest}
      icon={icon !== undefined}
    >
      {icon && icon}
      <Text color={modifiedColor.textColor || 'red800'}>{text}</Text>
    </Wrapper>
  )
}

export default Tag
