import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import { PrismicProvider } from '@prismicio/react'
import './src/styles/global.css'
import { linkResolver } from './src/utils/linkResolver'
import { theme } from './src/styles'
import PageTemplate from './src/pages/{PrismicPage.url}'
import PostTemplate from './src/pages/{PrismicPost.url}'
import AuthorTemplate from './src/pages/{PrismicAuthor.url}'
import { addSmoothScrollClass } from './src/utils/addSmoothScrollClass'
import { ConsentContextProvider } from './src/context/ConsentContext'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: 'audioeye-web',
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
          post: PostTemplate,
          author: AuthorTemplate,
        }),
      },
    ]}
  >
    <PrismicProvider
      linkResolver={linkResolver}
      richTextComponents={{
        hyperlink: ({ node, children, key }) => {
          const href =
            node.data?.url && node.data.url.replace(/^https?:\/\/(beta|betastaging|www)?\.?audioeye\.com(.+)/, '$2')
          const externalLink = href?.indexOf('http') > -1
          const anchorLink = href && href?.indexOf('#') !== -1 && !href.includes('get-started-free')
          const linkProps = {
            href: externalLink ? href : href && href?.endsWith('/') ? href : `${href}/`,
            key,
            target: externalLink ? '_blank' : null,
            rel: externalLink ? 'noopener noreferrer' : null,
            // handle smoothscrolling behavior for anchor links
            onClick: externalLink ? null : anchorLink ? addSmoothScrollClass : null,
          }
          return <a {...linkProps}>{children}</a>
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <ConsentContextProvider>{element}</ConsentContextProvider>
      </ThemeProvider>
    </PrismicProvider>
  </PrismicPreviewProvider>
)
