import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import HubspotForm from 'react-hubspot-form'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { useShowConsentForm } from '../../context/ConsentContext'

import { trackEvent, identify } from '../../utils/eventTrack'

import { trackForm } from '../form/hubspot/HubSpotFormAPI'
import HubspotStyles from '../form/hubspot/hs-form-styles'
import Text from './Text'
import Space from './Space'
import BackButton from './BackButton'
import { theme } from '../../styles'

const Wrapper = styled.div`
  width: 100%;
  min-height: ${p => (p.formLoaded ? 'auto' : '75vh')};

  [type='submit'] {
    width: 100% !important;
  }
`

const FormWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 1/ 2;
  display: flex;
  flex-direction: column;
  transition: height 200ms ease;

  & > div {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    & > div,
    h4 {
      max-width: 500px;
      margin: 0 auto;
      width: 100%;
    }
  }
`

const RequiredWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  font-size: ${theme.fonts.size.tinyBody};
  span {
    line-height: 1.2 !important;
    color: ${theme.color.red500};
  }
`

const Form = ({ formIds, formHeading, formParagraph, formName, submitButtonText, callback }) => {
  const [formLoaded, setFormLoaded] = useState(false)
  const { showConsentForm, showConsentFormLoading } = useShowConsentForm()
  const [success, setSuccess] = useState(false)

  const environment = process.env.GATSBY_ACTIVE_ENV === 'production' ? 'production' : 'staging'
  const consent = showConsentForm ? 'consentRequired' : 'noConsentRequired'

  const finalFormId = formIds[environment][consent]

  async function formSubmitHandler(e) {
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormFailedValidation') {
      const errors =
        e.data.data.length > 0 &&
        e.data.data?.filter(error => {
          if (error.errorTypes.length) {
            return error
          }
          return null
        })
      if (errors) {
        errors.forEach(error => {
          const fieldLabel = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} > label > span`
          )?.textContent
          const fieldWrapper = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} label.hs-error-msg`
          )
          if (fieldWrapper && fieldLabel) {
            fieldWrapper.textContent = `Please complete the ${fieldLabel.toLowerCase()} field.`
          }
        })

        const firstErr = errors[0]?.name
        if (firstErr) {
          const toFocus = document.querySelector(`.hs-form-lander .hs-input[name=${firstErr}]`)
          if (toFocus) toFocus.focus()
        }
      }
    }
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormSubmit') {
      if (e.data.id === formIds[environment].noConsentRequired || e.data.id === formIds[environment].consentRequired) {
        trackForm(e.data.data, finalFormId)
        setSuccess(true)

        const mappedData = mapValues(keyBy(e.data.data, 'name'), 'value')
        identify(mappedData)

        callback()

        window.sessionStorage.setItem('hs-form-data', JSON.stringify(e.data.data))
      }
    }
  }

  useEffect(() => {
    if (formLoaded) {
      const submitButton = document.querySelector('#commonForm form .hs_submit input')
      submitButton.innerText = submitButtonText
      submitButton.value = submitButtonText
      const persistedUtmParams = JSON.parse(localStorage.getItem('utm_params'))
      if (persistedUtmParams) {
        Object.entries(persistedUtmParams).forEach(param => {
          const utmHiddenInput = document.querySelector(`input[name="${param[0]}"]`)
          if (utmHiddenInput) utmHiddenInput.value = param[1] || ''
        })
      }

      const persistedGclidParam = JSON.parse(localStorage.getItem('gclid_param'))
      const gclidHiddenInput = document.querySelector('input[name="gclid__c"]')
      if (gclidHiddenInput) gclidHiddenInput.value = persistedGclidParam || ''

      const mutinyExperienceName = localStorage.getItem('mutiny_experience_name')
      const mutinySegmentName = localStorage.getItem('mutiny_segment_name')
      const mutinyVariationName = localStorage.getItem('mutiny_variation_name')

      const mutinyExperienceNameInput = document.querySelector(`input[name="mutiny_experience_name"]`)
      if (mutinyExperienceNameInput && mutinyExperienceName) mutinyExperienceNameInput.value = mutinyExperienceName

      const mutinySegmentNameInput = document.querySelector(`input[name="mutiny_segment_name"]`)
      if (mutinySegmentNameInput && mutinySegmentName) mutinySegmentNameInput.value = mutinySegmentName

      const mutinyVariationNameInput = document.querySelector(`input[name="mutiny_variation_name"]`)
      if (mutinyVariationNameInput && mutinyVariationName) mutinyVariationNameInput.value = mutinyVariationName
    }
  }, [formLoaded])

  useEffect(() => {
    window.addEventListener('message', formSubmitHandler)
    return () => {
      window.removeEventListener('message', formSubmitHandler)
    }
  }, [])

  return (
    <Wrapper css={HubspotStyles} formLoaded={formLoaded} id="commonForm">
      <div>
        <FormWrapper>
          <BackButton text="Back" onClick={() => navigate(-1)} />
          <Space height={32} />
          {formHeading && (
            <>
              <Text heading4 aria-level="2">
                {formHeading}
              </Text>
              <Space height={8} />
            </>
          )}
          {formParagraph && (
            <>
              <Text>{formParagraph}</Text>
              <Space height={8} />
            </>
          )}
          <Space height={16} />
          {!success && (
            <RequiredWrapper>
              <span>*&nbsp;</span>
              <Text tinyBody color="gray700">
                Required
              </Text>
            </RequiredWrapper>
          )}
          {!showConsentFormLoading && (
            <HubspotForm
              portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
              formId={finalFormId}
              onReady={() => {
                setFormLoaded(true)
                trackEvent(`${formName} Form Viewed`)
              }}
              onFormReady={() => {
                if (window._zi_fc?.fcRetrigger instanceof Function) {
                  window._zi_fc.fcRetrigger()
                }

                const formInputs = document.querySelectorAll(`#hsForm_${finalFormId} input`)
                formInputs.forEach(input => {
                  input.addEventListener('change', e => {
                    trackEvent(`Form Value Changed`, {
                      'Form Hubspot ID': finalFormId,
                      'Form Element Name': e.target.name,
                      'Form Element Type': 'input',
                      'New Value': e.target.value,
                    })
                  })

                  input.addEventListener('focus', e => {
                    trackEvent(`Form Value Focused`, {
                      'Form Hubspot ID': finalFormId,
                      'Form Element Name': e.target.name,
                      'Form Element Type': 'input',
                      'Current Value': e.target.value,
                    })
                  })
                })

                const formSelects = document.querySelectorAll(`#hsForm_${finalFormId} select`)
                formSelects.forEach(select => {
                  select.addEventListener('change', e => {
                    trackEvent(`Form Value Changed`, {
                      'Form Element Name': e.target.name,
                      'Form Element Type': 'select',
                      'New Value': e.target.value,
                    })
                  })

                  select.addEventListener('focus', e => {
                    trackEvent(`Form Value Focused`, {
                      'Form Element Name': e.target.name,
                      'Form Element Type': 'select',
                      'Current Value': e.target.value,
                    })
                  })
                })
              }}
              submitButtonClass="custom-hs-button"
              cssClass="custom-hs-css hs-form-lander expert-audit-form"
            />
          )}
        </FormWrapper>
      </div>
    </Wrapper>
  )
}

export default Form
