/* eslint-disable camelcase */
import React from 'react'
import ChecklistTable from './CheckListTable'
import TextLink from '../common/TextLink'

const sections = [
  {
    items: [
      {
        column1: 'Alt Text for Images',
        column2: (
          <>
            Provide descriptive and meaningful{' '}
            <TextLink to="/post/what-is-image-alt-text/" font="smallBody" text="alt text" /> for images, charts, and
            other non-text content.
          </>
        ),
      },
      {
        column1: 'Transcripts and Captions',
        column2: (
          <>
            Include transcripts for audio content and{' '}
            <TextLink to="/post/closed-caption-best-practices/" font="smallBody" text="captions for videos" /> to
            support users with hearing disabilities or impairments, such as deafness or hard of hearing.
          </>
        ),
      },
      {
        column1: 'Assistive Technology Compatibility',
        column2: (
          <>
            Ensure your digital content is compatible with{' '}
            <TextLink
              to="/post/why-assistive-technology-users-tend-to-be-late-adopters/"
              font="smallBody"
              text="assistive technologies"
            />
            , including screen readers or text-to-speech software.
          </>
        ),
      },
      {
        column1: 'Keyboard Accessibility',
        column2:
          'All interactive elements (links, forms, buttons) are navigable and usable by a keyboard alone and include focus indicators.',
      },
      {
        column1: 'Color Contrast Ratios',
        column2: (
          <>
            Text has sufficient contrast against its background to ensure good readability (minimum ratios of 4.5:1 for
            normal text and 3:1 for large text). Use a{' '}
            <TextLink to="/color-contrast-checker/" font="smallBody" text="color contrast checker" /> to help with this.
          </>
        ),
      },
      {
        column1: 'Color Use',
        column2: 'Color alone is not used to convey important information. Text labels or symbols are used as well.',
      },
      {
        column1: 'Semantic HTML',
        column2: 'Use proper heading tags (H1, H2, H3, etc.) to create a clear and logical content structure.',
      },
      {
        column1: 'Content Organization',
        column2: 'Content is organized in a way that’s easy to navigate and has clear sections and subsections.',
      },
      {
        column1: 'Labeling',
        column2: 'Provide clear, descriptive labels for all form fields.',
      },
      {
        column1: 'Error Messages',
        column2: 'Include accessible error messages that clearly help users understand and correct mistakes.',
      },
      {
        column1: 'ARIA Roles and Properties',
        column2: (
          <>
            Use Accessible Rich Internet Applications (
            <TextLink to="/post/aria-accessibility/" font="smallBody" text="ARIA" />) to enhance the accessibility of
            your more complex web applications and dynamic content.
          </>
        ),
      },
      {
        column1: 'Use Simple Language',
        column2: 'Write in clear, simple language to ensure content is easily understandable by all users.',
      },
      {
        column1: 'Zoom and Resize',
        column2: 'Text and layout are still readable and functional when zoomed in or resized.',
      },
    ],
  },
]

const WebsiteAccessibilityAuditChecklist = () => (
  <ChecklistTable checklistItems={sections} title="Website Accessibility Audit Checklist" />
)

export default WebsiteAccessibilityAuditChecklist
