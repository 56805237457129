import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import Fade from 'react-reveal/Fade'

import HubspotStyles from '../form/hubspot/hs-form-styles'
import Text from '../common/Text'
import Space from '../common/Space'
import { theme } from '../../styles'
import OnboardingForm from '../form/Onboarding/OnboardingForm'

const BG = styled.div`
  position: absolute;
  width: 150vw;
  height: 100%;
  background-size: 125%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-color: hsla(266, 94%, 20%, 1);
  background-image: radial-gradient(at 0% 5%, hsla(170, 69%, 48%, 0.47) 0px, transparent 50%),
    radial-gradient(at 98% 39%, hsla(266, 80%, 49%, 0.8) 0px, transparent 50%),
    radial-gradient(at 100% 99%, hsla(170, 69%, 48%, 0.82) 0px, transparent 50%);
  z-index: -1;
  ${props => props.hasSwoop && 'border-radius: 0 0 100% 100%;'};
  animation: gradient 12s ease-in-out infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 35%;
    }
    50% {
      background-position: 75% 100%;
    }
    100% {
      background-position: 0% 35%;
    }
  }

  @media (max-width: 1050px) {
    width: 275vw;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding: 80px 80px 100px 80px;
  grid-column-gap: 80px;
  width: calc(100% + 300px);
  margin-left: -150px;
  min-height: ${p => (p.formLoaded ? 'auto' : '75vh')};

  .react-reveal {
    width: 100%;
    z-index: 0;
  }

  @media (max-width: 1300px) {
    grid-column-gap: 56px;
  }

  @media (max-width: 1050px) {
    grid-column-gap: 40px;
    width: 100%;
    margin-left: 0px;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
  }

  @media (max-width: 800px) {
    padding: 48px 0px;
  }
`

const LeftWrapper = styled.div`
  grid-column: 1 /2;
  grid-row: 1/ 2;
  &:has(ul, ol) {
    align-items: flex-start;
  }
  ul,
  ol {
    margin-left: 20px;
    li {
      text-align: left;
    }
  }

  @media (max-width: 1050px) {
    grid-row: auto;
    align-items: center;
    * :not(li) {
      text-align: center !important;
    }

  @media (max-width: 480px) {
    * :not(li) {
      text-align: left !important;
    }
  }
`

const FormWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 1/ 2;
  background-color: ${theme.color.white};
  border-radius: 8px;
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  transition: height 200ms ease;
  overflow: hidden;

  & > div {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 1050px) {
    max-width: 500px;
    min-width: unset;
    display: block;
    margin: 32px auto 0px auto;
    padding: 32px;
    width: 100%;
    grid-row: auto;
    grid-column: 1 /2;
  }
`

const OnboardingBannerForm = ({ slice: { primary } }) => {
  const [formLoaded, setFormLoaded] = useState(false)
  const title = primary.heading.richText?.[0]?.text && <PrismicRichText field={primary.heading.richText} />
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const smallCapsText = primary.small_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.small_caps_text.richText} />
  )
  const largeCapsText = primary.large_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.large_caps_text.richText} />
  )
  const footnotes = primary.footnotes?.richText?.[0]?.text
  const hasSwoop = primary?.has_swoop

  useEffect(() => {
    setFormLoaded(true)
  }, [])

  return (
    <Wrapper css={HubspotStyles} formLoaded={formLoaded}>
      <LeftWrapper>
        {smallCapsText && (
          <Text font="smallCaps" richText color="green500">
            {smallCapsText}
          </Text>
        )}
        {largeCapsText && (
          <Text font="largeCaps" richText color="yellow200">
            {largeCapsText}
          </Text>
        )}
        {title && (
          <>
            <Text font="heading1" richText color="white">
              {title}
            </Text>
            <Space height={24} tabletHeight={16} />
          </>
        )}
        {text && (
          <Text richText color="white">
            {text}
          </Text>
        )}
        {footnotes && (
          <Text color="white" smallBody>
            {footnotes}
          </Text>
        )}
      </LeftWrapper>
      <Fade bottom when={formLoaded}>
        <FormWrapper>
          <OnboardingForm formName="Onboarding Banner Form" />
        </FormWrapper>
      </Fade>
      <BG className="bg" hasSwoop={hasSwoop === null || hasSwoop} />
    </Wrapper>
  )
}

export default OnboardingBannerForm

export const query = graphql`
  fragment OnboardingBannerForm on PrismicPageDataBodyOnboardingBannerForm {
    ...SliceBase
    primary {
      large_caps_text {
        ...TextContent
      }
      small_caps_text {
        ...TextContent
      }
      heading {
        ...TextContent
      }
      text {
        ...TextContent
      }
      footnotes {
        ...TextContent
      }
      has_swoop
    }
  }
`
