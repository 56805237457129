import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import getImage from '../../utils/getImage'
import Text from '../common/Text'

export const ImageEl = styled.img`
  margin-bottom: 32px;
  padding-bottom: 24px;
  border-radius: 24px;

  * {
    border-radius: 24px;
  }
`

export const AltText = styled(Text)`
  font-style: italic;
  margin-top: -32px;
  margin-bottom: 32px;
  align-self: flex-start;
`

const Image = ({
  slice: {
    /* eslint-disable-next-line */
    primary: { image, link, anchor_id },
  },
}) => {
  const imageEl = <>{getImage(image, ImageEl)}</>
  /* eslint-disable-next-line */
  const anchorId = anchor_id
  const relativeLinkRegex = /^\/(?!\/)/
  const hasLink = link.url
  const isRelative = relativeLinkRegex.test(link?.url)

  let totalEl = null

  if (hasLink && isRelative) {
    // Only render Gatsby link for relative links
    totalEl = <Link to={link.url}>{imageEl}</Link>
  } else if (hasLink && !isRelative) {
    totalEl = (
      <a href={link.url} target={link.target}>
        {imageEl}
      </a>
    )
  } else {
    totalEl = imageEl
  }

  return (
    <>
      <div id={anchorId || null} />
      {totalEl}
      {image?.alt && (
        <AltText smallBody color="gray500" aria-hidden="true">
          {image.alt}
        </AltText>
      )}
    </>
  )
}

export default Image

export const query = graphql`
  fragment PostImage on PrismicPostDataBodyImage {
    ...SliceBase
    primary {
      anchor_id
      image {
        alt
        url
        gatsbyImageData(width: 1160)
      }
      link {
        ...LinkContent
      }
    }
  }
`
