import React from 'react'
import { graphql } from 'gatsby'
import idx from 'idx'
import { PrismicRichText } from '@prismicio/react'
import styled from '@emotion/styled'
import Text from '../common/Text'
import Space from '../common/Space'
import Scanner from '../form/Scanner'

export const BG = styled.div`
  position: absolute;
  background-color: ${p => p.theme.color[p.bgColor] || p.theme.color.white};
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  top: 0px;

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 80px 0px;
  flex-direction: column;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '16px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '16px')};

  p {
    margin-bottom: 0px !important;
  }
`

const ScannerBanner = ({ slice: { primary } }) => {
  const title = primary.title1.richText?.[0]?.text && <PrismicRichText field={primary.title1.richText} />
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const backgroundColor = primary.background_color
  const anchorId = primary.anchor_id
  const hasTopPadding = idx(primary, _ => _.has_top_padding)
  const hasBottomPadding = idx(primary, _ => _.has_bottom_padding)

  return (
    <Wrapper
      id={anchorId || null}
      hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
    >
      {title && (
        <>
          <Text font="heading2" center richText>
            {title}
          </Text>
          <Space height={32} />
        </>
      )}
      {text && (
        <>
          <Text center richText>
            {text}
          </Text>
          <Space height={32} />
        </>
      )}
      <Scanner />
      <BG className="bg" bgColor={backgroundColor} />
    </Wrapper>
  )
}

export default ScannerBanner

export const query = graphql`
  fragment ScannerBanner on PrismicPageDataBodyScanner {
    ...SliceBase
    primary {
      anchor_id
      background_color
      has_top_padding
      has_bottom_padding
      title1 {
        ...TextContent
      }
      text {
        ...TextContent
      }
    }
  }

  fragment PostScanner on PrismicPostDataBodyScanner {
    ...SliceBase
    primary {
      anchor_id
      background_color
      has_top_padding
      has_bottom_padding
      title1 {
        ...TextContent
      }
      text {
        ...TextContent
      }
    }
  }
`
