/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { theme } from '../../styles'
import Form from '../common/Form'
import Button from '../common/Button'
import { YouTubePlayer } from '../common/YouTubePlayer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0px;
  position: relative;

  :after {
    ${props =>
      props.showGate &&
      `content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(241, 242, 246, 0.7);
        z-index: 2;
    `}
  }

  @media (max-width: 800px) {
    width: 100%;
    margin: 0 auto;
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: ${theme.color.offWhite};
  margin-top: -80px;

  @media (max-width: 1250px) {
    margin-left: -100%;
  }
`

const GatedForm = styled.div`
  // this is done this way because the browser stops the form submission if the dom node that started the request is removed
  ${props => !props.showGate && `display: none;`}
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 50px 100px 5px rgba(50, 50, 93, 0.25), 0 30px 60px -10px rgba(0, 0, 0, 0.3);
  border: 1px solid #e4e6ea;
  padding: 32px;
  position: absolute;
  top: 90px;
  left: calc(50% - 275px);
  width: 550px;
  z-index: 3;

  @media (max-width: 750px) {
    left: calc(50% - 200px);
    width: 400px;
  }

  @media (max-width: 425px) {
    left: calc(50% - 175px);
    width: 350px;
  }

  @media (max-width: 375px) {
    left: calc(50% - 150px);
    width: 300px;
  }
`

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin: 40px 0px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const VideoGate = ({ slice: { primary } }) => {
  const [showGate, setShowGate] = useState(false)

  useEffect(() => {
    if (window.localStorage.getItem(`video_gate_${primary.video.url}_submitted`) !== 'true') {
      setShowGate(true)
    }
  }, [])

  return (
    <Wrapper showGate={showGate}>
      <ButtonWrapper>
        <Button
          tabIndex={showGate ? '-1' : '0'}
          text="Schedule your personalized demo now"
          to="/schedule-meeting/"
          className="primary"
        />
      </ButtonWrapper>
      <VideoWrapper>
        <YouTubePlayer
          videoUrl={primary.video.url}
          redirectUrl={primary.post_video_redirect_url.url}
          tabIndex={showGate ? '-1' : '0'}
        />
      </VideoWrapper>
      <GatedForm showGate={showGate}>
        <Form
          callback={() => {
            window.localStorage.setItem(`video_gate_${primary.video.url}_submitted`, true)
            setShowGate(false)
          }}
          formName="Video Gate"
          formHeading={primary.form_heading}
          formParagraph={primary.form_paragraph}
          submitButtonText={primary.submit_button_text}
          formIds={{
            production: {
              noConsentRequired: primary.production_hubspot_form_id,
              consentRequired: primary.production_hubspot_form_id_consent_required,
            },
            staging: {
              noConsentRequired: primary.staging_hubspot_form_id,
              consentRequired: primary.staging_hubspot_form_id_consent_required,
            },
          }}
        />
      </GatedForm>
      <BG className="bg" />
    </Wrapper>
  )
}

export default VideoGate

export const query = graphql`
  fragment VideoGate on PrismicPageDataBodyVideoGate {
    ...SliceBase
    primary {
      production_hubspot_form_id
      staging_hubspot_form_id
      production_hubspot_form_id_consent_required
      staging_hubspot_form_id_consent_required
      form_heading
      form_paragraph
      submit_button_text
      video {
        url
      }
      post_video_redirect_url {
        url
      }
    }
  }
`
