import React from 'react'
import styled from '@emotion/styled'

import { navigate } from 'gatsby'
import Text from '../common/Text'
import Button from '../common/Button'
import SocialLinks from '../common/SocialLinks'
import { trackEvent } from '../../utils/eventTrack'

const CtaWrapper = styled.section`
  grid-row: 1 / 2;
  display: grid;
  border-radius: 8px;
  background-color: ${p => p.theme.color.gray50};
  gap: 16px;
  width: fit-content;

  &:has(img) {
    min-width: 250px;
    grid-template-rows: 120px auto;
  }

  ${p =>
    p.hasLink &&
    `
    transition: all 0.2s ease-in-out;
    cursor: pointer;
      &:hover {
        box-shadow: 0px 2px 20px 0px ${p.theme.color.darkShadow};
        transform: scale(1.05);
      }
    `}

  @media (max-width: 999px) {
    grid-row: auto;
    margin: 16px 24px;

    &:has(img) {
      max-width: 250px;
    }
  }

  @media (min-width: 1000px) and (max-width: 1500px) {
    ${props => props.hasLink && `grid-area: 2 / 1 / 3 / 4;`}
    display: flex;
    min-width: none !important;
    width: 100%;

    img {
      border-bottom-left-radius: 8px;
    }
  }
`

const Image = styled.img`
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  @media (min-width: 1000px) and (max-width: 1500px) {
    display: none;
  }
`

const TabletImage = styled(Image)`
  display: none;

  @media (min-width: 1000px) and (max-width: 1500px) {
    display: block;
    flex: 1;
    object-fit: none;
  }
`

const CTAContent = styled.div`
  display: grid;
  justify-items: flex-start;
  padding: 24px;
  padding-top: 16px;
  gap: 16px;
  height: 100%;

  .button {
    padding: 3px 3px;
    margin-right: 20px;
    margin-top: auto;
  }

  ${props =>
    props.hasImage &&
    `
      @media (min-width: 1000px) and (max-width: 1500px) {
        display: flex;
        flex: 4;
        align-items: center;
        padding: 24px 24px 24px 0;
        margin-left: -24px;
      }
    `}

  @media (max-width: 999px) {
    .button {
      padding: 3px 0px;
    }
  }
`

const StyledButton = styled(Button)`
  margin-top: 0 !important;
`

const SubmenuCta = ({ submenuTitle, isSubMenuOpen }) => {
  let ctaContent
  switch (submenuTitle) {
    case 'solutions':
      ctaContent = {
        image: {
          url: 'https://audioeye-web.cdn.prismic.io/audioeye-web/2f9fadf6-975c-425e-a0c1-b9a18157a7c2_NAV-cta-DAI-No-text.png',
          tabletUrl:
            'https://audioeye-web.cdn.prismic.io/audioeye-web/fe7ef196-762c-4cfc-9db8-235f68600568_person-icon.svg',
          alt: 'The 2023 Digital Accessibility Index poster',
        },
        text: 'The 2023 Digital Accessibility Index',
        buttonText: 'Read the report',
        url: 'https://www.audioeye.com/digital-accessibility-index/executive-summary/',
      }
      break
    case 'resources':
      ctaContent = {
        image: {
          url: 'https://audioeye-web.cdn.prismic.io/audioeye-web/b50a4e28-bfce-484f-972a-bf9937b27ef7_Comprehensive+guide.svg',
          tabletUrl:
            'https://audioeye-web.cdn.prismic.io/audioeye-web/baa416be-939f-44f3-8a9c-7ca72da22224_person-guide-icon.svg',
          alt: 'Accessible web design white paper poster',
        },
        text: 'Comprehensive Guide to Accessible Web Design',
        buttonText: 'Download Guide',
        url: '/accessible-web-design/download-pdf',
      }
      break
    case 'community':
      ctaContent = {
        text: "Let's connect",
        subtext: 'Follow along with our community',
        socials: true,
      }
      break
    default:
      ctaContent = null
  }

  if (!ctaContent) return null
  return (
    <CtaWrapper
      onClick={() => {
        trackEvent('Navigation CTA Clicked', {
          'Link Text': ctaContent?.buttonText,
          Destination: ctaContent.url,
        })
        if (ctaContent?.url) navigate(ctaContent.url)
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          if (ctaContent?.url) navigate(ctaContent.url)
        }
      }}
      className="focus-outline"
      hasLink={ctaContent?.url}
    >
      {ctaContent.image && <Image alt={ctaContent.image.alt} src={ctaContent.image.url} />}
      {ctaContent.image && <TabletImage alt={ctaContent.image.alt} src={ctaContent.image.tabletUrl} />}
      <CTAContent hasImage={ctaContent.image}>
        {ctaContent.text && <Text smallBodyBold>{ctaContent.text}</Text>}
        {ctaContent.subtext && (
          <Text tinyBody style={{ marginBottom: '8px' }}>
            {ctaContent.subtext}
          </Text>
        )}
        {ctaContent.buttonText && (
          <StyledButton
            buttonType="link CTA"
            text={ctaContent?.buttonText}
            to={ctaContent?.url}
            tabIndex={isSubMenuOpen ? 0 : -1}
            className="submenu-cta button"
            onClick={() => {
              trackEvent('Navigation CTA Clicked', {
                'Link Text': ctaContent?.buttonText,
                Destination: ctaContent.url,
              })
            }}
          />
        )}
        {ctaContent.socials && <SocialLinks color="offBlack" maxSize={24} />}
      </CTAContent>
    </CtaWrapper>
  )
}

export default SubmenuCta
