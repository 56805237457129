import React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby'
import QMSecFilings from './QMSecFilings'
import Text from '../common/Text'
import Button from '../common/Button'
import Space from '../common/Space'
import PostItem from '../postSlices/PostItem'

const Wrapper = styled.div``

const PressReleasesWrapper = styled.div`
  padding: 100px 0px 80px 0px;
`

const ChartWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  padding: 32px 0px;
  iframe {
    min-height: 500px;
  }
`

export const pressReleasesQuery = graphql`
  query pressReleasesQuery {
    allPrismicPost(
      sort: { fields: data___publish_date, order: DESC }
      filter: { data: { post_type: { eq: "Press" }, publish_date: { ne: null } } }
      limit: 3
    ) {
      edges {
        node {
          uid
          data {
            description
            publish_date
            hide_from_feeds
            password
            title {
              richText
            }
          }
        }
      }
      totalCount
    }
  }
`

const InvestorRelations = () => (
  <Wrapper>
    <PressReleasesWrapper>
      <Text heading3 aria-level="2" center>
        Latest Press Releases
      </Text>
      <Space height={48} />
      <ul>
        <StaticQuery
          query={pressReleasesQuery}
          render={({ allPrismicPost }) =>
            allPrismicPost &&
            allPrismicPost.edges.map((item, i) => {
              if (!item.node.data.password && !item.node.data.hide_from_feeds) {
                return (
                  <React.Fragment key={item.node.uid}>
                    <PostItem
                      uid={item.node.uid}
                      publishDate={item.node.data.publish_date}
                      title={item.node.data.title.richText[0].text}
                      description={item.node.data.description}
                      index={i}
                    />
                    <Space height={16} />
                  </React.Fragment>
                )
              }
              return null
            })
          }
        />
      </ul>
      <Space height={8} />
      <Button
        text="View all press releases"
        to="/press#press-releases"
        style={{ marginLeft: 'auto', display: 'block' }}
      />
    </PressReleasesWrapper>
    <ChartWrapper>
      <Text id="sec-filings-title" heading3 aria-level="2" center>
        SEC Filings
      </Text>
      <QMSecFilings />
    </ChartWrapper>
  </Wrapper>
)

export default InvestorRelations
