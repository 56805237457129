/* eslint-disable camelcase */
import React from 'react'
import WCAGChecklist from './WCAGChecklist'

const sections = [
  {
    items: [
      {
        column1: 'Sign Language',
        column2: 'Sign language interpretation is provided for media with audio.',
      },
      {
        column1: 'Extended Audio Description',
        column2:
          'When the original video contains insufficient pauses and is not optimized for audio description, a separate video with sufficient pauses and audio descriptions is provided.',
      },
      {
        column1: 'Media Alternative',
        column2: 'Pre-recorded media includes a descriptive transcript.',
      },
      {
        column1: 'Audio-Only',
        column2: 'Descriptive text is provided for live content that contains audio.',
      },
      {
        column1: 'Identify Purpose',
        column2:
          'ARIA is used to enhance HTML semantics, enabling users to more easily understand the purpose of interface elements.',
      },
      {
        column1: 'Contrast',
        column2: 'Images and text have a color contrast ratio 7:1; large text has a contrast ratio.',
      },
      {
        column1: 'Low or No Background Audio',
        column2: 'Audio content has little to no background noise, ensuring it is easily distinguished.',
      },
      {
        column1: 'Visual Presentation',
        column2:
          'Blocks of text over one sentence in length are: Less than 80 characters wide, not fully justified, have adequate line spacing, include appropriate foreground and background colors, and does not require horizontal scrolling if size is doubled.',
      },
      {
        column1: 'Images of Text',
        column2:
          'Text within an image is used only for decoration, or if the information cannot be presented through text alone.',
      },
      {
        column1: 'Keyboard',
        column2: 'All functionalities are available via the keyboard.',
      },
      {
        column1: 'No Timing',
        column2: 'There are no time limits or constraints to page content or functionality.',
      },
      {
        column1: 'Interruptions',
        column2: 'Interruptions such as alerts or updates can be postponed by the user.',
      },
      {
        column1: 'Re-Authenticating',
        column2: 'Users can re-authenticate a page and continue activities without losing data.',
      },
      {
        column1: 'Timeouts',
        column2:
          'Users are adequately warned of timeouts that can result in data loss (unless data can be preserved for more than hours of user inactivity).',
      },
      {
        column1: 'Three Flashes',
        column2: 'No page content flashes three times per second.',
      },
      {
        column1: 'Animations from Interactions',
        column2: 'Non-essential animations and movements can be disabled by users.',
      },
      {
        column1: 'Location',
        column2:
          'A web page that’s part of a sequence of web pages are indicated as such through breadcrumbs or specifying sequence steps.',
      },
      {
        column1: 'Link Purpose',
        column2: 'Users can distinguish the purpose of each link through link text alone.',
      },
      {
        column1: 'Section Headings',
        column2: 'Sections of content are designated using headers.',
      },
      {
        column1: 'Focus not Obscured (Enhanced)',
        column2: 'Elements with keyboard focus are entirely visible.',
      },
      {
        column1: 'Focus Appearance',
        column2:
          'Instances where custom indicators or backgrounds are in place have at least a 3:1 contrast between focused/unfocused states, and are at least as large as the area of 2 pixel thick perimeter surrounding the element. ',
      },
      {
        column1: 'Target Size',
        column2: 'Clickable targets are 44 by 44 pixels in size unless an alternative target size is provided.',
      },
      {
        column1: 'Concurrent Input Mechanisms',
        column2:
          'Content is not limited to one specific input type (i.e., keyboard-only or touch-only) and supports alternative inputs.',
      },
      {
        column1: 'Unusual Words',
        column2:
          'Unfamiliar or ambiguous words are defined through an index, definition list, or other appropriate method.',
      },
      {
        column1: 'Abbreviations',
        column2: 'Unfamiliar abbreviations are expanded and defined the first time it is used.',
      },
      {
        column1: 'Reading Level',
        column2:
          'More understandable alternatives are provided for advanced content (typically provided for content above a ninth-grade reading level).',
      },
      {
        column1: 'Pronunciation',
        column2: 'Pronunciation guides are provided for words that are vital to the comprehension of the page.',
      },
      {
        column1: 'Change on Request',
        column2:
          'Substantial changes (i.e. spawning of pop-up windows or changes in keyboard focus) are initiated by the user and can disable options if desired.',
      },
      {
        column1: 'Help',
        column2: 'Instructions or cues are provided to users who need assistance to complete tasks.',
      },
      {
        column1: 'Error Prevention',
        column2: 'Users can reverse, verify, or confirm submissions after information is submitted.',
      },
      {
        column1: 'Accessible Authentication (Enhanced)',
        column2:
          'Cognitive function tests are not required for any step in the authentication process unless the test can be bypassed or completed with assistance from another mechanism.',
      },
    ],
  },
]

const WCAGChecklistAAA = () => <WCAGChecklist checklistItems={sections} title="WCAG Checklist Level AAA" />

export default WCAGChecklistAAA
