import React, { useState, useEffect } from 'react'

import Text from '../../common/Text'
import Space from '../../common/Space'
import Table from './EarningsReportTable'
import { StyledEarningsReport } from './EarningsReportTable.style'
import { config } from '../../../../config/keys'
import { checkExpiredData, persistData, getPersistedData } from '../../../utils/localStorageHelpers'
import fetchTableData from '../../../utils/fetchTableData'
import useAirtableBase from '../../../utils/hooks/useAirtableBase'

const EarningsReportTables = ({ prismicTableKey, isTableHeader, bsTableHeader, gaapTableHeader }) => {
  const [bsData, setBsData] = useState()
  const [isData, setIsData] = useState()
  const [gaapData, setGaapData] = useState()
  const [bsSkipUnaudited, setBsSkipUnaudited] = useState(false)
  const [isSkipUnaudited, setIsSkipUnaudited] = useState(false)
  const [gaapSkipUnaudited, setGaapSkipUnaudited] = useState(false)
  const airtableBase = useAirtableBase(config.airtable.baseIDs[prismicTableKey])

  const IsTable = isTableData => (
    <Table
      metaData={config.airtable.tableMetaData.isTable}
      tableData={isTableData}
      skipUnauditedHeader={isSkipUnaudited}
    />
  )
  const BsTable = bsTableData => (
    <Table
      metaData={config.airtable.tableMetaData.bsTable}
      tableData={bsTableData}
      skipUnauditedHeader={bsSkipUnaudited}
    />
  )
  const GaapTable = gaapTableData => (
    <Table
      metaData={config.airtable.tableMetaData.gaapTable}
      tableData={gaapTableData}
      skipUnauditedHeader={gaapSkipUnaudited}
    />
  )

  const checkUnauditedHeader = unauditedData => {
    if (!unauditedData) return null
    const unauditedDataArr = Object.values(unauditedData).filter(item => item !== undefined)
    if (unauditedDataArr[0] === 'skip') return true
    return false
  }

  useEffect(() => {
    // check local storage and timestamp for existing data
    const ls = getPersistedData()
    const lsKey = `earnings-data-${prismicTableKey}`
    const lsEarningsData = ls[lsKey]
    async function fetchNewData() {
      const gaapRes = await fetchTableData(airtableBase, 'Non-GAAP')
      if (gaapRes) {
        setGaapSkipUnaudited(checkUnauditedHeader(gaapRes['<unaudited>']))
        setGaapData(gaapRes)
      }
      const bsRes = await fetchTableData(airtableBase, 'BS')
      if (bsRes) {
        setBsSkipUnaudited(checkUnauditedHeader(bsRes['<unaudited>']))
        setBsData(bsRes)
      }
      const isRes = await fetchTableData(airtableBase, 'IS')
      if (isRes) {
        setIsSkipUnaudited(checkUnauditedHeader(isRes['<unaudited>']))
        setIsData(isRes)
      }

      // set data timestamp
      persistData(
        Object.assign(ls, {
          [`${lsKey}`]: {
            timestamp: Date.now(),
            bsData: bsRes,
            isData: isRes,
            gaapData: gaapRes,
          },
        })
      )
    }

    if (lsEarningsData && checkExpiredData(lsEarningsData.timestamp)) {
      // exists and is not expired
      setBsData(lsEarningsData.bsData)
      setIsData(lsEarningsData.isData)
      setGaapData(lsEarningsData.gaapData)
    } else {
      // Request AirTable base data
      fetchNewData()
    }
  }, [])

  return (
    <StyledEarningsReport>
      {isData && (
        <>
          <Text center heading3 aria-level="2" role="heading">
            AUDIOEYE, INC.
            <br />
            {isTableHeader || 'STATEMENTS OF OPERATIONS'} <br />
            {!isSkipUnaudited && '(unaudited)'}
          </Text>
          <Space height={60} />
          <IsTable {...isData} />
        </>
      )}
      {bsData && (
        <>
          <Space height={100} />

          <Text center heading3 aria-level="2" role="heading">
            AUDIOEYE, INC.
            <br />
            {bsTableHeader || 'BALANCE SHEETS'}
            <br />
            {!bsSkipUnaudited && '(unaudited)'}
          </Text>
          <Space height={60} />
          <BsTable {...bsData} />
        </>
      )}
      {gaapData && (
        <>
          <Space height={100} />
          <Text center heading3 aria-level="2" role="heading">
            AUDIOEYE, INC.
            <br />
            {gaapTableHeader || 'RECONCILIATIONS OF GAAP to NON-GAAP FINANCIAL MEASURES'}
            <br />
            {!gaapSkipUnaudited && '(unaudited)'}
          </Text>
          <Space height={60} />
          <GaapTable {...gaapData} />
        </>
      )}
    </StyledEarningsReport>
  )
}

export default EarningsReportTables
