import React from 'react'

export const CircleCheckIcon = ({ alt }) => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" alt={alt}>
    <circle cx="15" cy="15.25" r="15" fill="none" className="icon" />
    <path
      d="M8.25 15.75L12.75 20.25L21.75 11.25"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
