import React, { useState } from 'react'
import styled from '@emotion/styled'
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'
import Text from '../common/Text'
import copyTextToClipboard from '../../utils/copyTextToClipboard.js'
import { CopyIcon } from '../../../static/icons/component-icons/CopyIcon.js'
import { Facebook } from '../../../static/icons/component-icons/FacebookIcon.js'
import { Twitter } from '../../../static/icons/component-icons/TwitterIcon.js'
import { LinkedIn } from '../../../static/icons/component-icons/LinkedInIcon.js'
import { CircleShareIcon } from '../../../static/icons/component-icons/CirlceShareIcon.js'
import { CircleCheckIcon } from '../../../static/icons/component-icons/CircleCheckIcon.js'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  padding: 24px 0px;
  position: relative;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 16px 0px;
  }
`

const SocialWrapper = styled.ul`
  display: flex;
  list-style-type: none;
  gap: 8px;

  ${p =>
    p.horizontal &&
    `
    justify-content: center;
  `}

  button {
    background: none;
    border-radius: 50%;

    &:hover,
    &:focus {
      outline-offset: 0px;
      .icon {
        fill: ${p => p.theme.color.purple500};
      }
    }
  }

  .icon {
    transition: all 75ms ease-in-out;
    fill: ${p => p.theme.color.gray500};
  }

  @media (max-width: 800px) {
    svg {
      width: 32px;
      height: 32px;
    }
    button {
      &:hover,
      &:focus {
        .icon {
          fill: ${p => p.theme.color.gray500};
        }
      }
    }
  }
`

const DropDownWrapper = styled(SocialWrapper)`
  flex-direction: column;
  display: grid;
  background-color: ${p => p.theme.color.white};
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px ${p => p.theme.color.darkShadow};
  padding: 16px;
  position: absolute;
  top: 80%;
  transition-property: transform, opacity;
  transition: 200ms;
  transform-origin: 100% 0;

  ${p =>
    p.isOpen
      ? `
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
    z-index: 9999999;
  `
      : `
  opacity: 0;
  pointer-events: none;
  transform: scale(0.90);
  z-index: -9999;
  `}
`

const DropDownBtn = styled.button`
  background: none;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  padding: 0px;

  .icon {
    transition: all 75ms ease-in-out;
    fill: ${p => p.theme.color.gray100};
  }

  .icon-stroke {
    transition: all 75ms ease-in-out;
    stroke: ${p => p.theme.color.gray800};
  }

  &:hover,
  &:focus {
    outline-offset: 0px;
    .icon {
      fill: ${p => p.theme.color.purple500};
    }
    .icon-stroke {
      transition: all 75ms ease-in-out;
      stroke: ${p => p.theme.color.white};
    }
  }
`

const ShareListItem = styled.li`
  margin-bottom: 0px;
`

const ShareButtons = ({ dropdown, isOpen }) => {
  const [isCopied, setIsCopied] = useState(false)
  const currentPath = typeof window !== 'undefined' && window.location
  const url = currentPath && new URL(currentPath)

  const handleShare = () => {
    copyTextToClipboard(url).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 3000)
    })
  }

  return (
    <>
      <ShareListItem>
        <button
          style={{ display: 'flex', border: '3px solid transparent', padding: '2px' }}
          type="button"
          onClick={handleShare}
          aria-label="Copy this url to clipboard"
          tabIndex={!dropdown ? '0' : dropdown && isOpen ? '0' : '-1'}
        >
          {isCopied ? <CircleCheckIcon alt="Checkmark icon" /> : <CopyIcon alt="copy icon" />}
        </button>
      </ShareListItem>
      <ShareListItem>
        <LinkedinShareButton
          style={{ display: 'flex', border: '3px solid transparent', padding: '2px' }}
          url={url.href}
          aria-label="opens a new linkedin tab"
          tabIndex={!dropdown ? '0' : dropdown && isOpen ? '0' : '-1'}
        >
          <LinkedIn alt="linkedin" />
        </LinkedinShareButton>
      </ShareListItem>
      <ShareListItem>
        <FacebookShareButton
          style={{ display: 'flex', border: '3px solid transparent', padding: '2px' }}
          url={url.href}
          aria-label="opens a new facebook tab"
          tabIndex={!dropdown ? '0' : dropdown && isOpen ? '0' : '-1'}
        >
          <Facebook alt="facebook" />
        </FacebookShareButton>
      </ShareListItem>
      <ShareListItem>
        <TwitterShareButton
          style={{ display: 'flex', border: '3px solid transparent', padding: '2px' }}
          url={url.href}
          aria-label="opens a new twitter tab"
          tabIndex={!dropdown ? '0' : dropdown && isOpen ? '0' : '-1'}
        >
          <Twitter alt="twitter" />
        </TwitterShareButton>
      </ShareListItem>
    </>
  )
}

const DropDown = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <DropDownBtn
        onClick={() => setIsOpen(!isOpen)}
        aria-label={isOpen ? 'Close the social sharing menu' : 'Open the social sharing menu'}
        onKeyDown={e => {
          if (e.key === 'Enter') setIsOpen(true)
        }}
      >
        <CircleShareIcon />
      </DropDownBtn>
      <DropDownWrapper isOpen={isOpen} aria-hidden={!isOpen}>
        <ShareButtons isOpen={isOpen} dropdown />
      </DropDownWrapper>
    </>
  )
}

const SharePost = ({ horizontal, dropDown }) => (
  <Wrapper>
    {!dropDown && (
      <Text smallCaps center>
        Share post
      </Text>
    )}
    {dropDown ? (
      <DropDown />
    ) : (
      <SocialWrapper horizontal={horizontal}>
        <ShareButtons dropdown={false} />
      </SocialWrapper>
    )}
  </Wrapper>
)

export default SharePost
