import React from 'react'
import { Link, navigate } from 'gatsby'
import styled from '@emotion/styled'

import { trackEvent } from '../../utils/eventTrack'
import Text from './Text'
import { OpenNewTabHiddenSpan } from './NewTabHiddenSpan'
import chevronPurple from '../../../static/icons/arrow-icons/chevron-icon-right-purple500.svg'
import chevronBlack from '../../../static/icons/arrow-icons/chevron-icon-right-offBlack.svg'
import chevronPurple900 from '../../../static/icons/arrow-icons/chevron-icon-right-purple900.svg'
import rightArrowPurple from '../../../static/icons/arrow-icons/right-arrow-icon-purple500.svg'
import rightArrowWhite from '../../../static/icons/arrow-icons/right-arrow-icon-white.svg'
import chevronWhite from '../../../static/icons/arrow-icons/chevron-icon-right-white.svg'
import rightArrowBlack from '../../../static/icons/arrow-icons/right-arrow-icon-black.svg'
import share from '../../../static/icons/misc-icons/share.svg'
import rightArrowPurple900 from '../../../static/icons/arrow-icons/right-arrow-icon-purple900.svg'
import { addSmoothScrollClass } from '../../utils/addSmoothScrollClass'
import { theme } from '../../styles'

const ButtonLinkWrapper = styled(Link)`
  position: relative;
  background-color: ${theme.color.purple500};
  padding: 24px 32px;
  border-radius: 16px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 150ms;
  z-index: 1;
  height: fit-content;
  width: fit-content;

  p {
    line-height: 1;
    color: ${theme.color.white};
    ${p => (p.wordWrap ? 'white-space: normal;' : 'white-space: nowrap;')}
  }

  :hover {
    background-color: ${theme.color.purple900};
  }

  :focus {
    background-color: ${theme.color.purple500};
  }

  ${p => {
    switch (p.buttontype) {
      case 'primary':
        return `
          background-color: ${theme.color.purple500};

          p {
            color: ${theme.color.white};
          }

          :hover {
            background-color: ${theme.color.purple900};
          }

          :focus {
            background-color: ${theme.color.purple500};
          }
        `
      case 'secondary':
        return `
          background-color: transparent;
          border-color: ${theme.color.purple500};

          p {
            color: ${theme.color.purple500};
          }

          :hover {
            background-color: transparent;
            border-color: ${theme.color.offBlack};

            p {
              color: ${theme.color.offBlack};
            }
          }

          :focus {
            background-color: transparent;
            border-color: ${theme.color.purple500};

            p {
              color: ${theme.color.purple500};
            }
          }
        `
      case 'tertiary':
        return `
          background-color: ${theme.color.offBlack};
          border-color: transparent;

          p {
            color: ${theme.color.white};
          }

          :hover {
            border-color: ${theme.color.offBlack};
            background-color: transparent;

            p {
              color: ${theme.color.offBlack};
            }
          }
          :focus {
            background-color: transparent;
            border-color: ${theme.color.offBlack};

            p {
              color: ${theme.color.offBlack};
            }
          }
        `
      case 'tertiary reversed':
        return `
          background-color: ${theme.color.white};
          border-color: transparent;

          p {
            color: ${theme.color.offBlack};
          }

          :hover {
            border-color: ${theme.color.white};
            background-color: transparent;

            p {
              color: ${theme.color.white};
            }
          }
          :focus {
            background-color: transparent;
            border-color: ${theme.color.white};
            outline: ${theme.darkBgFocusOutline};

            p {
              color: ${theme.color.white};
            }
          }
        `
      case 'pink':
        return `
          background-color: ${theme.color.pink500};

          p {
            color: ${theme.color.white};
          }

          :hover {
            background-color: ${theme.color.pink900};
          }

          :focus {
            background-color: ${theme.color.pink500};
          }
        `
      case 'reversed dark':
        return `
          background-color: transparent;
          border-color: ${theme.color.white};

          p {
            color: ${theme.color.white};
          }

          :hover {
            border-color: transparent;
            background-color: ${theme.color.white};

            p {
              color: ${theme.color.offBlack};
            }
          }
          :focus {
            background-color: transparent;
            border-color: ${theme.color.white};
            outline: ${theme.darkBgFocusOutline};

            p {
              color: ${theme.color.white};
            }
          }
        `
      case 'reversed dark arrow':
        return `
          background-color: transparent;
          border-color: ${theme.color.white};
          flex-direction: row-reverse;

          p {
            color: ${theme.color.white};
            font-size: ${theme.fonts.size.smallBody};
          }

          ::before {
            transition: all 100ms ease-in-out;
            content: '';
            background-image: url(${chevronWhite});
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            margin-left: 12px;
            transform: translateY(1px);
          }

          :hover {
            border-color: transparent;
            background-color: ${theme.color.white};

            p {
              color: ${theme.color.offBlack};
            }

            ::before {
              content: '';
              background-image: url(${rightArrowBlack});
              background-position: center;
              background-repeat: no-repeat;
              transform: translateX(-4px) translateY(1px);
              margin-left: 12px;
            }
          }
          :focus {
            border-color: transparent;
            background-color: ${theme.color.white};
            outline: ${theme.darkBgFocusOutline};

            p {
              color: ${theme.color.offBlack};
            }

            ::before {
              content: '';
              background-image: url(${rightArrowBlack});
              background-position: center;
              background-repeat: no-repeat;
              transform: translateX(-4px) translateY(1px);
              margin-left: 12px;
            }
          }
          @media(max-width: 800px) {
            ::before {
              display: none;
            }
          }
        `
      case 'link CTA':
        return `
          background-color: transparent;
          border-color: transparent;
          padding: 0px 0px;
          background-image: none!important;
          text-decoration: none!important;
          padding: 3px 28px 3px 3px !important;
          outline-offset: 4px;
          outline: 3px solid transparent;
          border-radius: 4px;

          p {
            color: ${theme.color.purple500};
            font-size: ${theme.fonts.size.smallBody};
          }

          ::before {
            transition: all 100ms ease-in-out;
            content: '';
            position: absolute;
            background-image: url(${chevronPurple});
            background-position: center;
            top: 0;
            bottom: 0;
            left: calc(100% - 28px);
            background-repeat: no-repeat;
            right: 0;
            width: 20px;
            margin-top: 1px;
          }

          :hover, :focus {
            border-color: transparent;
            background-color: transparent;

            p {
              color: ${theme.color.purple500};
            }

            ::before {
              background-image: url(${rightArrowPurple});
              width: 28px;
            }
          }
          :focus {
            padding: 3px 28px 3px 3px !important;

            ::before {
              left: unset;
            }
          }
          @media(max-width: 1050px) {
            width: auto!important;
            margin: 0 auto;
          }
        `

      case 'link CTA share':
        return `
          background-color: transparent;
          border-color: transparent;
          padding: 0px 0px;
          background-image: none!important;
          text-decoration: none!important;

          p {
            color: ${theme.color.purple500};
            font-size: ${theme.fonts.size.smallBody};
          }

          ::before {
            transition: all 100ms ease-in-out;
            content: '';
            position: absolute;
            background-image: url(${share});
            background-position: center;
            top: 0;
            bottom: 0;
            left: 100%;
            background-repeat: no-repeat;
            right: 0;
            width: 24px;
            height: 24px;
            margin-left: 8px;
            margin-top: -3px;
          }

          :hover, :focus {
            border-color: transparent;
            background-color: transparent;

            p {
              color: ${theme.color.purple500};
            }
          }
          :focus {
            padding: 3px 28px 3px 3px !important;

            ::before {
              left: unset;
              margin-top: 0px;
              margin-left: 8px;
            }
          }
          @media(max-width: 1050px) {
            width: auto!important;
            margin: 0 auto;
          }
        `

      case 'reverse link CTA':
        return `
          background-color: transparent;
          border-color: transparent;
          padding: 0px 0px;
          background-image: none!important;
          text-decoration: none!important;

          p {
            color: ${theme.color.purple500};
            font-size: ${theme.fonts.size.smallBody};
          }

          ::before {
            transition: all 100ms ease-in-out;
            content: '';
            position: absolute;
            background-image: url(${chevronPurple});
            background-position: center;
            top: 0;
            bottom: 0;
            background-repeat: no-repeat;
            right: 100%;
            width: 20px;
            margin-top: 1px;
            transform: scaleX(-1);
          }

          :hover, :focus {
            border-color: transparent;
            background-color: transparent;

            p {
              color: ${theme.color.purple500};
            }

            ::before {
              background-image: url(${rightArrowPurple});
              width: 28px;
            }
          }
          :focus {
            padding: 3px 3px 3px 28px !important;

            ::before {
              left: 0;
            }
          }
          @media(max-width: 1050px) {
            width: auto!important;
            margin: 0 auto;
          }
        `
      case 'white link CTA':
        return `
          background-color: transparent;
          border-color: transparent;
          padding: 0px 0px;
          background-image: none!important;
          text-decoration: none!important;

          p {
            color: ${theme.color.white};
            font-size: ${theme.fonts.size.smallBody};
          }

          ::before {
            transition: all 100ms ease-in-out;
            content: '';
            position: absolute;
            background-image: url(${chevronWhite});
            background-position: center;
            top: 0;
            bottom: 0;
            left: 100%;
            background-repeat: no-repeat;
            right: 0;
            width: 20px;
            margin-top: 1px;
          }

          :hover, :focus {
            border-color: transparent;
            background-color: transparent;

            p {
              color: ${theme.color.white};
            }

            ::before {
              background-image: url(${rightArrowWhite});
              width: 28px;
            }
          }
          :focus {
            padding: 3px 28px 3px 3px;
            outline: ${theme.darkBgFocusOutline};

            ::before {
              left: unset;
            }
          }
          @media(max-width: 1050px) {
            width: auto!important;
            margin: 0 auto;
          }
        `
      case 'black link CTA':
        return `
          background-color: transparent;
          border-color: transparent;
          padding: 0px 0px;
          background-image: none!important;
          text-decoration: none!important;

          p {
            color: ${theme.color.offBlack};
            font-size: ${theme.fonts.size.smallBody};
          }

          ::before {
            transition: all 100ms ease-in-out;
            content: '';
            background-image: url(${chevronBlack});
            position: absolute;
            background-position: center;
            top: 0;
            bottom: 0;
            left: 100%;
            background-repeat: no-repeat;
            right: 0;
            width: 20px;
            margin-top: 1px;
          }

          :hover, :focus {
            border-color: transparent;
            background-color: transparent;

            p {
              color: ${theme.color.offBlack};
            }

            ::before {
              background-image: url(${rightArrowBlack});
              width: 28px;
            }
          }
          :focus {
            padding: 3px 28px 3px 3px;
            outline: ${theme.focusOutline};

            ::before {
              left: unset;
            }
          }
          @media(max-width: 1050px) {
            width: auto!important;
            margin: 0 auto;
          }
        `

      case 'purple link CTA':
        return `
            background-color: transparent;
            border-color: transparent;
            padding: 0px 0px;
            background-image: none!important;
            text-decoration: none!important;

            p {
              color: ${theme.color.purple900};
              font-size: ${theme.fonts.size.smallBody};
            }

            ::before {
              transition: all 100ms ease-in-out;
              content: '';
              background-image: url(${chevronPurple900});
              position: absolute;
              background-position: center;
              top: 0;
              bottom: 0;
              left: 100%;
              background-repeat: no-repeat;
              right: 0;
              width: 20px;
              margin-top: 1px;
            }

            :hover, :focus {
              border-color: transparent;
              background-color: transparent;

              p {
                color: ${theme.color.purple900};
              }

              ::before {
                background-image: url(${rightArrowPurple900});
                width: 28px;
              }
            }
            :focus {
              padding: 3px 28px 3px 3px;
              outline: ${theme.focusOutline};

              ::before {
                left: unset;
              }
            }
            @media(max-width: 1050px) {
              width: auto!important;
              margin: 0 auto;
            }
          `
      default:
        return null
    }
  }};
  ${p => {
    switch (p.size) {
      case 'xx-small':
        return `
          padding: 12px;
          border-radius: 12px;

          p {
            font-size: ${theme.fonts.size.smallBody};
          }
        `
      case 'x-small':
        return `
          padding: 12px 24px;
          border-radius: 12px;

          p {
            font-size: ${theme.fonts.size.smallBody};
          }
        `
      case 'small':
        return `
          padding: 16px 24px;
          border-radius: 12px;

          p {
            font-size: ${theme.fonts.size.smallBody};
          }
        `
      default:
        return null
    }
  }};

  @media (max-width: 1050px) {
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

const ButtonWrapper = ButtonLinkWrapper.withComponent('button')
const ExternalButtonWrapper = ButtonLinkWrapper.withComponent('a')

const Button = ({ text, buttonType, size, to, ref, noScroll, tag, loginLink, wordWrap, ...rest }) => {
  const windowGlobal = typeof window !== 'undefined' && window
  const html = windowGlobal && document.querySelector('html')

  const onboardingButton = /#get-started-free\/?$/.test(to) || /get-started-free\/?$/.test(to)
  const siteScanButton = /#site-scan\/?$/.test(to) || /site-scan\/?$/.test(to)
  const expertAuditButton = /#expert-audit\/?$/.test(to) || /expert-audit\/?$/.test(to)
  const scheduleDemoButton = /#schedule-demo\/?$/.test(to) || /schedule-demo\/?$/.test(to)
  const sameTab =
    !/http/.test(to) ||
    /^audioeye.com$/.test(to) ||
    to?.includes('www.audioeye.com') ||
    onboardingButton ||
    siteScanButton ||
    expertAuditButton ||
    scheduleDemoButton
  let internalTarget = false

  const relativeLink = /^\/(?!\/)/

  if (sameTab) {
    internalTarget =
      to &&
      relativeLink.test(to.replace(/^https?:\/\/(beta|betastaging|www)?\.?audioeye\.com(.+)/, '$2')) &&
      to.replace(/^https?:\/\/(beta|betastaging|www)?\.?audioeye\.com(.+)/, '$2')
  }
  const opensPdf = to && to.includes('.pdf')
  const oldOnClick = rest.onClick || (() => {})

  const onboardingOnClick = e => {
    e.preventDefault()
    html.classList.remove('smooth-scroll')
    navigate(`${window.location.pathname}${window.location.search}#get-started-free`)
  }

  const siteScanOnClick = e => {
    e.preventDefault()
    html.classList.remove('smooth-scroll')
    navigate(`${window.location.pathname}#site-scan`)
  }

  const expertAuditOnClick = e => {
    e.preventDefault()
    html.classList.remove('smooth-scroll')
    navigate(`${window.location.pathname}${window.location.search}#expert-audit`)
  }

  const scheduleDemoOnClick = e => {
    e.preventDefault()
    html.classList.remove('smooth-scroll')
    navigate(`${window.location.pathname}${window.location.search}#schedule-demo`)
  }

  rest.onClick = e => {
    e.stopPropagation()

    trackEvent(`Button Clicked`, {
      'Button Text': text,
      Destination: to,
      Tag: tag,
    })

    if (opensPdf) {
      trackEvent(`PDF Downloaded`, {
        'PDF Name': to,
        Tag: tag,
      })
    }

    if (onboardingButton) {
      onboardingOnClick(e)
      return
    }

    if (siteScanButton) {
      siteScanOnClick(e)
      return
    }

    if (expertAuditButton) {
      expertAuditOnClick(e)
      return
    }

    if (scheduleDemoButton) {
      scheduleDemoOnClick(e)
      return
    }

    // Smooth scroll for anchor links
    if (!onboardingButton && !siteScanButton && !expertAuditButton && !scheduleDemoButton && !noScroll)
      addSmoothScrollClass()

    oldOnClick(e)
  }

  const el = (
    <>
      <Text bodyBold center>
        {text}
      </Text>
      {!sameTab && <OpenNewTabHiddenSpan isButton />}
    </>
  )

  if (onboardingButton) {
    return (
      <ButtonWrapper
        onClick={onboardingOnClick}
        buttontype={buttonType}
        size={size}
        className={`button ${rest?.className || ''}`}
        ref={ref}
        {...rest}
      >
        {el}
      </ButtonWrapper>
    )
  }

  if (siteScanButton) {
    return (
      <ButtonWrapper
        onClick={siteScanOnClick}
        buttontype={buttonType}
        size={size}
        className={`button ${rest?.className || ''}`}
        ref={ref}
        {...rest}
      >
        {el}
      </ButtonWrapper>
    )
  }

  if (expertAuditButton) {
    return (
      <ButtonWrapper
        onClick={expertAuditOnClick}
        buttontype={buttonType}
        size={size}
        className={`button ${rest?.className || ''}`}
        ref={ref}
        {...rest}
      >
        {el}
      </ButtonWrapper>
    )
  }

  if (scheduleDemoButton) {
    return (
      <ButtonWrapper
        onClick={scheduleDemoOnClick}
        buttontype={buttonType}
        size={size}
        className={`button ${rest?.className || ''}`}
        ref={ref}
        {...rest}
      >
        {el}
      </ButtonWrapper>
    )
  }

  if (loginLink) {
    return (
      <ExternalButtonWrapper
        buttontype={buttonType}
        size={size}
        href={to}
        className={`button ${rest?.className || ''}`}
        ref={ref}
        {...rest}
      >
        {el}
      </ExternalButtonWrapper>
    )
  }

  if (!sameTab && !internalTarget && to) {
    return (
      <ExternalButtonWrapper
        buttontype={buttonType}
        size={size}
        href={to}
        className={`button ${rest?.className || ''}`}
        ref={ref}
        target="_blank"
        wordWrap={wordWrap}
        {...rest}
      >
        {el}
      </ExternalButtonWrapper>
    )
  }

  if (internalTarget) {
    return (
      <ButtonLinkWrapper
        buttontype={buttonType}
        size={size}
        to={internalTarget}
        className={`button ${rest?.className || ''}`}
        ref={ref}
        {...rest}
      >
        {el}
      </ButtonLinkWrapper>
    )
  }

  if (!internalTarget && !to) {
    return (
      <ButtonWrapper
        buttontype={buttonType}
        size={size}
        className={`button ${rest?.className || ''}`}
        {...rest}
        ref={ref}
        wordWrap={wordWrap}
      >
        {el}
      </ButtonWrapper>
    )
  }
  return null
}

export default Button
