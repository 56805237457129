/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Facebook from './Facebook'
import Twitter from './Twitter'

import { useUserLang } from '../../context/UserLangContext'
// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({
  title = null,
  desc = null,
  banner = null,
  imageAlt,
  bannerUrl = null,
  pathname = null,
  noIndex,
  noFollow,
  canonical,
}) => {
  const { userLang } = useUserLang()

  return (
    <StaticQuery
      query={`${query}`}
      render={data => {
        const {
          siteMetadata: {
            siteUrl,
            defaultTitle,
            defaultDescription,
            defaultBanner,
            bannerAlt,
            siteLanguage,
            author,
            twitter,
            facebook,
          },
        } = data.site

        const seo = {
          title: title || defaultTitle,
          description: desc || defaultDescription,
          image: bannerUrl || `${siteUrl}${banner || defaultBanner}`,
          imageAlt: imageAlt || bannerAlt || defaultDescription,
          url: `${siteUrl}${pathname || ''}`,
          canonical,
        }

        // schema.org in JSONLD format
        // https://developers.google.com/search/docs/guides/intro-structured-data
        // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

        const schemaCorporation = {
          '@context': 'https://schema.org',
          '@type': 'Corporation',
          name: author,
          alternateName: author,
          url: siteUrl,
          logo: 'https://www.audioeye.com/logos/audioeye.png',
          sameAs: [
            'https://www.linkedin.com/company/audioeye-inc/',
            'https://twitter.com/audioeyeinc',
            'https://www.instagram.com/audioeyeinc/',
          ],
        }

        const schemaWebsite = {
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: siteUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: '{search_term_string}',
            },
            'query-input': 'required name=search_term_string',
          },
        }

        // Initial breadcrumb list
        const itemListElement = [
          {
            '@type': 'ListItem',
            item: {
              '@id': siteUrl,
              name: 'Homepage',
            },
            position: 1,
          },
        ]

        const breadcrumb = {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          description: 'Breadcrumbs list',
          name: 'Breadcrumbs',
          itemListElement,
        }

        let robotsMeta = null

        if (process.env.GATSBY_ACTIVE_ENV !== 'production') {
          robotsMeta = <meta name="robots" content="noindex" />
        } else if (noIndex && noFollow) {
          robotsMeta = <meta name="robots" content="noindex, nofollow" />
        } else if (noIndex && !noFollow) {
          robotsMeta = <meta name="robots" content="noindex" />
        } else if (!noIndex && noFollow) {
          robotsMeta = <meta name="robots" content="nofollow" />
        }

        return (
          <Fragment>
            <Helmet
              title={seo.title}
              link={seo.canonical ? [{ rel: 'canonical', key: seo.canonical, href: seo.canonical }] : []}
            >
              <html lang={userLang || siteLanguage} />
              <meta name="description" content={seo.description} />
              <meta name="image" content={seo.image} />
              {robotsMeta}
              <script type="application/ld+json">{JSON.stringify(schemaCorporation)}</script>
              <script type="application/ld+json">{JSON.stringify(schemaWebsite)}</script>
              <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
            </Helmet>
            <Facebook
              desc={seo.description}
              image={seo.image}
              imageAlt={seo.imageAlt}
              title={seo.title}
              type="website"
              url={seo.url}
              locale={userLang === 'en' ? 'en_US' : `${userLang}_${userLang.toUpperCase()}`}
              name={facebook}
            />
            <Twitter
              title={seo.title}
              image={seo.image}
              imageAlt={seo.imageAlt}
              desc={seo.description}
              username={twitter}
            />
          </Fragment>
        )
      }}
    />
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        bannerAlt
        headline
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`
