const generateScheduleDemoFormTranslations = ({ userLang }) => {
  const data = {
    en: {
      redirectLink: '/schedule-meeting/',
      heading: 'Schedule Demo',
      requiredField: 'Required Field',
    },
    de: {
      redirectLink: '/de/schedule-meeting-de/',
      heading: 'Demo planen',
      requiredField: 'Pflichtfeld',
      emailCommunications:
        'Da wir unser Unternehmen in Europa noch ausbauen, wird unser Vertriebsteam mit Ihnen auf Englisch kommunizieren. Vielen Dank für Ihre Geduld, während unser Unternehmen noch wächst.',
    },
    fr: {
      redirectLink: '/fr/schedule-meeting-fr/',
      heading: 'Planifier une démo',
      requiredField: 'Champs requis',
      emailCommunications:
        'Étant donné que nous étendons notre entreprise en Europe, les communications de notre équipe commerciale se feront en anglais. Nous vous remercions de votre patience pendant que notre entreprise se développe.',
    },
    es: {
      redirectLink: '/es/schedule-meeting-es/',
      heading: 'Agendar un demo',
      requiredField: 'Campo obligatorio',
      emailCommunications:
        'A medida que estamos expandiendo nuestra empresa a Europa, nuestra comunicación con usted por parte de nuestro equipo de ventas será en inglés. Gracias por su paciencia mientras nuestra empresa crece.',
    },
    it: {
      redirectLink: '/it/schedule-meeting-it/',
      heading: 'Programma una demo',
      requiredField: 'Campo obbligatorio',
      emailCommunications:
        'Poiché stiamo espandendo la nostra azienda in Europa, le nostre comunicazioni da parte del nostro team di vendita saranno in inglese. Grazie per la vostra pazienza mentre la nostra azienda cresce.',
    },
  }

  return data[userLang] || data.en
}

export default generateScheduleDemoFormTranslations
