/* eslint-disable indent */
import React, { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import idx from 'idx'

import Text from '../common/Text'
import Space from '../common/Space'
import Button from '../common/Button'
import Pagination from '../common/Pagination'
import PostGridItem from './PostGridItem'
import BlogSearch from '../common/BlogSearch'
import { Filter, categoryArr } from './Filter'

const Wrapper = styled.div`
  position: relative;
`

export const GridWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  gap: 32px;

  .react-reveal {
    max-width: 500px;
  }

  @media (max-width: 1450px) {
    justify-content: space-evenly;
    justify-items: center;
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 24px;
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  top: 0;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: #fafafa;

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const BottomWrapper = styled.nav`
  display: flex;
  justify-content: center;
  @media (max-width: 1050px) {
    flex-wrap: wrap;
  }
`

const Header = styled.div`
  display: grid;
  gap: 48px;
  margin-bottom: 48px;

  @media (max-width: 1050px) {
    gap: 32px;
    margin-bottom: 32px;
  }
`

const TitleText = styled(Text)`
  @media (max-width: 1050px) {
    margin-top: 16px;
    text-align: center;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`

const dontCapitalize = ['and', 'as', 'but', 'for', 'if', 'nor', 'or', 'so', 'yet', 'a', 'an', 'the', 'of']

const capitalizeAll = ['ada', 'wcag']

const capitalizeEachWord = inputString =>
  inputString
    .split(' ')
    .map((word, index) =>
      capitalizeAll.includes(word)
        ? word.toUpperCase()
        : index === 0 || !dontCapitalize.includes(word)
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word
    )
    .join(' ')

const PostsGrid = ({ pagination, allPostsData, categoryPage, tagPage, showSearch }) => {
  const [activeCategory, setActiveCategory] = useState(categoryPage || categoryArr[0])
  const gridLimit = pagination ? 18 : activeCategory === 'all' ? 8 : 9

  return (
    <Wrapper>
      <Space height={80} mobileHeight={48} />
      <Header>
        {pagination ? (
          <TitleText heading1>
            {tagPage
              ? `${capitalizeEachWord(tagPage.replace(/-/g, ' '))}`
              : activeCategory === 'all'
              ? 'All articles'
              : `${capitalizeEachWord(activeCategory)}`}
          </TitleText>
        ) : (
          <FlexContainer>
            <TitleText heading2>Recent Articles</TitleText>
            {showSearch && <BlogSearch />}
          </FlexContainer>
        )}
        {!tagPage && (
          <Filter categoryPage={categoryPage} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
        )}
      </Header>
      <GridWrapper>
        {activeCategory !== 'all'
          ? allPostsData.edges
              .filter(({ node }) =>
                node.data.categories.find(({ category }) =>
                  category?.document?.data.name.includes(activeCategory) ? node : null
                )
              )
              .map(
                ({ node }, i) =>
                  i < gridLimit && (
                    <Fragment key={node.data.title.text}>
                      <PostGridItem
                        uid={node.uid}
                        publishDate={node.data.publish_date}
                        title={node.data.title.text}
                        index={i}
                        img={idx(node.data, _ => _.banner)}
                        categories={node?.data?.categories}
                        description={node.data?.description}
                      />
                    </Fragment>
                  )
              )
          : allPostsData.edges.map(
              ({ node }, i) =>
                i < gridLimit && (
                  <Fragment key={node.data.title.text}>
                    {i === 5 && !pagination && <PostGridItem isBlogCta={!pagination} />}
                    <PostGridItem
                      uid={node.uid}
                      publishDate={node.data.publish_date}
                      title={node.data.title.text}
                      index={i}
                      img={idx(node.data, _ => _.banner)}
                      categories={node?.data?.categories}
                      description={node.data?.description}
                    />
                  </Fragment>
                )
            )}
      </GridWrapper>
      <Space height={64} />

      {pagination && allPostsData.pageInfo.pageCount > 1 ? (
        <BottomWrapper role="navigation" aria-label="Pagination" id="pagination-nav">
          <Pagination activeFilter={tagPage || activeCategory} pageInfo={allPostsData.pageInfo} />
        </BottomWrapper>
      ) : (
        <Button text="View all articles" to="/blog/posts/all" style={{ display: 'block' }} />
      )}
      <Space height={80} />
      <BG className="bg" />
    </Wrapper>
  )
}

export default PostsGrid
