import { invalidEmails } from '../../config/keys'

export default async function (email, setEmailError) {
  let threshHold = 0
  invalidEmails.forEach(domain => {
    const emailDomain = email.split('@')?.[1]
    const regEx = new RegExp(`^${domain}$`)
    const containsInvalid = regEx.test(emailDomain)
    if (containsInvalid) threshHold += 1
  })
  if (threshHold > 0) {
    setEmailError(true)
    return
  }
  setEmailError(false)
}
