import React, { useEffect, createContext, useState } from 'react'

const UserLang = createContext()

const UserLangProvider = ({ children }) => {
  const [userLang, setUserLang] = useState('en')

  useEffect(() => {
    const languages = ['en', 'de', 'fr', 'es', 'it']

    const currentURL = window.location.pathname
    const urlParts = currentURL.split('/')

    const url = window.location.search
    const paramName = 'lang'
    const params = {}
    const parser = document.createElement('a')
    parser.href = url
    const query = parser.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i += 1) {
      if (vars[i]) {
        const pair = vars[i].split('=')
        params[pair[0]] = decodeURIComponent(pair[1])
      }
    }
    const lang = params[paramName]

    // Check if there is a subfolder
    if (urlParts.length > 1 && languages.includes(urlParts[1])) {
      setUserLang(urlParts[1])
    } else if (languages.includes(lang)) {
      setUserLang(lang)
    } else {
      setUserLang('en')
    }
  }, [])

  return <UserLang.Provider value={{ userLang }}>{children}</UserLang.Provider>
}

const useUserLang = () => {
  const context = React.useContext(UserLang)
  if (context === undefined) {
    throw new Error('useUserLang must be used within a UserLangProvider')
  }
  return context
}

export { UserLangProvider, useUserLang }
