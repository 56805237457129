/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import idx from 'idx'
import { PrismicRichText } from '@prismicio/react'

import Text from '../common/Text'
import Space from '../common/Space'
import { theme } from '../../styles'
import Button from '../common/Button'

const BG = styled.div`
  display: flex;
  justify-self: center;
  position: absolute;
  top: 0;
  width: 250vw;
  margin-left: -50%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(
    to right,
    ${theme.color.purple900},
    ${theme.color.purple500} 60%,
    ${theme.color.green500} 80%
  );
  background: ${p =>
    p.backgroundColor === 'linear-gradient'
      ? `linear-gradient(to right, ${theme.color.purple900}, ${theme.color.purple500} 60%, ${theme.color.green500} 80%)`
      : theme.color[p.backgroundColor]};

  @media (max-width: 1050px) {
    background: ${p =>
      p.backgroundColor === 'linear-gradient'
        ? `linear-gradient(to right, ${theme.color.purple900}, ${theme.color.purple500}, ${theme.color.purple900})`
        : theme.color[p.backgroundColor]};
  }
`

const Wrapper = styled.div`
  display: flex;
  min-height: 72px;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 16px;

  @media (max-width: 800px) {
    padding: 12px;
    width: calc(100% + 48px);
    margin-left: -24px;
    flex-direction: column;

    p {
      text-align: center;
    }
  }
  p {
    margin-bottom: 0px !important;
  }
`

const StyledText = styled(Text)`
  p {
    font-weight: 500 !important;
  }

  strong {
    font-weight: 700 !important;
  }
`

const StyledButton = styled(Button)`
  p {
    font-size: 1rem !important;
  }
`

const AnnouncementBanner = ({ slice: { primary } }) => {
  const backgroundColor = idx(primary, _ => _.background_color)
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const buttonText = idx(primary, _ => _.button_text.text)
  const buttonUrl = idx(primary, _ => _.button_link.url)

  return (
    <Wrapper>
      {text && (
        <StyledText
          role="heading"
          aria-level="2"
          color={backgroundColor === 'green400' || backgroundColor === 'orange500' ? 'offBlack' : 'white'}
          richText
        >
          {text}
        </StyledText>
      )}
      <Space width={120} tabletWidth={60} mobileWidth={0} mobileHeight={8} />
      <StyledButton
        text={buttonText}
        to={buttonUrl}
        buttonType={
          backgroundColor === 'green400'
            ? 'black link CTA'
            : backgroundColor === 'orange500'
            ? 'link CTA'
            : 'white link CTA'
        }
      />
      <BG className="bg" backgroundColor={backgroundColor} />
    </Wrapper>
  )
}
export default AnnouncementBanner

export const query = graphql`
  fragment AnnouncementBanner on PrismicPageDataBodyAnnouncementBanner {
    ...SliceBase
    primary {
      background_color
      text {
        ...TextContent
      }
      button_text {
        ...TextContent
      }
      button_link {
        uid
        url
      }
    }
  }
`
