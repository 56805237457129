/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import { FeatureGateContext } from '../utils/hooks/FeatureGateContext'
import { useFeatureGates } from '../utils/getGatesClient'
import PageContent from '../templates/PageContent'

const Page = props => {
  const {
    data: { prismicPage },
  } = props
  if (!prismicPage?.data) return null
  const [authenticated, setAuthenticated] = useState(false)

  const featureGates = useFeatureGates()
  const pagePassword = prismicPage.data?.password?.text

  useEffect(() => {
    if (!authenticated && pagePassword?.length) {
      setAuthenticated(false)
      // eslint-disable-next-line no-alert
      const passwordValue = typeof window !== 'undefined' ? window.prompt('Password required') : undefined
      if (passwordValue === pagePassword) setAuthenticated(true)
    }
  }, [])

  return (
    <FeatureGateContext.Provider value={featureGates}>
      {authenticated || !pagePassword ? <PageContent {...prismicPage.data} props={props} /> : null}
    </FeatureGateContext.Provider>
  )
}

export default withPrismicPreview(Page)

export const query = graphql`
  query pageBySlug($url: String!) {
    prismicPage(url: { eq: $url }) {
      _previewable
      id
      uid
      url
      first_publication_date
      last_publication_date
      data {
        folder {
          document {
            ... on PrismicFolder {
              id
              data {
                name
              }
            }
          }
        }
        banner {
          url
        }
        title {
          ...TextContent
        }
        description {
          ...TextContent
        }
        hide_header_footer_nav
        noindex
        nofollow
        canonical_url
        show_cta
        show_secondary_cta
        show_footer
        show_header
        micro_site_header_footer
        password {
          text
        }
        has_table_of_contents
        hide_table_of_contents_cta
        show_scanner_form
        scanner_form_label_text
        table_of_contents_heading_text
        table_of_contents_cta_text {
          ...TextContent
        }
        table_of_contents_cta_link {
          ...LinkContent
        }
        table_of_contents_links {
          table_of_content_link_text {
            ...TextContent
          }
          table_of_content_anchor_link {
            ...TextContent
          }
        }
        body {
          ...PageDataBody
        }
      }
    }
  }
`
