import React from 'react'
import { theme } from '../../../../styles'

export const PressGraphic = ({ index }) => {
  const colors = [
    [theme.color.purple200, theme.color.purple500],
    [theme.color.blue100, theme.color.blue500],
    [theme.color.green100, theme.color.green500],
    [theme.color.yellow100, theme.color.yellow500],
    [theme.color.red100, theme.color.red500],
    [theme.color.purple200, theme.color.purple500],
  ]
  return (
    <svg
      width="100%"
      viewBox="0 0 393 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', top: '0', left: '0', height: 'auto' }}
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H384.727C389.146 0 392.727 3.58172 392.727 8V180H0V8Z"
        fill={colors[index][0]}
      />
      <path
        opacity="0.25"
        d="M0.000466086 127.742L0 180H392.727L392.727 53.6648C392.728 52.7234 392.501 51.7957 392.065 50.9603C391.63 50.1248 390.998 49.4062 390.225 48.8653L366.843 32.5504C359.863 27.6697 351.342 25.4776 342.862 26.3808C334.383 27.284 326.52 31.2212 320.733 37.4616L291.469 69.0868C285.744 75.2612 277.985 79.1847 269.602 80.144C261.22 81.1032 252.77 79.0346 245.789 74.3142L227.505 61.9432C221.008 57.5564 213.226 55.4559 205.394 55.9749C197.562 56.4939 190.128 59.6026 184.272 64.8081L149.18 95.9124C143.912 100.584 137.358 103.573 130.366 104.491C123.374 105.41 116.265 104.217 109.962 101.065L77.8959 85.0296C71.5286 81.8387 64.3395 80.6461 57.2777 81.6093C50.2158 82.5725 43.6132 85.6461 38.3412 90.4245L2.01742 123.501C1.40768 124.032 0.91406 124.683 0.56712 125.413C0.220179 126.143 0.0273018 126.935 0.000466086 127.742Z"
        fill={colors[index][1]}
      />
      <path
        opacity="0.25"
        d="M0 163.511V180H392.727V68.9252C392.727 67.9997 392.507 67.0875 392.083 66.263C391.659 65.4386 391.045 64.7254 390.29 64.1819L342.181 29.584C338.27 26.7723 333.821 24.7853 329.106 23.7453C324.392 22.7054 319.513 22.6346 314.769 23.5374C310.026 24.4402 305.52 26.2975 301.528 28.9945C297.536 31.6916 294.144 35.1714 291.56 39.2193L273.88 66.9534C270.797 71.7782 266.573 75.7835 261.576 78.6214C256.578 81.4594 250.958 83.0443 245.204 83.2386C239.449 83.4329 233.733 82.2307 228.553 79.7363C223.373 77.242 218.885 73.5308 215.478 68.9252L212.234 64.5353C208.776 59.8591 204.204 56.1059 198.926 53.6115C193.648 51.117 187.83 49.959 181.991 50.2409C176.151 50.5228 170.474 52.2359 165.465 55.227C160.456 58.2181 156.273 62.394 153.289 67.3813L132.159 102.723C128.413 108.998 122.787 113.958 116.066 116.912C109.345 119.866 101.863 120.667 94.6618 119.204L69.7261 114.182C63.9991 113.022 58.0718 113.288 52.4739 114.957C46.8761 116.625 41.7821 119.644 37.6472 123.742L1.76237 159.456C0.662681 160.527 0.0299473 161.983 0 163.511Z"
        fill={colors[index][1]}
      />
    </svg>
  )
}
