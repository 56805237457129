/* eslint-disable indent */

import React from 'react'
import styled from '@emotion/styled'
import FindIcon from '../../../static/icons/misc-icons/magnifying-find-icon.svg'
import FixIcon from '../../../static/icons/misc-icons/gear-fix-icon.svg'
import MonitorIcon from '../../../static/icons/misc-icons/dashboard-monitor-icon.svg'
import PointCard from '../../../static/vector-elements/right-point-card.svg'
import { theme } from '../../styles'

import Text from '../common/Text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 64px 0px 80px 0px;

  @media (max-width: 1050px) {
    padding: 80px 10px;
    align-items: center;
  }

  @media (max-width: 800px) {
    padding: 64px 10px;
  }
`

const ProcessWrapper = styled.ol`
  display: flex;
  justify-content: center;
  grid-auto-flow: column;
  list-style-type: none;
  width: 100%;
  margin-left: -12px;

  @media (max-width: 1050px) {
    grid-row: 2/ 3;
    grid-column: 1/ 2;
    margin-top: 64px;
    height: auto;
    align-items: center;
    flex-direction: column;
    margin-left: 0px;
  }
`

const ProcessCard = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  width: 100%;
  margin-bottom: 0px;
  z-index: ${p => p.index};
  margin-left: ${p => p.index !== -1 && '-15%'};

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    background-image: url(${PointCard});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (max-width: 1400px) {
    margin-left: ${p => p.index !== -1 && '-8%'};
  }

  @media (max-width: 1050px) {
    z-index: 0;
    margin-left: 0px;

    ::before {
      transform: rotate(90deg);
    }
  }

  @media (max-width: 800px) {
    ::before {
      width: 120%;
    }
  }

  .text-content {
    max-width: 220px;
  }
`

const BGHeading = styled.div`
  padding-top: 64px;
  padding-bottom: 40px;
  z-index: -999;
  height: 100%;
  background-color: ${theme.color.purple900};
  border-radius: 12px;
  margin: -45px 60px 0px 24px;
  padding-left: 26px;

  @media (max-width: 1400px) {
    margin-left: -10px;
    margin-right: 20px;
  }

  @media (max-width: 1050px) {
    position: relative;
    margin: 0 auto;
    width: 60%;
    height: 100%;
    padding-left: 0;
    background-color: transparent;

    ::before {
      content: '';
      background-color: ${theme.color.purple900};
      width: 100%;
      height: 100%;
      z-index: -999;
      border-radius: 12px;
      position: absolute;
      min-height: 950px;
      bottom: 6%;
    }

    h3 {
      margin: 0 auto;
      padding: 0px 16px;
      max-width: 480px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    ::before {
      min-height: 900px;
    }
  }

  @media (max-width: 400px) {
    ::before {
      min-height: 930px;
    }
  }
`

const Icon = styled.img`
  object-fit: contain;
  height: 80px;
  margin: 24px;

  @media (max-width: 1280px) {
    height: 70px;
    margin: 12px;
  }
`

const Number = styled.div`
  position: absolute;
  top: -10px;
  background-color: ${theme.color.purple900};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;

  @media (max-width: 1280px) {
    top: 0px;
  }

  @media (max-width: 1050px) {
    background-color: ${theme.color.purple500};
    width: 32px;
    height: 32px;
  }
`

const TitleWrapper = styled.div`
  text-align: center !important;
  margin: 0px 8px;
  margin-bottom: 48px;

  @media (max-width: 1050px) {
    margin-bottom: 0px;
  }
`

const ProductProcess = () => (
  <Wrapper>
    <TitleWrapper>
      <Text heading1 center>
        How AudioEye Works
      </Text>
    </TitleWrapper>
    <ProcessWrapper>
      <ProcessCard index={-1}>
        <Number>
          <Text color="white" bodyBold>
            1
          </Text>
        </Number>
        <Text heading3 center>
          Find
        </Text>
        <Icon src={FindIcon} alt="" />
        <Text smallBodyBold center className="text-content">
          Automation tests for over 400 accessibility issues
        </Text>
      </ProcessCard>

      <ProcessCard index={-2}>
        <Number>
          <Text color="white" bodyBold>
            2
          </Text>
        </Number>
        <Text heading3 center>
          Fix
        </Text>
        <Icon src={FixIcon} alt="" />
        <Text smallBodyBold center className="text-content">
          We fix most accessibility issues automatically
        </Text>
      </ProcessCard>

      <ProcessCard index={-3}>
        <Number>
          <Text color="white" bodyBold>
            3
          </Text>
        </Number>
        <Text heading3 center>
          Monitor
        </Text>
        <Icon src={MonitorIcon} alt="" />
        <Text smallBodyBold center className="text-content">
          Continuously monitor and protect for compliance
        </Text>
      </ProcessCard>
    </ProcessWrapper>
    <BGHeading>
      <Text heading3 role="presentation" center color="white">
        ADA and WCAG Compliance Plan on Day One
      </Text>
    </BGHeading>
  </Wrapper>
)

export default ProductProcess
