import React from 'react'
import styled from '@emotion/styled'
import website from '../../config/website'

const SkipToMainLink = styled.a`
  opacity: 0;
  z-index: -999;
  position: ${p => (p.isDai ? 'fixed' : 'absolute')};

  &:focus {
    top: 20px;
    left: 15px;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms;
    text-decoration: none;
    font-family: ${p => p.theme.fonts.family.bodyBold};
    font-size: ${p => p.theme.fonts.size.bodyBold};
    font-weight: ${p => p.theme.fonts.weight.bodyBold};
    letter-spacing: ${p => p.theme.fonts.letterSpacing.bodyBold};
    opacity: 1;
    background-color: ${p => p.theme.color.purple500};
    color: ${p => p.theme.color.white};
    z-index: 100;
  }
`

const SkipNavLink = ({ children = 'Skip to main content', isDai, ...props }) => (
  <SkipToMainLink
    {...props}
    href={`#${website.skipNavId}`}
    data-reach-skip-link
    rel="noopener noreferrer"
    isDai={isDai}
  >
    {children}
  </SkipToMainLink>
)

export default SkipNavLink
