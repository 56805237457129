import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import HubspotForm from 'react-hubspot-form'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { useShowConsentForm } from '../../context/ConsentContext'

import Text from '../common/Text'
import { theme } from '../../styles'
import { trackForm } from '../form/hubspot/HubSpotFormAPI'
import HubspotStyles from '../form/hubspot/hs-form-styles'
import { trackEvent, identify } from '../../utils/eventTrack'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  height: 100%;
  z-index: -1;
  background-color: ${props => (props.backgroundColor === 'offWhite' ? theme.color.offWhite : theme.color.white)};
  margin-left: -100%;
  margin-top: -80px;
`

const Wrapper = styled.div`
  display: flex;
  column-gap: 80px;
  padding: 80px 0;

  @media (max-width: 1050px) {
    flex-direction: column;
  }

  > div {
    flex: 1;
  }

  .custom-hs-css {
    ${props => props.showConsentForm && `display: flex;`}
    ${props => props.showConsentForm && `flex-direction: column;`}
    ${props => !props.showConsentForm && `display: grid;`}
    ${props => !props.showConsentForm && `grid-template-columns: 2fr 1fr;`}
    ${props => !props.showConsentForm && `align-items: flex-end;`}

    .hs_submit {
      ${props => props.showConsentForm && `margin-top: 32px;`}
    }

    .hs-form-field.hs-email {
      margin-bottom: 0px;
    }

    input.hs-input {
      height: 44px;
      grid-column: 1 / 3;
      border: 2px solid ${theme.color.purple500};
      border-radius: ${props => (props.showConsentForm ? '8px' : '8px 0px 0px 8px')};
    }

    input.custom-hs-button {
      padding: unset;
      height: 44px;
      font-size: ${theme.fonts.size.smallBodyBold};
      margin: 0px;
      align-self: bottom;
      width: 100%;
      border-radius: ${props => (props.showConsentForm ? '8px' : '0px 8px 8px 0px')};
      grid-column: 3 / 4;

      &:hover,
      &:focus {
        padding: unset;
      }
    }

    .hs-error-msgs {
      position: absolute;
    }
    .hs_error_rollup {
      display: none;
    }
  }
`

const TextWrapper = styled.div`
  text-align: right;

  @media (max-width: 1050px) {
    text-align: center;
  }
`

const NewsletterSubscribe = ({ slice: { primary } }) => {
  const [formLoaded, setFormLoaded] = useState(false)
  const { showConsentForm, showConsentFormLoading } = useShowConsentForm()
  const stagingHubSpotFormId = primary.staging_hubspot_form_id
  const productionHubSpotFormId = primary.production_hubspot_form_id
  const stagingHubSpotFormIdConsentRequired = primary?.staging_hubspot_form_id_consent_required
  const productionHubSpotFormIdConsentRequired = primary?.production_hubspot_form_id_consent_required

  let finalFormId

  if (process.env.GATSBY_ACTIVE_ENV === 'production') {
    finalFormId = showConsentForm ? productionHubSpotFormIdConsentRequired : productionHubSpotFormId
  } else {
    finalFormId = showConsentForm ? stagingHubSpotFormIdConsentRequired : stagingHubSpotFormId
  }

  function formSubmitHandler(e) {
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormFailedValidation') {
      let errors = null
      if (Array.isArray(e.data.data)) {
        errors = e.data.data.filter(error => {
          if (error.errorTypes.length) {
            return error
          }
          return null
        })
      }

      if (errors) {
        errors.forEach(error => {
          const fieldLabel = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} > label > span`
          )?.textContent
          const fieldWrapper = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} label.hs-error-msg`
          )
          if (fieldWrapper && fieldLabel) {
            fieldWrapper.textContent = `Please complete the ${fieldLabel.toLowerCase()} field.`
          }
        })

        const firstErr = errors[0]?.name
        if (firstErr) {
          const toFocus = document.querySelector(`.hs-form-lander .hs-input[name=${firstErr}]`)
          if (toFocus) toFocus.focus()
        }
      }
    }
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormSubmit') {
      if (
        e.data.id === stagingHubSpotFormId ||
        e.data.id === productionHubSpotFormId ||
        e.data.id === stagingHubSpotFormIdConsentRequired ||
        e.data.id === productionHubSpotFormIdConsentRequired
      ) {
        trackForm(e.data.data, finalFormId)

        const mappedData = mapValues(keyBy(e.data.data, 'name'), 'value')
        identify(mappedData)
      }
    }
  }

  useEffect(() => {
    if (formLoaded) {
      const persistedUtmParams = JSON.parse(localStorage.getItem('utm_params'))
      if (persistedUtmParams) {
        Object.entries(persistedUtmParams).forEach(param => {
          const hiddenInput = document.querySelector(`input[name="${param[0]}"]`)
          if (hiddenInput) hiddenInput.value = param[1] || ''
        })
      }

      const persistedGclidParam = JSON.parse(localStorage.getItem('gclid_param'))
      const gclidHiddenInput = document.querySelector('input[name="gclid__c"]')
      if (gclidHiddenInput) gclidHiddenInput.value = persistedGclidParam || ''

      const mutinyExperienceName = localStorage.getItem('mutiny_experience_name')
      const mutinySegmentName = localStorage.getItem('mutiny_segment_name')
      const mutinyVariationName = localStorage.getItem('mutiny_variation_name')

      const mutinyExperienceNameInput = document.querySelector(`input[name="mutiny_experience_name"]`)
      if (mutinyExperienceNameInput && mutinyExperienceName) mutinyExperienceNameInput.value = mutinyExperienceName

      const mutinySegmentNameInput = document.querySelector(`input[name="mutiny_segment_name"]`)
      if (mutinySegmentNameInput && mutinySegmentName) mutinySegmentNameInput.value = mutinySegmentName

      const mutinyVariationNameInput = document.querySelector(`input[name="mutiny_variation_name"]`)
      if (mutinyVariationNameInput && mutinyVariationName) mutinyVariationNameInput.value = mutinyVariationName
    }
  }, [formLoaded])

  useEffect(() => {
    window.addEventListener('message', formSubmitHandler)
    return () => {
      window.removeEventListener('message', formSubmitHandler)
    }
  }, [])

  return (
    <Wrapper css={HubspotStyles} showConsentForm={showConsentForm}>
      <TextWrapper>
        <Text heading3>{primary.form_heading}</Text>
      </TextWrapper>
      {!showConsentFormLoading && (
        <HubspotForm
          portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
          formId={finalFormId}
          loading={<div>Loading...</div>}
          onReady={() => {
            setFormLoaded(true)
            trackEvent('Newsletter Subscription Form Viewed')
          }}
          onFormReady={() => {
            if (window._zi_fc?.fcRetrigger instanceof Function) {
              window._zi_fc.fcRetrigger()
            }

            const formInputs = document.querySelectorAll(`#hsForm_${finalFormId} input`)
            formInputs.forEach(input => {
              input.addEventListener('change', e => {
                trackEvent(`Form Value Changed`, {
                  'Form Hubspot ID': finalFormId,
                  'Form Element Name': e.target.name,
                  'Form Element Type': 'input',
                  'New Value': e.target.value,
                })
              })

              input.addEventListener('focus', e => {
                trackEvent(`Form Value Focused`, {
                  'Form Hubspot ID': finalFormId,
                  'Form Element Name': e.target.name,
                  'Form Element Type': 'input',
                  'Current Value': e.target.value,
                })
              })
            })

            const formSelects = document.querySelectorAll(`#hsForm_${finalFormId} select`)
            formSelects.forEach(select => {
              select.addEventListener('change', e => {
                trackEvent(`Form Value Changed`, {
                  'Form Element Name': e.target.name,
                  'Form Element Type': 'select',
                  'New Value': e.target.value,
                })
              })

              select.addEventListener('focus', e => {
                trackEvent(`Form Value Focused`, {
                  'Form Element Name': e.target.name,
                  'Form Element Type': 'select',
                  'Current Value': e.target.value,
                })
              })
            })
          }}
          submitButtonClass="custom-hs-button"
          cssClass="custom-hs-css hs-form-lander"
        />
      )}
      <BG className="bg" backgroundColor={primary.background_color} />
    </Wrapper>
  )
}
export default NewsletterSubscribe

export const query = graphql`
  fragment NewsletterSubscribe on PrismicPageDataBodyNewsletterSubscribe {
    ...SliceBase
    primary {
      production_hubspot_form_id
      staging_hubspot_form_id
      production_hubspot_form_id_consent_required
      staging_hubspot_form_id_consent_required
      form_heading
      background_color
    }
  }
`
