import { css } from '@emotion/react'
import { theme } from '.'

export default css`
  > div {
    background-image: linear-gradient(to bottom, rgba(45, 3, 99, 0.85) 0%, rgba(113, 25, 225, 0.85) 100%);
  }
  * {
    font-size: ${theme.fonts.size.smallBody};
    font-family: ${theme.fonts.family.smallBody};
    font-weight: ${theme.fonts.weight.smallBody};
    letter-spacing: ${theme.fonts.letterSpacing.smallBody};
    color: ${theme.color.offBlack};
  }
  input {
    border-radius: 50%;
  }
  section {
    padding: 8px;
  }
  section > div {
    align-items: flex-start;
    padding: 16px;
  }
  h2 {
    font-size: ${theme.fonts.size.heading3}!important;
    font-family: ${theme.fonts.family.heading3}!important;
    font-weight: ${theme.fonts.weight.heading3}!important;
    letter-spacing: ${theme.fonts.letterSpacing.heading3}!important;
  }
  td,
  td p,
  th {
    font-size: ${theme.fonts.size.smallBody};
    font-family: ${theme.fonts.family.smallBody};
    font-weight: ${theme.fonts.weight.smallBody};
    letter-spacing: ${theme.fonts.letterSpacing.smallBody};
  }

  th[scope='col'] {
    font-size: ${theme.fonts.size.smallBodyMedium};
    font-family: ${theme.fonts.family.smallBodyMedium};
    font-weight: ${theme.fonts.weight.smallBodyMedium};
    letter-spacing: ${theme.fonts.letterSpacing.smallBodyMedium};
  }

  form button {
    position: relative;
    background-image: none;
    box-shadow: none;
    padding: 8px 24px;
    border-radius: 8px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all 150ms;
    z-index: 1;
    height: fit-content;
    width: fit-content;
    font-size: ${theme.fonts.size.smallBodyMedium};
    font-family: ${theme.fonts.family.smallBodyMedium};
    font-weight: ${theme.fonts.weight.smallBodyMedium};
    letter-spacing: ${theme.fonts.letterSpacing.smallBodyMedium};

    /* secondary */
    background-color: transparent;
    border-color: ${theme.color.purple500};
    color: ${theme.color.purple500};

    :hover {
      background-color: transparent;
      border-color: ${theme.color.offBlack};
      color: ${theme.color.offBlack};
      background-image: none;
      box-shadow: none;
    }

    :focus {
      background-color: transparent;
      border-color: ${theme.color.purple500};
      color: ${theme.color.purple500};
    }

    &[type='submit'] {
      /* primary */
      background-color: ${theme.color.purple500};
      color: ${theme.color.white};

      :hover {
        background-color: ${theme.color.purple900};
      }

      :focus {
        background-color: ${theme.color.purple500};
      }
    }
  }
`
