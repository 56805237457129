/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import styled from '@emotion/styled'

import { GridWrapper } from '../blog/PostsGrid'
import PostGridItem from '../blog/PostGridItem'
import TempGridItem from '../blog/TempGridItem'
import Text from '../common/Text'

const Wrapper = styled.div`
  display: grid;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 0px;
  gap: 32px;

  @media (max-width: 1500px) {
    margin-left: 0px;
    width: 100%;
  }

  @media (max-width: 1050px) {
    padding: 64px 0px;
  }
`

const FeaturedContent = ({ slice: { primary, items } }) => {
  const heading = primary.heading.richText?.[0]?.text && <PrismicRichText field={primary.heading.richText} />
  const subtitle = primary.subtitle.richText?.[0]?.text && <PrismicRichText field={primary.subtitle.richText} />

  return (
    <Wrapper>
      {heading && (
        <Text font="heading2" richText center>
          {heading}
        </Text>
      )}
      {subtitle && (
        <Text richText center>
          {subtitle}
        </Text>
      )}
      <GridWrapper>
        {items.map(
          (item, i) =>
            i < 3 && (
              <React.Fragment key={i}>
                {item.linked_content.document ? (
                  <PostGridItem
                    key={item.linked_content.document?.uid}
                    uid={item.linked_content.document?.uid}
                    publishDate={item.linked_content.document?.data.publish_date}
                    title={item.linked_content.document?.data.title.text}
                    index={i}
                    img={item.linked_content.document?.data.banner}
                    categories={[
                      {
                        category: {
                          document: {
                            data: {
                              name: 'Blog',
                            },
                          },
                        },
                      },
                    ]}
                    description={item.linked_content.document?.data?.description}
                  />
                ) : (
                  <TempGridItem
                    key={item.link?.url}
                    uid={item.link?.url}
                    publishDate={item.date}
                    title={item.heading.text}
                    index={i}
                    img={item.image}
                    category={item.content_type}
                    description={item.subheading.text}
                  />
                )}
              </React.Fragment>
            )
        )}
      </GridWrapper>
    </Wrapper>
  )
}

export default FeaturedContent

export const query = graphql`
  fragment FeaturedContent on PrismicPageDataBodyFeaturedContent {
    ...SliceBase
    items {
      linked_content {
        document {
          ... on PrismicPost {
            id
            data {
              title {
                text
              }
              banner {
                alt
                url
                gatsbyImageData(width: 540)
              }
              description
              publish_date
              categories {
                category {
                  document {
                    ... on PrismicCategory {
                      id
                      data {
                        name
                      }
                    }
                  }
                }
              }
            }
            uid
          }
        }
      }
      image {
        url
      }
      heading {
        ...TextContent
      }
      subheading {
        ...TextContent
      }
      date
      content_type
      link {
        url
      }
      button_text {
        ...TextContent
      }
      button_link {
        ...LinkContent
      }
    }
    primary {
      heading {
        ...TextContent
      }
      subtitle {
        ...TextContent
      }
    }
  }
`
