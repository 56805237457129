import React from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import Feature from '../components/pageSlices/Feature'
import BannerTitle from '../components/pageSlices/BannerTitle'
import TrustedByCompanies from '../components/pageSlices/TrustedByCompanies'
import CTABannerLogos from '../components/pageSlices/CTABannerLogos'
import FaqType from '../components/FaqType'
import FlexibleText from '../components/pageSlices/FlexibleText'
import FeaturedIntegrations from '../components/pageSlices/FeaturedIntegrations'
import BannerTitleScan from '../components/pageSlices/BannerTitleScan'
import IntegrationPlatforms from '../components/pageSlices/IntegrationPlatforms'
import ResourcesPanel from '../components/pageSlices/ResourcesPanel'
import BannerBlocks from '../components/pageSlices/BannerBlocks'
import BannerInfo from '../components/pageSlices/BannerInfo'
import RichTextSlice from '../components/pageSlices/RichText'
import Quote from '../components/pageSlices/Quote'
import StaticContentBlock from '../components/pageSlices/StaticContentBlock'
import FeaturedTabs from '../components/pageSlices/FeaturedTabs'
import ManagedCards from '../components/pageSlices/pricing/ManagedCards'
import FeaturesTable from '../components/pageSlices/pricing/FeaturesTable'
import TwoColumnCards from '../components/pageSlices/TwoColumnCards'
import AboutUsCards from '../components/pageSlices/AboutUsCards'
import ScannerBanner from '../components/pageSlices/ScannerBanner'
import AnnouncementBanner from '../components/pageSlices/AnnouncementBanner'
import QuicklinksBar from '../components/pageSlices/QuicklinksBar'
import FeaturedLogos from '../components/pageSlices/FeaturedLogos'
import Image from '../components/pageSlices/Image'
import FourColCards from '../components/pageSlices/FourColCards'
import Sitemap from '../components/pageSlices/Sitemap'
import BannerForm from '../components/pageSlices/BannerForm'
import OnboardingBannerForm from '../components/pageSlices/OnboardingBannerForm'
import FeaturedContent from '../components/pageSlices/FeaturedContent'
import InlineCta from '../components/pageSlices/InlineCta'
import ScheduleMeeting from '../components/pageSlices/ScheduleMeeting'
import NewsletterSubscribe from '../components/pageSlices/NewsletterSubscribe'
import MultiLevelList from '../components/pageSlices/MultiLevelList'
import QuoteAndLogos from '../components/pageSlices/QuoteAndLogos'
import VideoGate from '../components/pageSlices/VideoGate'
import FigmaEmbed from '../components/pageSlices/FigmaEmbed'

export const renderPageSlices = slices => {
  if (!slices) return null
  return <SliceZone slices={slices} components={components} />
}
const components = {
  feature: Feature,
  'banner-title': BannerTitle,
  'trusted-by-companies': TrustedByCompanies,
  'cta-banner-logos': CTABannerLogos,
  'faq-section': FaqType,
  'flexible-text': FlexibleText,
  'featured-integrations': FeaturedIntegrations,
  'banner-title-scan': BannerTitleScan,
  'integration-platforms': IntegrationPlatforms,
  'resources-panel': ResourcesPanel,
  'banner-blocks': BannerBlocks,
  'banner-info': BannerInfo,
  'rich-text': RichTextSlice,
  quote: Quote,
  'static-component': StaticContentBlock,
  'featured-tabs': FeaturedTabs,
  'managed-cards': ManagedCards,
  'features-table': FeaturesTable,
  'two-column-cards': TwoColumnCards,
  'about-us-cards': AboutUsCards,
  scanner: ScannerBanner,
  'announcement-banner': AnnouncementBanner,
  'quicklinks-bar': QuicklinksBar,
  'featured-logos': FeaturedLogos,
  image: Image,
  'four-col-cards': FourColCards,
  sitemap: Sitemap,
  'banner-form': BannerForm,
  'onboarding-banner-form': OnboardingBannerForm,
  'featured-content': FeaturedContent,
  'inline-cta': InlineCta,
  'schedule-meeting': ScheduleMeeting,
  'newsletter-subscribe': NewsletterSubscribe,
  'multi-level-list': MultiLevelList,
  'quote-and-logos': QuoteAndLogos,
  'video-gate': VideoGate,
  figma_embed: FigmaEmbed,
}

export const query = graphql`
  fragment PageDataBody on PrismicPageDataBodySlicesType {
    # Type-specific fields
    ...Feature
    ...BannerTitle
    ...TrustedByCompanies
    ...CTABannerLogos
    ...FlexibleText
    ...FaqType
    ...FeaturedIntegrations
    ...IntegrationPlatforms
    ...ResourcesPanel
    ...BannerBlocks
    ...BannerInfo
    ...RichTextSlice
    ...Quote
    ...FeaturedTabs
    ...StaticContentBlock
    ...ManagedCards
    ...FeaturesTable
    ...BannerTitleScan
    ...TwoColumnCards
    ...AboutUsCards
    ...ScannerBanner
    ...AnnouncementBanner
    ...QuicklinksBar
    ...FeaturedLogos
    ...Image
    ...FourColCards
    ...Sitemap
    ...BannerForm
    ...OnboardingBannerForm
    ...FeaturedContent
    ...InlineCta
    ...ScheduleMeeting
    ...NewsletterSubscribe
    ...MultiLevelList
    ...QuoteAndLogos
    ...VideoGate
    ...FigmaEmbed
  }
`
