/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import Tag from '../common/Tag'
import Text from '../common/Text'
import toKebabCase from '../../utils/toKebabCase'

const Wrapper = styled.div`
  padding: 24px 0px;
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 1050px) {
    justify-content: flex-start;
  }
`

const TagSection = ({ tags }) => {
  if (!tags) return null
  return (
    <Wrapper>
      <Text>Topics:</Text>
      {tags && (
        <TagWrapper>
          {tags.map(tag => {
            const tagName = toKebabCase(tag.tag.document?.data?.name)
            return (
              <Tag
                key={tagName}
                text={tag.tag.document?.data?.name}
                color="gray"
                fontSize="smallCapsSans"
                size="small"
                noMargin
                fontFamily="smallBody"
                isButton
                to={`/blog/posts/${tagName}/`}
              />
            )
          })}
        </TagWrapper>
      )}
    </Wrapper>
  )
}

export default TagSection
