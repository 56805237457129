/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'

import Text from '../common/Text'
import Space from '../common/Space'

const Wrapper = styled.div`
  width: 100%;
  background: ${p => p.theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
`

const StyledListItem = styled.li`
  &.level_2 {
    margin-left: 24px;
    list-style-type: circle;
  }
`

const ListItem = ({ text, level }) => <StyledListItem className={level}>{text}</StyledListItem>

const MultiLevelList = ({ slice: { primary, items } }) => (
  <Wrapper className="narrow-width">
    <Text heading2>{primary.list_title.text}</Text>
    <Space height={8} />
    <ul>
      {items.map(item => (
        <ListItem
          text={item?.list?.richText && <PrismicRichText field={item.list.richText} />}
          level={item?.list_level}
        />
      ))}
    </ul>
  </Wrapper>
)

export default MultiLevelList

export const query = graphql`
  fragment PostMultiLevelList on PrismicPostDataBodyMultiLevelList {
    ...SliceBase
    primary {
      list_title {
        ...TextContent
      }
    }
    items {
      list {
        ...TextContent
      }
      list_level
    }
  }
`
