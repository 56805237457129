import React, { useEffect, createContext, useState } from 'react'

const ConsentContext = createContext()

const ConsentContextProvider = ({ children }) => {
  const [showConsentForm, setShowConsentForm] = useState(false)
  const [showConsentFormLoading, setShowConsentFormLoading] = useState(true)

  useEffect(() => {
    const getUserCountry = async () => {
      const userInfo = await fetch(`/user/fetch-user-info`, {
        headers: {
          'Content-type': 'application/json',
        },
        method: 'GET',
      })

      if (userInfo.ok) {
        const user = await userInfo.json()

        const consent =
          user.country.code === 'DE' ||
          user.country.code === 'IT' ||
          user.country.code === 'ES' ||
          user.country.code === 'FR' ||
          user.country.code === 'DK' ||
          user.country.code === 'FI' ||
          user.country.code === 'IS' ||
          user.country.code === 'NO' ||
          user.country.code === 'NL' ||
          user.country.code === 'GB' ||
          user.country.code === 'UK' ||
          user.country.code === 'SE' ||
          user.country.code === 'IE'

        setShowConsentForm(consent)
      }

      setShowConsentFormLoading(false)
    }

    getUserCountry()
  }, [])

  return (
    <ConsentContext.Provider value={{ showConsentForm, showConsentFormLoading }}>{children}</ConsentContext.Provider>
  )
}

const useShowConsentForm = () => {
  const context = React.useContext(ConsentContext)
  if (context === undefined) {
    throw new Error('useShowConsentForm must be used within a ConsentContextProvider')
  }
  return context
}

export { ConsentContextProvider, useShowConsentForm }
