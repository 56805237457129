export const useFeatureGates = () => {
  const featureGates = {
    plgGateActive: false,
  }
  return featureGates
}

export const getFeatureGates = () => {
  const featureGates = {
    plgGateActive: false,
  }
  return featureGates
}
