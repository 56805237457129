import { useStaticQuery, graphql } from 'gatsby'

export default function () {
  const { prismicBlogCta } = useStaticQuery(
    graphql`
      query BlogCtaQuery {
        prismicBlogCta {
          data {
            title {
              ...TextContent
            }
            text {
              ...TextContent
            }
            button_text {
              ...TextContent
            }
            button_link {
              ...LinkContent
            }
          }
        }
      }
    `
  )
  return prismicBlogCta
}
