import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import styled from '@emotion/styled'
import HubspotForm from 'react-hubspot-form'
import { PrismicRichText } from '@prismicio/react'
import Fade from 'react-reveal/Fade'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { useShowConsentForm } from '../../context/ConsentContext'

import { trackForm } from '../form/hubspot/HubSpotFormAPI'
import { trackEvent, identify } from '../../utils/eventTrack'
import { useFeatureGate } from '../../utils/hooks/FeatureGateContext'
import HubspotStyles from '../form/hubspot/hs-form-styles'
import Text from '../common/Text'
import Space from '../common/Space'
import { theme } from '../../styles'
import VideoIframe from '../common/VideoIframe'

const BG = styled.div`
  position: absolute;
  width: 150vw;
  height: 100%;
  background-size: 125%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-color: hsla(266, 94%, 20%, 1);
  background-image: radial-gradient(at 0% 5%, hsla(170, 69%, 48%, 0.47) 0px, transparent 50%),
    radial-gradient(at 98% 39%, hsla(266, 80%, 49%, 0.8) 0px, transparent 50%),
    radial-gradient(at 100% 99%, hsla(170, 69%, 48%, 0.82) 0px, transparent 50%);
  z-index: -1;
  ${props => props.hasSwoop && 'border-radius: 0 0 100% 100%;'};
  animation: gradient 12s ease-in-out infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 35%;
    }
    50% {
      background-position: 75% 100%;
    }
    100% {
      background-position: 0% 35%;
    }
  }

  @media (max-width: 1050px) {
    width: 275vw;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding: 80px 80px 100px 80px;
  grid-column-gap: 80px;
  width: calc(100% + 300px);
  margin-left: -150px;
  min-height: ${p => (p.formLoaded ? 'auto' : '75vh')};

  .react-reveal {
    width: 100%;
    z-index: 0;
  }

  @media (max-width: 1300px) {
    grid-column-gap: 56px;
  }

  @media (max-width: 1050px) {
    grid-column-gap: 40px;
    width: 100%;
    margin-left: 0px;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
  }

  @media (max-width: 800px) {
    padding: 48px 0px;
  }
`

const LeftWrapper = styled.div`
  grid-column: 1 /2;
  grid-row: 1/ 2;
  &:has(ul, ol) {
    align-items: flex-start;
  }
  ul,
  ol {
    margin-left: 20px;
    li {
      text-align: left;
    }
  }

  @media (max-width: 1050px) {
    grid-row: auto;
    align-items: center;
    * :not(li) {
      text-align: center !important;
    }

  @media (max-width: 480px) {
    * :not(li) {
      text-align: left !important;
    }
  }
`

const FormWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 1/ 2;
  background-color: ${theme.color.white};
  border-radius: 8px;
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  transition: height 200ms ease;
  overflow: hidden;

  & > div {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 1050px) {
    max-width: 500px;
    min-width: unset;
    display: block;
    margin: 32px auto 0px auto;
    padding: 32px;
    width: 100%;
    grid-row: auto;
    grid-column: 1 /2;
  }
`

const RequiredWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  font-size: ${theme.fonts.size.tinyBody};
  span {
    line-height: 1.2 !important;
    color: ${theme.color.red500};
  }
`

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin: 40px 0px;
`

const BannerForm = ({ slice: { primary } }) => {
  const [formLoaded, setFormLoaded] = useState(false)
  const [success, setSuccess] = useState(false)
  const { showConsentForm, showConsentFormLoading } = useShowConsentForm()
  const title = primary.heading.richText?.[0]?.text && <PrismicRichText field={primary.heading.richText} />
  const subtitle = primary.subheading.richText?.[0]?.text && <PrismicRichText field={primary.subheading.richText} />
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const smallCapsText = primary.small_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.small_caps_text.richText} />
  )
  const largeCapsText = primary.large_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.large_caps_text.richText} />
  )
  const footnotes = primary.footnotes?.richText?.[0]?.text
  const formCardHeading = primary.form_card_heading.richText?.[0]?.text && (
    <PrismicRichText field={primary.form_card_heading.richText} />
  )
  const stagingHubSpotFormId = primary?.staging_hubspot_form_id
  const productionHubSpotFormId = primary?.production_hubspot_form_id
  const stagingHubSpotFormIdConsentRequired = primary?.staging_hubspot_form_id_consent_required
  const productionHubSpotFormIdConsentRequired = primary?.production_hubspot_form_id_consent_required
  const meetingFollowUpPage = primary?.meeting_form_follow_up_page
  const video = primary?.video
  const hasSwoop = primary?.has_swoop
  let finalFormId

  if (process.env.GATSBY_ACTIVE_ENV === 'production') {
    finalFormId = showConsentForm ? productionHubSpotFormIdConsentRequired : productionHubSpotFormId
  } else {
    finalFormId = showConsentForm ? stagingHubSpotFormIdConsentRequired : stagingHubSpotFormId
  }

  const featureGates = useFeatureGate()

  function formSubmitHandler(e) {
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormFailedValidation') {
      const errors =
        e.data.data.length > 0 &&
        e.data.data?.filter(error => {
          if (error.errorTypes.length) {
            return error
          }
          return null
        })
      if (errors) {
        errors.forEach(error => {
          const fieldLabel = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} > label > span`
          )?.textContent
          const fieldWrapper = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} label.hs-error-msg`
          )
          if (fieldWrapper && fieldLabel) {
            fieldWrapper.textContent = `Please complete the ${fieldLabel.toLowerCase()} field.`
          }
        })

        const firstErr = errors[0]?.name
        if (firstErr) {
          const toFocus = document.querySelector(`.hs-form-lander .hs-input[name=${firstErr}]`)
          if (toFocus) toFocus.focus()
        }
      }
    }
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormSubmit') {
      if (
        e.data.id === stagingHubSpotFormId ||
        e.data.id === productionHubSpotFormId ||
        e.data.id === stagingHubSpotFormIdConsentRequired ||
        e.data.id === productionHubSpotFormIdConsentRequired
      ) {
        trackForm(e.data.data, finalFormId)
        setSuccess(true)
        const mappedData = mapValues(keyBy(e.data.data, 'name'), 'value')
        identify(mappedData)
        if (meetingFollowUpPage?.url) {
          const utmParams = JSON.parse(localStorage.getItem('utm_params'))

          if (meetingFollowUpPage.url === '/schedule-meeting/') {
            if (utmParams?.utm_campaign !== 'toolbar_referral') {
              navigate(meetingFollowUpPage.url)
            }
          } else {
            navigate(meetingFollowUpPage.url)
          }

          window.sessionStorage.setItem('hs-form-data', JSON.stringify(e.data.data))
        }
      }
    }
  }

  useEffect(() => {
    if (formLoaded) {
      const persistedUtmParams = JSON.parse(localStorage.getItem('utm_params'))
      if (persistedUtmParams) {
        Object.entries(persistedUtmParams).forEach(param => {
          const utmHiddenInput = document.querySelector(`input[name="${param[0]}"]`)
          if (utmHiddenInput) utmHiddenInput.value = param[1] || ''
        })
      }

      const persistedGclidParam = JSON.parse(localStorage.getItem('gclid_param'))
      const gclidHiddenInput = document.querySelector('input[name="gclid__c"]')
      if (gclidHiddenInput) gclidHiddenInput.value = persistedGclidParam || ''

      const mutinyExperienceName = localStorage.getItem('mutiny_experience_name')
      const mutinySegmentName = localStorage.getItem('mutiny_segment_name')
      const mutinyVariationName = localStorage.getItem('mutiny_variation_name')

      const mutinyExperienceNameInput = document.querySelector(`input[name="mutiny_experience_name"]`)
      if (mutinyExperienceNameInput && mutinyExperienceName) mutinyExperienceNameInput.value = mutinyExperienceName

      const mutinySegmentNameInput = document.querySelector(`input[name="mutiny_segment_name"]`)
      if (mutinySegmentNameInput && mutinySegmentName) mutinySegmentNameInput.value = mutinySegmentName

      const mutinyVariationNameInput = document.querySelector(`input[name="mutiny_variation_name"]`)
      if (mutinyVariationNameInput && mutinyVariationName) mutinyVariationNameInput.value = mutinyVariationName
    }
  }, [formLoaded])

  useEffect(() => {
    if (formLoaded) {
      const formNameInput = document.querySelector('input[name="form_name"]')
      let formName
      if (formNameInput) {
        formName = formNameInput.value
      }

      const agencyInput = document.querySelector('input[name="agency_or_freelancer__c"]')
      if (agencyInput) {
        agencyInput.addEventListener('change', e => {
          if (e.target.value === 'Yes') {
            trackEvent(`Agency Input Value Changed`, {
              Value: true,
              'Form Name': formName,
            })
          }
        })
      }
      const testVariantInput = document.querySelector('input[name="variant"]')
      if (testVariantInput && featureGates) {
        const testData = JSON.stringify(featureGates)
        testVariantInput.value = testData
      }
    }

    if (window.location.pathname === '/partner-overview') {
      const howCanWeHelpInput = document.querySelector('form fieldset .hs-message.hs-fieldtype-textarea')
      if (howCanWeHelpInput) {
        howCanWeHelpInput.style.display = 'none'
      }
    }
  }, [formLoaded])

  useEffect(() => {
    window.addEventListener('message', formSubmitHandler)
    return () => {
      window.removeEventListener('message', formSubmitHandler)
    }
  }, [])

  return (
    <Wrapper css={HubspotStyles} formLoaded={formLoaded}>
      <LeftWrapper>
        {smallCapsText && (
          <Text font="smallCaps" richText color="green500">
            {smallCapsText}
          </Text>
        )}
        {largeCapsText && (
          <Text font="largeCaps" richText color="yellow200">
            {largeCapsText}
          </Text>
        )}
        {title && (
          <>
            <Text font="heading1" richText color="white">
              {title}
            </Text>
            <Space height={24} tabletHeight={16} />
          </>
        )}
        {subtitle && (
          <>
            <Text font="heading2" richText color="white">
              {subtitle}
            </Text>
            <Space height={24} tabletHeight={16} />
          </>
        )}
        {text && (
          <Text richText color="white">
            {text}
          </Text>
        )}
        {footnotes && (
          <Text color="white" smallBody>
            {footnotes}
          </Text>
        )}
        {video.url && (
          <VideoWrapper>
            <VideoIframe allowFullScreen videoSrc={video.url} />
          </VideoWrapper>
        )}
      </LeftWrapper>
      <Fade bottom when={formLoaded}>
        <FormWrapper>
          {formCardHeading && (
            <>
              <Text font="heading4" aria-level="2" richText>
                {formCardHeading}
              </Text>
              <Space height={16} />
            </>
          )}
          {(stagingHubSpotFormId ||
            productionHubSpotFormId ||
            stagingHubSpotFormIdConsentRequired ||
            productionHubSpotFormIdConsentRequired) && (
            <>
              {!success && (
                <RequiredWrapper>
                  <span>*&nbsp;</span>
                  <Text tinyBody color="gray700">
                    Required Field
                  </Text>
                </RequiredWrapper>
              )}
              {!showConsentFormLoading && (
                <HubspotForm
                  portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
                  formId={finalFormId}
                  loading={<div>Loading...</div>}
                  onReady={() => {
                    setFormLoaded(true)
                    trackEvent('Banner Form Viewed')
                  }}
                  onFormReady={() => {
                    if (window._zi_fc?.fcRetrigger instanceof Function) {
                      window._zi_fc.fcRetrigger()
                    }

                    const formInputs = document.querySelectorAll(`#hsForm_${finalFormId} input`)
                    formInputs.forEach(input => {
                      input.addEventListener('change', e => {
                        trackEvent(`Form Value Changed`, {
                          'Form Hubspot ID': finalFormId,
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'input',
                          'New Value': e.target.value,
                        })
                      })

                      input.addEventListener('focus', e => {
                        trackEvent(`Form Value Focused`, {
                          'Form Hubspot ID': finalFormId,
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'input',
                          'Current Value': e.target.value,
                        })
                      })
                    })

                    const formSelects = document.querySelectorAll(`#hsForm_${finalFormId} select`)
                    formSelects.forEach(select => {
                      select.addEventListener('change', e => {
                        trackEvent(`Form Value Changed`, {
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'select',
                          'New Value': e.target.value,
                        })
                      })

                      select.addEventListener('focus', e => {
                        trackEvent(`Form Value Focused`, {
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'select',
                          'Current Value': e.target.value,
                        })
                      })
                    })
                  }}
                  submitButtonClass="custom-hs-button"
                  cssClass="custom-hs-css hs-form-lander"
                />
              )}
            </>
          )}
        </FormWrapper>
      </Fade>
      <BG className="bg" hasSwoop={hasSwoop === null || hasSwoop} />
    </Wrapper>
  )
}

export default BannerForm

export const query = graphql`
  fragment BannerForm on PrismicPageDataBodyBannerForm {
    ...SliceBase
    primary {
      production_hubspot_form_id
      staging_hubspot_form_id
      production_hubspot_form_id_consent_required
      staging_hubspot_form_id_consent_required
      meeting_form_follow_up_page {
        url
      }
      large_caps_text {
        ...TextContent
      }
      small_caps_text {
        ...TextContent
      }
      heading {
        ...TextContent
      }
      subheading {
        ...TextContent
      }
      text {
        ...TextContent
      }
      footnotes {
        ...TextContent
      }
      form_card_heading {
        ...TextContent
      }
      has_swoop
      video {
        url
      }
    }
  }
`
