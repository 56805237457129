import { useCallback, useEffect } from 'react'

export default function useEscape(active, onClose) {
  const onEchap = useCallback(
    event => {
      if (event.keyCode === 27) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (active) {
      window.addEventListener('keydown', onEchap)

      return () => {
        window.removeEventListener('keydown', onEchap)
      }
    }
  }, [onEchap, active])
}
