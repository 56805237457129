import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import { GridWrapper } from './PostsGrid'
import PostGridItem from './PostGridItem'
import Text from '../common/Text'

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  padding: 64px 0px;

  @media (max-width: 1050px) {
    padding: 64px 0px;
  }
`

const PostsWrapper = styled(GridWrapper)`
  padding-top: 40px;
`

const RelatedPosts = ({ postCategory, uid }) => {
  if (!postCategory) return null

  const relatedPostsQuery = graphql`
    query relatedPostsQuery {
      allPrismicPost(
        sort: { fields: data___publish_date, order: DESC }
        filter: {
          data: {
            post_type: { eq: "Blog" }
            publish_date: { ne: null }
            hide_from_feeds: { eq: false }
            password: { eq: null }
          }
        }
      ) {
        totalCount
        edges {
          node {
            uid
            data {
              description
              publish_date
              hide_from_feeds
              password
              title {
                text
              }
              banner {
                alt
                url
                gatsbyImageData(width: 540)
              }
              categories {
                category {
                  document {
                    ... on PrismicCategory {
                      id
                      data {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  return (
    <Wrapper>
      <Text heading2 center>
        Keep Reading
      </Text>
      <StaticQuery
        query={relatedPostsQuery}
        render={({ allPrismicPost: allPostsData }) => {
          if (!allPostsData) {
            return null
          }
          const relatedPosts = allPostsData.edges.filter(({ node }) => {
            if (node.uid === uid) {
              return null
            }

            return node.data.categories.find(({ category }) =>
              category?.document?.data.name.includes(postCategory) ? node : null
            )
          })

          if (relatedPosts?.length > 0)
            return (
              <PostsWrapper>
                {relatedPosts.map(
                  ({ node }, i) =>
                    i < 3 &&
                    node?.uid && (
                      <PostGridItem
                        key={node?.uid}
                        uid={node?.uid}
                        publishDate={node?.data.publish_date}
                        title={node?.data.title.text}
                        index={i}
                        img={node?.data.banner}
                        categories={node?.data?.categories}
                        description={node?.data?.description}
                      />
                    )
                )}
              </PostsWrapper>
            )
          return null
        }}
      />
    </Wrapper>
  )
}

export default RelatedPosts
