import React from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import Text from '../common/Text'

const Wrapper = styled.nav`
  display: flex;
  gap: 24px;
  align-items: center;

  @media (max-width: 1050px) {
    flex-direction: column;
    gap: 16px;
  }
`

const FilterList = styled.ul`
  display: flex;
  list-style: none;
  gap: 12px;

  li {
    margin-bottom: 0px;
  }

  @media (max-width: 1050px) {
    flex-wrap: wrap;
  }
`

const FilterListButton = styled.button`
  background-color: ${p => (p.activeCategory ? p.theme.color.purple900 : 'transparent')};
  color: ${p => (p.activeCategory ? p.theme.color.white : p.theme.color.offBlack)};
  border-radius: 8px;
  font-size: ${p => p.theme.fonts.size.tinyBody};
  font-family: ${p => p.theme.fonts.family.smallCaps};
  letter-spacing: ${p => p.theme.fonts.letterSpacing.smallCaps};
  text-transform: uppercase;
  padding: 6px 12px;

  &:hover {
    background-color: ${p => (p.activeCategory ? p.theme.color.purple500 : p.theme.color.gray100)};
  }
`

// TODO: ensure this array is sourced from the Prismic Category Document type instead of this hardcoded array
export const categoryArr = ['all', 'accessibility', 'community', 'compliance', 'partnership', 'product']

const FilterListItem = ({ category, activeCategory, setActiveCategory, categoryPage }) => {
  const windowGlobal = typeof window !== `undefined` && window

  const handleCategoryChange = () => {
    if (categoryPage) navigate(`/blog/posts/${category}/`)
    setActiveCategory(category)
    setTimeout(() => {
      const firstGridItem = windowGlobal && document.querySelector('.post-grid-link')
      if (firstGridItem) firstGridItem.focus()
    }, 1000)
  }
  return (
    <li>
      <FilterListButton activeCategory={category === activeCategory} onClick={handleCategoryChange}>
        {category}
      </FilterListButton>
    </li>
  )
}

export const Filter = ({ activeCategory, setActiveCategory, categoryPage }) => (
  <Wrapper aria-label="Filter">
    <Text smallBodyBold>Filter by category:</Text>
    <FilterList>
      {categoryArr.map(category => (
        <FilterListItem
          categoryPage={categoryPage}
          category={category}
          key={category}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      ))}
    </FilterList>
  </Wrapper>
)
