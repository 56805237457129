/* eslint-disable camelcase */
import React from 'react'
import ChecklistTable from './CheckListTable'

const sections = [
  {
    items: [
      {
        column1: 'Conversion Goals and Tracking',
        column2: 'Ensure your conversion goals are clearly defined and that your tracking is properly set up.',
      },
      {
        column1: 'Call-to-Action (CTA) Analysis',
        column2: 'Check CTA visibility and clarity. Ensure your CTAs are strategically placed throughout your site.',
      },
      {
        column1: 'Form Usability',
        column2:
          'Simplify form fields and ensure they’re relevant. Verify that error messages are clear and have a smooth submission process.',
      },
      {
        column1: 'Landing Page Effectiveness',
        column2:
          'Confirm landing page relevance and alignment with CTAs and that you have a compelling, visually appealing design.',
      },
      {
        column1: 'User Experience (UX)',
        column2: 'Check ease of navigation and page load speed on both desktop and mobile devices.',
      },
    ],
  },
]

const WebsiteCROAuditChecklist = () => <ChecklistTable checklistItems={sections} title="Website CRO Audit Checklist" />

export default WebsiteCROAuditChecklist
