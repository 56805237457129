/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import idx from 'idx'
import PostContent from '../templates/PostContent'

const PostPage = props => {
  const {
    location,
    data: { prismicPost },
  } = props
  if (!prismicPost?.data) return null

  const [authenticated, setAuthenticated] = useState(false)
  const postPassword = prismicPost.data.password

  useEffect(() => {
    if (!authenticated && postPassword?.length) {
      setAuthenticated(false)
      // eslint-disable-next-line no-alert
      const passwordValue = typeof window !== 'undefined' ? window.prompt('Password required') : undefined
      if (passwordValue === postPassword) setAuthenticated(true)
    }
  }, [])

  const mappedPost = prismicPost.data

  const [author, setAuthor] = useState({
    first_name: { text: 'AudioEye' },
    last_name: { text: '' },
    profile_image: {
      url: 'https://audioeye-web.cdn.prismic.io/audioeye-web/0c3d9cf6-5954-421a-a2fb-b73616290d10_audioeye-author-photo.svg',
    },
    description: [{ text: '' }],
    uid: 'audioeye',
    id: null,
    url: null,
    title: null,
  })

  useEffect(() => {
    if (idx(mappedPost, _ => _.author.document.data.first_name)) {
      setAuthor({
        first_name: mappedPost.author.document.data.first_name,
        last_name: mappedPost.author.document.data.last_name,
        profile_image: mappedPost.author.document.data.profile_image,
        description: mappedPost.author.document.data.description.text,
        uid: mappedPost.author.document.uid,
        id: mappedPost.author.document.id,
        title: mappedPost.author.document?.data?.title,
      })
    } else if (idx(mappedPost, _ => _.author.url)) {
      setAuthor({
        first_name: { text: idx(mappedPost, _ => _.guest_author_name.text) || 'Guest Author' },
        last_name: { text: '' },
        profile_image: {
          url: 'https://images.prismic.io/audioeye-web/a113e748-692f-4650-86b5-1e65a4495653_author-icon.png?auto=compress,format',
        },
        description: [{ text: '' }],
        url: mappedPost.author.url,
        target: mappedPost.author.target,
        title: mappedPost.author.document?.data?.title,
      })
    }
  }, [])

  const preloadImg = mappedPost?.banner?.url
  const postType = mappedPost?.post_type

  return authenticated || !postPassword ? (
    <PostContent
      postData={mappedPost}
      author={author}
      preloadImg={preloadImg}
      socialBannerVideo={mappedPost?.video?.url}
      location={location}
      postType={postType}
      uid={prismicPost.uid}
    />
  ) : null
}

export default withPrismicPreview(PostPage)

export const query = graphql`
  query PostBySlug($id: String!) {
    prismicPost(id: { eq: $id }) {
      _previewable
      id
      uid
      url
      data {
        post_type
        password
        has_table_of_contents
        hide_table_of_contents_cta
        show_scanner_form
        scanner_form_label_text
        table_of_contents_heading_text
        table_of_contents_cta_text {
          ...TextContent
        }
        table_of_contents_cta_link {
          ...LinkContent
        }
        table_of_contents_links {
          table_of_content_link_text {
            ...TextContent
          }
          table_of_content_anchor_link {
            ...TextContent
          }
        }
        canonical_url
        show_cta
        show_secondary_cta
        video {
          url
        }
        banner {
          alt
          url
          gatsbyImageData(width: 1160)
        }
        summary {
          ...TextContent
        }
        title {
          ...TextContent
        }
        title_override {
          ...TextContent
        }
        description
        publish_date
        guest_author_name {
          ...TextContent
        }
        author {
          link_type
          url
          document {
            ...PostAuthor
          }
        }
        body {
          ...PostDataBody
        }
        categories {
          category {
            document {
              ... on PrismicCategory {
                id
                data {
                  name
                }
              }
            }
          }
        }
        tags {
          tag {
            document {
              ... on PrismicTag {
                id
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
