import { getFeatureGates } from './getGatesClient'

export const trackEvent = async (eventName, data) => {
  const featureGates = getFeatureGates()

  const marketingEvents = ['Form Value Changed', 'Form Value Focused']
  const facebookEvents = ['Page Viewed', 'Button Clicked', 'Hubspot Form Submitted']

  const identifiedData = {
    ...data,
    mvtest: featureGates,
  }

  if (typeof window !== 'undefined') {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ...identifiedData,
      })
    }

    if (window.heap) {
      window.heap.track(eventName, {
        ...identifiedData,
      })
    }

    if (window.datadogRum?.addAction) {
      if (!marketingEvents.includes(eventName)) {
        window.datadogRum.addAction(eventName, identifiedData)
      }
    }

    if (facebookEvents.includes(eventName)) {
      const facebookEventNameTranslations = {
        'Scanner Gate Form': 'Scanner Gate Form Submission',
        'Color Contrast Checker - Gated': 'Color Contrast Checker Form Submission',
        'Free Consultation Form': 'Free Consultation Form Submission',
        'Onboarding Banner Form': 'Free Trial Sign Up',
        'Onboarding Modal': 'Free Trial Sign Up',
        'Contact Us Form': 'Contact Us Form Submission',
        'Gated Content: Comprehensive Guide to Accessible Web Design (Guide)': 'Accessible Design Guide Download',
      }

      let finalEventName
      let email

      if (
        eventName === 'Button Clicked' &&
        data.Destination.includes('/building-for-digital-accessibility-at-scale-download/')
      ) {
        finalEventName = 'Digital Accessibility at Scale White Paper Download'
      } else if (eventName === 'Hubspot Form Submitted') {
        if (facebookEventNameTranslations[data['Hubspot Form Name']]) {
          finalEventName = facebookEventNameTranslations[data['Hubspot Form Name']]
          email = data.Email
        }
      } else if (eventName === 'Page Viewed') {
        finalEventName = 'PageView'
      }

      if (finalEventName && process.env.GATSBY_ACTIVE_ENV === 'production') {
        await fetch(`/track/facebook-event`, {
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            url: window.location.href,
            eventName: finalEventName,
            email,
          }),
          method: 'POST',
        })
      }
    }
  }
}

export const identify = async formData => {
  if (window.heap && formData.email) {
    window.heap.identify(formData.email)
  }
}
