import React, { useState } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'

import { trackEvent } from '../../utils/eventTrack'
import SearchIcon from '../../../static/icons/misc-icons/search.svg'

const BlogSearch = ({ setQuery, query }) => {
  const [searchQuery, setSearchQuery] = useState(query || '')

  const formSubmit = e => {
    if (e.target.checkValidity()) {
      trackEvent(`Blog Search`, {
        'Search Query': searchQuery,
      })
      navigate(`/blog/search/?query=${searchQuery}`)
    }
  }

  return (
    <Form role="search" action="/blog/search/" method="get" onSubmit={formSubmit}>
      <Label htmlFor="query">Search Articles:</Label>
      <InputContainer>
        <Input
          name="query"
          id="query"
          type="text"
          placeholder="Search..."
          value={searchQuery}
          aria-label="Search for articles"
          aria-controls="searchResults"
          onChange={e => {
            if (setQuery) setQuery(e.target.value)
            setSearchQuery(e.target.value)
          }}
          onBlur={e => {
            trackEvent(`Form Value Changed`, {
              'Form Name': `Blog Search`,
              'Form Element Name': 'query',
              'Form Element Type': 'input',
              'New Value': e.target.value,
            })
          }}
          onFocus={e => {
            trackEvent(`Form Value Focused`, {
              'Form Name': `Blog Search`,
              'Form Element Name': 'query',
              'Form Element Type': 'input',
              'New Value': e.target.value,
            })
          }}
          required
        />
        <Button type="submit" aria-label="Submit to search for article">
          <img src={SearchIcon} alt="Search" aria-hidden="true" />
        </Button>
      </InputContainer>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  position: relative;

  input {
    max-width: 400px;
  }
`

const Label = styled.label`
  font-size: ${props => props.theme.fonts.size.smallBodyBold};
  font-family: ${props => props.theme.fonts.family.smallBodyBold};
  font-weight: ${props => props.theme.fonts.weight.smallBodyBold};
  line-height: ${props => props.theme.fonts.lineHeight.smallBodyBold};
  display: block;
`

const Input = styled.input`
  border-radius: 8px;
  border: 2px solid ${props => props.theme.color.purple500};
  display: block;
  width: 100%;
  padding: 10px 16px;
  height: 48px;
  font-size: ${props => props.theme.fonts.size.smallBody};
  font-family: ${props => props.theme.fonts.family.smallBody};
  font-weight: ${props => props.theme.fonts.weight.smallBody};
  line-height: ${props => props.theme.fonts.lineHeight.smallBody};
`

const Button = styled.button`
  position: absolute;
  right: 0;
  width: 47px;
  height: 47px;
  background-color: ${props => props.theme.color.purple500};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  img {
    position: relative;
  }
`

const InputContainer = styled.div`
  display: flex;
  position: relative;
`

export default BlogSearch
