const theme = {
  color: {
    purple900: '#250353',
    purple800: '#360279',
    purple700: '#450498',
    purple600: '#5808BF',
    purple500: '#7119E1',
    purple400: '#8753F4',
    purple300: '#B9A1FF',
    purple200: '#D2C7FF',
    purple100: '#E7E2FF',
    purple50: '#F5F1FD',
    purple25: '#FCFAFF',
    green900: '#00393D',
    green800: '#015959',
    green700: '#03857D',
    green600: '#23AC9C',
    green500: '#22C7B3',
    green400: '#4CDAC9',
    green300: '#82E5D9',
    green200: '#B0ECE5',
    green100: '#CEF3EF',
    green50: '#E7F9F6',
    green25: '#F7FDFC',
    offBlack: '#141316',
    daiBlack: '#13171F',
    white: '#FFFFFF',
    offWhite: '#F1F2F6', // gray50 in Design system
    grayNewDesign: '#6C758E',
    gray900: '#14181F',
    gray800: '#2B323B',
    gray700: '#454B54',
    gray600: '#505863',
    gray500: '#69707D',
    gray400: '#888E99',
    gray300: '#B0B6C0',
    gray200: '#D0D3DB',
    gray100: '#E4E6EA',
    gray50: '#F1F2F6',
    gray25: '#FBFCFE',
    blue900: '#262B73',
    blue800: '#2B2FA1',
    blue700: '#414AC8',
    blue600: '#5165EC',
    blue500: '#6980F2',
    blue400: '#8098F9',
    blue300: '#A8BBFA',
    blue200: '#CDDAFE',
    blue100: '#E0E9FF',
    blue50: '#EEF4FF',
    blue25: '#F5F8FF',
    yellow900: '#472207',
    yellow800: '#7E3D00',
    yellow700: '#AF621B',
    yellow600: '#CE820F',
    yellow500: '#EA9D08',
    yellow400: '#FBB346',
    yellow300: '#FFCD70',
    yellow200: '#FFDB92',
    yellow100: '#FFEBBD',
    yellow50: '#FFF6E2',
    yellow25: '#FFFCF5',
    red900: '#660600',
    red800: '#990A00',
    red700: '#B81F14',
    red600: '#D34137',
    red500: '#F96B61',
    red400: '#FF8C85',
    red300: '#FFA9A3',
    red200: '#FBC9C5',
    red100: '#FDE4E3',
    red50: '#FEF2F1',
    red25: '#FFFAFA',
    pink900: '#99004D',
    pink500: '#E60073',
    pink200: '#F066AB',
    pink100: '#FFA1BE',
    pink50: '#FFE5F0',
    orange100: '#FEFDFB',
    orange200: '#FFFBF5',
    orange300: '#FDF5E8',
    orange500: '#FFD088',
    orange900: '#952C00',
    transparent: 'transparent',
    dropShadow: 'rgba(145, 140, 151, 0.15)',
    darkShadow: '	rgba(102, 99, 105, 0.18)',
  },
  fonts: {
    size: {
      heading1: '2.4rem',
      heading2: '2rem',
      heading3: '1.6rem',
      heading4: '1.2rem',
      largeBody: '1.2rem',
      xLargeBodyBold: '1.6rem',
      bodyBold: '1rem',
      bodyMedium: '1rem',
      body: '1rem',
      smallBodyBold: '.8rem',
      smallBodyMedium: '.8rem',
      smallBody: '.8rem',
      tinyBody: '.64rem',
      largeCaps: '1rem',
      largeCapsInter: '.9rem',
      smallCaps: '.7rem',
      smallCapsSans: '.7rem',
    },
    family: {
      heading1: 'EpilogueVariable, Helvetica, Arial, sans-serif',
      heading2: 'EpilogueVariable, Helvetica, Arial, sans-serif',
      heading3: 'EpilogueVariable, Helvetica, Arial, sans-serif',
      heading4: 'EpilogueVariable, Helvetica, Arial, sans-serif',
      largeBody: 'InterVariable, Helvetica, Arial, sans-serif',
      xLargeBodyBold: 'InterVariable, Helvetica, Arial, sans-serif',
      bodyBold: 'InterVariable, Helvetica, Arial, sans-serif',
      bodyMedium: 'InterVariable, Helvetica, Arial, sans-serif',
      body: 'InterVariable, Helvetica, Arial, sans-serif',
      smallBodyBold: 'InterVariable, Helvetica, Arial, sans-serif',
      smallBodyMedium: 'InterVariable, Helvetica, Arial, sans-serif',
      smallBody: 'InterVariable, Helvetica, Arial, sans-serif',
      tinyBody: 'InterVariable, Helvetica, Arial, sans-serif',
      largeCaps: 'JetBrains MonoVariable, Helvetica, Arial, sans-serif',
      largeCapsInter: 'InterVariable, Helvetica, Arial, sans-serif',
      smallCaps: 'JetBrains MonoVariable, Helvetica, Arial, sans-serif',
      smallCapsSans: 'InterVariable, Helvetica, Arial, sans-serif',
    },
    weight: {
      heading1: '700',
      heading2: '700',
      heading3: '700',
      heading4: '700',
      largeBody: '400',
      xLargeBodyBold: '700',
      bodyBold: '700',
      bodyMedium: '500',
      body: '400',
      smallBodyBold: '700',
      smallBodyMedium: '500',
      smallBody: '400',
      tinyBody: '500',
      largeCaps: '500',
      largeCapsInter: '500',
      smallCaps: '400',
      smallCapsSans: '400',
    },

    lineHeight: {
      heading1: 1.175,
      heading2: 1.2,
      heading3: 1.25,
      heading4: 1.3,
      largeBody: 1.35,
      xLargeBodyBold: 1.25,
      bodyBold: 1.45,
      bodyMedium: 1.45,
      body: 1.5,
      smallBodyBold: 1.5,
      smallBodyMedium: 1.5,
      smallBody: 1.5,
      tinyBody: 1.9,
      largeCaps: 1.8,
      largeCapsInter: 1.8,
      smallCaps: 1.8,
      smallCapsSans: 1.8,
    },
    letterSpacing: {
      heading1: '.02rem',
      heading2: '.01rem',
      heading3: '.005rem',
      heading4: 0,
      largeBody: 0,
      bodyBold: 0,
      bodyMedium: 0,
      body: 0,
      smallBodyBold: 0,
      smallBodyMedium: 0,
      smallBody: 0,
      tinyBody: 0,
      largeCaps: '.0375rem',
      largeCapsInter: '.0375rem',
      smallCaps: '.0375rem',
      smallCapsSans: '.0375rem',
    },
  },
  focusOutline: '3px solid #7119E1',
  darkBgFocusOutline: '3px solid #25D0B4',
}

export default theme
