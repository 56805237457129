/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import idx from 'idx'
import styled from '@emotion/styled'

import Text from '../common/Text'
import Button from '../common/Button'
import Space from '../common/Space'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: stretch;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 150px;

  @media (max-width: 1050px) {
    display: block;
  }
`

const BlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.15s ease-in-out;
  margin: 16px;
  padding: 24px;

  @media (max-width: 1050px) {
    margin: 10px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p {
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    transform: scale(1.175);
  }

  @media (max-width: 800px) {
    &:hover {
      transform: none;
    }
  }
`

const Image = styled.img`
  max-height: 64px;
  max-width: 150px;
  min-width: 110px;

  @media (max-width: 1050px) {
    min-width: 90px;
    max-width: 100%;
  }
`

const Block = ({ image, link }) => (
  <BlockWrapper>
    {link ? (
      <Link to={link}>
        <div style={{ display: 'flex', minHeight: '88px', alignItems: 'center' }}>{getImage(image, Image)}</div>
      </Link>
    ) : (
      <div style={{ display: 'flex', minHeight: '88px', alignItems: 'center' }}>{getImage(image, Image)}</div>
    )}
  </BlockWrapper>
)

const InfoWrapper = styled.div`
  background: ${theme.color.purple900};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  padding: 56px 64px;
  border-radius: 16px 0px 0px 16px;

  @media (max-width: 1200px) {
    padding: 56px 32px;
  }

  @media (max-width: 1050px) {
    align-items: center;
    width: 100%;
    padding: 32px;
    border-radius: 16px 16px 0 0;

    * {
      text-align: center !important;
    }
  }

  p {
    max-width: 700px;
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  background: linear-gradient(134.47deg, #f7f7f7 -1.04%, #fcfcfc 94.92%);
  border-radius: 0px 16px 16px 0px;

  @media (max-width: 1050px) {
    width: 100%;
    border-radius: 0 0 16px 16px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;

  @media (max-width: 800px) {
    width: 100%;
  }
`

const ComplexHeading = styled(Text)`
  span {
    font-size: ${theme.fonts.size.heading3};
    font-family: ${theme.fonts.family.heading3};
    font-weight: ${theme.fonts.weight.heading3};
    line-height: ${theme.fonts.lineHeight.heading3};
    letter-spacing: ${theme.fonts.letterSpacing.heading3};
  }
`

const FeaturedIntegrations = ({ slice: { primary, items } }) => {
  const title = primary.title1?.richText?.[0]?.text && primary.title1.text
  const coloredText = primary.colored_text?.richText?.[0]?.text && primary.colored_text.text
  const titleAfterColoredText =
    primary.title_after_colored_text?.richText?.[0]?.text && primary.title_after_colored_text.text
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const linkText = idx(primary, _ => _.link_text.text)
  const linkUrl = idx(primary, _ => _.link_url.url) || (idx(primary, _ => _.link_url.uid) && `/${primary.link_url.uid}`)

  return (
    <Wrapper>
      <InfoWrapper>
        {title && (
          <ComplexHeading heading3 color="white">
            {title}
            {coloredText && (
              <Text isSpan className="colored-text" color="green300">
                {' '}
                {coloredText}
              </Text>
            )}
            {titleAfterColoredText && (
              <Text isSpan className="secondary-heading" color="white">
                {' '}
                {titleAfterColoredText}
              </Text>
            )}
          </ComplexHeading>
        )}
        {text && (
          <>
            <Space height={25} />
            <Text richText color="white">
              {text}
            </Text>
          </>
        )}
        {linkText && (
          <LinkWrapper>
            <Button to={linkUrl} text={linkText} buttonType="tertiary reversed" />
          </LinkWrapper>
        )}
      </InfoWrapper>
      <BlocksWrapper>
        {items &&
          items[0] &&
          items.map((item, index) => (
            <Block
              image={item.image}
              link={(item.link && item.link.uid && `/${item.link.uid}`) || (item.link && item.link.url)}
              linkText={idx(item.link_text, _ => _.text)}
              key={`${item.image.alt}-${index}`}
            />
          ))}
      </BlocksWrapper>
    </Wrapper>
  )
}

export default FeaturedIntegrations

export const query = graphql`
  fragment FeaturedIntegrations on PrismicPageDataBodyFeaturedIntegrations {
    ...SliceBase
    items {
      image {
        alt
        url
        gatsbyImageData(width: 200)
      }
      link_text {
        ...TextContent
      }
      link {
        ...LinkContent
      }
    }
    primary {
      text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
      colored_text {
        ...TextContent
      }
      title_after_colored_text {
        ...TextContent
      }
      link_text {
        ...TextContent
      }
      link_url {
        ...LinkContent
      }
    }
  }
`
