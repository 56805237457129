import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import getImage from '../../utils/getImage'

const ImageWrapper = styled.div`
  margin-top: ${p => (p.hasTopPadding ? '80px' : '0px')};
  margin-bottom: ${p => (p.hasBottomPadding ? '80px' : '0px')};
  width: 100%;
  height: auto;
`

export const ImageEl = styled.img`
  width: 100%;
  height: auto;
`

const Image = ({ slice: { primary } }) => {
  const hasTopPadding = primary?.has_top_padding
  const hasBottomPadding = primary?.has_bottom_padding

  return (
    <ImageWrapper hasBottomPadding={hasBottomPadding} hasTopPadding={hasTopPadding}>
      {getImage(primary.image, ImageEl, { hasTopPadding, hasBottomPadding })}
    </ImageWrapper>
  )
}

export default Image

export const query = graphql`
  fragment Image on PrismicPageDataBodyImage {
    ...SliceBase
    primary {
      has_top_padding
      has_bottom_padding
      image {
        alt
        url
        gatsbyImageData(width: 1160)
      }
    }
  }
`
