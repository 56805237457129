import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import HubspotForm from 'react-hubspot-form'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { useShowConsentForm } from '../../context/ConsentContext'

import { trackEvent, identify } from '../../utils/eventTrack'

import { trackForm } from '../form/hubspot/HubSpotFormAPI'
import HubspotStyles from '../form/hubspot/hs-form-styles'
import Text from './Text'
import Space from './Space'
import { theme } from '../../styles'

import { useUserLang } from '../../context/UserLangContext'

import generateScheduleDemoFormTranslations from '../../data/generateScheduleDemoFormTranslations'

const Wrapper = styled.div`
  width: 100%;
  min-height: ${p => (p.formLoaded ? 'auto' : '75vh')};

  [type='submit'] {
    width: 100% !important;
  }
`

const FormWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 1/ 2;
  display: flex;
  flex-direction: column;
  transition: height 200ms ease;

  & > div {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    & > div,
    h4 {
      max-width: 500px;
      margin: 0 auto;
      width: 100%;
    }
  }
`

const RequiredWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  font-size: ${theme.fonts.size.tinyBody};
  span {
    line-height: 1.2 !important;
    color: ${theme.color.red500};
  }
`

const ScheduleDemoForm = () => {
  const [formLoaded, setFormLoaded] = useState(false)
  const { showConsentForm, showConsentFormLoading } = useShowConsentForm()
  const [success, setSuccess] = useState(false)
  const { userLang } = useUserLang()
  const languageData = generateScheduleDemoFormTranslations({ userLang })

  const formIds = {
    production: {
      consentRequired: {
        en: '051afc10-1a39-40ac-85b0-00fdd04f4216',
        de: 'f0003479-addc-43f1-800b-9ba22fdef0b1',
        fr: 'eeee751e-f1da-4295-bc0c-de904e8236c9',
        es: '79673f6f-9118-41bf-82ec-a9d3e17c7c3a',
        it: 'fe3fb3b3-faec-44ee-9345-65f5de398955',
      },
      noConsentRequired: {
        en: '496fc7a9-716e-4248-8e7b-d4e826a2609f',
        de: 'd1ddd147-ec03-4ed2-a875-1093175f02ae',
        fr: '12ba35af-bac3-4cc9-a087-e8b8cd13c736',
        es: 'b43d714d-13c3-43c4-aa4e-7e6f126b2bdb',
        it: '041a1f62-35f0-4e36-8ca3-4d1485bad5d5',
      },
    },
    staging: {
      consentRequired: {
        en: '6a2c0140-6fdc-4501-9290-5ccecaa30b81',
        de: '3a06f380-e959-4258-9684-e7619a7813ef',
        fr: '174a025a-953e-40cf-bb81-a1c133355811',
        es: 'cc22a475-1623-4cf5-9f30-fe6457ca81cd',
        it: '4bd75c74-7df0-414d-929f-ea1f0fea90be',
      },
      noConsentRequired: {
        en: 'f18a5d8a-4364-417f-ae23-7bd8323d2e3f',
        de: '529361df-6340-42e2-97b7-25b2220ffa85',
        fr: '5f318b92-caf8-4bba-a335-0d2242de67c8',
        es: '02bd7e7f-461f-4d68-b943-52a2869c90f2',
        it: 'f2849091-c2b8-40bb-9f7b-8172b0dd438c',
      },
    },
  }

  const environment = process.env.GATSBY_ACTIVE_ENV === 'production' ? 'production' : 'staging'
  const consent = showConsentForm ? 'consentRequired' : 'noConsentRequired'
  const lang = userLang

  const finalFormId = formIds[environment][consent][lang]

  async function formSubmitHandler(e) {
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormFailedValidation') {
      const errors =
        e.data.data.length > 0 &&
        e.data.data?.filter(error => {
          if (error.errorTypes.length) {
            return error
          }
          return null
        })
      if (errors) {
        errors.forEach(error => {
          const fieldLabel = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} > label > span`
          )?.textContent
          const fieldWrapper = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} label.hs-error-msg`
          )
          if (fieldWrapper && fieldLabel) {
            fieldWrapper.textContent = `Please complete the ${fieldLabel.toLowerCase()} field.`
          }
        })

        const firstErr = errors[0]?.name
        if (firstErr) {
          const toFocus = document.querySelector(`.hs-form-lander .hs-input[name=${firstErr}]`)
          if (toFocus) toFocus.focus()
        }
      }
    }
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormSubmit') {
      if (e.data.id === finalFormId) {
        trackForm(e.data.data, finalFormId)
        setSuccess(true)

        const mappedData = mapValues(keyBy(e.data.data, 'name'), 'value')
        identify(mappedData)

        const utmParams = JSON.parse(localStorage.getItem('utm_params'))

        let agencyOrFreelancer = false

        e.data.data.forEach(key => {
          if (key.name === 'agency_or_freelancer__c' && key.value === 'Yes') {
            agencyOrFreelancer = true
          }
        })

        /* eslint-disable indent */
        if (utmParams.utm_campaign !== 'toolbar_referral') {
          const revenue = parseInt(mappedData?.['0-2/annualrevenue']?.replaceAll('$', '')?.replaceAll(',', ''), 10)
          const finalRevenue = Number.isNaN(revenue) ? 0 : revenue
          let redirect = '/schedule-meeting/'

          if (agencyOrFreelancer) {
            redirect = '/schedule-meeting-partner/'
          } else if (finalRevenue >= 500000000) {
            redirect = '/schedule-meeting-enterprise/'
          } else if (finalRevenue >= 100000000 && finalRevenue < 500000000) {
            redirect = '/schedule-meeting-mid-market/'
          } else if (finalRevenue >= 10000000 && finalRevenue < 100000000) {
            redirect = '/schedule-meeting-lower-mid-market/'
          }

          navigate(redirect)
        }

        window.sessionStorage.setItem('hs-form-data', JSON.stringify(e.data.data))
      }
    }
  }

  useEffect(() => {
    if (formLoaded) {
      const persistedUtmParams = JSON.parse(localStorage.getItem('utm_params'))
      if (persistedUtmParams) {
        Object.entries(persistedUtmParams).forEach(param => {
          const utmHiddenInput = document.querySelector(`input[name="${param[0]}"]`)
          if (utmHiddenInput) utmHiddenInput.value = param[1] || ''
        })
      }

      const persistedGclidParam = JSON.parse(localStorage.getItem('gclid_param'))
      const gclidHiddenInput = document.querySelector('input[name="gclid__c"]')
      if (gclidHiddenInput) gclidHiddenInput.value = persistedGclidParam || ''

      const mutinyExperienceName = localStorage.getItem('mutiny_experience_name')
      const mutinySegmentName = localStorage.getItem('mutiny_segment_name')
      const mutinyVariationName = localStorage.getItem('mutiny_variation_name')

      const mutinyExperienceNameInput = document.querySelector(`input[name="mutiny_experience_name"]`)
      if (mutinyExperienceNameInput && mutinyExperienceName) mutinyExperienceNameInput.value = mutinyExperienceName

      const mutinySegmentNameInput = document.querySelector(`input[name="mutiny_segment_name"]`)
      if (mutinySegmentNameInput && mutinySegmentName) mutinySegmentNameInput.value = mutinySegmentName

      const mutinyVariationNameInput = document.querySelector(`input[name="mutiny_variation_name"]`)
      if (mutinyVariationNameInput && mutinyVariationName) mutinyVariationNameInput.value = mutinyVariationName
    }
  }, [formLoaded])

  useEffect(() => {
    window.addEventListener('message', formSubmitHandler)
    return () => {
      window.removeEventListener('message', formSubmitHandler)
    }
  }, [])

  return (
    <Wrapper css={HubspotStyles} formLoaded={formLoaded}>
      <div>
        <FormWrapper>
          <Text heading4 aria-level="2" color="purple500">
            {languageData?.heading}
          </Text>
          <Space height={16} />

          {!success && (
            <RequiredWrapper>
              <span>*&nbsp;</span>
              <Text tinyBody color="gray700">
                {languageData?.requiredField}
              </Text>
            </RequiredWrapper>
          )}
          {!showConsentFormLoading && (
            <HubspotForm
              portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
              formId={finalFormId}
              onReady={() => {
                setFormLoaded(true)
                trackEvent('Schedule Demo Form Viewed')
              }}
              onFormReady={() => {
                if (window._zi_fc?.fcRetrigger instanceof Function) {
                  window._zi_fc.fcRetrigger()
                }

                const formInputs = document.querySelectorAll(`#hsForm_${finalFormId} input`)
                formInputs.forEach(input => {
                  input.addEventListener('change', e => {
                    trackEvent(`Form Value Changed`, {
                      'Form Name': 'Free Consultation Form',
                      'Form Hubspot ID': finalFormId,
                      'Form Element Name': e.target.name,
                      'Form Element Type': 'input',
                      'New Value': e.target.value,
                    })
                  })

                  input.addEventListener('focus', e => {
                    trackEvent(`Form Value Focused`, {
                      'Form Name': 'Free Consultation Form',
                      'Form Hubspot ID': finalFormId,
                      'Form Element Name': e.target.name,
                      'Form Element Type': 'input',
                      'Current Value': e.target.value,
                    })
                  })
                })

                const formSelects = document.querySelectorAll(`#hsForm_${finalFormId} select`)
                formSelects.forEach(select => {
                  select.addEventListener('change', e => {
                    trackEvent(`Form Value Changed`, {
                      'Form Element Name': e.target.name,
                      'Form Element Type': 'select',
                      'New Value': e.target.value,
                    })
                  })

                  select.addEventListener('focus', e => {
                    trackEvent(`Form Value Focused`, {
                      'Form Element Name': e.target.name,
                      'Form Element Type': 'select',
                      'Current Value': e.target.value,
                    })
                  })
                })
              }}
              submitButtonClass="custom-hs-button"
              cssClass="custom-hs-css hs-form-lander expert-audit-form"
            />
          )}
          {!showConsentFormLoading && formLoaded && userLang !== 'en' && (
            <>
              <Space height={16} />
              <Text
                tinyBody
                center
                color="gray700"
                style={{
                  display: 'inline-block',
                  width: '100%',
                }}
              >
                {languageData?.emailCommunications}
              </Text>
            </>
          )}
        </FormWrapper>
      </div>
    </Wrapper>
  )
}

export default ScheduleDemoForm
