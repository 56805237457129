/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import Text from '../common/Text'
import Button from '../common/Button'
import Space from '../common/Space'

const BlogCtaWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  background-image: url('https://audioeye-web.cdn.prismic.io/audioeye-web/fe0888a6-f3c8-4e19-98f3-1266058b3815_blog-bg-color-blob-dai.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 8px;

  @media (max-width: 1050px) {
    min-height: 430px;
  }
`

const BlogCtaCard = ({ title, text, button_text, button_link }) => {
  const ctaTitle = title?.richText?.[0].text && <PrismicRichText field={title.richText} />
  const ctaText = text?.richText?.[0].text && <PrismicRichText field={text.richText} />
  const buttonText = button_text?.text
  const buttonUrl = button_link?.url

  return (
    <BlogCtaWrapper>
      <div>
        {ctaTitle && ctaText && (
          <>
            <Text font="heading3" richText color="purple900">
              {ctaTitle}
            </Text>
            <Space height={8} />
            <Text font="smallBodyBold" richText color="purple500">
              {ctaText}
            </Text>
          </>
        )}
      </div>
      {buttonText && <Button text={buttonText} to={buttonUrl} />}
    </BlogCtaWrapper>
  )
}

export default BlogCtaCard
