import styled from '@emotion/styled'

export const StyledEarningsReport = styled.div`
  margin: 80px 0px;
`

export const StyledTable = styled.table`
  display: grid;
  width: 100%;
  font-family: ${p => p.theme.fonts.family.body};
  margin: 0 auto;
  gap: 8px;

  td,
  th {
    border-bottom: solid 1px gray;
    padding: 0.4em;
    font-size: ${p => p.theme.fonts.size.body};
    font-family: ${p => p.theme.fonts.family.body};
    font-weight: ${p => p.theme.fonts.weight.body};
    line-height: ${p => p.theme.fonts.lineHeight.body};
    letter-spacing: ${p => p.theme.fonts.letterSpacing.body};
  }
  .table-header {
    font-weight: ${p => p.theme.fonts.weight.bodyBold};
  }

  .no-border {
    border: none;
  }
`

export const TableHead = styled.thead``

export const TableHeader = styled.th`
  text-align: ${p => p.alignment || 'left'};
`

export const TableRow = styled.tr`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr repeat(${p => p.colCount}, 1fr);
  align-items: flex-end;
`

export const TableHeadRow = styled(TableRow)`
  grid-template-rows: repeat(${p => p.rowCount}, auto);
  th {
    margin: 0px 4px;
    padding: 4px 0px;
  }
`

export const TableCell = styled.td`
  text-align: ${p => p.alignment || 'right'};
  &.total {
    border-bottom: 4px double black;
    margin-left: 16px;
  }
  &.sub-total {
    margin-left: 16px;
  }
`
