import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Text from './Text'
import chevron from '../../../static/icons/arrow-icons/dropdown-chevron-icon.svg'
import disabledChevron from '../../../static/icons/arrow-icons/dropdown-chevron-icon-gray300.svg'

const PageBlockButton = styled(Link)`
  min-width: 40px;
  min-height: 35px;
  border: 1px solid ${p => (p.disabled ? p.theme.color.gray300 : p.theme.color.offBlack)};
  border-radius: 3px;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${p => (p.currentPage ? p.theme.color.offBlack : p.theme.color.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 0px 10px;
  z-index: 2;

  &:first-of-type {
    margin-left: 0px;
  }

  @media (max-width: 1050px) {
    min-width: unset;
    margin-left: 8px;
    margin-top: 8px;
  }
`

const Img = styled.img`
  width: 14px;
  transform: ${p => (p.previous ? 'rotate(90deg)' : 'rotate(270deg)')};
`

const Pagination = ({ pageInfo, activeFilter }) => {
  if (!pageInfo) return null
  const { currentPage, pageCount } = pageInfo

  const getPageNumberPath = currentIndex => (currentIndex === 0 ? '1' : `${currentIndex + 1}`)
  const prevPagePath =
    currentPage - 1 === 1
      ? `/blog/posts/${activeFilter}`
      : `/blog/posts/${activeFilter}/${(currentPage - 1).toString()}`
  const nextPagePath = `/blog/posts/${activeFilter}/${(currentPage + 1).toString()}`

  return (
    <>
      {currentPage - 1 !== 0 && (
        <PageBlockButton to={prevPagePath} rel="prev" aria-label="Previous page">
          <Img src={currentPage - 1 === 0 ? disabledChevron : chevron} previous alt="" />
        </PageBlockButton>
      )}
      {Array.from({ length: pageCount }, (_, i) => (
        <PageBlockButton
          key={i + 1}
          to={`/blog/posts/${activeFilter}/${i !== 0 ? getPageNumberPath(i) : ''}`}
          currentPage={currentPage === i + 1}
          aria-current={currentPage === i + 1}
        >
          <Text smallBodyBold color={currentPage === i + 1 ? 'white' : ''}>
            {i + 1}
          </Text>
        </PageBlockButton>
      ))}
      {currentPage !== pageCount && (
        <PageBlockButton to={nextPagePath} rel="next" aria-label="Next page">
          <Img src={currentPage === pageCount ? disabledChevron : chevron} next alt="" />
        </PageBlockButton>
      )}
    </>
  )
}

export default Pagination
