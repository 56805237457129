import React from 'react'
import Text from '../components/common/Text'
import TextLink from '../components/common/TextLink'

const generateCheckSiteTranslations = ({ userLang }) => {
  const data = {
    en: {
      loading: {
        heading: "We're setting things up...",
        message: "You'll be redirected to your personal AudioEye dashboard in just a few moments!",
      },
      accountExists: {
        heading: 'An account with this website already exists.',
        message: 'Please login to your AudioEye Portal to add additional websites.',
        buttonText: 'AudioEye Portal',
      },
      error: {
        heading: 'An error occurred',
        message: (
          <>
            <Text style={{ display: 'inline' }}>
              We encountered an error while creating your account. Please try again or{' '}
            </Text>
            <TextLink
              text="contact us"
              to="https://help.audioeye.com/hc/en-us/requests/new?ticket_form_id=360000590331"
            />
            <Text style={{ display: 'inline' }}> if the problem persists.</Text>
          </>
        ),
      },
      siteError: {
        heading: 'An error occurred',
        message: (
          <Text style={{ display: 'inline' }}>
            There is an issue with the website domain you entered. Please try again or contact us.
          </Text>
        ),
      },
    },
    de: {
      loading: {
        heading: 'Wir bereiten die Dinge vor...',
        message: 'Sie werden in wenigen Augenblicken zu Ihrem persönlichen AudioEye-Dashboard weitergeleitet!',
      },
      accountExists: {
        heading: 'Es besteht bereits ein Konto auf dieser Website.',
        message: 'Bitte melden Sie sich bei Ihrem AudioEye-Portal an, um weitere Websites hinzuzufügen.',
        buttonText: 'AudioEye-Portal',
      },
      error: {
        heading: 'Ein Fehler ist aufgetreten',
        message: (
          <>
            <Text style={{ display: 'inline' }}>
              Beim Erstellen Ihres Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder{' '}
            </Text>
            <TextLink
              text="kontaktieren Sie uns"
              to="https://help.audioeye.com/hc/en-us/requests/new?ticket_form_id=360000590331"
            />
            <Text style={{ display: 'inline' }}> wenn das Problem weiterhin besteht.</Text>
          </>
        ),
      },
      siteError: {
        heading: 'Ein Fehler ist aufgetreten',
        message: (
          <Text style={{ display: 'inline' }}>
            Es liegt ein Problem mit der von Ihnen eingegebenen Website-Domain vor. Bitte versuchen Sie es erneut oder
            kontaktieren Sie uns.
          </Text>
        ),
      },
    },
    fr: {
      loading: {
        heading: 'Nous préparons les choses...',
        message: 'Vous serez redirigé vers votre tableau de bord AudioEye personnel dans quelques instants !',
      },
      accountExists: {
        heading: 'Un compte sur ce site existe déjà.',
        message: 'Veuillez vous connecter à votre portail AudioEye pour ajouter des sites Web supplémentaires.',
        buttonText: 'Portail AudioEye',
      },
      error: {
        heading: "Une erreur s'est produite",
        message: (
          <>
            <Text style={{ display: 'inline' }}>
              Nous avons rencontré une erreur lors de la création de votre compte. Veuillez réessayer ou{' '}
            </Text>
            <TextLink
              text="contactez-nous"
              to="https://help.audioeye.com/hc/en-us/requests/new?ticket_form_id=360000590331"
            />
            <Text style={{ display: 'inline' }}> si le problème persiste.</Text>
          </>
        ),
      },
      siteError: {
        heading: "Une erreur s'est produite",
        message: (
          <Text style={{ display: 'inline' }}>
            Il y a un problème avec le domaine du site Web que vous avez saisi. Veuillez réessayer ou contactez-nous.
          </Text>
        ),
      },
    },
    es: {
      loading: {
        heading: 'Estamos configurando todo...',
        message: '¡Serás redirigido a tu panel personal de AudioEye en tan solo unos momentos!',
      },
      accountExists: {
        heading: 'Ya existe una cuenta con este sitio web.',
        message: 'Inicia sesión en tu portal de AudioEye para agregar sitios web adicionales',
        buttonText: 'Portal de AudioEye',
      },
      error: {
        heading: 'Ocurrió un error',
        message: (
          <>
            <Text style={{ display: 'inline' }}>Encontramos un error al crear su cuenta. Inténtelo de nuevo o </Text>
            <TextLink
              text="contáctenos"
              to="https://help.audioeye.com/hc/en-us/requests/new?ticket_form_id=360000590331"
            />
            <Text style={{ display: 'inline' }}> si el problema persiste.</Text>
          </>
        ),
      },
      siteError: {
        heading: 'Ocurrió un error',
        message: (
          <Text style={{ display: 'inline' }}>
            Hay un problema con el dominio del sitio web que ingresó. Por favor inténtelo de nuevo o contáctenos.
          </Text>
        ),
      },
    },
    it: {
      loading: {
        heading: 'Stiamo sistemando le cose...',
        message: 'Verrai reindirizzato alla tua dashboard personale AudioEye in pochi istanti!',
      },
      accountExists: {
        heading: 'Esiste già un account con questo sito.',
        message: 'Accedi al tuo Portale AudioEye per aggiungere altri siti web',
        buttonText: 'Portale AudioEye',
      },
      error: {
        heading: 'Si è verificato un errore',
        message: (
          <>
            <Text style={{ display: 'inline' }}>
              Abbiamo riscontrato un errore durante la creazione del tuo account. Riprova o{' '}
            </Text>
            <TextLink
              text="contattaci"
              to="https://help.audioeye.com/hc/en-us/requests/new?ticket_form_id=360000590331"
            />
            <Text style={{ display: 'inline' }}> se il problema persiste.</Text>
          </>
        ),
      },
      siteError: {
        heading: 'Si è verificato un errore',
        message: (
          <Text style={{ display: 'inline' }}>
            Si è verificato un problema con il dominio del sito web che hai inserito. Per favore riprova o contattaci.
          </Text>
        ),
      },
    },
  }

  return data[userLang] || data.en
}

export default generateCheckSiteTranslations
