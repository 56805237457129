import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-width: 0px;
`

export const YouTubePlayer = ({ videoUrl, redirectUrl, tabIndex }) => {
  // Extract video ID from the YouTube video URL
  const getVideoId = url => {
    const regex = /youtu\.be\/([a-zA-Z0-9_-]{11})/
    const match = url.match(regex)
    return match && match[1]
  }

  useEffect(() => {
    // Function to handle player state change events
    const onPlayerStateChange = event => {
      if (event.data === window.YT.PlayerState.ENDED) {
        navigate(redirectUrl)
      }
    }

    // Load the YouTube IFrame Player API asynchronously
    const script = document.createElement('script')
    script.src = 'https://www.youtube.com/iframe_api'
    document.body.appendChild(script)

    // Define global function called onYouTubeIframeAPIReady to initialize the player
    window.onYouTubeIframeAPIReady = () => {
      // Create a new YouTube player
      // eslint-disable-next-line
      const player = new window.YT.Player('youtube-player', {
        videoId: getVideoId(videoUrl),
        events: {
          onStateChange: onPlayerStateChange,
        },
      })
    }

    // Clean up function
    return () => {
      document.body.removeChild(script)
      window.onYouTubeIframeAPIReady = null
    }
  }, [videoUrl, redirectUrl])

  return (
    <div>
      <Iframe
        tabIndex={tabIndex}
        id="youtube-player"
        src={`https://www.youtube.com/embed/${getVideoId(videoUrl)}?enablejsapi=1`}
        title="YouTube Video"
      />
    </div>
  )
}
