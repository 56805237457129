const generateSrcSet = (src, defaultDim = { width: 0, height: 0 }, width) => {
  if (!src || !width) return null
  if (src.includes('.svg')) return null

  const url = new URL(src)
  const { searchParams } = url
  let maxWidth = searchParams.get('w')
  let maxHeight = searchParams.get('h')

  maxWidth = maxWidth || defaultDim.width
  maxHeight = maxHeight || defaultDim.height

  if (!maxWidth || !maxHeight) return null

  const srcSet = Object.values(
    [0.5, 0.75, 1, 1.5, 2].reduce((set, dpr) => {
      searchParams.set('w', width)
      searchParams.delete('h')
      searchParams.set('dpr', dpr)
      url.search = searchParams.toString()
      const imgUrl = url.toString()

      const size = Math.round(Math.min(width * dpr, maxWidth, width))

      if (!Object.prototype.hasOwnProperty.call(set, size)) {
        set[size] = `${imgUrl} ${size}w`
      }
      return set
    }, {})
  )

  return srcSet
}

export default generateSrcSet
