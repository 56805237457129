import React, { useEffect } from 'react'
import FocusLock from 'react-focus-lock'
import styled from '@emotion/styled'

import ModalPortal from './ModalPortal'
import useEscape from './useEchap'
import CrossIcon from '../../../../static/icons/misc-icons/close-icon.svg'

import { useUserLang } from '../../../context/UserLangContext'
import generateAriaLabelTranslations from '../../../data/generateAriaLabelTranslations'

const Overlay = styled.button`
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background-color: transparent;
  background-image: linear-gradient(to bottom, rgba(45, 3, 99, 0.85) 0%, rgba(113, 25, 225, 0.85) 100%);
  z-index: 1;
`

const Content = styled.div`
  background: white;
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%);
  width: ${p => (p.modalWidth ? `${p.modalWidth}px` : '50%')};
  height: auto;
  max-height: 80vh;
  max-width: 85vw;
  overflow: auto;
  padding: ${props => (props.onboardingModal ? '40px 48px' : '60px')};
  border-radius: 12px;
  box-shadow: 0 50px 100px 5px rgba(50, 50, 93, 0.25), 0 30px 60px -10px rgba(0, 0, 0, 0.3);
  z-index: 2;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-template-columns: 1fr;

  @media (max-width: 1050px) {
    width: ${p => (p.modalWidth ? `${p.modalWidth}px` : '100%')};
  }

  @media (max-width: 800px) {
    padding: ${props => (props.onboardingModal ? '32px 24px' : '60px 30px')};
    transform: translate(0);
    max-width: unset;
    margin: 16px;
    left: 0;
    width: calc(100vw - (var(--scrollbar-width) + 32px));

    ${p => p.mobileStyles}
  }
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px;
  background: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  z-index: 9999;

  :hover {
    background-color: rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 800px) {
    top: 12px;
    right: 12px;
  }
`

const CloseIcon = styled.img`
  width: 14px;
`

const ModalAnimated = ({ active, children, onClose, modalWidth, ariaLabel, mobileStyles, onboardingModal }) => {
  useEscape(active, onClose)
  const { userLang } = useUserLang()
  const languageData = generateAriaLabelTranslations({ userLang })

  useEffect(() => {
    const htmlElement = document.querySelector('html')
    if (active && !htmlElement.classList.contains('overflow-hidden')) {
      htmlElement.classList.add('overflow-hidden')
    } else {
      htmlElement.classList.remove('overflow-hidden')
    }
  }, [active])

  return (
    <ModalPortal active={active}>
      <div aria-label={ariaLabel} aria-modal="true" role="dialog">
        <Overlay onClick={onClose} aria-label={languageData?.close} tabIndex="-1" aria-hidden="true" />
        <FocusLock returnFocus>
          <Content modalWidth={modalWidth} mobileStyles={mobileStyles} onboardingModal={onboardingModal}>
            <CloseButton aria-label={languageData?.close} onClick={onClose}>
              <CloseIcon alt="" src={CrossIcon} />
            </CloseButton>
            {children}
          </Content>
        </FocusLock>
      </div>
    </ModalPortal>
  )
}

export default ModalAnimated
