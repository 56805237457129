import React from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import BodyText from '../components/postSlices/BodyText'
import CodeBlock from '../components/postSlices/CodeBlock'
import Quote from '../components/postSlices/Quote'
import Image from '../components/postSlices/Image'
import PostVideo from '../components/postSlices/Video'
import Html from '../components/postSlices/Html'
import PostEarningsTable from '../components/postSlices/PostEarningsTable'
import MultiLevelList from '../components/postSlices/MultiLevelList'
import Feature from '../components/pageSlices/Feature'
import ScannerBanner from '../components/pageSlices/ScannerBanner'
import ContentBlock from '../components/postSlices/ContentBlock'
import FaqType from '../components/FaqType'
import StaticContentBlock from '../components/pageSlices/StaticContentBlock'

export const renderPostSlices = slices => {
  if (!slices) return null
  return <SliceZone slices={slices} components={components} />
}

const components = {
  text: BodyText,
  code_block: CodeBlock,
  quote: Quote,
  image: Image,
  video: PostVideo,
  raw_html: Html,
  'post-earnings-table': PostEarningsTable,
  'multi-level-list': MultiLevelList,
  feature: Feature,
  scanner: ScannerBanner,
  content_block: ContentBlock,
  'faq-section': FaqType,
  'static-component': StaticContentBlock,
}

export const query = graphql`
  fragment PostDataBody on PrismicPostDataBodySlicesType {
    ...PostBodyText
    ...PostQuote
    ...PostCodeBlock
    ...PostImage
    ...PostVideo
    ...PostHtml
    ...PostEarningsTable
    ...PostMultiLevelList
    ...PostFeature
    ...PostScanner
    ...PostContentBlock
    ...PostFaqType
    ...PostStaticContentBlock
  }
`
