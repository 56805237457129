import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { Script, graphql } from 'gatsby'

import { PrismicRichText } from '@prismicio/react'
import { trackEvent } from '../../utils/eventTrack'
import Text from '../common/Text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  margin-top: 80px;

  @media (max-width: 800px) {
    margin-top: 40px;
  }
`

const Header = styled.div`
  padding: 0px 80px;
  padding-bottom: 32px;

  @media (max-width: 800px) {
    padding: 0px 0px;
    padding-bottom: 48px;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
`

const ScheduleMeeting = ({ slice: { primary } }) => {
  const heading = primary.heading.richText?.[0]?.text && <PrismicRichText field={primary.heading.richText} />
  const meetingID = primary.meeting_embed_id?.text

  const hsFormData = typeof window !== 'undefined' && window.sessionStorage.getItem('hs-form-data')
  const parsedData = JSON.parse(hsFormData)
  const mappedData = mapValues(keyBy(parsedData, 'name'), 'value')

  const hydratedMeetingUrl = `https://meetings.hubspot.com/${meetingID}?embed=true&firstname=${
    mappedData.firstname || ''
  }&lastname=${mappedData.lastname || ''}&email=${mappedData.email || ''}`

  useEffect(() => {
    window.addEventListener('message', async e => {
      if (e.data?.meetingBookSucceeded !== undefined) {
        if (e.data.meetingBookSucceeded === true) {
          trackEvent(`Hubspot Meeting Scheduled`, {
            'Hubspot URL': hydratedMeetingUrl,
          })
        }
        if (e.data.meetingBookSucceeded === false) {
          trackEvent(`API Exception`, {
            Label: `Hubspot meeting general error`,
            'Full Exception': e.data?.meetingsPayload,
          })
        }
      }
    })
  }, [])

  return (
    <Wrapper>
      <Header>
        {heading ? (
          <Text font="heading3" center richText>
            {heading}
          </Text>
        ) : (
          <Text heading3 aria-level="1" center>
            Find some time with our team of accessibility experts to understand your path to compliance text
          </Text>
        )}
      </Header>
      <FormWrapper>
        <div className="meetings-iframe-container" style={{ width: '100%' }} data-src={hydratedMeetingUrl} />
      </FormWrapper>
      <Script src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" type="text/javascript" />
    </Wrapper>
  )
}

export default ScheduleMeeting

export const query = graphql`
  fragment ScheduleMeeting on PrismicPageDataBodyScheduleMeeting {
    ...SliceBase
    primary {
      heading {
        ...TextContent
      }
      meeting_embed_id {
        text
      }
    }
  }
`
