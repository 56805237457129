/* eslint-disable prefer-destructuring */
export default fullName => {
  const user = {}
  if (!fullName) {
    user.firstName = ''
    user.lastName = ''
    return user
  }
  const splitName = fullName.split(' ').filter(item => {
    if (item.length > 0) return item
    return null
  })

  if (splitName.length === 1) {
    user.firstName = splitName[0]
    user.lastName = ' '
  }

  if (splitName.length === 2) {
    user.firstName = splitName[0]
    user.lastName = splitName[1]
  }

  if (splitName.length === 3) {
    user.firstName = `${splitName[0]} ${splitName[1]}`
    user.lastName = splitName[2]
  }
  return user
}
