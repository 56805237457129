/* eslint-disable no-unused-expressions */
import React, { useState } from 'react'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import Select from 'react-select'
import styled from '@emotion/styled'

import en from 'react-phone-number-input/locale/en.json'

import { theme } from '../../styles'
import Text from '../common/Text'
import Input from './Input'

import { useUserLang } from '../../context/UserLangContext'
import generateAriaLabelTranslations from '../../data/generateAriaLabelTranslations'

const customSelectStyles = {
  control: provided => ({
    ...provided,
    borderRadius: '8px',
    borderColor: theme.color.gray300,
    minHeight: '42px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '3px 8px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: theme.color.gray300,
    cursor: 'pointer',
  }),
  menu: provided => ({
    ...provided,
    width: 'max-content',
  }),
}

const CustomCountrySelect = styled(Select)`
  font-size: ${theme.fonts.size.smallBodyMedium};
  font-family: ${theme.fonts.family.smallBodyMedium};
  font-weight: ${theme.fonts.weight.smallBodyMedium};
  background-color: ${theme.color.white};
  margin-top: 8px;
  margin-right: 11px;
  border: transparent;
  min-width: 100px;
  height: 50%;

  input:focus {
    border: none !important;
  }
`

const OpenCustomCountrySelect = styled(CustomCountrySelect)`
  font-size: ${theme.fonts.size.smallBodyMedium};
  font-family: ${theme.fonts.family.smallBodyMedium};
  font-weight: ${theme.fonts.weight.smallBodyMedium};
  text-align-last: left;
  z-index: 3;

  .react-select__control {
    box-shadow: 0 0 0 2px ${theme.color.purple500} !important;

    &:hover {
      border-color: ${theme.color.gray300} !important;
    }
  }
`

const Phone = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

const PhoneInputWrapper = styled.div`
  display: flex;
`

const PhoneInput = styled(Input)``

const PhoneLabelText = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  grid-template-columns: auto 1fr;
  justify-items: flex-end;
  align-items: baseline;
`

const PhoneNumberInput = ({ formData, updateFormData, labelText }) => {
  const [country, setCountry] = useState('US')
  const [openCountry, setOpenCountry] = useState(false)
  const [phoneWarn, setPhoneWarn] = useState(false)

  const { userLang } = useUserLang()
  const languageData = generateAriaLabelTranslations({ userLang })

  const formatNationalPhoneNumber = phoneNumberString => {
    const match = phoneNumberString.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }
    return phoneNumberString
  }

  const handlePhoneInput = value => {
    setPhoneWarn(true)
    const isNum = new RegExp('^[0-9+-.() ]*$')
    if (isNum.test(value)) {
      const countryCode = country.value ? getCountryCallingCode(country.value) : getCountryCallingCode(country)
      const fullNum = `+${countryCode}${value}`
      countryCode === '1'
        ? updateFormData({ phone: formatNationalPhoneNumber(value), intlPhoneNum: fullNum })
        : updateFormData({ phone: value, intlPhoneNum: fullNum })
      if (value.length >= 10) setPhoneWarn(false)
      if (value.length < 10) setPhoneWarn(true)
    }
  }

  const openArr = []
  getCountries().map(item => openArr.push({ value: item, label: `${en[item]}` }))

  const CountrySelect = () => {
    const [ariaFocusMessage, setAriaFocusMessage] = useState()
    const onFocus = ({ focused }) => {
      setAriaFocusMessage(focused.label)
    }

    return (
      <>
        {!!ariaFocusMessage && !!openCountry && (
          <span aria-live="polite" className="sr-only">{`${ariaFocusMessage}`}</span>
        )}
        {openCountry ? (
          <OpenCustomCountrySelect
            autoFocus
            isSearchable
            menuIsOpen={openCountry}
            defaultValue={country}
            closeMenuOnSelect
            onChange={setCountry}
            onBlur={() => setOpenCountry(false)}
            options={openArr}
            aria-label={languageData?.phoneNumberCountryCode}
            styles={customSelectStyles}
            className="react-select-container"
            classNamePrefix="react-select"
            ariaLiveMessages={{ onFocus }}
          />
        ) : (
          <CustomCountrySelect
            styles={customSelectStyles}
            defaultValue={country}
            defaultInputValue={
              country.label ? `+${getCountryCallingCode(country.value)}` : `+${getCountryCallingCode(country)}`
            }
            onFocus={() => setOpenCountry(true)}
            options={openArr}
            aria-label={languageData?.phoneNumberCountryCode}
          />
        )}
      </>
    )
  }

  return (
    <>
      <label htmlFor="phone-input-component" id="phone-input-label">
        <PhoneLabelText>
          <Text smallBodyMedium>{labelText || 'Phone Number'}</Text>
          {phoneWarn && (
            <Text smallBody color="red500" id="phone-sub-label" style={{ textAlign: 'right' }}>
              Must be 10 digits
            </Text>
          )}
        </PhoneLabelText>
        <Phone id="phone-component">
          <PhoneInputWrapper id="phone-input-component-wrapper">
            <CountrySelect value={country} onChange={setCountry} />
            <PhoneInput
              id="phone-input-component"
              name="phone"
              aria-required="false"
              type="tel"
              autoComplete="tel"
              onChange={e => {
                if (e.target.value.length <= 14) handlePhoneInput(e.target.value)
              }}
              value={formData.phone}
            />
          </PhoneInputWrapper>
        </Phone>
      </label>
    </>
  )
}

export default PhoneNumberInput
