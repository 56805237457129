/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import idx from 'idx'
import Fade from 'react-reveal/Fade'
import { Link, navigate } from 'gatsby'
import formatDate from '../../utils/formatDate'
import { theme } from '../../styles'
import Text from '../common/Text'
import Tag from '../common/Tag'
import Space from '../common/Space'
import generateSrcSet from '../../utils/generateSrcSet'

const Card = styled.li`
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  border-radius: 16px;
  background: #fff;
  overflow: hidden;
  position: relative;
  margin-bottom: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-width: 0px;
  transition: all 125ms ease-in-out;
  cursor: pointer;
  justify-content: center;

  &:hover {
    box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
    transform: scale(1.025);
  }

  @media (max-width: 800px) {
    &:hover {
      box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
      transform: none;
    }
  }
`

const Img = styled.img`
  width: 100%;
  align-self: center;
`

const CardContent = styled.div`
  display: grid;
  padding: 16px;
  gap: 16px;
  height: 100%;

  a {
    display: block;
    min-height: 58px;
  }
`

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;

  > p {
    line-height: 1;
  }
`

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PostCard = ({ uid, title, description, category, publishDate }) => (
  <>
    <Link to={`${uid}`} onClick={e => e.stopPropagation()} className="post-grid-link">
      <Text bodyBold numberOfLines={2}>
        {title}
      </Text>
    </Link>
    {description && (
      <Text smallBody numberOfLines={2}>
        {description}
      </Text>
    )}
    <CardFooter>
      {category ? (
        <CategoryWrapper>
          <Tag
            key={category}
            text={category}
            color="gray"
            noMargin
            fontSize="smallCapsSans"
            fontFamily="smallBody"
            size="micro"
            style={{ marginRight: '6px' }}
          />
        </CategoryWrapper>
      ) : (
        <Space width={8} />
      )}
      <Text smallCaps color="gray700">
        {publishDate && formatDate(publishDate)}
      </Text>
    </CardFooter>
  </>
)

const TempGridItem = ({ publishDate, title, uid, img, description, category, index }) => {
  let modifiedImg = img
  if (!img?.url) {
    modifiedImg = {
      url: 'https://images.prismic.io/audioeye-web/953cc937-895c-47a9-ab87-0e60c31f5c1e_audioeye-default-blog-image.png?auto=compress,format&w=400',
      alt: 'AudioEye Logo',
      dimensions: {},
    }
  }

  const src = idx(modifiedImg, _ => _.url)
  const srcSet = generateSrcSet(
    src,
    idx(modifiedImg, _ => _.dimensions),
    400
  )

  return (
    <Fade bottom delay={index * 80}>
      <Card
        className="link-list-item"
        onClick={() => navigate(`${uid}`)}
        onKeyDown={e => {
          if (e.key === 'Enter') navigate(`${uid}`)
        }}
      >
        <Img alt={idx(modifiedImg, _ => _.alt) || ''} src={src} loading="lazy" srcSet={srcSet} />
        <CardContent>
          <PostCard uid={uid} title={title} description={description} category={category} publishDate={publishDate} />
        </CardContent>
      </Card>
    </Fade>
  )
}

export default TempGridItem
