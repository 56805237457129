const generateOpensInANewTabTranslations = ({ userLang }) => {
  const data = {
    en: {
      lowercase: 'opens in a new tab',
      uppercase: 'Opens in a new tab',
    },
    de: {
      lowercase: 'öffnet sich in einem neuen Tab',
      uppercase: 'Öffnet in einem neuen Tab',
    },
    fr: {
      lowercase: "s'ouvre dans un nouvel onglet",
      uppercase: 'Ouvre dans un nouvel onglet',
    },
    es: {
      lowercase: 'se abre en una nueva pestaña',
      uppercase: 'Se abre en una nueva pestaña',
    },
    it: {
      lowercase: 'si apre in una nuova scheda',
      uppercase: 'Si apre in una nuova scheda',
    },
  }

  return data[userLang] || data.en
}

export default generateOpensInANewTabTranslations
