import React from 'react'
import { theme } from '../../../../styles'

export const EarningsCallIcon = ({ index }) => {
  const colors = [
    theme.color.purple500,
    theme.color.blue500,
    theme.color.green500,
    theme.color.yellow500,
    theme.color.red500,
    theme.color.purple500,
  ]
  return (
    <svg
      style={{ gridArea: '1 / 1 / 2 / -1', zIndex: '1' }}
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52" cy="52" r="51.6604" fill={colors[index]} stroke={colors[index]} strokeWidth="0.679245" />
      <path
        d="M44.5818 61.1765C48.1943 59.5401 52.2255 59.0649 56.1195 59.8164C60.0134 60.5679 63.5783 62.509 66.3225 65.3721"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6966 38.8599C41.4705 41.7504 45.0802 43.6992 49.0186 44.4326C52.9571 45.1659 57.0263 44.647 60.6546 42.9487"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.1356 60.6539C43.3392 57.8762 42.9399 54.9998 42.9494 52.1103C42.9494 43.6009 46.2448 36.2777 50.9591 33.0395"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.9858 71.1962C49.3858 70.0549 48.022 68.6146 46.9695 66.9549"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.3249 36.2167C56.4089 34.9845 55.303 33.9056 54.0485 33.0205"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.0485 71.1963C58.7781 67.9428 62.0773 60.6196 62.0773 52.1255C62.0915 49.0466 61.6389 45.9834 60.7347 43.0402"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M52.519 32.9632V71.2573" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M71.6623 52.1103H33.3721" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M57.3249 33.5735C54.076 32.7223 50.6601 32.7423 47.4214 33.6314C44.1826 34.5205 41.2353 36.2473 38.8763 38.638C36.5174 41.0287 34.83 43.9988 33.9842 47.2491C33.1385 50.4995 33.1641 53.9153 34.0586 57.1526"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.7025 70.3495C50.0984 71.4285 53.728 71.5391 57.1833 70.6688C60.6386 69.7985 63.7828 67.9818 66.2624 65.4229C68.7421 62.864 70.4589 59.6643 71.2201 56.1833C71.9814 52.7024 71.7567 49.0781 70.5714 45.7178"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9847 79C42.0514 79 46.9695 74.082 46.9695 68.0153C46.9695 61.9485 42.0514 57.0305 35.9847 57.0305C29.918 57.0305 25 61.9485 25 68.0153C25 74.082 29.918 79 35.9847 79Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2191 75.7847V73.3245C28.2206 72.1861 28.6138 71.0829 29.3327 70.2001C30.0515 69.3173 31.0522 68.7088 32.1668 68.4768"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.6654 68.4539C40.8091 68.6585 41.8445 69.2583 42.5911 70.1485C43.3377 71.0386 43.748 72.1627 43.7504 73.3245V75.777"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4431 60.4213H38.7271C39.0306 60.4213 39.3216 60.5418 39.5362 60.7564C39.7508 60.971 39.8714 61.2621 39.8714 61.5655V67.1533C39.8714 68.1881 39.4603 69.1806 38.7285 69.9123C37.9968 70.644 37.0043 71.0551 35.9695 71.0551C34.9347 71.0551 33.9422 70.644 33.2105 69.9123C32.4787 69.1806 32.0676 68.1881 32.0676 67.1533V63.7815C32.0676 63.339 32.155 62.9008 32.3249 62.4921C32.4947 62.0834 32.7436 61.7123 33.0572 61.4001C33.3709 61.0878 33.7431 60.8406 34.1525 60.6727C34.562 60.5047 35.0006 60.4193 35.4431 60.4213V60.4213Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M39.8866 63.7815H32.0829" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.0829 73.2635V78.2867" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.8866 73.2635V78.2867" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M68.2219 45.9695C74.2886 45.9695 79.2067 41.0514 79.2067 34.9847C79.2067 28.918 74.2886 24 68.2219 24C62.1552 24 57.2372 28.918 57.2372 34.9847C57.2372 41.0514 62.1552 45.9695 68.2219 45.9695Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.4563 42.7542V40.294C60.4587 39.1563 60.8523 38.0539 61.571 37.1719C62.2898 36.29 63.2901 35.682 64.4039 35.4501"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.9026 35.4234C73.0462 35.628 74.0817 36.2278 74.8283 37.118C75.5749 38.0081 75.9851 39.1322 75.9875 40.294V42.7465"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.6803 27.3908H69.0648C69.8741 27.3908 70.6502 27.7123 71.2224 28.2845C71.7947 28.8567 72.1162 29.6328 72.1162 30.4421V34.1304C72.1162 35.1652 71.7051 36.1577 70.9733 36.8894C70.2416 37.6212 69.2491 38.0322 68.2143 38.0322C67.1801 38.0333 66.1878 37.6237 65.4555 36.8935C64.7231 36.1633 64.3106 35.1722 64.3086 34.138V30.751C64.3086 30.3088 64.3959 29.8709 64.5655 29.4625C64.7351 29.054 64.9836 28.6831 65.2968 28.3709C65.6101 28.0587 65.9819 27.8115 66.3909 27.6433C66.7999 27.4751 67.2381 27.3893 67.6803 27.3908V27.3908Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M64.3201 40.233V45.2601" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M72.1238 40.233V45.2601" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M72.0399 31.6664C72.0399 31.6664 70.8956 32.3988 69.7514 30.2323C68.6071 32.3988 64.4116 31.6664 64.4116 31.6664"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
