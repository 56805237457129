/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'

import MetaSection from './MetaSection'
import BackButton from '../common/BackButton'
import Text from '../common/Text'
import Space from '../common/Space'

const TitleWrapper = styled.div`
  margin-bottom: 1em;
`

const TitleSection = ({ author, postData }) => {
  const title = postData?.title && <PrismicRichText field={postData.title.richText} />
  return (
    <>
      <TitleWrapper>
        <Space height={24} tabletHeight={0} />
        <BackButton
          text={`Back to ${postData.post_type === 'Press' ? 'press' : 'blog'}`}
          to={`/${postData.post_type === 'Press' ? 'press' : 'blog'}`}
        />
        <Space height={24} />
        {title && (
          <Text font="heading1" richText numberOfLines={4}>
            {title}
          </Text>
        )}
      </TitleWrapper>
      <MetaSection author={author} postData={postData} />
    </>
  )
}
export default TitleSection
