import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { theme } from '../../../styles'
import Text from '../../common/Text'

export const FormHeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  min-height: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .hide-desktop {
    display: none;
    @media (max-width: 1050px) {
      display: flex;
    }
  }

  .hide-mobile {
    display: flex;
    @media (max-width: 1050px) {
      display: none;
    }
  }
`

export const FormInner = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;
`

export const RequiredWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 16px;

  span {
    color: ${theme.color.red500};
    line-height: 1;
  }
`

export const StageWrapper = styled.div`
  flex-direction: ${p => (p.checkSite ? 'column' : 'row')};
  text-align: ${p => (p.checkSite ? 'center' : 'left')};
  padding-top: ${p => (p.checkSite ? '24px' : '0px')};

  ${p =>
    p.open
      ? css`
          position: relative;
          width: 100%;
          display: flex;
        `
      : css`
          position: absolute;
          left: 500vw;
          display: none;
        `}

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`

export const FormHeader = ({ heading, required }) => (
  <>
    <Text center role="heading" aria-level="2" color="purple500" heading4>
      {heading || 'Start Free 14-Day Trial'}
    </Text>
    <RequiredWrapper>
      <span>*&nbsp;</span>
      <Text smallBodyBold color="gray700" style={{ lineHeight: '1' }}>
        {required || 'Required'}
      </Text>
    </RequiredWrapper>
  </>
)
