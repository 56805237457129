import React from 'react'

export const Youtube = ({ alt }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt={alt}>
    <path
      className="icon"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM23.8319 10.9881C23.622 10.204 23.0057 9.58779 22.2216 9.37789C20.8018 8.99707 15.106 8.99707 15.106 8.99707C15.106 8.99707 9.41016 8.99707 7.99033 9.37789C7.2062 9.58779 6.58999 10.204 6.38009 10.9881C5.99927 12.408 5.99927 15.3721 5.99927 15.3721C5.99927 15.3721 5.99927 18.3362 6.38009 19.756C6.58999 20.5402 7.2062 21.1564 7.99033 21.3662C9.41016 21.7471 15.106 21.7471 15.106 21.7471C15.106 21.7471 20.8018 21.7471 22.2216 21.3662C23.0057 21.1564 23.622 20.5402 23.8319 19.756C24.2127 18.3362 24.2127 15.3721 24.2127 15.3721C24.2127 15.3721 24.2112 12.408 23.8319 10.9881ZM13.2832 18.1031L18.015 15.3714L13.2832 12.6396V18.1031Z"
      fill="black"
    />
  </svg>
)
