/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import { theme } from '../../styles'

import Text from '../common/Text'
import TextLink from '../common/TextLink'

const Wrapper = styled.div`
  border-top: 2px solid ${theme.color.offWhite};
  display: grid;
  grid-template-columns: 350px 4fr;
  padding: 80px 0px;

  @media (max-width: 1050px) {
    padding: 64px 32px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const Header = styled.div`
  margin-bottom: 24px;
  width: 100%;
`

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 16px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const Sitemap = ({ slice: { primary, items } }) => {
  const title = primary.section_heading.richText?.[0]?.text && (
    <PrismicRichText field={primary.section_heading.richText} />
  )

  return (
    <Wrapper>
      {title && (
        <Header>
          <Text font="heading3" richText>
            {title}
          </Text>
        </Header>
      )}
      <ListWrapper>
        {items.map((item, indx) => (
          <TextLink key={`sitemap-item-${indx}`} text={item.link_text.text} to={item.link_url.url} font="smallBody" />
        ))}
      </ListWrapper>
    </Wrapper>
  )
}

export default Sitemap

export const query = graphql`
  fragment Sitemap on PrismicPageDataBodySitemap {
    ...SliceBase
    items {
      link_text {
        ...TextContent
      }
      link_url {
        uid
        url
      }
    }
    primary {
      section_heading {
        ...TextContent
      }
    }
  }
`
