import React from 'react'

export const LinkedIn = ({ alt }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt={alt}>
    <path
      className="icon"
      d="M15 0C6.71625 0 0 6.71625 0 15C0 23.2837 6.71625 30 15 30C23.2837 30 30 23.2837 30 15C30 6.71625 23.2837 0 15 0ZM12.5 20H10V12.5H12.5V20ZM11.25 11.3863C10.4913 11.3863 9.875 10.7662 9.875 10C9.875 9.235 10.49 8.61375 11.25 8.61375C12.01 8.61375 12.625 9.235 12.625 10C12.625 10.7662 12.0087 11.3863 11.25 11.3863ZM21.25 20H18.7525V16.4237C18.7525 14.0725 16.25 14.2712 16.25 16.4237V20H13.75V12.5H16.25V13.8663C17.34 11.8463 21.25 11.6962 21.25 15.8012V20Z"
      fill="none"
    />
  </svg>
)
