import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import quoteIcon from '../../../static/icons/misc-icons/circle-quote-icon.svg'

const BlockQuote = styled.blockquote`
  border-top: 2px solid ${p => p.theme.color.green100};
  border-bottom: 2px solid ${p => p.theme.color.green100};
  padding: 64px 0px;
  margin: 80px 0px 56px 0px;
  display: grid;
  grid-template-rows: 0 1fr;

  p {
    font-size: ${p => p.theme.fonts.size.largeBody};
    color: ${p => p.theme.color.gray700};
  }

  ::before {
    margin: 0 auto;
    grid-row: 1 / 2;
    transform: translateY(-104px);
    content: url(${quoteIcon});
  }
`

const Quote = ({ slice: { primary } }) => (
  <BlockQuote className="narrow-width">
    <div dangerouslySetInnerHTML={{ __html: primary.quote.html }} />
  </BlockQuote>
)

export default Quote

export const query = graphql`
  fragment PostQuote on PrismicPostDataBodyQuote {
    ...SliceBase
    primary {
      quote {
        ...TextContent
      }
    }
  }
`
