/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'

import Text from '../common/Text'
import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'

const BG = styled.div`
  position: absolute;
  bottom: ${p => (p.overlapTop ? '50%' : '0')};
  width: 500vw;
  margin-left: -100%;
  height: 100%;
  z-index: -2;
  background-color: ${p => theme.color[p.backgroundColor] || p.theme.color.white};
`

const Wrapper = styled.div`
  position: relative;
  padding: ${props => (props.hasTopPadding ? `64px 0px 32px 0px` : '32px 0px')};
  width: 100%;
  ${p => p.hasMarginBottom && `margin-bottom: 80px;`}
  margin-top: ${p => (p.overlapTop ? '-10%' : '0')};

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    margin-left: -10px;
    padding: ${props => (props.hasTopPadding ? `80px 0px 40px 0px` : '40px 0px')};
    margin-top: ${p => (p.overlapTop ? '-15%' : '0')};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  padding: 8px;
  ${props =>
    !props.removeCardStyles &&
    `
      box-shadow: 0px 0px 14px 3px ${theme.color.darkShadow};
      background-color: ${theme.color.white};
  `}
  margin: 0 auto;
`

const LogosWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: ${p => (p.hasTitle ? '8px 8px 12px 8px' : '12px 0px')};
`

const Logo = styled.img`
  height: 90px;
  object-fit: contain;
  padding: 16px;

  @media (max-width: 1500px) {
    height: 75px;
  }
`

const FeaturedLogos = ({ slice: { items, primary } }) => {
  const title = primary.heading.richText?.[0]?.text && <PrismicRichText field={primary.heading.richText} />
  const richTitle = primary.rich_text_heading.richText?.[0]?.text && (
    <PrismicRichText field={primary.rich_text_heading.richText} />
  )
  const backgroundColor = primary.background_color
  const anchorId = primary.anchor_id
  const overlapTop = primary.overlap_top
  const hasMarginBottom = primary.has_margin_bottom
  const hasTopPadding = primary.has_top_padding
  const removeCardStyles = primary.remove_card_styles

  return (
    <Wrapper
      id={anchorId || null}
      overlapTop={overlapTop}
      hasMarginBottom={hasMarginBottom}
      hasTopPadding={hasTopPadding}
    >
      <Container removeCardStyles={removeCardStyles}>
        {title && (
          <Text font="bodyBold" center aria-level="2" richText style={{ paddingTop: '20px' }} color="gray600">
            {title}
          </Text>
        )}
        {richTitle && (
          <Text font="body" center aria-level="2" richText color="gray600" style={{ paddingTop: '20px' }}>
            {richTitle}
          </Text>
        )}
        <LogosWrapper hasTitle={title?.props?.field?.[0]?.text}>
          {items.map(item =>
            item.logo_link.url ? (
              <a
                href={item?.logo_link?.url}
                target={item?.logo_link?.url ? '_blank' : null}
                key={item?.logo_link?.url}
                rel="noopener noreferrer"
              >
                {getImage(item.logo, Logo)}
                {item?.logo_link?.url && (
                  <OpenNewTabHiddenSpan overGraphic newTabText={item?.logo?.alt.split('logo')[0] || ''} />
                )}
              </a>
            ) : (
              getImage(item.logo, Logo, { key: item.logo.url })
            )
          )}
        </LogosWrapper>
      </Container>
      <BG className="bg" backgroundColor={backgroundColor} overlapTop={overlapTop} />
    </Wrapper>
  )
}

export default FeaturedLogos

export const query = graphql`
  fragment FeaturedLogos on PrismicPageDataBodyFeaturedLogos {
    ...SliceBase
    items {
      logo {
        alt
        url
        gatsbyImageData(width: 180)
      }
      logo_link {
        url
        uid
      }
    }
    primary {
      anchor_id
      background_color
      overlap_top
      has_margin_bottom
      has_top_padding
      remove_card_styles
      heading {
        ...TextContent
      }
      rich_text_heading {
        ...TextContent
      }
    }
  }
`
