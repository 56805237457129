// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

exports.linkResolver = function linkResolver(doc) {
  if (doc.type === 'author') {
    return `/author/${doc.uid}/`
  }

  if (doc.type === 'page') {
    if (doc.uid === 'home') return `/`
    if (doc?.data?.folder) return `/${[doc.data.folder?.slug, doc.uid].filter(Boolean).join('/')}`
    return `/${doc.uid}/`
  }

  if (doc.type === 'post') {
    return `/post/${doc.uid}/`
  }

  return `/${doc.uid}/`
}
