const useUrlParams = (url, paramName) => {
  const params = {}
  const parser = document.createElement('a')
  parser.href = url
  const query = parser.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i += 1) {
    if (vars[i]) {
      const pair = vars[i].split('=')
      params[pair[0]] = decodeURIComponent(pair[1])
    }
  }
  return params[paramName]
}

export default useUrlParams
