import React from 'react'
import styled from '@emotion/styled'

import { theme } from '../../styles'
import Text from './Text'
import tooltipIcon from '../../../static/icons/misc-icons/tooltip-icon.svg'

const TooltipWrapper = styled.div`
  position: ${props => (props.inline ? 'relative' : 'absolute')};
  top: ${props => (props.inline ? '1px' : '12px')};
  right: ${props => (props.inline ? '0px' : '12px')};
  height: 24px;
  padding: 4px;
  cursor: pointer;

  ${props => props.inline && 'display: inline;'}

  div {
    visibility: hidden;
    width: ${p => (p.width ? `${p.width}px` : '250px')};
    background-color: ${theme.color.offBlack};
    padding: 16px;
    border-radius: 8px;
    line-height: ${theme.fonts.lineHeight.smallBody};
    position: absolute;
    z-index: 3;
    ${props => (props.location === 'bottom' ? 'top' : 'bottom')}: 125%;
    left: 50%;
    margin-left: ${p => (p.width ? `-${p.width / 2}px` : '-125px')};
    opacity: 0;
    transition: opacity 0.3s;

    :after {
      content: '';
      position: absolute;
      ${props => (props.location === 'bottom' ? 'bottom' : 'top')}: 100%;
      ${props => props.location === 'bottom' && 'rotate: 180deg;'}
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: ${theme.color.offBlack} transparent transparent transparent;
    }
  }

  :hover,
  :focus {
    div {
      visibility: visible;
      opacity: 1;
    }
  }

  :focus {
    outline: none;
    ::after {
      content: '';
      position: absolute;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      border-radius: 50%;
      border: 3px solid ${theme.color.purple500};
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`

const TooltipIcon = styled.img`
  width: 16px;
  height: 16px;
  vertical-align: top;
`

const Tooltip = ({ text, width, formVisible, inline, title, location, tabIndex }) => {
  let tabIndexFinal

  if (tabIndex) {
    tabIndexFinal = tabIndex
  } else if (formVisible) {
    tabIndexFinal = '-1'
  } else {
    tabIndexFinal = '0'
  }

  return (
    <TooltipWrapper
      width={width || 250}
      aria-labelledby="tooltip-text"
      tabIndex={tabIndexFinal}
      inline={inline}
      location={location}
    >
      <TooltipIcon src={tooltipIcon} alt="" />
      <div role="tooltip" id={`tooltip-text-${text.split(' ')[0]}`}>
        {title && (
          <Text smallBodyBold color="white">
            {title}
          </Text>
        )}
        <Text tinyBody color="white">
          {text}
        </Text>
      </div>
    </TooltipWrapper>
  )
}

export default Tooltip
