import { css } from '@emotion/react'
import { theme } from '../../../styles'

const HubspotStyles = css`
  .hs-form-iframe,
  #reactHubspotForm0 {
    width: 100% !important;
  }
  .custom-hs-css {
    span {
      font-family: ${theme.fonts.family.smallBodyMedium};
      font-size: ${theme.fonts.size.smallBodyMedium};
      font-weight: ${theme.fonts.weight.smallBodyMedium};
    }
    max-width: 500px;

    input,
    textarea,
    select {
      width: 100%;
      font-family: ${theme.fonts.family.smallBody};
      font-size: ${theme.fonts.size.smallBody};
      font-weight: ${theme.fonts.weight.smallBody};
      background-color: ${theme.color.white};
      padding: 8px;
      border-radius: 8px;
      border: 1px solid ${theme.color.gray300};
      border-image: initial;

      ::placeholder {
        color: ${theme.color.gray700};
        font-weight: 300;
      }

      @media (max-width: 800px) {
        margin-top: 0px;
      }

      &[type='checkbox'] {
        width: auto;
      }
    }

    .hs-input {
      width: 100% !important;
    }

    .hs-form-field {
      margin-bottom: 12px;
    }

    fieldset div.input {
      margin-right: 0px;
    }

    fieldset.form-columns-2 > div:first-of-type > div.input {
      margin-right: 16px;
    }

    select {
      width: calc(100% - 6px);
      height: 44px;
    }

    ul {
      margin-left: 0px;
      margin-top: 8px;
    }

    li {
      list-style-type: none;
    }

    .hs_received_demand_letter {
      margin-bottom: 0;
    }

    .hs-form-booleancheckbox-display {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-top: 12px;
      span p {
        font-family: ${theme.fonts.family.smallBody}!important;
        font-size: ${theme.fonts.size.smallBody}!important;
        font-weight: ${theme.fonts.weight.smallBody}!important;
      }
    }

    .hs-field-desc {
      font-family: ${theme.fonts.family.smallBody}!important;
      font-size: ${theme.fonts.size.smallBody}!important;
      font-weight: ${theme.fonts.weight.smallBody}!important;
    }

    .hs-richtext {
      p,
      p em {
        font-size: ${theme.fonts.size.tinyBody}!important;
        font-family: ${theme.fonts.family.tinyBody}!important;
        font-weight: ${theme.fonts.weight.tinyBody}!important;
      }
    }

    .hs-error-msg,
    .hs-error-msgs,
    .hs-form-required {
      label {
        margin-top: 4px;
      }
      * {
        color: ${theme.color.red500};
        font-size: ${theme.fonts.size.tinyBody};
        font-family: ${theme.fonts.family.tinyBody};
        font-weight: ${theme.fonts.weight.tinyBody};
      }
    }

    .hs-form-required {
      margin-left: 3px;
      color: ${theme.color.red500};
    }

    ul.inputs-list {
      display: grid;
      grid-template-columns: auto 1fr;
      margin-top: 0px;
      margin-block-end: 0px;
    }

    label.hs-form-radio-display {
      width: 100%;
      margin-bottom: 0px;
    }

    li.hs-form-radio {
      margin-bottom: 0px;
    }

    .hs_agency_or_freelancer__c {
      @media (min-width: 481px) {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    .hs-form-radio-display > span,
    .hs-form-booleancheckbox-display > span {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0px;
      position: relative;
      padding: 0px 16px 8px 0px;
      transition: 180ms transform ease-in-out;
      cursor: pointer;

      :hover {
        &:before {
          border-color: ${theme.color.purple500};
          border-width: 2px;
        }
      }

      :before {
        content: '';
        background: ${theme.color.white};
        border-radius: 6px;
        border: 1px solid ${theme.color.gray300};
        display: inline-block;
        min-width: 22px;
        min-height: 22px;
        max-width: 22px;
        max-height: 22px;
        position: relative;
        margin-right: 16px;
      }

      :after {
        content: '';
        position: absolute;
        display: none;
      }
    }

    .hs-form-booleancheckbox-display span {
      display: block;

      :before {
        top: 5px;
      }
    }

    .hs-form-radio .hs-input,
    .hs-form-booleancheckbox-display .hs-input {
      position: absolute;
      opacity: 0;
      padding: 0;
      margin: 0;
      width: 0 !important;

      &:focus + span {
        border-color: ${theme.color.purple500};
      }

      &:checked + span:before {
        background-color: ${theme.color.purple500};
      }

      &:checked + span:after {
        display: block;
        left: 8px;
        top: 5px;
        width: 6px;
        height: 11px;
        border: solid ${theme.color.white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      &:focus + span:before {
        outline-offset: 4px;
        outline: ${theme.focusOutline};
      }
    }

    .hs-form-booleancheckbox-display .hs-input {
      &:checked + span:after {
        top: 10px;
      }
    }

    .custom-hs-button {
      display: flex;
      justify-content: center;
      background-color: ${theme.color.purple500};
      font-size: ${theme.fonts.size.bodyBold};
      font-family: ${theme.fonts.family.bodyBold};
      font-weight: ${theme.fonts.weight.bodyBold};
      padding: 24px 32px;
      border-radius: 16px;
      border: 2px solid transparent;
      cursor: pointer;
      transition: all 150ms;
      z-index: 1;
      height: fit-content;
      width: fit-content;
      line-height: 1;
      color: ${theme.color.white};
      white-space: nowrap;
      margin: 24px auto 0px auto;
      -webkit-appearance: none !important;

      :hover {
        background-color: ${theme.color.purple900};
      }

      :focus {
        padding-bottom: 16px;
      }

      @media (max-width: 800px) {
        width: 100%;
      }
    }

    @media (max-width: 1050px) {
      margin: 0 auto;
      margin-top: 24px;
    }
  }

  .legal-consent-container {
    font-size: 12px;

    .hs-fieldtype-booleancheckbox {
      margin-bottom: 0;
    }

    .hs-form-booleancheckbox {
      margin-bottom: 0;

      label {
        margin-bottom: 0;
      }
    }

    .hs-form-booleancheckbox-display {
      > span {
        margin-left: 0;
        padding: 0;

        p {
          font-weight: 500 !important;
        }
      }

      .hs-form-required {
        display: inline;
      }
    }
  }
`

export default HubspotStyles
