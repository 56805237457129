import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'

import Text from '../common/Text'
import TextLink from '../common/TextLink'
import Space from '../common/Space'
import getImage from '../../utils/getImage'

const Wrapper = styled.div`
  margin: 80px 0 0;
  margin-bottom: 24px;

  @media (max-width: 1050px) {
    margin: 32px 0 0;
  }
`

const CardGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    flex-basis: 33.33%;

    @media (max-width: 767px) {
      flex-basis: 50%;
    }

    @media (max-width: 425px) {
      flex-basis: 100%;
    }
  }
`

const CardWrapper = styled.div`
  text-align: center;
  padding: 0 24px;

  @media (max-width: 1050px) {
    width: 100%;
  }
`

const TextLinkWrapper = styled.div`
  padding-bottom: 32px;

  @media (max-width: 1050px) {
    padding-bottom: 24px;
  }

  a {
    display: block;
    text-align: left;
    margin-top: 16px;
  }
`

const CardImage = styled.img`
  max-width: 100%;
  display: block;
  margin: 16px auto 12px;

  @media (max-width: 1050px) {
    width: 100%;
    max-width: 600px;
    margin: 24px auto 12px;
  }
`

const Card = ({ fullName, jobTitle, image, readMoreLink }) => (
  <CardWrapper>
    {getImage(image, CardImage)}
    {fullName && (
      <Text bodyBold richText>
        {fullName}
      </Text>
    )}
    {jobTitle && (
      <Text font="body" richText>
        {jobTitle}
      </Text>
    )}
    {readMoreLink?.url && (
      <TextLinkWrapper>
        <TextLink to={readMoreLink.url} text="Read more" />
      </TextLinkWrapper>
    )}
  </CardWrapper>
)

const AboutUsCards = ({ slice: { primary, items } }) => {
  const heading = primary.heading.richText?.[0]?.text && <PrismicRichText field={primary.heading.richText} />
  console.log(items)

  return (
    <Wrapper>
      <Text font="heading2" center richText>
        {heading}
      </Text>
      <Space height={24} />
      <CardGroupWrapper>
        {items.map(item => (
          <Card
            fullName={item.full_name}
            jobTitle={item.job_title}
            image={item.image}
            readMoreLink={item.read_more_link}
          />
        ))}
      </CardGroupWrapper>
    </Wrapper>
  )
}

export default AboutUsCards

export const query = graphql`
  fragment AboutUsCards on PrismicPageDataBodyAboutUsCards {
    ...SliceBase
    items {
      image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      full_name
      job_title
      read_more_link {
        ...LinkContent
      }
    }
    primary {
      heading {
        ...TextContent
      }
    }
  }
`
