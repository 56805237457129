export const videoPlatforms = {
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  INVALID: '',
}

export const sanitizeVideoUrl = (url, autoPlay = false, videoPlatform) => {
  if (videoPlatform === videoPlatforms.VIMEO) {
    const regExp =
      /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i
    const match = regExp.exec(url)
    const vimeoId = match && match[1].length === 9 ? match[1] : false
    const urlAutoPlay = /autoplay=1?/.test(url)
    if (vimeoId) {
      if (autoPlay || urlAutoPlay) {
        return `https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1&autopause=0&muted=1&title=0&portrait=0&byline=0?texttrack=es`
      }
      return `https://player.vimeo.com/video/${vimeoId}?title=0&portrait=0&byline=0&?texttrack=es`
    }
  }
  if (videoPlatform === videoPlatforms.YOUTUBE) {
    const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
    const match = url.match(regExp)
    const youtubeId = match && match[1].length === 11 ? match[1] : false
    if (youtubeId) {
      return `https://www.youtube.com/embed/${youtubeId}`
    }
  }
  return url
}
