import React from 'react'

export const CircleShareIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" alt="">
    <circle cx="20" cy="20" r="20" fill="none" className="icon" />
    <path
      d="M10 24.0435V29L11.2272 25.932C12.4817 22.7957 15.5193 20.7391 18.8972 20.7391H19.913V25.6957L29 18.2609L19.913 10V15.7826H18.2609C13.6985 15.7826 10 19.4811 10 24.0435Z"
      stroke="none"
      strokeLinejoin="round"
      className="icon-stroke"
    />
  </svg>
)
