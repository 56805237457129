const fetchTableData = async (airtableBase, tableName) =>
  new Promise(resolve => {
    const data = {}
    let colNames = []

    airtableBase(tableName)
      .select({
        view: 'Grid view',
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record, i) => {
            let label = record.fields.Label
            if (data[label]) {
              console.warn('Multiple earnings results with same name: ', label)
              label += `_${i}`
            }
            if (record.fields.Label) {
              colNames = Object.keys(record.fields)
                .filter(field => field !== 'Label')
                .sort()
                .reverse()
            }

            data[label] = {
              [colNames[0]]: record.fields[colNames[0]],
              [colNames[1]]: record.fields[colNames[1]],
              [colNames[2]]: record.fields[colNames[2]],
              [colNames[3]]: record.fields[colNames[3]],
            }
            data.colNames = colNames
          })
          fetchNextPage()
        },
        err => {
          // on finish
          if (err) {
            console.error(err)
            resolve()
          } else {
            resolve(data, colNames)
          }
        }
      )
  })

export default fetchTableData
