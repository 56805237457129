/* eslint-disable camelcase */
import React from 'react'
import WCAGChecklist from './WCAGChecklist'

const sections = [
  {
    items: [
      {
        column1: 'Non-text Content',
        column2:
          'All non-text content (i.e. images, image buttons, and image map hot spots) have appropriate alternative text.',
      },
      {
        column1: 'Audio-Only and Video-Only',
        column2: 'Descriptive and accurate transcripts are provided for video- and audio-only content.',
      },
      {
        column1: 'Captions',
        column2: 'Captions are provided for pre-recorded videos or on-live videos (e.g., YouTube)',
      },
      {
        column1: 'Information and Relationships',
        column2:
          'Headings include the right semantic markup. Tables are linked to the appropriate headers and include captions if necessary. Text labels are associated with the right form inputs.',
      },
      {
        column1: 'Meaningful Sequence',
        column2: 'Navigation order follows code order; navigation is clear and intuitive.',
      },
      {
        column1: 'Sensory Characteristics',
        column2:
          'Instructions are not dependent on visual location, shape, or size (i.e., ‘Instructions are listed on the right’ or ‘Click the square button to continue’).',
      },
      {
        column1: 'Use of Color',
        column2:
          'Color alone is not used to portray information, nor is it used to distinguish links from surrounding text unless the color contrast ratio is appropriate.',
      },
      {
        column1: 'Audio Control',
        column2:
          'Users can pause, stop, change volume, or mute audio content that automatically plays on a page for longer than three seconds.',
      },
      {
        column1: 'Keyboard',
        column2: 'Web elements can easily be shared via a keyboard and include pager-specific keyboard shortcuts.',
      },
      {
        column1: 'No Keyboard Trap',
        column2: 'Keyboard focus and shortcuts are not locked or trapped on any page elements.',
      },
      {
        column1: 'Character Key Shortcuts',
        column2:
          'Users must be able to disable key commands and change to non-printable keys, such as (Ctrl, Alt, etc.).',
      },
      {
        column1: 'Timing Adjustable',
        column2: 'Pages with time limits allow users to adjust, turn off, or expand the time limit.',
      },
      {
        column1: 'Pause, Stop, Hide',
        column2:
          'Automatically moving, blinking, scrolling, or updating content lasting longer than five seconds can be paused, stopped, or hidden by users.',
      },
      {
        column1: 'Three Flashes or Below',
        column2: 'Page content does not flash more than three times per second.',
      },
      {
        column1: 'Bypass Blocks',
        column2: 'Users can skip navigation or other page elements that are repeated across web pages.',
      },
      {
        column1: 'Page Titles',
        column2: 'The webpage has a descriptive, informative page title.',
      },
      {
        column1: 'Focus Order',
        column2: 'The order of links is clear and follows a logical, intuitive order.',
      },
      {
        column1: 'Link Purpose',
        column2:
          'Users can understand the purpose of a link from the text alone. Links with similar text that redirect to different locations are easily distinguishable.',
      },
      {
        column1: 'Pointer Gestures',
        column2: 'User actions can be performed with a single pointer gesture.',
      },
      {
        column1: 'Pointer Cancellation',
        column2: 'Inadvertent activation of controls is avoided by non-use of the ‘onmousedown’ activation.',
      },
      {
        column1: 'Motion Actuation',
        column2:
          'Functionalities triggered by user movement or moving the device can be disabled, and similar functionality can be achieved via standard controls like buttons or controls.',
      },
      {
        column1: 'Error Identification',
        column2: 'Form validation or input errors are clearly identified and access to the error is quickly provided.',
      },
    ],
  },
]

const WCAGChecklistA = () => <WCAGChecklist checklistItems={sections} title="WCAG Checklist Level A" />

export default WCAGChecklistA
