/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'

import Text from '../common/Text'

export const Wrapper = styled.div`
  margin-bottom: 56px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }
`

const BodyText = ({ slice: { primary } }) => {
  const { anchor_id } = primary
  const title = primary.title1.richText?.[0]?.text && <PrismicRichText field={primary.title1.richText} />
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />

  return (
    <Wrapper id={anchor_id || null} className="narrow-width">
      {title && <Text richText>{title}</Text>}
      {text && <Text richText>{text}</Text>}
    </Wrapper>
  )
}

export default BodyText

export const query = graphql`
  fragment PostBodyText on PrismicPostDataBodyText {
    ...SliceBase
    primary {
      anchor_id
      text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
    }
  }
`
