import React from 'react'
import TextLink from '../components/common/TextLink'
import { theme } from '../styles'

const generateFooterTranslations = ({ userLang }) => {
  const data = {
    en: {
      termsOfServiceLinkText: 'Terms of Service',
      termsOfServiceLink: '/terms-of-service/',
      privacyPolicyLinkText: 'Privacy Policy',
      privacyPolicyLink: '/privacy-policy/',
      trustCenterLinkText: 'Trust Center',
      cookieSettingsLinkText: 'Cookie Settings',
      disclaimer: (
        <>
          Copyright ©2024 AudioEye, Inc. All rights reserved. AUDIOEYE is a U.S. registered trademark of AudioEye, Inc.
          The products of AudioEye are protected by patents. For additional information,{' '}
          <TextLink
            font="tinyBody"
            style={{ color: theme.color.gray400 }}
            to="/ip"
            text="visit our intellectual property page"
            className="dark-bg-link dark-bg-focus"
            anchorClassName="dark-bg-link dark-bg-focus"
          />{' '}
          or{' '}
          <TextLink
            font="tinyBody"
            style={{ color: theme.color.gray400 }}
            to="mailto:ip@audioeye.com"
            text="contact us via email"
            className="dark-bg-link dark-bg-focus"
            anchorClassName="dark-bg-link dark-bg-focus"
            noSmoothScroll
          />
          .
        </>
      ),
    },
    de: {
      termsOfServiceLinkText: 'Nutzungsbedingungen',
      termsOfServiceLink: '/de/terms-of-service-de/',
      privacyPolicyLinkText: 'Datenschutzrichtlinie',
      privacyPolicyLink: '/de/privacy-policy-de/',
      trustCenterLinkText: 'Trustcenter',
      cookieSettingsLinkText: 'Cookie-Einstellungen',
      disclaimer: (
        <>
          Copyright ©2024 AudioEye, Inc. Alle Rechte vorbehalten. AUDIOEYE ist eine in den USA eingetragene Marke der
          AudioEye, Inc. Die Produkte von AudioEye sind durch Patente geschützt. Für zusätzliche Informationen
          kontaktieren Sie bitte{' '}
          <TextLink
            font="tinyBody"
            style={{ color: theme.color.gray400 }}
            to="mailto:ip@audioeye.com"
            text="ip@audioeye.com"
            className="dark-bg-link dark-bg-focus"
            anchorClassName="dark-bg-link dark-bg-focus"
            noSmoothScroll
          />
          .
        </>
      ),
    },
    fr: {
      termsOfServiceLinkText: "Conditions D'utilisation",
      termsOfServiceLink: '/fr/terms-of-service-fr/',
      privacyPolicyLinkText: 'Politique de Confidentialité',
      privacyPolicyLink: '/fr/privacy-policy-fr/',
      trustCenterLinkText: 'Centre de Confiance',
      cookieSettingsLinkText: 'Paramètres des Cookies',
      disclaimer: (
        <>
          Copyright ©2024 AudioEye, Inc. Tous droits réservés. AUDIOEYE est une marque déposée aux États-Unis
          d'AudioEye, Inc. Les produits AudioEye sont protégés par des brevets. Pour plus d’informations, veuillez
          contacter{' '}
          <TextLink
            font="tinyBody"
            style={{ color: theme.color.gray400 }}
            to="mailto:ip@audioeye.com"
            text="ip@audioeye.com"
            className="dark-bg-link dark-bg-focus"
            anchorClassName="dark-bg-link dark-bg-focus"
            noSmoothScroll
          />
          .
        </>
      ),
    },
    es: {
      termsOfServiceLinkText: 'Términos de servicio',
      termsOfServiceLink: '/es/terms-of-service-es/',
      privacyPolicyLinkText: 'Política de privacidad',
      privacyPolicyLink: '/es/privacy-policy-es/',
      trustCenterLinkText: 'Centro de confianza',
      cookieSettingsLinkText: 'Configuración de cookies',
      disclaimer: (
        <>
          Derechos de autor ©2024 AudioEye, Inc. Todos los derechos reservados. AUDIOEYE es una marca registrada
          estadounidense de AudioEye, Inc. Los productos de AudioEye están protegidos por patentes. Para más
          información,{' '}
          <TextLink
            font="tinyBody"
            style={{ color: theme.color.gray400 }}
            to="mailto:ip@audioeye.com"
            text="contáctanos en ip@audioeye.com"
            className="dark-bg-link dark-bg-focus"
            anchorClassName="dark-bg-link dark-bg-focus"
            noSmoothScroll
          />
          .
        </>
      ),
    },
    it: {
      termsOfServiceLinkText: 'Termini di servizio',
      termsOfServiceLink: '/it/terms-of-service-it/',
      privacyPolicyLinkText: 'Politica sulla riservatezza',
      privacyPolicyLink: '/it/privacy-policy-it/',
      trustCenterLinkText: 'Centro de confianza',
      cookieSettingsLinkText: 'Configuración de cookies',
      disclaimer: (
        <>
          Copyright ©2024 AudioEye, Inc. Tutti i diritti riservati. AUDIOEYE è un marchio registrato negli Stati Uniti
          di AudioEye, Inc. I prodotti AudioEye sono protetti da brevetti. Per ulteriori informazioni{' '}
          <TextLink
            font="tinyBody"
            style={{ color: theme.color.gray400 }}
            to="mailto:ip@audioeye.com"
            text="contattare ip@audioeye.com"
            className="dark-bg-link dark-bg-focus"
            anchorClassName="dark-bg-link dark-bg-focus"
            noSmoothScroll
          />
          .
        </>
      ),
    },
  }

  return data[userLang] || data.en
}

export default generateFooterTranslations
