import idx from 'idx'

export const DEFAULT_POST_PER_PAGE = 15
export const DEFAULT_LANG = 'en-us'
export const DEFAULT_SORT = 'desc' // asc | desc
export const DEFAULT_POST_TYPE = 'Blog'

// Parameters
// pageSize: number
// order: string
// lang: string
// page: number
// post_type: string
// full_text: string

export const getPrismicPosts = async params => {
  const apiRef = await fetch('https://audioeye-web.prismic.io/api')
  const refJson = await apiRef.json()
  const ref = idx(refJson, _ => _.refs[0].ref)

  if (ref && params) {
    const queryParams = {
      ref,
      pageSize: params.pageSize || DEFAULT_POST_PER_PAGE,
      orderings: `[my.post.publish_date desc]`,
      lang: params.lang || DEFAULT_LANG,
      page: params.page || 1,
      q: `[[at(document.type, "post")][at(my.post.post_type, "${
        params.post_type || DEFAULT_POST_TYPE
      }")][not(my.post.hide_from_feeds, true)][missing(my.post.password)][has(my.post.publish_date)]${
        params.full_text ? `[fulltext(document, "${params.full_text}")]` : ''
      }]`,
      fetchLinks: `author.first_name,author.last_name`,
    }

    const url = new URL('https://audioeye-web.cdn.prismic.io/api/v2/documents/search')
    url.search = new URLSearchParams(Object.entries(queryParams)).toString()

    return fetch(url).then(res => res.json())
  }
}

export const getPrismicNewsItems = async params => {
  const apiRef = await fetch('https://audioeye-web.prismic.io/api')
  const refJson = await apiRef.json()
  const ref = idx(refJson, _ => _.refs[0].ref)

  if (ref && params) {
    const queryParams = {
      ref,
      pageSize: params.pageSize || DEFAULT_POST_PER_PAGE,
      orderings: `[my.news_items.date ${params.order || DEFAULT_SORT}]`,
      lang: params.lang || DEFAULT_LANG,
      page: params.page || 1,
      q: `[[at(document.type, "news_items")]${params.full_text ? `[fulltext(document, "${params.full_text}")]` : ''}]`,
    }

    const url = new URL('https://audioeye-web.cdn.prismic.io/api/v2/documents/search')
    url.search = new URLSearchParams(Object.entries(queryParams)).toString()

    return fetch(url).then(res => res.json())
  }
}
