import React, { useState } from 'react'
import styled from '@emotion/styled'
import Text from './Text'
import Space from './Space'
import chevron from '../../../static/icons/arrow-icons/dropdown-chevron-icon-white.svg'
import chevronPurple from '../../../static/icons/arrow-icons/dropdown-chevron-icon-purple.svg'

const Accordion = ({ children, title, titleSmallBodyBold, index, lightMode, desktop }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const toggleAccordion = () => {
    setIsAccordionOpen(prevState => !prevState)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleAccordion()
    }
  }

  return (
    <>
      <StyledText
        role="heading"
        aria-level="2"
        bodyBold={titleSmallBodyBold === undefined}
        smallBodyBold={titleSmallBodyBold}
        color={!lightMode ? 'white' : 'offBlack'}
        onKeyPress={handleKeyPress}
      >
        <Button
          tabIndex="0"
          onClick={toggleAccordion}
          onKeyPress={handleKeyPress}
          type="button"
          aria-expanded={isAccordionOpen}
          aria-controls={`accordion-content-${index}`}
          id={`accordiong-id-${index}`}
        >
          {title}{' '}
          <Arrow
            alt=""
            role="presentation"
            src={lightMode ? chevronPurple : chevron}
            isOpen={isAccordionOpen}
            desktop={desktop}
          />
        </Button>
      </StyledText>
      <Space height={15} />
      <Content
        role="region"
        aria-labelledby={`accordiong-id-${index}`}
        isOpen={isAccordionOpen}
        aria-hidden={!isAccordionOpen}
        id={`accordion-content-${index}`}
        desktop={desktop}
      >
        {children}
      </Content>
    </>
  )
}

export default Accordion

const StyledText = styled(Text)`
  cursor: pointer;
`

const Button = styled.button`
  background-color: transparent;
  padding: 0;
`

const Content = styled.div`
  @media (max-width: ${props => (props.desktop ? '9999px' : '999px')}) {
    cursor: pointer;
    transform-origin: top;
    transition: transform 0.4s ease;
    will-change: transform;
    opacity: ${props => (props.isOpen ? `1` : `0`)};
    visibility: ${props => (props.isOpen ? `visible` : `hidden`)};
    transform: ${props => (props.isOpen ? `scaleY(1)` : `scaleY(0)`)};
    position: ${props => (props.isOpen ? `static` : `absolute`)};
  }
`

const Arrow = styled.img`
  transform: ${p => (p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.4s ease;
  height: 18px;
  margin-left: 12px;

  @media (min-width: 1000px) {
    ${props => !props.desktop && 'display: none;'}
  }
`
