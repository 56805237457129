const generateAriaLabelTranslations = ({ userLang }) => {
  const data = {
    en: {
      close: 'close',
      back: 'back',
      phoneNumberCountryCode: 'Phone Number Country Code',
      signUpForm: 'sign up form',
      showPassword: 'Show password',
      mainMenu: 'main menu',
      openTheMenu: 'Open the menu',
      closeMenu: 'Close menu',
      playVideo: 'Play AudioEye video opens in a modal',
      videoModal: 'Video Modal',
    },
    de: {
      close: 'schließen',
      back: 'zurück',
      phoneNumberCountryCode: 'Landesvorwahl der Telefonnummer',
      signUpForm: 'Anmeldeformular',
      showPassword: 'Passwort anzeigen',
      mainMenu: 'Hauptmenü',
      openTheMenu: 'Öffnen Sie das Menü',
      closeMenu: 'Menü schließen',
      playVideo: 'AudioEye-Video abspielen wird modal geöffnet',
      videoModal: 'Videomodal',
    },
    fr: {
      close: 'fermer',
      back: 'dos',
      phoneNumberCountryCode: 'Numéro de téléphone Indicatif du pays',
      signUpForm: "formulaire d'inscription",
      showPassword: 'Montrer le mot de passe',
      mainMenu: 'menu principal',
      openTheMenu: 'Ouvrir le menu',
      closeMenu: 'Fermer le menu',
      playVideo: "La lecture de la vidéo AudioEye s'ouvre dans un mode modal",
      videoModal: 'Vidéo modale',
    },
    es: {
      close: 'cerrar',
      back: 'atrás',
      phoneNumberCountryCode: 'Número de teléfono Código de país',
      signUpForm: 'formulario de registro',
      showPassword: 'Mostrar contraseña',
      mainMenu: 'menú principal',
      openTheMenu: 'abre el menú',
      closeMenu: 'Cerrar menú',
      playVideo: 'Reproducir video AudioEye se abre en un modal',
      videoModal: 'Vídeo modal',
    },
    it: {
      close: 'chiuso',
      back: 'Indietro',
      phoneNumberCountryCode: 'Numero di telefono Codice Paese',
      signUpForm: 'modulo di iscrizione',
      showPassword: 'Mostra password',
      mainMenu: 'menu principale',
      openTheMenu: 'Apri il menù',
      closeMenu: 'Chiudi menù',
      playVideo: 'La riproduzione del video AudioEye si apre in modalità modale',
      videoModal: 'Videomodale',
    },
  }

  return data[userLang] || data.en
}

export default generateAriaLabelTranslations
