/* eslint-disable camelcase */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import kebabCase from 'lodash/kebabCase'

import { theme } from '../../styles'
import grayArrowIcon from '../../../static/icons/arrow-icons/gray-left-arrow-icon.svg'
import Text from '../common/Text'
import Space from '../common/Space'
import { InputContainer, CheckboxControl, CheckboxLabel, CheckboxInput, FocusedBorder } from '../form/common'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px 0px;
  max-width: 100vw;

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    max-width: unset;
    margin-left: 0px;
    overflow-x: scroll;
    align-items: flex-start;
    padding: 80px 24px 0px 0px;
    margin-bottom: 80px;
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 800px) {
    overflow-x: scroll;
    width: max-content;
    min-width: 200px;
  }
`

const ColumnHeader = styled.div`
  display: flex;
  justify-content: center;
  flex: 3;
  border: 2px solid ${p => p.theme.color[p.borderColor]};
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  height: 80px;
  align-items: center;

  p {
    text-align: left;
  }
`

const RowGroup = styled.div``

const Row = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  border: 1px solid ${theme.color.gray200};
  border-bottom: none;
  background-color: ${p => p.theme.color[p.backgroundColor]};

  &:nth-of-type(1) {
    border-radius: 16px 16px 0px 0px;
  }

  div:nth-of-type(1) {
    padding-left: 16px;
    flex: 3;
  }

  div:nth-of-type(3) {
    padding-left: 24px;
    padding-right: 24px;
    flex: 1;
  }

  &:last-of-type {
    border-bottom: 1px solid ${theme.color.gray200};
    border-radius: 0px 0px 16px 16px;
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;

    input {
      width: 100%;
    }
  }
`

const HeadingRow = styled(Row)`
  margin-bottom: 24px;
  border-color: transparent;
  padding-left: 0px;

  div:nth-of-type(2) {
    flex: 3;
  }

  div:nth-of-type(3) {
    flex: 6;
  }

  div:nth-of-type(4) {
    flex: 1;
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const TableCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 6;
  padding: 16px 0px;
  width: 100%;
`

const MobHeadingBlock = styled.div`
  display: none;
`

const ArrowIcon = styled.img`
  margin-left: 16px;
`

const MobileSwipeTextContainer = styled.div`
  display: none;
`

const CheckboxBlock = styled(Block)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  label {
    justify-content: center;
  }
`

const Section = ({ items, checkboxes, handleCheckboxChange }) => (
  <>
    {items.map(
      (
        item,
        i // eslint-disable-next-line react/no-array-index-key
      ) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row role="row" key={`$TITLE-${i}`} backgroundColor={i % 2 ? 'white' : 'gray50'}>
          <Block role="rowheader" aria-labelledby={`table-header-${kebabCase(item.column1)}`}>
            <Text smallBodyMedium>{item.column1}</Text>
          </Block>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(item.column1)}`}>
            <Text smallBodyMedium>{item.column2}</Text>
          </Block>
          <CheckboxBlock role="cell" aria-labelledby={`table-header-${kebabCase(item.column1)}-checkbox`}>
            <InputContainer>
              <CheckboxInput
                name={kebabCase(item.column2)}
                type="checkbox"
                value="Yes"
                id={`${kebabCase(item.column2)}-yes`}
                onChange={() => handleCheckboxChange(i)}
              />
              <CheckboxLabel htmlFor={`${kebabCase(item.column2)}-yes`}>
                <CheckboxControl
                  style={{ maxWidth: '22px', minWidth: '22px', marginLeft: '16px' }}
                  selected={checkboxes[i]}
                >
                  <FocusedBorder className="focused-border reduced-radius" />
                </CheckboxControl>
              </CheckboxLabel>
            </InputContainer>
          </CheckboxBlock>
        </Row>
      )
    )}
  </>
)

const WCAGChecklist = ({ checklistItems, title }) => {
  const [checkboxes, setCheckboxes] = useState(new Array(17).fill(false))

  const handleCheckboxChange = index => {
    const newCheckboxes = [...checkboxes]
    newCheckboxes[index] = !newCheckboxes[index]
    setCheckboxes(newCheckboxes)
  }

  return (
    <Wrapper>
      <Text heading2>{title}</Text>
      <Space height={80} />
      <TableWrapper role="table" aria-label={title}>
        <MobHeadingBlock>
          <Text heading3>Comparison</Text>
        </MobHeadingBlock>
        <div role="rowgroup" style={{ zIndex: '1' }}>
          <HeadingRow role="row">
            <MobileSwipeTextContainer>
              <ArrowIcon src={grayArrowIcon} style={{ marginRight: '16px' }} />
              <Text smallBody color="gray700">
                Swipe to see and compare plans
              </Text>
            </MobileSwipeTextContainer>
            <ColumnHeader borderColor="gray300">
              <Text bodyBold center role="columnheader">
                Success Criteria
              </Text>
            </ColumnHeader>
            <ColumnHeader borderColor="gray300">
              <Text bodyBold center role="columnheader">
                Description
              </Text>
            </ColumnHeader>
            <ColumnHeader borderColor="gray300">
              <Text bodyBold center role="columnheader" aria-label="Mark as Complete">
                Complete
              </Text>
            </ColumnHeader>
          </HeadingRow>
        </div>
        <TableCard>
          {checklistItems.map(currentSection => (
            <RowGroup role="rowgroup" key={kebabCase(currentSection.column1)}>
              <Section
                title={currentSection.column1}
                items={currentSection.items}
                checkboxes={checkboxes}
                handleCheckboxChange={handleCheckboxChange}
              />
            </RowGroup>
          ))}
        </TableCard>
      </TableWrapper>
    </Wrapper>
  )
}

export default WCAGChecklist
