import React from 'react'

export const Facebook = ({ alt }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt={alt}>
    <path
      className="icon"
      d="M15 0C6.71625 0 0 6.71625 0 15C0 23.2837 6.71625 30 15 30C23.2837 30 30 23.2837 30 15C30 6.71625 23.2837 0 15 0ZM18.75 10H17.0625C16.39 10 16.25 10.2762 16.25 10.9725V12.5H18.75L18.4888 15H16.25V23.75H12.5V15H10V12.5H12.5V9.615C12.5 7.40375 13.6638 6.25 16.2863 6.25H18.75V10Z"
      fill="none"
    />
  </svg>
)
