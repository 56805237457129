import styled from '@emotion/styled'
import { theme } from '../../styles'

export default styled.div`
  width: ${p => (p.widthPercent ? `${p.widthPercent}%` : '100%')};
  background: ${p => theme.color[p.color] || theme.color.gray100};
  height: 1px;
  max-width: ${p => (p.maxWidth ? '800px' : 'unset')};
  margin: ${p => (p.margin ? `${p.margin}px` : '0px')};
`
