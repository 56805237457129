import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import Text from '../common/Text'
import { theme } from '../../styles'

const Content = styled.div`
  padding: 32px;
  margin-bottom: 64px;
  background-color: ${p => p.theme.color.gray900};
  border-radius: 8px;

  p {
    font-family: ${theme.fonts.family.smallCaps};
    font-size: ${theme.fonts.size.smallBody};
    margin-bottom: 0 !important;
  }

  pre {
    white-space: normal;
  }
`

const CodeBlock = ({ slice: { primary } }) => {
  const codeBlock = primary.code_block.richText?.[0]?.text && <PrismicRichText field={primary.code_block.richText} />
  const codeColor = primary?.color

  return (
    <Content>
      {codeBlock && (
        <Text color={codeColor || 'white'} richText>
          {codeBlock}
        </Text>
      )}
    </Content>
  )
}

export default CodeBlock

export const query = graphql`
  fragment PostCodeBlock on PrismicPostDataBodyCodeBlock {
    ...SliceBase
    primary {
      code_block {
        ...TextContent
      }
      color
    }
  }
`
