const generateHeaderTranslations = ({ userLang }) => {
  const data = {
    en: {
      logoLink: '/',
      loginLinkText: 'Login',
      startFreeTrialButtonText: 'Start Free Trial',
      scheduleDemoButtonText: 'Schedule Demo',
      aboutUsLinkText: 'About Us',
    },
    de: {
      logoLink: '/de/home-de/',
      loginLinkText: 'Anmeldung',
      startFreeTrialButtonText: 'Kostenlos Testen',
      scheduleDemoButtonText: 'Demo Vereinbaren',
      aboutUsLinkText: 'Über uns',
    },
    fr: {
      logoLink: '/fr/home-fr/',
      loginLinkText: 'Login',
      startFreeTrialButtonText: "Commencer l'essai gratuit",
      scheduleDemoButtonText: 'Planifier une démo',
      aboutUsLinkText: 'À propos de nous',
    },
    es: {
      logoLink: '/',
      loginLinkText: 'el login',
      startFreeTrialButtonText: 'Comienza tu prueba gratis',
      scheduleDemoButtonText: 'Agendar un demo',
      aboutUsLinkText: 'Acerca de',
    },
    it: {
      logoLink: '/',
      loginLinkText: 'lo accesso',
      startFreeTrialButtonText: 'Inizia la prova gratuita',
      scheduleDemoButtonText: 'Programma una demo',
      aboutUsLinkText: 'Chi siamo',
    },
  }

  return data[userLang] || data.en
}

export default generateHeaderTranslations
