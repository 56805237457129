/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import SEO from '../components/SEO'
import { renderPostSlices } from '../utils/renderPostSlices'
import Layout from '../components/Layout'
import SharePost from '../components/blog/SharePost'
import TitleSection from '../components/blog/TitleSection'
import Summary from '../components/blog/Summary'
import TagSection from '../components/blog/TagSection'
import RelatedPosts from '../components/blog/RelatedPosts'
import Space from '../components/common/Space'
import Scanner from '../components/form/Scanner'
import Text from '../components/common/Text'
import Button from '../components/common/Button'

const Wrapper = styled.div`
  .narrow-width {
    width: 750px;

    @media (max-width: 1050px) {
      width: 100%;
    }
  }
`

const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(.author-section) {
    img {
      width: 100%;
      height: auto;
    }
  }
  ul,
  ol {
    list-style-position: outside;
    margin: 1.75rem 0px 32px 1.75rem;

    @media (max-width: 800px) {
      margin-left: 24px;
    }
  }
  p,
  li {
    code {
      padding: 0.2rem 0.5rem;
      margin: 0.5rem 0;
    }
    p {
      display: inline;
    }
  }
`

const StyledScanner = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 80px 0px;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 80px;

  p {
    margin-bottom: 0px !important;
  }
`

const BlogCTACard = styled.div`
  padding: 40px;
  border-radius: 16px;
  max-width: 750px;
  margin-bottom: ${props => (props.noMargin ? '0' : '56px')};
  background: linear-gradient(to bottom right, #fbfcfe, #d6f5f5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 45px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: 801px) {
    margin-left: auto;
    margin-right: auto;
    ${props => props.hideDesktop && 'display: none;'}
  }
`

const PostContent = ({ postData, preloadImg, author, location, postType, uid, socialBannerVideo }) => (
  <Layout
    showHeader
    customSEO
    preloadImg={preloadImg}
    showCTA={postData.show_cta}
    showSecondaryCTA={postData.show_secondary_cta}
    tableOfContentsData={{
      hasTableOfContents: postData.has_table_of_contents,
      ctaData: {
        text: postData.table_of_contents_cta_text,
        link: postData.table_of_contents_cta_link,
      },
      items: postData.table_of_contents_links,
      hideTableOfContentsCta: postData.hide_table_of_contents_cta,
      showScannerForm: postData.show_scanner_form,
      scannerFormLabelText: postData.scanner_form_label_text,
      tableOfContentsHeadingText: postData.table_of_contents_heading_text,
    }}
  >
    <SEO
      bannerUrl={postData?.banner?.url}
      title={postData?.title_override?.text ? postData?.title_override.text : postData?.title?.text}
      pathname={location.pathname}
      desc={postData?.description}
      node={postData}
      canonical={postData?.canonical_url}
    />
    <Wrapper>
      <TitleSection postData={postData} author={author} />
      {postType !== 'Press' && (
        <Summary
          blogCTA={
            <BlogCTACard hideDesktop>
              <div>
                <Text heading4>Ready to see AudioEye in action?</Text>
              </div>
              <Button text="Watch Demo" to="/watch-demo/" />
            </BlogCTACard>
          }
          image={postData?.banner}
          text={postData?.summary}
          video={socialBannerVideo}
        />
      )}
      <PostWrapper>{renderPostSlices(postData.body)}</PostWrapper>
      <BlogCTACard noMargin>
        <div>
          <Text heading4>Ready to see AudioEye in action?</Text>
        </div>
        <Button text="Watch Demo" to="/watch-demo/" />
      </BlogCTACard>
      <StyledScanner>
        <Text heading2 center>
          Ready to test your website for accessibility?
        </Text>
        <Space height={32} />
        <Text center richText>
          Scan your website now.
        </Text>
        <Space height={32} />
        <Scanner />
      </StyledScanner>
      <SharePost horizontal />
      <TagSection tags={postData?.tags?.[0]?.tag?.document && postData.tags} />
      <RelatedPosts uid={uid} postCategory={postData?.categories?.[0]?.category.document?.data?.name} />
    </Wrapper>
    <Space height={64} />
  </Layout>
)

export default PostContent
