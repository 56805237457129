/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import formatDate from '../../utils/formatDate'

import Text from '../common/Text'
import getImage from '../../utils/getImage'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 16px;
  }
`

const LinkWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  p {
    text-decoration: none;
  }
`

const AnchorWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 48px;
  gap: 8px;

  @media (max-width: 1050px) {
    padding-right: 16px;
  }
`

const Img = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-right: 24px;

  @media (max-width: 800px) {
    width: 60px;
    height: 60px;
  }
`

const AuthorNameWrapper = styled.div`
  display: ${p => (p.inPost ? 'flex' : 'block')};
  p {
    margin-bottom: 0px !important;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const AuthorContent = ({ profile_image, first_name, last_name, title, publishDate, inPost }) => {
  const authorTitle = title?.text.length > 0 && <PrismicRichText field={title.richText} />
  const name = `${first_name?.text} ${last_name?.text}`
  return (
    <>
      {getImage(profile_image, Img)}
      <Col>
        <AuthorNameWrapper inPost={inPost}>
          {name && inPost ? <Text heading4>{name}</Text> : <Text heading1>{name}</Text>}
          {authorTitle && (
            <div style={{ display: 'flex', gap: '4px' }}>
              {inPost && (
                <Text body color="gray500" className="hide-mobile">
                  ,{' '}
                </Text>
              )}
              <Text body color="gray500" richText>
                {authorTitle}
              </Text>
            </div>
          )}
        </AuthorNameWrapper>
        {publishDate && (
          <Text color="gray900" smallBody className="hide-mobile">
            Posted {publishDate && formatDate(publishDate)}
          </Text>
        )}
      </Col>
    </>
  )
}

const AuthorSection = ({ publishDate, inPost, authorData }) => (
  <Wrapper>
    {authorData?.uid ? (
      <>
        {publishDate && (
          <Text color="gray900" smallBody className="hide-desktop">
            Posted {publishDate && formatDate(publishDate)}
          </Text>
        )}
        <LinkWrapper to={`/author/${authorData.uid}`}>
          <AuthorContent {...authorData} inPost={inPost} publishDate={publishDate} />
        </LinkWrapper>
      </>
    ) : (
      <>
        {publishDate && (
          <Text color="gray900" smallBody className="hide-desktop">
            Posted {publishDate && formatDate(publishDate)}
          </Text>
        )}
        <AnchorWrapper href={authorData?.url} target={authorData?.target}>
          <AuthorContent {...authorData} inPost={inPost} publishDate={publishDate} />
        </AnchorWrapper>
      </>
    )}
  </Wrapper>
)

export default AuthorSection
