import React from 'react'

export const CopyIcon = ({ alt }) => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" alt={alt}>
    <circle cx="15" cy="15.25" r="15" fill="none" className="icon" />
    <path
      d="M17.1354 13.7082C17.1147 13.686 17.0934 13.6644 17.0727 13.6427L17.0696 13.6393C16.9948 13.5627 16.9056 13.5016 16.8071 13.4596C16.7086 13.4176 16.6028 13.3955 16.4958 13.3945H16.4895C16.3289 13.3947 16.172 13.4428 16.0388 13.5325C15.9056 13.6223 15.8021 13.7497 15.7416 13.8984C15.6811 14.0472 15.6662 14.2106 15.699 14.3679C15.7317 14.5251 15.8105 14.669 15.9254 14.7813C16.7866 15.6438 16.7863 16.9939 15.9254 17.856L13.4436 20.3381C12.5811 21.2003 11.2301 21.2003 10.3676 20.3381C9.50517 19.476 9.50517 18.1246 10.3676 17.2618L12.5968 15.0298C12.6731 14.955 12.7338 14.8658 12.7754 14.7674C12.817 14.669 12.8387 14.5634 12.8393 14.4565C12.8398 14.3497 12.8192 14.2438 12.7785 14.145C12.7379 14.0462 12.6781 13.9564 12.6025 13.8808C12.527 13.8053 12.4372 13.7454 12.3384 13.7048C12.2396 13.6641 12.1338 13.6435 12.0269 13.644C11.9201 13.6445 11.8144 13.6661 11.716 13.7077C11.6176 13.7493 11.5284 13.81 11.4535 13.8862L9.22123 16.1189C8.52267 16.8326 8.13386 17.793 8.13921 18.7917C8.14457 19.7904 8.54366 20.7466 9.24984 21.4528C9.95601 22.159 10.9122 22.558 11.9109 22.5634C12.9096 22.5688 13.87 22.1799 14.5837 21.4814L17.0658 18.9993C17.7614 18.3021 18.1588 17.3619 18.1742 16.3772C18.1896 15.3925 17.8217 14.4403 17.1483 13.7217L17.1354 13.7082Z"
      fill="white"
    />
    <path
      d="M22.0294 8.67267C21.3177 7.96269 20.3535 7.56396 19.3482 7.56396C18.3429 7.56396 17.3786 7.96269 16.6669 8.67267L14.1836 11.1551C13.4738 11.8667 13.0752 12.8308 13.0752 13.8359C13.0752 14.8409 13.4738 15.805 14.1836 16.5166C14.3346 16.6702 14.5404 16.7576 14.7558 16.7595H14.7615C14.9221 16.7596 15.0792 16.7117 15.2126 16.6221C15.3459 16.5325 15.4496 16.4052 15.5103 16.2564C15.571 16.1076 15.586 15.9442 15.5534 15.7868C15.5207 15.6295 15.442 15.4854 15.3271 15.3731C14.4656 14.5106 14.4662 13.1602 15.3271 12.298L17.8092 9.81625C18.6717 8.95379 20.0227 8.95379 20.8852 9.81625C21.0893 10.017 21.2513 10.2564 21.3619 10.5205C21.4726 10.7845 21.5295 11.068 21.5295 11.3542C21.5295 11.6405 21.4726 11.9239 21.3619 12.188C21.2513 12.452 21.0893 12.6914 20.8852 12.8922L18.6651 15.1151L18.6563 15.1236C18.5413 15.2358 18.4623 15.3798 18.4294 15.5372C18.3965 15.6945 18.4113 15.8581 18.4719 16.007C18.5324 16.1559 18.636 16.2834 18.7693 16.3731C18.9026 16.4629 19.0597 16.5109 19.2205 16.511H19.2255C19.441 16.5091 19.6469 16.4218 19.798 16.2681L22.0304 14.0355C22.7404 13.3237 23.1391 12.3593 23.1389 11.3539C23.1387 10.3485 22.7397 9.38423 22.0294 8.67267V8.67267Z"
      fill="white"
    />
  </svg>
)
