/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import { Link, navigate } from 'gatsby'
import formatDate from '../../../utils/formatDate'
import Text from '../../common/Text'
import { theme } from '../../../styles'
import { PressGraphic } from './graphics/PressGraphic'
import { AnnouncementsIcon } from './graphics/AnnouncementsIcon'
import { EarningsCallIcon } from './graphics/EarningsCallIcon'
import { IndustryNewsIcon } from './graphics/IndustryNewsIcon'
import { PartnershipsIcon } from './graphics/PartnershipsIcon'
import { QuarterlyResultsIcon } from './graphics/QuarterlyResultsIcon'

const Card = styled.li`
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  border-width: 0px;
  border-radius: 8px 8px 6px 6px;
  background: transparent;
  width: 100%;
  height: 315px;
  display: flex;
  flex-direction: column;
  transition: all 125ms ease-in-out;
  margin-bottom: 0px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
    transform: scale(1.025);
  }

  @media (max-width: 800px) {
    height: 294px;
    &:hover {
      box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
      transform: none;
    }
  }
`

const ImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: grid;
  border-radius: 6px 6px 0px 0px;
  grid-template-columns: 1fr;
  justify-items: center;
  overflow: hidden;
  height: 100%;
  position: relative;
`

const CardContent = styled.div`
  padding: 24px;

  a {
    display: block;
  }
`

const DividerLine = styled.div`
  width: 32px;
  margin: 8px auto;
  height: 2px;
  background-color: ${theme.color.purple200};
`

const PressGridItem = ({ publishDate, title, uid, category, index }) => {
  const identifyIcon = pressCategory => {
    switch (pressCategory) {
      case 'announcements':
        return <AnnouncementsIcon index={index} />
      case 'quarterly results':
        return <QuarterlyResultsIcon index={index} />
      case 'earnings call':
        return <EarningsCallIcon index={index} />
      case 'industry news':
        return <IndustryNewsIcon index={index} />
      case 'partnership':
        return <PartnershipsIcon index={index} />
      default:
        return <IndustryNewsIcon index={index} />
    }
  }
  const Icon = () => identifyIcon(category)

  return (
    <Card
      className="link-list-item"
      onClick={() => navigate(`/post/${uid}`)}
      onKeyDown={e => {
        if (e.key === 'Enter') navigate(`/post/${uid}`)
      }}
    >
      <ImageWrapper>
        <Icon />
        <PressGraphic index={index} />
      </ImageWrapper>
      <CardContent>
        <Link to={`/post/${uid}`} onClick={e => e.stopPropagation()}>
          <Text bodyBold numberOfLines={2} center>
            {title}
          </Text>
        </Link>
        <DividerLine className="divider-line" />
        <Text tinyBody color="gray700" center>
          {publishDate && formatDate(publishDate)}
        </Text>
      </CardContent>
    </Card>
  )
}

export default PressGridItem
