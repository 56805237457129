import { trackEvent } from '../../../utils/eventTrack'
import prependUrl from '../../../utils/prependUrl'

export default async function (url) {
  try {
    const res = await fetch(`${process.env.GATSBY_AEYE_PORTAL_URL}/site/validity?url=${prependUrl(url)}`)
    return res
  } catch (err) {
    trackEvent(`API Exception`, {
      Label: 'Check site validity general error',
      'Full Exception': err,
    })
    return false
  }
}
