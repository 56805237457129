/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import styled from '@emotion/styled'

import Text from '../common/Text'
import TextLink from '../common/TextLink'
import { theme } from '../../styles'

const BG = styled.div`
  position: absolute;
  background-color: ${p => theme.color[p.backgroundColor] || theme.color.white};
  width: 500vw;
  margin-left: -20%;
  height: 100%;
  z-index: -1;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 64px 80px;
  padding-top: ${p => (p.hasTopPadding ? '64px' : '16px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '64px' : '16px')};

  @media (max-width: 1050px) {
    margin-left: 0px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 32px 16px;
  }
`

const LinkContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-left: 2em;

  .divider-line:last-of-type {
    display: none;
  }

  @media (max-width: 1050px) {
    justify-content: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto;
    align-self: stretch;
    margin: 0;
    margin-left: -3px;
    margin-top: 1em;

    .divider-line {
      display: none;
    }
  }

  @media (max-width: 800px) {
    grid-row-gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
`

const LinkWrapper = styled.li`
  list-style: none;
  white-space: nowrap;

  a {
    text-decoration-color: ${props => (props.darkBG ? theme.color.white : theme.color.purple500)}!important;

    &:hover {
      ${props => props.darkBG && 'background-image: none !important;'}
    }
  }

  span {
    &:hover {
      text-decoration: none;
      color: ${theme.color.offBlack}!important;
      background-size: 2px 100%;
      z-index: 1;

      &.dark-bg-link {
        color: ${theme.color.purple500}!important;
      }
    }
  }
`

const DividerLine = styled.div`
  width: 1px;
  margin: 0px 8px;
  height: 100%;
  min-height: 30px;
  background-color: ${p => (p.darkBG ? theme.color.gray400 : theme.color.darkShadow)};
`

const QuicklinksBar = ({ slice: { primary, items } }) => {
  const smallCapsHeader = primary.small_caps_header.richText?.[0]?.text && (
    <PrismicRichText field={primary.small_caps_header.richText} />
  )
  const hasTopPadding = primary?.has_top_padding
  const hasBottomPadding = primary?.has_bottom_padding
  const backgroundColor = primary.background_color
  const anchorId = primary.anchor_id
  const darkBG = primary.background_color === 'purple900'

  return (
    <Wrapper id={anchorId || null} hasTopPadding={hasTopPadding} hasBottomPadding={hasBottomPadding}>
      <Text font="smallCaps" richText style={{ whiteSpace: 'nowrap' }} color={darkBG ? 'white' : 'offBlack'}>
        {smallCapsHeader}
      </Text>
      <LinkContainer role="list">
        {items.map(
          (item, i) =>
            item.quicklink_bar_link_text && (
              <React.Fragment key={`${item.quicklink_bar_link_text.text}-${i}`}>
                <LinkWrapper darkBG={darkBG}>
                  <TextLink
                    text={item.quicklink_bar_link_text.text}
                    to={item.quicklink_bar_link_url?.url}
                    font="smallBodyBold"
                    anchorClassName={darkBG ? 'dark-bg-focus' : ''}
                    className={darkBG ? 'dark-bg-link' : ''}
                    key={item.quicklink_bar_link_text.text}
                    newTab={item.quicklink_bar_link_url.target === '_blank'}
                    color={darkBG ? 'white' : 'purple500'}
                  />
                </LinkWrapper>
                <DividerLine className="divider-line" darkBG={darkBG} />
              </React.Fragment>
            )
        )}
      </LinkContainer>
      <BG className="bg" backgroundColor={backgroundColor} />
    </Wrapper>
  )
}

export default QuicklinksBar

export const query = graphql`
  fragment QuicklinksBar on PrismicPageDataBodyQuicklinksBar {
    ...SliceBase
    primary {
      anchor_id
      background_color
      has_top_padding
      has_bottom_padding
      small_caps_header {
        ...TextContent
      }
    }
    items {
      quicklink_bar_link_text {
        ...TextContent
      }
      quicklink_bar_link_url {
        ...LinkContent
      }
    }
  }
`
