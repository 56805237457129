import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  position: relative;
  justify-items: center;
  padding: 80px 80px 100px 80px;
  width: calc(100% + 300px);
  margin-left: -150px;

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
  }

  @media (max-width: 800px) {
    padding: 48px 0px;
  }
`

const FigmaEmbed = ({ slice: { primary } }) => {
  const figmaEmbedUrl = primary?.figma_embed_url

  return (
    <>
      <Wrapper>
        <iframe
          style={{ border: 'none' }}
          width="100%"
          height="1000px"
          title="Figma Prototype"
          src={figmaEmbedUrl}
          allowFullScreen
        />
      </Wrapper>
    </>
  )
}

export default FigmaEmbed

export const query = graphql`
  fragment FigmaEmbed on PrismicPageDataBodyFigmaEmbed {
    ...SliceBase
    primary {
      figma_embed_url
    }
  }
`
