export default function (url, options) {
  if (typeof url !== 'string') {
    throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof url}\``)
  }
  const trimmedUrl = url.trim()
  const populatedOptions = {
    https: true,
    ...options,
  }
  if (/^\.*\/|^(?!localhost)\w+:/.test(trimmedUrl)) {
    return trimmedUrl
  }
  return trimmedUrl.replace(/^(?!(?:\w+:)?\/\/)/, populatedOptions.https ? 'https://' : 'http://')
}
