import React from 'react'

export const Medium = ({ alt }) => (
  <svg width="31" height="31" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" alt={alt}>
    <path
      className="icon"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2002 30C23.4845 30 30.2002 23.2843 30.2002 15C30.2002 6.71573 23.4845 0 15.2002 0C6.91592 0 0.200195 6.71573 0.200195 15C0.200195 23.2843 6.91592 30 15.2002 30ZM16.912 15.146C16.912 11.7507 14.1785 8.99805 10.8062 8.99805C7.43393 8.99805 4.7002 11.7507 4.7002 15.146C4.7002 18.5412 7.43414 21.2936 10.8062 21.2936C14.1782 21.2936 16.912 18.5412 16.912 15.146ZM23.61 15.146C23.61 11.9492 22.2432 9.35818 20.557 9.35818C18.8709 9.35818 17.5042 11.95 17.5042 15.146C17.5042 18.3419 18.8711 20.9337 20.5573 20.9337C22.2434 20.9337 23.6103 18.3419 23.6103 15.146H23.61ZM26.35 15.146C26.35 12.2824 25.8691 9.96102 25.2763 9.96102C24.6834 9.96102 24.2027 12.2826 24.2027 15.146C24.2027 18.0093 24.6832 20.3309 25.2763 20.3309C25.8694 20.3309 26.35 18.0087 26.35 15.146Z"
      fill="black"
    />
  </svg>
)
