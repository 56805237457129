/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { theme } from '../../../styles'
import getImage from '../../../utils/getImage'

import Text from '../../common/Text'
import Space from '../../common/Space'
import Button from '../../common/Button'
import greenCheckMarkIcon from '../../../../static/icons/check-icons/green-check-icon.svg'
import grayCheckMarkIcon from '../../../../static/icons/check-icons/gray-check-icon.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px 0px;

  @media (max-width: 1500px) {
    width: 100%;
    margin-left: 0px;
  }
`

const Header = styled.div`
  max-width: 1000px;
`

const CardWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 16px;

  @media (max-width: 1350px) {
    gap: 16px;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`

const GradientWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex: 2;
  padding: 16px;
  border-radius: 16px;
  background: linear-gradient(90deg, ${p => p.theme.color.purple100}, ${p => p.theme.color.purple700});

  @media (max-width: 1350px) {
    gap: 16px;
    flex-wrap: wrap;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    background: linear-gradient(180deg, ${p => p.theme.color.purple100}, ${p => p.theme.color.purple700});
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  background-color: ${theme.color.white};
  outline: 2px solid rgba(255, 255, 255, 0.3);

  .rich-text-container p {
    margin-bottom: 0px;
  }

  @media (max-width: 1500px) {
    min-width: 300px;
  }

  @media (max-width: 1350px) {
    min-width: 275px;
  }

  @media (max-width: 1050px) {
    .button {
      width: fit-content !important;
    }
  }
`

const CardListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 22px;

  li {
    margin-bottom: 12px;
    padding-inline-start: 8px;
    list-style-type: none;
    list-style-image: url(${props => (props.second ? grayCheckMarkIcon : greenCheckMarkIcon)});
  }
`

const Image = styled.img`
  display: flex;
  width: 42px;
  object-fit: contain;
`

const PlanCard = ({
  card_title,
  small_caps_text,
  text,
  feature_list_heading,
  second_feature_list_heading,
  card_feature_list,
  second_card_feature_list,
  has_cta,
  firstCard,
  card_price_text,
  icon,
}) => {
  const cardTitle = card_title.richText?.[0]?.text && <PrismicRichText field={card_title.richText} />
  const cardSmallCaps = small_caps_text.richText?.[0]?.text && <PrismicRichText field={small_caps_text.richText} />
  const cardText = text.richText?.[0]?.text && <PrismicRichText field={text.richText} />
  const cardPriceText = card_price_text.richText?.[0]?.text && <PrismicRichText field={card_price_text.richText} />
  const featureListHeading = feature_list_heading.richText?.[0]?.text && (
    <PrismicRichText field={feature_list_heading.richText} />
  )
  const secondFeatureListHeading = second_feature_list_heading?.richText?.[0]?.text && (
    <PrismicRichText field={second_feature_list_heading?.richText} />
  )
  const cardFeatureList = card_feature_list.richText?.[0]?.text && (
    <PrismicRichText field={card_feature_list.richText} />
  )
  const secondCardFeatureList = second_card_feature_list?.richText?.[0]?.text && (
    <PrismicRichText field={second_card_feature_list?.richText} />
  )
  const iconImage = icon
  return (
    <Card>
      {getImage(iconImage, Image)}
      {cardTitle && (
        <Text font="bodyBold" richText>
          {cardTitle}
        </Text>
      )}
      {cardSmallCaps && (
        <Text font="smallCapsSans" richText color="purple500">
          {cardSmallCaps}
        </Text>
      )}
      {cardText && (
        <Text font="smallBody" richText>
          {cardText}
        </Text>
      )}
      {cardPriceText && (
        <Text font="largeBody" richText>
          {cardPriceText}
        </Text>
      )}
      {featureListHeading && (
        <Text font="smallBodyBold" richText>
          {featureListHeading}
        </Text>
      )}
      {cardFeatureList && (
        <CardListWrapper>
          <Text font="smallBody" richText>
            {cardFeatureList}
          </Text>
        </CardListWrapper>
      )}
      {secondFeatureListHeading && (
        <Text font="smallBodyBold" richText>
          {secondFeatureListHeading}
        </Text>
      )}
      {secondCardFeatureList && (
        <CardListWrapper second>
          <Text font="smallBody" richText>
            {secondCardFeatureList}
          </Text>
        </CardListWrapper>
      )}
      {has_cta && firstCard && (
        <Button text="Start free trial" to="#get-started-free" buttonType="link CTA" style={{ margin: '8px 0px' }} />
      )}
      {has_cta && !firstCard && (
        <Button text="Talk to an expert" to="#schedule-demo" buttonType="link CTA" style={{ margin: '8px 0px' }} />
      )}
    </Card>
  )
}

const ManagedCards = ({ slice: { primary, items } }) => {
  const heading = primary.heading.richText?.[0]?.text && <PrismicRichText field={primary.heading.richText} />
  const subtitle = primary.subtitle.richText?.[0]?.text && <PrismicRichText field={primary.subtitle.richText} />
  const buttonText = primary.cta_button_text.text
  const anchorId = primary.anchor_id
  const firstCardData = items[0]
  const cardsData = items.slice(1)

  return (
    <Wrapper id={anchorId || null}>
      <Header>
        <Text richText font="heading2" center>
          {heading}
        </Text>
        <Space height={24} />
        <Text center richText>
          {subtitle}
        </Text>
      </Header>
      <CardWrapper>
        <GradientWrapper>
          <PlanCard {...firstCardData} firstCard />
          {cardsData && cardsData.map(item => <PlanCard {...item} key={item.card_title && item.card_title.text} />)}
        </GradientWrapper>
      </CardWrapper>
      {buttonText && <Button text={buttonText} to="#get-started-free" />}
    </Wrapper>
  )
}

export default ManagedCards

export const query = graphql`
  fragment ManagedCards on PrismicPageDataBodyManagedCards {
    ...SliceBase
    items {
      icon {
        alt
        url
        gatsbyImageData(width: 50)
      }
      card_title {
        ...TextContent
      }
      small_caps_text {
        ...TextContent
      }
      text {
        ...TextContent
      }
      feature_list_heading {
        ...TextContent
      }
      second_feature_list_heading {
        ...TextContent
      }
      card_feature_list {
        ...TextContent
      }
      second_card_feature_list {
        ...TextContent
      }
      has_cta
      card_price_text {
        ...TextContent
      }
    }
    primary {
      anchor_id
      heading {
        ...TextContent
      }
      subtitle {
        ...TextContent
      }
      cta_button_text {
        ...TextContent
      }
    }
  }
`
