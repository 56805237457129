/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import { theme } from '../../styles'

import Text from '../common/Text'
import Space from '../common/Space'
import getImage from '../../utils/getImage'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0px;

  @media (max-width: 1050px) {
    padding: 64px 32px;
  }

  @media (max-width: 800px) {
    padding: 64px 8px;
  }
`

const Header = styled.div`
  margin-bottom: 24px;
  width: 100%;
`

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  flex-wrap: wrap;
  grid-column-gap: 24px;
  margin: 32px 0px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-bottom: 0px;
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    margin-top: 24px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`

const CardWrapper = styled.div`
  padding: 40px;
  border-radius: 6px;
  background-color: ${theme.color.offWhite};
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
    @media (max-width: 800px) {
      transform: none;
    }
  }

  p {
    margin-bottom: 0 !important;
  }

  @media (max-width: 1200px) {
    padding: 32px;
  }

  @media (max-width: 800px) {
    padding: 24px;
  }
`

const CardImage = styled.img`
  height: 64px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
`

const Card = ({ item, color }) => {
  const cardHeader = item.card_heading.richText?.[0]?.text && <PrismicRichText field={item.card_heading.richText} />
  const cardText = item.card_text.richText?.[0]?.text && <PrismicRichText field={item.card_text.richText} />
  const image = item.image?.url && item.image

  return (
    <CardWrapper color={color}>
      {image && (
        <>
          {getImage(image, CardImage)}
          {(cardHeader || cardText) && <Space height={16} />}
        </>
      )}
      {cardHeader && (
        <>
          <Text center font="heading2" richText color="purple500">
            {cardHeader}
          </Text>
          {cardText && <Space height={8} />}
        </>
      )}
      {cardText && (
        <Text center font="body" richText>
          {cardText}
        </Text>
      )}
    </CardWrapper>
  )
}

const FourColCards = ({ slice: { primary, items } }) => {
  const title = primary.heading.richText?.[0]?.text && <PrismicRichText field={primary.heading.richText} />
  const anchorId = primary.anchor_id

  return (
    <Wrapper id={anchorId || null}>
      {title && (
        <Header>
          <Text center font="heading2" richText>
            {title}
          </Text>
        </Header>
      )}
      <CardsWrapper>
        {items.map((item, indx) => (
          <Card item={item} key={`four-col-item-${indx}`} />
        ))}
      </CardsWrapper>
    </Wrapper>
  )
}

export default FourColCards

export const query = graphql`
  fragment FourColCards on PrismicPageDataBodyFourColCards {
    ...SliceBase
    items {
      card_heading {
        ...TextContent
      }
      card_text {
        ...TextContent
      }
      image {
        alt
        url
        gatsbyImageData(width: 540)
      }
    }
    primary {
      anchor_id
      heading {
        ...TextContent
      }
    }
  }
`
