import React from 'react'
import { theme } from '../../../../styles'

export const QuarterlyResultsIcon = ({ index }) => {
  const colors = [
    theme.color.purple500,
    theme.color.blue500,
    theme.color.green500,
    theme.color.yellow500,
    theme.color.red500,
    theme.color.purple500,
  ]
  return (
    <svg
      width="104"
      height="104"
      style={{ gridArea: '1 / 1 / 2 / -1', zIndex: '1' }}
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52" cy="52" r="51.6604" fill={colors[index]} stroke={colors[index]} strokeWidth="0.679245" />
      <path
        d="M51.5 80C66.6878 80 79 67.6878 79 52.5C79 37.3121 66.6878 25 51.5 25C36.3122 25 24 37.3121 24 52.5C24 67.6878 36.3122 80 51.5 80Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 32.9162V72.0836"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.5256 52.4998C47.3681 52.4998 44 49.1316 44 44.9742C44 40.8168 47.3681 37.4486 51.5256 37.4486C55.683 37.4486 59.0512 40.8168 59.0512 44.9742"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.5256 52.4998C55.683 52.4998 59.0512 55.8679 59.0512 60.0253C59.0512 64.1828 55.683 67.5509 51.5256 67.5509C47.3681 67.5509 44 64.1828 44 60.0253"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
