/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'

import Text from '../common/Text'
import Space from '../common/Space'

const Wrapper = styled.div`
  width: 100%;
  background: ${p => p.theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  ${p => p.hasPaddingBottom && `padding-bottom: 80px;`}
`

const StyledListItem = styled.li`
  margin-left: 24px;

  &.level_2 {
    margin-left: 48px;
    list-style-type: ${props => props.type};
  }
`

const ListItem = ({ text, level, type }) => (
  <StyledListItem type={type} className={level}>
    {text}
  </StyledListItem>
)

const MultiLevelList = ({ slice: { primary, items } }) => {
  const level1Array = items.filter(obj => obj.list_level === 'level_1')
  const level2Array = items.filter(obj => obj.list_level === 'level_2')

  const level1List = level1Array.map(item => (
    <ListItem text={item?.list?.richText && <PrismicRichText field={item.list.richText} />} level={item?.list_level} />
  ))

  let listType2

  if (primary.list_type_2 === 'unordered') {
    listType2 = 'disc'
  } else if (primary.list_type_2 === 'ordered') {
    listType2 = 'decimal'
  } else if (primary.list_type_2 === 'abc') {
    listType2 = 'lower-alpha'
  } else {
    listType2 = 'disc'
  }

  const level2List =
    level2Array.length > 0 ? (
      <ul>
        {level2Array.map(item => (
          <ListItem
            text={item?.list?.richText && <PrismicRichText field={item.list.richText} />}
            level={item?.list_level}
            type={listType2}
          />
        ))}
      </ul>
    ) : null

  let list

  if (primary.list_type === 'unordered') {
    list = (
      <ul>
        {level1List}
        {level2List}
      </ul>
    )
  } else if (primary.list_type === 'ordered') {
    list = (
      <ol>
        {level1List}
        {level2List}
      </ol>
    )
  } else if (primary.list_type === 'abc') {
    list = (
      <ol type="a">
        {level1List}
        {level2List}
      </ol>
    )
  } else {
    list = (
      <ul>
        {level1List}
        {level2List}
      </ul>
    )
  }

  return (
    <Wrapper className="narrow-width" hasPaddingBottom={primary.has_padding_bottom}>
      <Text heading2>{primary.list_title.text}</Text>
      <Space height={8} />
      {list}
    </Wrapper>
  )
}

export default MultiLevelList

export const query = graphql`
  fragment MultiLevelList on PrismicPageDataBodyMultiLevelList {
    ...SliceBase
    primary {
      list_title {
        ...TextContent
      }
      list_type
      list_type_2
      has_padding_bottom
    }
    items {
      list {
        ...TextContent
      }
      list_level
    }
  }
`
